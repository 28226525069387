import React, {Component} from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";

import RatingField from "./RatingField";
import NpsBlock from "./NpsBlock";
import './ApplicationListDataTable.scss';
import { useHistory } from "react-router";
import icoList from '../../../../images/ico_list.png';
import icoListBlack from '../../../../images/ico_list_black.svg';
import SourceList from "./SourceList";

@inject('filterStore')
@observer
class ApplicationListDataTable extends Component {

    constructor(props) {
        super(props);
        this.filterStore = this.props.filterStore;
        this.state = {
            applications: this.props.applications,
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.applications !== prevProps.applications) {
            this.setState({
                applications: this.props.applications
            });
        }
    }

    render() {
        const {
            applications,
        } = this.state;

        return (
            <div className="p-datatable p-component">
                <div className="p-datatable-wrapper">
                    <table role="grid">
                        <thead className="p-datatable-thead">
                        <tr>
                            <Column
                                className={cn('date-col')}
                                field="time"
                                header="Дата"
                                sortable
                            />
                            <Column
                                className={cn('status-col')}
                                field="status"
                                header="Статус"
                                sortable
                            />
                            <Column
                                className={cn('rating-col')}
                                field="rating"
                                header="Оценка"
                                sortable
                            />
                            <Column
                                className={cn('nps-col')}
                                field="nps"
                                header="NPS"
                                sortable
                            />
                            <Column
                                className={cn('manager-col')}
                                field="branch"
                                header="Филиал"
                                sortable
                            />
                            <Column
                                className={cn('client-col')}
                                field="customer"
                                header="Клиент"
                                sortable
                            />
                            <Column
                                className={cn('auto-col')}
                                field="car"
                                header="Товар / Услуга"
                                sortable
                            />
                            <Column
                                className={cn('chat-col')}
                                header="&nbsp;"
                                ico={icoListBlack}
                            />
                        </tr>
                        </thead>
                        {applications.length > 0 &&
                        <tbody className={cn('p-datatable-tbody')}>
                            {applications.map((item, key) => {
                                return (
                                    <Row item={item} key={key} />
                                )
                            })}
                        </tbody>}
                    </table>
                </div>
            </div>
        );
    }
}

function Row(props) {
    const { item } = props;
    const history = useHistory();
    const handleClick = () => {
        !window.getSelection().toString().length && history.push(`/application/detail/${item.id}`);
    }
    const [data, time] = item.time.replaceAll(/\s/g, "\n").split('\n');

    return (
        <tr className="p-datatable-row"
            onClick={handleClick}>
            <td className="date-col">
                <div>{data}</div>
                <div>{time}</div>
                <div className={cn('number-col')}>
                    <span
                          onClick={(e)=>{
                              e.preventDefault()
                              e.stopPropagation();

                              let a = document.createElement("a");
                              document.body.appendChild(a);
                              a.href = `/application/detail/${item.id}`;
                              a.target = "_blank";
                              a.click();
                              document.body.removeChild(a);
                          }}
                    >
                    № {item.number}
                    <span className={cn('chain_ico')}></span>
                    </span>
                </div>
            </td>
            <td className="status-col">
                <span className="p-tag application-tag" style={
                    {
                        color: item.status.foreground,
                        backgroundColor: item.status.background
                    }
                }
                >
                    {item.status.title}
                </span>

                <SourceList
                    sourceList={item.counters.adlinks}
                    avitopub={item.review.avitopub}
                />

            </td>
            <td className="rating-col">
                <RatingField
                    review={item.review}
                />
            </td>
            <td className={cn('rating-col')}>
                <NpsBlock
                    nps={item.nps}
                />
            </td>
            <td className="manager-col">
                <div>{item.branch.title}</div>
            </td>
            <td className="client-col">
                <div>{item.customer.lastname}</div>
                <div>{item.customer.firstname}</div>
            </td>
            <td className="auto-col">
                {item.purchase.type.code === 'vehicle' && <>
                    <div>
                        <span>{item.purchase.car.brand} {item.purchase.car.model}</span><span>,&nbsp;{item.purchase.car.year}</span>
                    </div>
                    <div>{item.purchase.car.vin}</div>
                </>}
                {item.purchase.type.code === 'generic' && <>
                    <div>
                        <span>&#8212;</span>
                    </div>
                    <div>&nbsp;</div>
                </>}
            </td>
            <td className="chat-col">
                {!!item.counters?.comments && <div className={cn('comments-count')}>
                    <div className={cn('comments-count__ico')}>
                        <img src={icoList} alt="" width={20} height={17} />
                    </div>
                    <div className={cn('comments-count__text')}>
                        <span>{item.counters?.comments}</span>
                    </div>
                </div>}
            </td>
        </tr>
    )
}

@inject('filterStore')
@observer
class Column extends Component {

    constructor(props) {
        super(props);
        this.filterStore = this.props.filterStore;
        this.defaultSort = 'pi-sort-alt'
    }

    render() {
        const { className, field, header, sortable, ico } = this.props;

        return (
            <React.Fragment>
                {sortable ?
                    <th className={cn('p-sortable-column', className)} aria-sort="none" onClick={() => {

                        const newSortOrder = (this.filterStore.filter.sort.order === 'pi-sort-alt') ? 'asc' : (this.filterStore.filter.sort.order === 'asc') ? 'desc' : 'asc';

                        this.filterStore.setFieldValue('sort', {
                            column: field, order: newSortOrder
                        });

                    }}
                    >
                        <span className="p-column-title">{header}</span>
                        <span
                            className={cn("p-sortable-column-icon", "pi", "pi-fw", (field === this.filterStore.filter.sort.column) ?
                                this.filterStore.filter.sort.order :
                                this.defaultSort
                            )}
                        />

                    </th>
                    :
                    <th className={cn('p-sortable-column', className)} aria-sort="none">
                        {ico ?
                            <div className={cn('ico__wrapper')}>
                                <div className={cn('comments-count__ico')}>
                                    <img src={ico} width={52} height={22} alt="Заметки" title="Заметки"/>
                                </div>
                            </div>
                        :
                            <span className="p-column-title">{header}</span>
                        }

                    </th>
                }
            </React.Fragment>

        )
    }
}

export default ApplicationListDataTable;
