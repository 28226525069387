import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

const BranchesBlock = ({ branches, isShort }) => {

    const [isOpenSourceBox, setOpenSourceBox] = useState(false);
    const buttonRef = useRef();

    const toggleSourceBox = () => {
        setOpenSourceBox((isOpenSourceBox) => !isOpenSourceBox);
    }

    const clickOutsideHandler = (event) => {
        if (!buttonRef.current?.contains(event.target)) {
            setOpenSourceBox(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', clickOutsideHandler, false);
        return () => {
            document.removeEventListener('click', clickOutsideHandler, false);
        }
    }, []);

    return (
        <div
            className={cn('source')}
        >
            {branches.length > 1 &&
            <div
                className={'source__title'}
                onClick={toggleSourceBox}
                ref={buttonRef}
            >
                <div className={cn('ico', 'ico__bag')} />
                <span title={branches[0].title} className={cn({'short': isShort})}>Филиалы: {branches.length}</span>
                <i className={cn('pi', 'pi-angle-down')} />
            </div>}
            {branches.length === 1 &&
            <div
                className={cn('source__title', 'no-cursor')}
            >
                <div className={cn('ico', 'ico__bag')} />
                <span title={branches[0].title} className={cn({'short': isShort})}>
                    <Link to={`/analytics/branch/${branches[0].id}`} title={branches[0].title} alt={branches[0].title}>{branches[0].title}</Link>
                </span>
            </div>
            }
            {isOpenSourceBox && <div className={'source__body'}>
                <ul>
                    {branches.map((item, key)  => (
                        <li key={`${key}${item.id}`}>
                            <Link to={`/analytics/branch/${item.id}`} title={item.title} alt={item.title}>{item.title}</Link>
                        </li>
                    ))}
                </ul>
            </div>}
        </div>

    );
}

export default BranchesBlock;
