import React, { useEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { inject, observer } from "mobx-react";
import chartService from "../charts/chartsService";

/**
 *
 * @param chartStore
 * @param reviewFilterStore
 * @param filter внешний фильтр (сделано для дашборда)
 * @returns {JSX.Element}
 * @constructor
 */
const Grade = ({ chartStore, reviewFilterStore, filter=null }) => {

    am4core.useTheme(am4themes_animated);
    const chart = useRef(null);
    const chartsFilter = filter ?? reviewFilterStore.parseFilterToChart();

    let x = null;
    const destroyChart = () => {
//TODO x null !!! (во всех графиках)
        x && x.dispose();
    }

    useEffect(() => {
        chartStore.getData(chartsFilter, 'grade')
            .then((data) => {
                chartService.initGradeChart(data.data, x, 'chart-source4')
                    .then(result => {
                        chart.current = result
                    });
            });
        return () => destroyChart();
    }, [JSON.stringify(chartsFilter)]);

    return (
        <fieldset className={'mr_0'}>
            <legend>Распределение новых отзывов</legend>
            <div id='chart-source4' style={{ width: '100%', height: '100%' }} />
        </fieldset>
    )
}
export default inject('chartStore', 'reviewFilterStore')(observer(Grade));

