export default class CarModel {
    constructor(car = {}) {
        this._brand = car.brand;
        this._model = car.model;
        this._year = car.year;
        this._color = car.color;
        this._vin = car.vin;
    }

    get brand() {
        return this._brand;
    }

    get model() {
        return this._model;
    }

    get year() {
        return this._year;
    }

    get color() {
        return this._color;
    }

    get vin() {
        return this._vin;
    }

    toApiObject() {
        return {
            brand: this.brand,
            model: this.model,
            year: this.year,
            color: this.color,
            vin: this.vin,
        }
    }
}
