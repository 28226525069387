    import React, { Component, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Sentry from '@sentry/browser';
import cn from "classnames";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import OkDatePicker from '../../../common/OkDatePicker';

import applicationService from '../../../../services/application'
import autoServicesService from '../../../../services/services'
import okAnalyticsService from "../../../../services/okAnalytics";
import './ApplicationFormAdd.scss';
import '../../../common/ErrorPopup.scss';

import {
    AppRequestGenericModel,
    AppRequestVehicleModel
} from "models/purchase";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import OkInputMask from "../common/OkInputMask";

const ApplicationFormAdd = inject('uiStore')(observer(({uiStore}) => {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [services, setServices] = useState([]);
    const [typeForm, setTypeForm] = useState('');
    const [backErrors, setBackErrors] = useState([]);

    const [initForm, setInitForm] = useState(
        {
            autoServices: '',//+
            carBrand: '',
            carModel: '',
            carYear: '',
            carColor: '',
            carVin: '',
            customerLastname: '', //+
            customerFirstname: '', //+
            customerPhone: '', //+
            customerEmail: '',
            purchaseDate: '' //+
        }
    );
    const formikRef = useRef();

    useEffect(() => {
        customResetField();
    }, [typeForm]);

    useEffect(() => {
        autoServicesService.getBranches().then(data => {

            const services = data.map((service) => ({
                value: service.id,
                label: service.title,
                type: service.type.defaultPurchase.code,
            }));

            setServices(services);
            setLoading(false)
        })

        okAnalyticsService.addEntity({
            page: 'application_add',
            target: 'page',
            event: 'open',
        });

    }, []);

    useEffect(() => {
        // если сервис всего одни. сразу его выбираем и устанавливаем его тип для формы
        if(services.length === 1) {
            formikRef.current.setFieldValue(
                "autoServices",
                services[0].value
            );
            setTypeForm(services[0].type)
        }
    }, [services])


    const customResetField = () => {
//TODO Перепистать использование formik с useFormik()
            formikRef.current && [
                "carBrand",
                "carModel",
                "carYear",
                "carColor",
                "carVin",
                "customerLastname",
                "customerFirstname",
                "customerPhone",
                "customerEmail",
                "purchaseDate",
            ].map(field => {
                formikRef.current.setFieldValue(
                    field,
                    ""
                );
            })
    }

    const reloadForm = (e) => {
        e.preventDefault();
        setSuccess(false);
        setError(false);
    }

    const errorClass = (isError) => {
        return (isError === true) ? 'p-error' : '';
    }

    const validateForm = (values) => {

        const errors = {};

        values.carBrand = values.carBrand.trim();
        values.carModel = values.carModel.trim();
        values.carColor = values.carColor.trim();
        values.customerLastname = values.customerLastname.trim();
        values.customerFirstname = values.customerFirstname.trim();
        values.customerEmail = values.customerEmail.trim();

        if (!values.autoServices) errors.autoServices = true;

        if(typeForm === 'vehicle') {
            if (!values.carBrand) errors.carBrand = true;
            if (!values.carModel) errors.carModel = true;

            if (!values.carYear
                || isNaN(values.carYear)
                || (Number(values.carYear) < 1900 || Number(values.carYear) > new Date().getFullYear())
            ) {
                errors.carYear = 'От 1900 до ' + new Date().getFullYear();
            }

            if (!values.carColor) errors.carColor = true;

            if (!values.carVin
                || !/^[A-HJ-NPR-Z\d]{14}\d{3}$/i.test(values.carVin)
                || values.carVin.length !== 17) {
                errors.carVin = 'Некорректный формат';
            }

            if (values.customerEmail !== ''
                && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(values.customerEmail)) {
                errors.customerEmail = 'Некорректный формат';
            }
        }

        if (!values.customerLastname
            || !/^[а-яА-ЯёЁ]+$/i.test(values.customerLastname)) {
            errors.customerLastname = 'Некорректный формат';
        }
        if (!values.customerFirstname
            || !/^[а-яА-ЯёЁ]+$/i.test(values.customerFirstname)) {
            errors.customerFirstname = 'Некорректный формат';
        }

        if (!values.customerPhone) errors.customerPhone = true;
        if (!values.purchaseDate) errors.purchaseDate = true;

        return errors;
    }

    const removeSpaces = (e, maxLength, setFieldValue) => {
        setFieldValue(e.target.name, e.target.value.split(' ').join('').substr(0, maxLength), true);
    }

    const onFormSubmit = (values) => {
        let data = {};

        switch (typeForm) {
            case 'generic':
                data = new AppRequestGenericModel(
                    {
                        branch_id: values.autoServices,
                        purchase: {
                            date: moment(values.purchaseDate).format("YYYY-MM-DD").toString(),
                        },
                        customer: {
                            lastname: values.customerLastname,
                            firstname: values.customerFirstname,
                            phone: values.customerPhone,
                            email: values.customerEmail,
                        },
                    }
                ).toApiObject();
                break;
            case 'vehicle':
                data = new AppRequestVehicleModel(
                    {
                        branch_id: values.autoServices,
                        purchase: {
                            date: moment(values.purchaseDate).format("YYYY-MM-DD").toString(),
                            car: {
                                brand: values.carBrand,
                                model: values.carModel,
                                year: values.carYear,
                                color: values.carColor,
                                vin: values.carVin,
                            },
                        },
                        customer: {
                            lastname: values.customerLastname,
                            firstname: values.customerFirstname,
                            phone: values.customerPhone,
                            email: values.customerEmail,
                        },
                    }
                ).toApiObject();
                break;
            default:
        }

        setLoading(true);
        applicationService.saveApplication(data)
            .then(() => {

                okAnalyticsService.addEntity({
                    page: 'application_add',
                    target: 'application_add_form',
                    event: 'send_application',
                });

                setSuccess(true);
            })
            .catch((error) => {
                error.error.data.map(item => {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: error.error.description,
                            detail: item.message,
                        }
                    )
                });
                setBackErrors(error.error.data?.map(item => item.field))
                Sentry.captureException(new Error(JSON.stringify(error)));
                setError(true);
            }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <div className={cn('page__application', 'page')}>
                {success ? <SuccessBlock reloadForm={reloadForm} /> :
                    <>
                        <div className={'title'}>
                            <h1>Заявка на отзыв</h1>
                        </div>
                        <div className={cn('form')}>
                            {loading && <div className={cn('form-loading')} />}
                            {<React.Fragment>

                                <Formik
                                    innerRef={formikRef}
                                    enableReinitialize
                                    initialValues={initForm}
                                    validate={validateForm}
                                    backErrors={backErrors}
                                    onSubmit={(values) => {
                                        onFormSubmit(values);
                                    }}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          setFieldValue,
                                          handleSubmit,
                                      }) => {
                                        return <form onSubmit={handleSubmit}>
                                            <div className={'text-field'} style={{ width: '100%' }}>
                                                <div className="p-float-label">
                                                    <Dropdown
                                                        style={{ width: '100%' }}
                                                        id='autoServices'
                                                        type="text"
                                                        name="autoServices"
                                                        options={services}
                                                        className={errorClass((errors.autoServices && touched.autoServices))}
                                                        onChange={(e) => {
                                                            setTypeForm(services.find(item => item.value === e.target.value).type);
                                                            handleChange(e);
                                                        }}
                                                        value={values.autoServices}
                                                    />
                                                    <label htmlFor='autoServices'>Филиал <span
                                                        style={{ color: 'red' }}
                                                    >*</span></label>
                                                </div>
                                            </div>
                                            {typeForm !== '' && <>
                                                {typeForm === 'vehicle' && <>
                                                    <div className={'text-field'}>
                                                    <div className="p-float-label">
                                                        <InputText
                                                            id='carBrand'
                                                            type="text"
                                                            name="carBrand"
                                                            className={errorClass(
                                                                (
                                                                    !!backErrors.find(item => item === 'purchase.car.brand') ||
                                                                    (errors.carBrand && touched.carBrand))
                                                            )
                                                            }
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.carBrand}
                                                        />
                                                        <label htmlFor='carBrand'>Марка <span
                                                            style={{ color: 'red' }}
                                                        >*</span></label>
                                                    </div>
                                                </div>
                                                    <div className={'text-field'}>
                                                    <div className="p-float-label">
                                                        <InputText
                                                            id='carModel'
                                                            type="text"
                                                            name='carModel'
                                                            className={errorClass(
                                                                (
                                                                    !!backErrors.find(item => item === 'purchase.car.model') ||
                                                                    (errors.carModel && touched.carModel))
                                                            )
                                                            }
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.carModel}
                                                        />
                                                        <label htmlFor='carModel'>Модель<span
                                                            style={{ color: 'red' }}
                                                        >*</span></label>
                                                    </div>
                                                </div>
                                                    <div className={'text-field'}>
                                                    <div className="p-float-label">
                                                        <InputText
                                                            id='carYear'
                                                            name='carYear'
                                                            className={errorClass(
                                                                (
                                                                    !!backErrors.find(item => item === 'purchase.car.year') ||
                                                                    (errors.carYear && touched.carYear))
                                                            )
                                                            }
                                                            onChange={(e) => {
                                                                removeSpaces(e, 4, setFieldValue)
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.carYear}
                                                        />
                                                        {(errors.carYear && touched.carYear) &&
                                                            <span className={'inputTextError'}>{errors.carYear}</span>}
                                                        <label htmlFor='carYear'>Год <span
                                                            style={{ color: 'red' }}
                                                        >*</span></label>
                                                    </div>
                                                </div>
                                                    <div className={'text-field'}>
                                                    <div className="p-float-label">
                                                        <InputText
                                                            id='carColor'
                                                            name='carColor'
                                                            type='text'
                                                            className={errorClass(
                                                                (
                                                                    !!backErrors.find(item => item === 'purchase.car.color') ||
                                                                    (errors.carColor && touched.carColor)
                                                                )
                                                            )}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.carColor}
                                                        />
                                                        <label htmlFor='carColor'>Цвет <span
                                                            style={{ color: 'red' }}
                                                        >*</span></label>
                                                    </div>
                                                </div>
                                                    <div className={'text-field'}>
                                                    <div className="p-float-label">
                                                        <InputText
                                                            id='carVin'
                                                            name='carVin'
                                                            type="text"
                                                            className={errorClass(
                                                                (
                                                                    !!backErrors.find(item => item === 'purchase.car.vin') ||
                                                                    (errors.carVin && touched.carVin)
                                                                )
                                                            )}
                                                            onChange={(e) => {
                                                                removeSpaces(e, 17, setFieldValue)
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.carVin}
                                                        />
                                                        {(errors.carVin && touched.carVin) &&
                                                            <span className={'inputTextError'}>{errors.carVin}</span>}
                                                        <label htmlFor='carVin'>VIN <span
                                                            style={{ color: 'red' }}
                                                        >*</span></label>
                                                    </div>
                                                </div>
                                                    <div className={'text-field'}>
                                                        <div className="p-float-label">
                                                            <OkInputMask
                                                                autoClear={false}
                                                                mask="+7 (n99) 999-99-99"
                                                                id='customerPhone'
                                                                name='customerPhone'
                                                                type="text"
                                                                className={errorClass(
                                                                    !!backErrors.find(item => item === 'customer.phone') ||
                                                                    (errors.customerPhone && touched.customerPhone)
                                                                )}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.customerPhone}
                                                            />
                                                            <label htmlFor='customerPhone'>Телефон клиента <span
                                                                style={{ color: 'red' }}
                                                            >*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className={'text-field'}>
                                                        <div className="p-float-label">
                                                            <InputText
                                                                id='customerFirstname'
                                                                name='customerFirstname'
                                                                type="text"
                                                                className={errorClass(
                                                                    (
                                                                        !!backErrors.find(item => item === 'customer.firstname') ||
                                                                        (errors.customerFirstname && touched.customerFirstname)
                                                                    )
                                                                )}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.customerFirstname}
                                                            />
                                                            {(errors.customerFirstname && touched.customerFirstname) &&
                                                                <span className={'inputTextError'}>{errors.customerFirstname}</span>}
                                                            <label htmlFor='customerFirstname'>Имя клиента <span
                                                                style={{ color: 'red' }}
                                                            >*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className={'text-field'}>
                                                        <div className="p-float-label">
                                                            <InputText
                                                                id='customerLastname'
                                                                name='customerLastname'
                                                                type="text"
                                                                className={errorClass(
                                                                    (
                                                                        !!backErrors.find(item => item === 'customer.lastname') ||
                                                                        (errors.customerLastname && touched.customerLastname)
                                                                    )
                                                                )}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.customerLastname}
                                                            />
                                                            {(errors.customerLastname && touched.customerLastname) &&
                                                                <span className={'inputTextError'}>{errors.customerLastname}</span>}
                                                            <label htmlFor='customerLastname'>Фамилия клиента <span
                                                                style={{ color: 'red' }}
                                                            >*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className={'text-field'}>
                                                        <div className="p-float-label">
                                                            <InputText
                                                                id='customerEmail'
                                                                name='customerEmail'
                                                                type="text"
                                                                className={errorClass((errors.customerEmail && touched.customerEmail))}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.customerEmail}
                                                            />
                                                            <label htmlFor='customerEmail'>E-mail клиента</label>
                                                        </div>
                                                    </div>
                                                    <div className={'text-field'}>
                                                        <OkDatePicker
                                                            id='purchaseDate'
                                                            name='purchaseDate'
                                                            className={errorClass(
                                                                (
                                                                    !!backErrors.find(item => item === 'purchase.date') ||
                                                                    (errors.purchaseDate && touched.purchaseDate)
                                                                )
                                                            )}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.purchaseDate}
                                                        />
                                                        <div className="p-float-label">&nbsp;</div>
                                                    </div>
                                                </>}

                                                {typeForm === 'generic' && <>
                                                    <div className={'text-field'}>
                                                        <div className="p-float-label">
                                                            <InputText
                                                                id='customerFirstname'
                                                                name='customerFirstname'
                                                                type="text"
                                                                className={errorClass(
                                                                    (
                                                                        !!backErrors.find(item => item === 'customer.firstname') ||
                                                                        (errors.customerFirstname && touched.customerFirstname)
                                                                    )
                                                                )}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.customerFirstname}
                                                            />
                                                            {(errors.customerFirstname && touched.customerFirstname) &&
                                                                <span className={'inputTextError'}>{errors.customerFirstname}</span>}
                                                            <label htmlFor='customerFirstname'>Имя клиента <span
                                                                style={{ color: 'red' }}
                                                            >*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className={'text-field'}>
                                                        <div className="p-float-label">
                                                            <InputText
                                                                id='customerLastname'
                                                                name='customerLastname'
                                                                type="text"
                                                                className={errorClass(
                                                                    (
                                                                        !!backErrors.find(item => item === 'customer.lastname') ||
                                                                        (errors.customerLastname && touched.customerLastname)
                                                                    )
                                                                )}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.customerLastname}
                                                            />
                                                            {(errors.customerLastname && touched.customerLastname) &&
                                                                <span className={'inputTextError'}>{errors.customerLastname}</span>}

                                                            <label htmlFor='customerLastname'>Фамилия клиента <span
                                                                style={{ color: 'red' }}
                                                            >*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className={'text-field'}>
                                                        <div className="p-float-label">
                                                            <OkInputMask
                                                                mask="+7 (n99) 999-99-99"
                                                                id='customerPhone'
                                                                name='customerPhone'
                                                                type="text"
                                                                className={errorClass(
                                                                    !!backErrors.find(item => item === 'customer.phone') ||
                                                                    (errors.customerPhone && touched.customerPhone)
                                                                )}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.customerPhone}
                                                            />
                                                            <label htmlFor='customerPhone'>Телефон клиента <span
                                                                style={{ color: 'red' }}
                                                            >*</span></label>
                                                        </div>
                                                    </div>
                                                    <div className={'text-field'}>
                                                        <OkDatePicker
                                                            id='purchaseDate'
                                                            name='purchaseDate'
                                                            className={errorClass(
                                                                (
                                                                    !!backErrors.find(item => item === 'purchase.date') ||
                                                                    (errors.purchaseDate && touched.purchaseDate)
                                                                )
                                                            )}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.purchaseDate}
                                                        />
                                                        <div className="p-float-label">&nbsp;</div>
                                                    </div>
                                                </>}
                                                <div className={cn('form-btn__wrapper')}>
                                                    <Button label="Отправить заявку"
                                                            type="submit"
                                                            data-tag='analytics_add_form'
                                                            className={cn('submit-btn')}
                                                    />
                                                </div>
                                            </>}
                                        </form>
                                    }}
                                </Formik>
                            </React.Fragment>}
                        </div>
                    </>
                }
            </div>
        </>    );
}));

class SuccessBlock extends Component {
    render() {
        return (
            <React.Fragment>
                <div className={cn('form', 'successForm')}>
                    <div className={'successText'}>Ваша заявка успешно отправлена!</div>
                    <div className={'btn__wrapper'}>
                        <Button label="Подать еще одну заявку" onClick={(e) => {
                            this.props.reloadForm(e)
                        }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default ApplicationFormAdd;
