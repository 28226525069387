import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { SliceGrouper } from "@amcharts/amcharts4/.internal/plugins/sliceGrouper/SliceGrouper";

class ChartService {
    initRatingChart = (data = [], container, chartSource = '') => {

        container = am4core.create(chartSource, am4charts.XYChart);
        container.padding(0, 0, 25, 0);

        let dateAxis = container.xAxes.push(new am4charts.DateAxis());
        dateAxis.tooltip.disabled = true;
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.labels.template.disabled = true;

        let valueAxis = container.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.labels.template.disabled = true;

// Create series
        let series = container.series.push(new am4charts.LineSeries());

        series.dataFields.valueY = "value";
        series.dataFields.dateX = "date";
        series.tooltipText = "{tooltipDate}\nОценка: {value}"
        series.strokeWidth = 2;
        series.fill = am4core.color("#0FAFE2");
        series.tensionX = 0.8;
        series.fillOpacity = 0.5;


        let fillModifier = new am4core.LinearGradientModifier();
        fillModifier.opacities = [1, 0];
        fillModifier.offsets = [0, 1];
        fillModifier.gradient.rotation = 90;
        series.segments.template.fillModifier = fillModifier;

// Make a panning cursor
        container.cursor = new am4charts.XYCursor();
        container.cursor.behavior = "none";
        container.cursor.xAxis = dateAxis;
        container.cursor.snapToSeries = series;
        container.cursor.lineY.disabled = true;
        container.cursor.lineX.disabled = true;


// Make bullets grow on hover
        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.strokeWidth = 1;
        bullet.circle.radius = 2;
        bullet.circle.fill = am4core.color("#fff");

        let bullethover = bullet.states.create("hover");
        bullethover.properties.scale = 1.3;
        container.data = data;

        return Promise.resolve(container);
    }

    initDynamicsChart = (data = [], container, chartSource = '') => {
        container = am4core.create(chartSource, am4charts.XYChart);
        container.padding(0, 0, 25, 0);

        let categoryAxis = container.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.disabled = true;

        categoryAxis.renderer.labels.template.disabled = true;
        categoryAxis.dataFields.category = "date";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.inside = true;
        categoryAxis.renderer.minGridDistance = 30;

        let valueAxis = container.yAxes.push(new am4charts.ValueAxis());

        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.renderer.grid.template.disabled = true;

// Create series
        let series = container.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "date";
        series.name = "value";

        let columnTemplate = series.columns.template;
        columnTemplate.tooltipText = "{tooltip}{date}\nКол-во: {value}"
        columnTemplate.fillOpacity = 1;
        columnTemplate.strokeWidth = 0;
        columnTemplate.strokeOpacity = 1;
        columnTemplate.fill = am4core.color("#8BD8AB");
        columnTemplate.width = 25;

        let fillModifier = new am4core.LinearGradientModifier();
        fillModifier.opacities = [1, 0.5];
        fillModifier.offsets = [0, 1];
        fillModifier.gradient.rotation = 90;
        columnTemplate.fillModifier = fillModifier;

        container.data = data;

        return Promise.resolve(container);

    }

    initPieChart = (data = [], summary, container, chartSource = '') => {
        container = am4core.create(chartSource, am4charts.PieChart);
        //container.padding(0, 65, 20, 65);
        container.padding(0, 0, 0, 40);
        container.maskBullets = false;
        container.innerRadius = am4core.percent(30);
        container.radius = am4core.percent(60);
        let series = container.series.push(new am4charts.PieSeries());

        let grouper = series.plugins.push(new SliceGrouper());
        grouper.threshold = 1;
        grouper.groupName = "Прочее";
        grouper.clickBehavior = "zoom";

        series.dataFields.value = "count";
        series.dataFields.category = "source";

        series.labels.template.text = "[#7A7A7A]{source}[/]\n{count}\n[{textColor}]{change}[/]";
        series.labels.template.fontSize = 12;
        series.labels.template.paddingTop = 0;
        series.labels.template.paddingLeft = 0;
        series.labels.template.paddingRight = 0;
        series.labels.template.paddingBottom = 5;
        series.slices.template.propertyFields.fill = "color";
        series.slices.template.tooltipText = "";


        let rgm = new am4core.RadialGradientModifier();
        rgm.opacities.push(1, 0.5, 1, 0.5, 1);
        series.slices.template.fillModifier = rgm;
        series.slices.template.strokeModifier = rgm;
        series.slices.template.strokeOpacity = 0.6;
        series.slices.template.strokeWidth = 0;

        let label = series.createChild(am4core.Label);
        label.text = summary.total;
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fontSize = 14;

        container.data = data;

        return Promise.resolve(container);
    }

    initGradeChart = (data = [], container, chartSource = '') => {
        container = am4core.create(chartSource, am4charts.XYChart);
        container.padding(0, 120, 30, 0);
        container.maskBullets = false;

        let categoryAxis = container.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "rating";
        categoryAxis.renderer.minGridDistance = 1;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.disabled = true;

        let valueAxis = container.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.min = 0;

        let series = container.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "rating";
        series.dataFields.valueX = "published";
        series.columns.template.fill = am4core.color("#FFC547");
        series.columns.template.tooltipText = "Оценка: {rating}\nПрирост: {published}\nДобавлено: {added}\nУдалено: {deleted}"
        series.columns.template.strokeWidth = 0;
        series.columns.template.height = 15;

        let fillModifier = new am4core.LinearGradientModifier();
        fillModifier.opacities = [0.5, 1];
        fillModifier.offsets = [0, 1];
        series.columns.template.fillModifier = fillModifier;

        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.horizontalCenter = "left";
        labelBullet.label.truncate = false;
        labelBullet.label.dx = 5;

        labelBullet.label.adapter.add("text", function(text, target) {
            const data = target.dataItem.dataContext;
            if (Number(data.deleted) === 0) {
                return "{published} ([font-size: 14px #6FCF97]{added}[/] [font-size: 14px #BDBDBD]{deleted}[/])"
            }
            else {
                return "{published} ([font-size: 14px #6FCF97]{added}[/] [font-size: 14px #EB5757]{deleted}[/])"
            }
        });

        labelBullet.locationX = 0;

        container.data = data;
        return Promise.resolve(container);
    }
}

const chartService = new ChartService();
export default chartService;
