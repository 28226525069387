import React from "react";
import cn from "classnames";
import claimsService from "../../../../../services/claims";

const ClaimStatus = ({status}) => {
    const { CLAM_STATUS } = claimsService;
    return (
        <span className={cn('claim-status', `claim-status__${status}`)}>{CLAM_STATUS[status]}</span>
    );
}

export default ClaimStatus
