import { observable } from "mobx";
import storageFilesService from "../services/storageDownloadFiles";

class StorageFileModel {
    #date;
    #status;
    #file;
    #manager;

    constructor(object = {}) {
        
    }


    get date() {
        return this.#date;
    }
    get status() {
        return this.#status;
    }
    get file() {
        return this.#file;
    }
    get manager() {
        return this.#manager;
    }


}

class StorageFilesStore {

    @observable filter = {
        page: {
            size: 100,
            number: 1,
        },
        sort: {
            column: "date",
            order: "desc",
        }

    };

    #storageFilesService;

    constructor(storageFilesService) {
        this.#storageFilesService = storageFilesService;

    }


    getFileList() {
        return this.#storageFilesService.getFileList(this.filter)
            .then(result => {
                return result
            })
    }

    downloadFile(id) {
        return this.#storageFilesService.downloadFile(id)
            .then(result => {
                return result
            })
    }
}

const storageFilesStore = new StorageFilesStore(storageFilesService);

export default storageFilesStore;