import React, { useEffect, useState } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { reaction } from "mobx";
import _ from "underscore";

import applicationService from '../../../../../services/application'
import managersService from "../../../../../services/managers";
import applicationStatusService from "../../../../../services/application-status";
import filterService from '../../../../../services/filter';
import autoServicesService from '../../../../../services/services';
import { Paginator } from "primereact/paginator";
import { useIsMount } from 'hooks';
import ApplicationListDataTable from "../../common/ApplicationListDataTable";
import ApplicationListDashboard from "../../common/ApplicatonListDashboard";
import ApplicationModel from "../../../../../models/application";

import 'primeflex/primeflex.css';
import { InputText } from "primereact/inputtext";
import moment from "moment";
import OkDateRangePicker from "../../../../common/OkDateRangePicker";
import SlideNav from "../../../analytics/SlideNav";
import { FilterButton, FilterButtonSimple } from "components/common/FilterButton";
import '../../../../App.scss';
import './../ApplicationList.scss';
import PagerSizeSelector from "components/pages/review/list/common/PagerSizeSelector";
import Download from "../../../review/list/common/Download";
import { ProgressSpinner } from "primereact/progressspinner";

import { Button } from "primereact/button";
import { useHistory } from "react-router";

const ApplicationListDesktop = inject('filterStore', 'uiStore', 'userStore')(
    observer(({ filterStore, uiStore, userStore }) => {
        const [applications, setApplications] = useState([]);
        const [appStatuses, setAppStatuses] = useState([]);
        const [dashboard, setDashboard] = useState(null);
        const [pager, setPager] = useState(null);
        const [firstPage, setFirstPage] = useState(0);
        const [selectedPeriod, setSelectedPeriod] = useState(null);
        const [disabledFilter, setDisabledFilter] = useState(false);
        const [visibleNewNegative, setVisibleNewNegative] = useState(false);
        const [newNegativeCounter, setNewNegativeCounter] = useState(null);

        const newNegativeId = [];

        const [appServices, setAppServices] = useState([
            { value: 0, name: "Все филиалы" }
        ]);

        const [appManagers, setAppManagers] = useState([
            { value: 0, name: "Все менеджеры" }
        ]);

        const listScores = [
            { value: 1, name: "1" },
            { value: 2, name: "2" },
            { value: 3, name: "3" },
            { value: 4, name: "4" },
            { value: 5, name: "5" },
        ];

        const { filter, setFieldValue, isNewNegativeOn, setNewNegativeOn, setPageSize } = filterStore;
        const { setMessage } = uiStore;

        const [datePeriod, setDatePeriod] = useState({
            'from': filter.date.from,
            'to': filter.date.to,
        });

        const history = useHistory();
        const isMount = useIsMount();

        const [tableIsLoading, setTableIsLoading] = useState(false);
        const [dashboardIsIsLoading, setDashboardIsLoading] = useState(false);

// первоначальная загрузка данных
        useEffect(() => {
            setTableIsLoading(true);
            setDashboardIsLoading(true);

            // получаем дашбоард
            applicationService.getDashboard(filter).then(result=>{
                setDashboard(result);
                setDashboardIsLoading(false);
            })

            Promise.all([
                autoServicesService.getServices(),
                managersService.getManagers(),
                applicationStatusService.getApplicationStatus(),
                applicationService.getApplicationsList(filter),
                applicationService.getNewNeNativeCounter(),
            ]).then((
                [
                    servicesData,
                    managersData,
                    applStatusData,
                    applListData,
                    newNegativeData,
                ]
            ) => {
// получаем список сервисов для фильтра
                if (servicesData) {
                    const services = servicesData.map((service) => ({
                        value: service.id,
                        name: service.title,
                    }));
                    setAppServices((prev) => {
                        return [
                            ...prev,
                            ...services,
                        ]
                    });
                }

// получаем список манагеров для фильтра
                if (managersData) {
                    const managers = managersData.map((manager) => {
                        return { value: manager.id, name: manager.fullname };
                    });
                    setAppManagers((prev) => {
                        return [
                            ...prev,
                            ...managers,
                        ]
                    });
                }

// получаем список статусов для фильтра
                if (applStatusData) {
                    let statuses = applStatusData.map((status) => {
                        if (!!status.negative_filter === true) {
                            newNegativeId.push({value: status.id});
                        }

                        return  { value: status.id, name: status.title, sortorder: status.sortorder };
                    });
                    setAppStatuses(statuses);
                }

// получаем список заявок
                if (applListData) {
                    const applications = applListData.applications.map(item => {
                        return new ApplicationModel(item);
                    });
                    setApplications(applications);
                    setPager(applListData.pager);
                    applListData.pager.number > 1 && setFirstPage((applListData.pager.number - 1) * applListData.pager.size);

                    setTableIsLoading(false);
                }

// кол-во заявок со статусом "Негатив перехвачен"
                if(newNegativeData) {
                    setNewNegativeCounter(newNegativeData)
                }

                if(isNewNegativeOn && !!newNegativeData) {
                    setVisibleNewNegative(true);
                    setDisabledFilter(true);
                }
                if(isNewNegativeOn && !newNegativeData) {
                    filterStore.resetOldFilter();
                    setVisibleNewNegative(false);
                    setDisabledFilter(false);
                    setNewNegativeOn(false)
                }

            })
                .catch(error => {
                    if (error.error) {
                        error.error.data.map(item => {
                            setMessage(
                                {
                                    severity: 'error',
                                    summary: error.error.description,
                                    detail: item.message,
                                }
                            )
                        });
                    }
                });
//выбираем период в слайдере
            setSelectedPeriod(getCurrentPeriod());
        }, []);


        useEffect(() => {
            const disposers = [
                reaction(
                    () => filterStore.isNewNegativeOn,
                    (value) => {
                        if(value) {
                            filterStore.saveFilter();
                            filterStore.getNewNegative(newNegativeId, userStore.profile.client.createdAt)
                        }
                        else {
                            filterStore.resetOldFilter();
                        }
                    }
                ),
                reaction(
                    () => filter.query,
                    (value) => {
                        getDebounceApplicationsList(value)
                    }
                ),
                reaction(
                    () => filter.sort,
                    () => {
                        getApplicationsList();
                    }
                ),
                reaction(
                    () => filter.branch_id,
                    branch_id => {
                        getManagers(branch_id[0].value);
                        filterStore.setDefaultManager(0);
                    }
                ),
            ];
            return () => {
                disposers.map(func => func());
            }
        }, []);

// Следим за фильтрами для дашбоарда и списка
        useEffect(() => {
            setTableIsLoading(true);
            setDashboardIsLoading(true);

            if (!isMount) {
                Promise.all([
                    applicationService.getDashboard(filter),
                    applicationService.getApplicationsList(filter),
                ]).then((
                    [
                        dashboardData,
                        applListData,
                    ]
                ) => {

// получаем дашбоард
                    if (dashboardData) {
                        setDashboard(dashboardData);
                    }

// получаем список заявок
                    if (applListData) {
                        const applications = applListData.applications.map(item => {
                            return new ApplicationModel(item);
                        });
                        setApplications(applications);
                        setPager(applListData.pager);
                    }
                    setTableIsLoading(false);
                    setDashboardIsLoading(false);

                });

            }
        }, [
            JSON.stringify(filter.date),
            JSON.stringify(filter.branch_id),
            JSON.stringify(filter.manager_id),
            filter.page.size,
        ])

// Следим за фильтрами для списка
        useEffect(() => {
            if (!isMount) {
                getApplicationsList()
            }

        }, [
            JSON.stringify(filter.status_id),
            JSON.stringify(filter.scores),
            JSON.stringify(filter.page.number),
        ]);

        const debounceFunc = () => {
            //e.target.value.length === 2 || e.target.value.trim().match(/[\wа-яА-ЯёЁ\d]/) !== null
            getApplicationsList();
        }

        const getDebounceApplicationsList = _.debounce(debounceFunc, 800);

        const getApplicationsList = () => {
            setTableIsLoading(true);

            applicationService.getApplicationsList(filter).then((applListData) => {
                const applications = applListData.applications.map(item => {
                    return new ApplicationModel(item);
                });
                setApplications(applications);
                setPager(applListData.pager);
                setTableIsLoading(false);
            });
        }

        const getManagers = (branch_id) => {
            setAppManagers([
                { value: 0, name: "Все менеджеры" }
            ]);

            managersService.getManagers(branch_id).then(data => {

                const managers = data.map((manager) => {
                    return { value: manager.id, name: manager.fullName };
                });
                setAppManagers((prev) => {
                    return [
                        ...prev,
                        ...managers,
                    ]
                });
            })

        }

        const setPage = (pageNum) => {
            pageNum++;
            setFieldValue('page', { size: filter.page.size, number: pageNum });
        }

        /**
         * передаем период в календарь
         * @param date
         */
        const handleChangePeriod = (date) => {
            setFieldValue('date', {
                from: filterService.periods[date][0],
                to: filterService.periods[date][1],
            });
        };

        /**
         * ловим изменение даты в календаре
         * @param {Object} date
         */
        const onChangeRangeDate = (date) => {
            setFieldValue('date', {
                from: date.startDate,
                to: date.endDate,
            });
        };

        /**
         * ловим изменения фильтра
         * @param {String} field
         * @param {String} value
         */
        const handlerChangeFilter = (field, value) => {
            if (!isMount) {
                setFieldValue(field, value);
            }
        }

        /**
         *
         * @returns {string}
         */
        const getCurrentPeriod = () => {
            // TODO это дело подружить с периодом в сервисе FilterService
            const periodList = {
                'today': {
                    'from': moment().toString(),
                    'to': moment().toString()
                },
                'yesterday': {
                    'from': moment().subtract(1, 'day').toString(),
                    'to': moment().subtract(1, 'day').toString()
                },
                'week': {
                    'from': moment().subtract(7, 'day').toString(),
                    'to': moment().toString()
                },
                'month': {
                    'from': moment().subtract(30, 'day').toString(),
                    'to': moment().toString()
                },
            }

            let period = 'month';

            Object.keys(periodList).find(key => {
                if (
                    moment(periodList[key].from).isSame(datePeriod.from, 'day') &&
                    moment(periodList[key].to).isSame(datePeriod.to, 'day')
                ) {
                    period = key;
                }
            });
            return  period;
        }

        const setCountPagerSize = (count) => {
            setPageSize(count);
            //загружем данные для первой странице
            setPage(0)
            //переключаем пагинацию на выбор первой страницы
            setFirstPage(1)
        }

        const clear = () => {
            filterStore.clear();

            setDisabledFilter(false);
            setVisibleNewNegative(false);
        }


        return (
            <>
                <div className={cn('page__application-list', 'page')}>
                    <div className={'application-list'}>
                        <>
                            <div className={'title'}>
                                <h1>Список заявок</h1>

                                <Button
                                    label="Создать заявку"
                                    icon="pi pi-plus"
                                    className={cn('p-button-outlined', 'new-appl')}
                                    onClick={() => {
                                        history.push(`/application/add`);
                                    }}
                                />

                                <Download
                                    filter={filter}
                                    isMobile={false}
                                    uiStore={uiStore}
                                    userStore={userStore}
                                    dest={'appl'}
                                />
                            </div>

                            <div className={cn('application-list-filter')}>
                                <div className={cn('filter__header')}>
                                    <div className={'filter__title'}>
                                        <h3>Фильтр</h3>
                                    </div>
                                    <div
                                        className={'filter__clear-btn'}
                                        onClick={clear}
                                    >Очистить все
                                    </div>
                                </div>

                                <div className={cn('filter__wrapper')}>
                                    <div className={cn('filter__item')}>
                                        {selectedPeriod && <SlideNav
                                            handleChangeNav={handleChangePeriod}
                                            data={[
                                                { 'today': 'Сегодня' },
                                                { 'yesterday': 'Вчера' },
                                                { 'week': 'Неделя' },
                                                { 'month': 'Месяц' },
                                            ]}
                                            value={selectedPeriod}
                                            disabled={disabledFilter}
                                        />}
                                    </div>
                                    <div className={cn('filter__item')}>
                                        <OkDateRangePicker
                                            onChangeDateHandler={onChangeRangeDate}
                                            dateRange={{
                                                'from': filter.date.from,
                                                'to': filter.date.to,
                                            }}
                                            disabled={disabledFilter}
                                        />
                                    </div>
                                    {appServices.length > 1 && <div className={cn('filter__item')}>
                                        <FilterButtonSimple
                                            list={appServices}
                                            onChange={(value) => handlerChangeFilter('branch_id', value)}
                                            onClear={() => setFieldValue('branch_id', [{ value: 0 }])}
                                            filter={filter.branch_id}
                                            loading={tableIsLoading}
                                            search={true}
                                            defaultValue={{ value: 0 }}
                                        />
                                    </div>}
                                    {appManagers.length > 1 && <div className={cn('filter__item')}>
                                        <FilterButtonSimple
                                            list={appManagers}
                                            onChange={(value) => handlerChangeFilter('manager_id', value)}
                                            onClear={() => setFieldValue('manager_id', [{ value: 0 }])}
                                            filter={filter.manager_id}
                                            loading={tableIsLoading}
                                            search={true}
                                            defaultValue={{ value: 0 }}
                                        />
                                    </div>}
                                </div>
                            </div>

                            <div className={cn('dashboard')}>
                                {dashboardIsIsLoading ? <div className={'loading_tmp'}><ProgressSpinner /></div> : <>
                                    <ApplicationListDashboard value={dashboard} />
                                </>}
                            </div>

                            <div className={cn('application-list__table')}>
                                <div className={cn('application-list__wrapper')}>
                                    <div className={cn('application-list-filter')}>
                                        <div className={cn('filter__wrapper')}>
                                            <div className={cn('filter__item')}>
                                                <FilterButton
                                                    name='Статус'
                                                    list={appStatuses}
                                                    onChange={(value) => handlerChangeFilter('status_id', value)}
                                                    onClear={() => setFieldValue('status_id', [])}
                                                    filter={filter.status_id}
                                                    loading={tableIsLoading}
                                                    search={true}
                                                    sort={false}
                                                    disabled={disabledFilter}
                                                />
                                            </div>
                                            <div className={cn('filter__item')}>
                                                <FilterButton
                                                    name='Оценка'
                                                    list={listScores}
                                                    onChange={(value) => handlerChangeFilter('scores', value)}
                                                    onClear={() => setFieldValue('scores', [])}
                                                    filter={filter.scores}
                                                    loading={tableIsLoading}
                                                    disabled={disabledFilter}
                                                />
                                            </div>
                                            <div className={cn('filter__item')}>
                                                <div className={cn(
                                                    "filter-button",
                                                    "warning",
                                                    {'disabled' : !newNegativeCounter}
                                                )}
                                                     onClick={() => {
                                                         if(newNegativeCounter) {
                                                             setVisibleNewNegative(prev => !prev);
                                                             setDisabledFilter(prev => !prev);
                                                             setNewNegativeOn(!isNewNegativeOn)
                                                         }
                                                     }}>
                                                    <div className={
                                                        cn("filter-button__title",
                                                            {'active' : visibleNewNegative},
                                                        )
                                                    }>
                                                        <span>Негатив перехвачен</span>
                                                        {!!newNegativeCounter && <span className="badge">{newNegativeCounter}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={cn('filter__item', 'word-field')}>
                                                <div className={'text-field'}>
                                                <span className="p-input-icon-left">
                                                    <i className="pi pi-search" />
                                                    <InputText
                                                        id="query"
                                                        name="query"
                                                        className={cn('input-search')}
                                                        value={filter.query}
                                                        onChange={(e) => {
                                                            setFieldValue('query', e.target.value)
                                                        }}
                                                        placeholder="Поиск по клиенту или товару / услуге"
                                                    />
                                                </span>
                                                </div>
                                            </div>
                                            <div className={cn('filter__item', 'list-filter__pager-size')}>
                                                <PagerSizeSelector
                                                    setCount={setCountPagerSize}
                                                    value={filter.page.size}
                                                    options={[
                                                        10, 30, 50, 100
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <>
                                        {tableIsLoading ? <div className={'loading_tmp'}><ProgressSpinner /></div> : <>
                                            <ApplicationListDataTable
                                                applications={applications}
                                                isLoadingTable={tableIsLoading}
                                            />
                                            <>
                                                {pager && <Paginator
                                                    rows={pager.size}
                                                    totalRecords={pager.itemCount}
                                                    first={firstPage}
                                                    onPageChange={(e) => {
                                                        setPage(e.page)
                                                        setFirstPage(e.first)
                                                    }}
                                                />}
                                            </>
                                        </>}
                                    </>
                                </div>
                            </div>
                        </>
                    </div>
                </div>

            </>
        );
    })
);

export default ApplicationListDesktop;
