import React, { useEffect, useState } from "react";
import cn from "classnames";
import Tabs from "../Tabs";
import { FilterReview } from "../Filter";
import SourcePlate from "../SourcePlate"
import { inject, observer } from "mobx-react";
import PrepareNumber from "../../../../components/common/PrepareNumber";
import { ToolTip } from "components/common/ToolTip";
import DiffPercentString from "../DiffPercentString";
import DiffAwgTimeReply from "../DiffAwgTimeReply";
import ExportReport from "../ExportReport";
import Charts from "../reviews/Charts";

const ReviewsDesktop = ({ analyticsStore, uiStore, userStore }) => {

    const { filter } = analyticsStore;
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        analyticsStore.getReviewsList()
            .then(result => {
                setItems(result);
                setLoading(false);
            });
    }, [JSON.stringify(filter)]);

    const handlerGetReport = (event) => {
        if (event === 'GET_REPORT') {
            analyticsStore.downloadXlsReportReport()
                .then(_ => {
                    uiStore.setMessage(
                        {
                            severity: 'success',
                            summary: '',
                            detail: 'Отчет будет отправлен на почту '+ userStore.profile.user.email,
                            sticky: false,
                        }
                    );
                })
                .catch(e => e)
        }
    }


    return (
        <>
            <div className={cn('page__analytics', 'page', 'review-table')}>
                <div className={cn('title')}>
                    <h1>Аналитика</h1>
                    <ExportReport
                        isMobile={false}
                        userStore={userStore}
                        handlerGetReport={handlerGetReport}
                    />

                </div>
                <div className={cn('analytics-tabs')}>
                    <Tabs/>
                </div>
                <div className={cn('analytics-filter')}>
                    <FilterReview loading={loading}/>
                </div>
                <Charts/>
                <div className={cn('analytics-table', 'table')}>
                    <div className={cn('table__wrapper')}>
                        {loading && <div className={'loader'}/>}
                        <div className="p-datatable p-component">
                            <div className="p-datatable-wrapper">
                                <table role="grid">
                                    <thead className="p-datatable-thead">
                                    <tr>
                                        <th>Филиал</th>
                                        <th className={cn('source')}>Источник</th>
                                        <th className={cn('rating')}>
                                            <div className={cn('answer__tooltip', 'align-right')}>
                                                Рейтинг
                                                <div className={'tooltip__wrapper'}>
                                                    <ToolTip mobile={false}>
                                                        В скобках указан рейтинг<br/> за выбранный период по отзывам
                                                    </ToolTip>
                                                </div>
                                            </div>
                                        </th>
                                        <th className={cn('scores', 'align__right')}>Оценки</th>
                                        <th>&nbsp;</th>
                                        <th className={cn('review', 'align__right')}>Отзывы</th>
                                        <th>&nbsp;</th>
                                        <th className={cn('align__right', 'answer')}>
                                            <div className={'answer__tooltip'}>
                                                С ответом
                                                <div className={'tooltip__wrapper'}>
                                                    <ToolTip mobile={false}>
                                                        Доля ответов на отзывы:<br/><b>% итого (% за выбранный
                                                        период)</b>
                                                    </ToolTip>
                                                </div>
                                            </div>
                                        </th>
                                        <th className={cn('align__right', 'time')}>
                                            <div className={'answer__tooltip'}>
                                                Время ответа
                                                <div className={'tooltip__wrapper'}>
                                                    <ToolTip mobile={false}>
                                                        Среднее время ответа на отзывы:<br/><b>за последние 90 дней (за
                                                        выбранный период)</b>
                                                    </ToolTip>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {items?.total && <FirstRow
                                        data={items?.total}
                                    />}

                                    {items.items?.length > 0 && items.items.map((item, key) => (
                                        <Row
                                            data={item}
                                            key={key + '_' + item.id}
                                        />
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const FirstRow = ({ data }) => {

    return (
        <tr className={cn('first-row')}>
            <td>
                <div className={cn('name')}>
                    <div className={cn('ico', 'ico__bag')}/>
                    <div>Все филиалы</div>
                </div>
            </td>
            <td className={cn('source')}>&nbsp;</td>
            <td>
                <span className={cn('rating')}>
                    <span className={cn('pi-star pi')}/>
                    <span className={cn('value')}>
                        {Number(data.close.rating.toFixed(1)).toLocaleString('ru-RU')}
                    </span>
                    <PrepareNumber value={data.diff.rating}/>
                </span>
            </td>

            <td className={cn('align__right')}>
                {Number(`${data.close.scores}`).toLocaleString('ru-RU')}
            </td>
            <td>
                <PrepareNumber value={data.diff.scores}/>
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.close.reviews}`).toLocaleString('ru-RU')}
            </td>
            <td>
                <PrepareNumber value={data.diff.reviews}/>
            </td>
            <td className={cn('align__right')}>
                <DiffPercentString data={data}/>
            </td>
            <td className={cn('align__right')}>
                <DiffAwgTimeReply data={data}/>
            </td>
        </tr>
    );
};

/**
 *
 * @param {Object} data
 * @returns {JSX.Element}
 * @constructor
 */
const Row = ({ data }) => {
    const [title, title2] = data.branch.title.split(' - ');

    return (
        <tr>
            <td>
                <div className={cn('name')}>
                    <div className={cn('ico', 'ico__bag')}/>
                    <div title={data.branch.title}>
                        {title} {!!title2 > 0 && <>-<br/>{title2}</>}
                    </div>
                </div>
            </td>
            <td className={cn('source')}>
                <SourcePlate
                    adplatforms={data.adplatforms}
                    sources={data.sources}
                />
            </td>

            <td>
                <span className={cn('rating')}>
                    <span className={cn('pi-star pi')}/>
                    <span className={cn('value')}>
                        {Number(data.close.rating.toFixed(1)).toLocaleString('ru-RU')}
                    </span>
                    <PrepareNumber value={data.diff.rating}/>
                    {data.diff.reviews ?
                        <span className={cn('text-color--gray')}>&nbsp;({Number((data.diff.weightedReview/data.diff.reviews).toFixed(1)).toLocaleString('ru-RU')})</span>
                        :
                        <span className={cn('text-color--gray')}>&nbsp;(0)</span>}
                </span>
            </td>

            <td className={cn('align__right')}>
                {Number(`${data.close.scores}`).toLocaleString('ru-RU')}
            </td>
            <td>
                <PrepareNumber value={data.diff.scores}/>
            </td>
            <td className={cn('align__right')}>
                {Number(`${data.close.reviews}`).toLocaleString('ru-RU')}
            </td>
            <td>
                <PrepareNumber value={data.diff.reviews}/>
            </td>
            <td className={cn('align__right')}>
                <DiffPercentString data={data}/>
            </td>
            <td className={cn('align__right')}>
                <DiffAwgTimeReply data={data}/>
            </td>
        </tr>
    );
}

export default inject('analyticsStore', 'uiStore', 'userStore')(observer(ReviewsDesktop));
