import cn from "classnames";
import React, {useEffect, useState} from "react";
import OkInputText from "../../../../common/OkInputText";
import OkInputMask from "../../../application/common/OkInputMask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { inject, observer } from "mobx-react";

const MessengerInfo = ({geoStore}) => {

    const {form} = geoStore;

    const [errors, setErrors] = useState([]);

    return (
        <>
            <div className={cn('block')}>
                <div className={cn('block__title')}>
                    <h3>Мессенджеры</h3>
                </div>
                <div className={cn('block__form')}>
                    <div className={cn("form__row")}>
                        <div className={cn('text-field')} style={{display: 'flex', alignItems: 'center'}}>
                            <FontAwesomeIcon icon={['brands', 'whatsapp']} size="2x" fixedWidth style={{marginRight: '5px'}} />
                            <div className={cn("p-float-label")} style={{width: '100%'}}>
                                <OkInputMask
                                    id={'whatsapp'}
                                    mask="+7 (n99) 999-99-99"
                                    type="text"
                                    name={'whatsapp'}
                                    required={form.whatsapp_phone.required}
                                    value={form.whatsapp_phone.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('whatsapp_phone', e.target.value)
                                    }}
                                />
                                <label htmlFor="whatsapp">Whatsapp</label>
                            </div>

                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <div className={cn('text-field')} style={{display: 'flex', alignItems: 'center'}}>
                            <FontAwesomeIcon icon={['brands', 'telegram']} size="2x" fixedWidth style={{marginRight: '5px'}} />
                            <div className={cn("p-float-label")} style={{width: '100%'}}>
                                <OkInputText
                                    id={'telegram'}
                                    type="text"
                                    name={'telegram'}
                                    required={form.tg_link.required}
                                    validate={'url'}
                                    message={'не верный формат URL'}
                                    value={form.tg_link.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('tg_link', e.target.value)
                                    }}
                                />

                                <label htmlFor="telegram">Telegram</label>
                            </div>

                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <div className={cn('text-field')} style={{display: 'flex', alignItems: 'center'}}>
                            <FontAwesomeIcon icon={['brands', 'viber']} size="2x" fixedWidth style={{marginRight: '5px'}}/>
                            <div className={cn("p-float-label")} style={{width: '100%'}}>
                                <OkInputMask
                                    id={'viber'}
                                    mask="+7 (n99) 999-99-99"
                                    type="text"
                                    name={'viber'}
                                    required={form.viber_phone.required}
                                    value={form.viber_phone.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('viber_phone', e.target.value)
                                    }}
                                />
                                <label htmlFor="viber">Viber</label>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default inject('geoStore')(observer(MessengerInfo));
