import React from "react";
import {Rating} from "primereact/rating";

const RatingField = (props) => {
    const {review} = props;
    return (
        <React.Fragment>
            {review ?
                <Rating value={review.rating} readonly cancel={false}
                        className={'application-rating'}/>
                : 'отсутствует'}
        </React.Fragment>
    );
}

export default RatingField;
