import a4Color from "images/pdf/a4_color.pdf";
import a4Gray from "images/pdf/a4_gray.pdf";

import a5Gray from "images/pdf/a5_gray.pdf";
import a5Color from "images/pdf/a5_color.pdf";

import cart from "images/pdf/cart.pdf";
import monitor from "images/pdf/monitor.pdf";

import pyramidColor from "images/pdf/pyramid_color.pdf";
import pyramidGray from "images/pdf/pyramid_gray.pdf";

export const PDF_DOWNLOAD = {
    CART: {
        gray: {
            templates: cart,
            position: {
                xOffset: 50,
                yOffset: 171,
                width: 128,
                height: 128,
            }
        },
    },
    A4: {
        gray: {
            templates: a4Gray,
            position: {
                xOffset: 72,
                yOffset: 200,
                width: 133,
                height: 133,
            }
        },
        color: {
            templates: a4Color,
            position: {
                xOffset: 64,
                yOffset: 200,
                width: 133,
                height: 133,
            }
        },
    },
    A5: {
        gray: {
            templates: a5Gray,
            position: {
                xOffset: 50,
                yOffset: 133,
                width: 90,
                height: 90,
                xOffsetDouble: [421],
            }
        },
        color: {
            templates: a5Color,
            position: {
                xOffset: 50,
                yOffset: 133,
                width: 90,
                height: 90,
                xOffsetDouble: [417],
            }
        },

    },
    PYRAMID: {
        gray: {
            templates: pyramidGray,
            position: {
                xOffset: 71,
                yOffset: 147,
                width: 108,
                height: 108,
                xOffsetDouble: [261, 529],
            }
        },
        color: {
            templates: pyramidColor,
            position: {
                xOffset: 66,
                yOffset: 160,
                width: 112,
                height: 112,
                xOffsetDouble: [273, 538],
            }
        },
    },
    MONITOR: {
        gray: {
            templates: monitor,
            position: {
                xOffset: 1274,
                yOffset: 815,
                width: 265,
                height: 265,
            }
        },
    }
}
