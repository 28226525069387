import { observable, action } from "mobx";
import moment from "moment";
import _ from  "underscore";
import reviewsService from "../services/review";

class ReviewFilterStore {
    /**
     * @type {ReviewService}
     * @private
     */
    _reviewsService;

    @observable filter = {
        date: {
            from: new Date(moment().subtract(1, 'month').toString()),
            to: new Date()
        },
        branches: [],
        tags: [],
        autotags: [],
        sources: [],
        scores: [],
        claim: [],
        publication: [],
        attention: [
            { value: 0 }
        ],
        answer: [
            //{ value: 1 }
        ],
        pager: {
            size: 10,
            number: 1,
        },
        sorting: {
            column: "posted",
            order: "desc",
        }
    };

    @observable branches = [];

    @observable tags = [];

    @observable tags_auto = [];

    @observable sources = [];

    scores = [
        { value: 1, name: '1' },
        { value: 2, name: '2' },
        { value: 3, name: '3' },
        { value: 4, name: '4' },
        { value: 5, name: '5' },
    ];

    statuses = [
        { value: 0, name: 'Без официального ответа' },
        { value: 1, name: 'С официальным ответом' },
    ];

    claims = [
        { value: 'accepted', name: 'Удовлетворена' },
        { value: 'submitted', name: 'На рассмотрении' },
        { value: 'declined', name: 'Отклонена' },
    ];

    publicationsStatuses = [
        { value: 'published', name: 'Опубликован' },
        { value: 'unpublished', name: 'Не опубликован' },
        { value: 'removed', name: 'Снят с публикации' },
    ];

    constructor(reviewsService) {
        this._reviewsService = reviewsService;
        this.parseFilterToApi();
    }

    @action setFieldValue = (fieldName, fieldValue) => {
        if (this.filter.hasOwnProperty(fieldName)) {
            this.filter[fieldName] = fieldValue;
        }
    }

    parseFilterToChart() {
        return _.pick(this.parseFilterToApi(), 'date', 'branches', 'sources', 'tags');
    }


//TODO так как модель фильтра изменилась можно это переделать
    parseFilterToApi() {
        //это поле парсим если в нем только одно значение.
        const BINARY_FIELD = 'answer';

        let newObj = {
            date: {
             'from': moment(new Date(this.filter.date.from)).format("YYYY-MM-DD").toString(),
             'to': moment(new Date(this.filter.date.to)).format("YYYY-MM-DD").toString(),
            },
            page: {
                size: this.filter.pager.size,
                number: this.filter.pager.number,
            },
            sort: this.filter.sorting,
        };

        _.mapObject(this.filter, (val, key) => {
            if (_.isArray(val) && val.length > 0 && key !== BINARY_FIELD) {
                newObj[key] = this.filter[key].map(val=> val.value).join(',');
            }
            if (key === BINARY_FIELD && val.length === 1) {
                newObj[key] = this.filter[key].map(val=> val.value).join(',');
            }
        });

        return newObj;
    }

    @action setDate = (date) => {
        this.filter.date = date;
    }

    @action setSorting = (sorting) => {
        this.filter.sorting = sorting;
    }

    @action setPageSize = (size) => {
        this.filter.pager.size = size;
    }

    /**
     * @param {{size:number, number:number}} pager
     */
    @action setPager = (pager = {}) => {
        this.filter.pager = {
            ...this.filter.pager,
            ...pager,
        };
    }

    loadAttentionNum = () => {

        return this._reviewsService
            .getCounters(
                this.parseFilterToApi(this.filter)
            )
            .then((result) => result)
            .catch(e => e)
    }

    @action clear() {
        this.filter = {
            date: {
                from: new Date(moment().subtract(1, 'month').toString()),
                to: new Date()
            },
            branches: [],
            tags: [],
            autotags: [],
            sources: [],
            scores: [],
            claim: [],
            publication: [],
            attention: [
                { value: 0 }
            ],
            answer: [],
            pager: {
                size: 10,
                number: 1,
            },
            sorting: {
                column: "posted",
                order: "desc",
            }
        };

    }

    getBranches() {
        this._reviewsService
            .getBranch()
            .then(data => {
                this.branches = data.items?.map(item => {
                    return {
                        value: item.id,
                        name: item.title,
                    }
                })
            });
    }

    getSources() {
        const REMOVE_BRANCH_TITLE_ARRAY = ['okreview', 'flamp']
        this._reviewsService
            .getSources()
            .then(data => {
                this.sources = data.items
                    .filter(item => {
                        return !REMOVE_BRANCH_TITLE_ARRAY.find(source => source === item.code)
                    })
                    .map(item => (
                        {
                            value: item.id,
                            name: item.title,
                            code: item.code,
                        }
                    ));
            });
    }

}

const reviewFilterStore = new ReviewFilterStore(
    reviewsService,
)
export default reviewFilterStore;
