import {scroller} from "react-scroll";
import cn from "classnames";
import {Button} from "primereact/button";
import React from "react";
import './NavigateBtnUp.scss';

function NavigateBtnUp() {

    const onScrollToTop = () => {
        scroller.scrollTo('root', {
            duration: 500,
            delay: 0,
            smooth: 'easeInOutQuart'
        });
    }

    return (
        <div className={cn('wrapper-btn')}>
            <Button
                icon="pi pi-chevron-up"
                className={cn('p-button-rounded', 'btn-up')}
                onClick={() => {
                    onScrollToTop();
                }}
            />
        </div>
    )
}

export default NavigateBtnUp;