import { Dropdown } from "antd";
import { Button } from "primereact/button";
import cn from "classnames";
import React from "react";

const MergeButtonDrop = ({ items = [], onClick, disabled, label }) => (
    <Dropdown
        menu={{
            items,
            selectable: true,
            onClick,
        }}
        trigger={['click']}
        disabled={disabled}
    >
        <a onClick={(e) => e.preventDefault()}>
            <Button
                label={label}
                disabled={disabled}
                className={cn('p-button-outlined')}
            />
        </a>
    </Dropdown>

)

export default MergeButtonDrop;