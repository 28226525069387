import React from "react";
import cn from "classnames";
import OkInputText from "../../../../common/OkInputText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { inject, observer } from "mobx-react";

const SocialMediaInfo = ({geoStore}) => {
    const {form} = geoStore;

    return (
        <>
            <div className={cn('block')}>
                <div className={cn('block__title')}>
                    <h3>Социальный сети</h3>
                </div>
                <div className={cn('block__form')}>
                    <div className={cn("form__row")}>

                        <div className={cn('text-field')} style={{display: 'flex', alignItems: 'center'}}>
                            <FontAwesomeIcon icon={['brands', 'vk']} size="2x" fixedWidth style={{marginRight: '5px'}}/>
                            <div className={cn("p-float-label")} style={{width: '100%'}}>
                            <OkInputText
                                id={'vkontakte'}
                                type="text"
                                name={'vkontakte'}
                                validate={'url'}
                                message={'не верный формат URL'}
                                required={form.vk_url.required}
                                value={form.vk_url.value}
                                onChange={(e) => {
                                    geoStore.setFormFieldValue('vk_url', e.target.value)
                                }}
                            />
                                <label htmlFor="vkontakte">ВКонтакте</label>
                            </div>

                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <div className={cn('text-field')} style={{display: 'flex', alignItems: 'center'}}>
                            <FontAwesomeIcon icon={['brands', 'youtube']} size="2x" fixedWidth style={{marginRight: '5px'}}/>
                            <div className={cn("p-float-label")} style={{width: '100%'}}>
                                <OkInputText
                                    id={'youtube'}
                                    type="text"
                                    name={'youtube'}
                                    validate={'url'}
                                    message={'не верный формат URL'}
                                    required={form.youtube_link.required}
                                    value={form.youtube_link.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('youtube_link', e.target.value)
                                    }}
                                />
                                <label htmlFor="youtube">Youtube</label>
                            </div>

                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <div className={cn('text-field')} style={{display: 'flex', alignItems: 'center'}}>
                            <FontAwesomeIcon icon={['brands', 'odnoklassniki']} size="2x" fixedWidth style={{marginRight: '5px'}}/>
                                <div className={cn("p-float-label")} style={{width: '100%'}}>
                                    <OkInputText
                                        id={'odnoklassniki'}
                                        type="text"
                                        name={'odnoklassniki'}
                                        validate={'url'}
                                        message={'не верный формат URL'}
                                        required={form.ok_link.required}
                                        value={form.ok_link.value}
                                        onChange={(e) => {
                                            geoStore.setFormFieldValue('ok_link', e.target.value)
                                        }}
                                    />
                                    <label htmlFor="odnoklassniki">Одноклассники</label>
                                </div>

                        </div>
                    </div>


                    <div className={cn("form__row")}>
                        <div className={cn('text-field')} style={{display: 'flex', alignItems: 'center'}}>
                            <FontAwesomeIcon icon={['brands', 'x-twitter']} size="2x" fixedWidth style={{marginRight: '5px'}}/>
                            <div className={cn("p-float-label")} style={{width: '100%'}}>
                                <OkInputText
                                    id={'twitter'}
                                    type="text"
                                    name={'twitter'}
                                    validate={'url'}
                                    message={'не верный формат URL'}
                                    required={form.twitter_link.required}
                                    value={form.twitter_link.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('twitter_link', e.target.value)
                                    }}
                                />
                                <label htmlFor="twitter">Twitter</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default inject('geoStore')(observer(SocialMediaInfo));