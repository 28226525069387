import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { useIsMount } from '../../../hooks/index';
import './SlideNav.scss';

/**
 *
 * @param handleChangeNav
 * @param {Array} data [{'branches': 'Филиалы'},{'managers': 'Сотрудники'}]
 * @param {String} value
 * @param {Boolean} disabled
 * @returns {JSX.Element}
 * @constructor
 */
const SlideNav = ({ handleChangeNav, data = [], value= '', disabled = false}) => {
    const nav = useRef();
    const slide = useRef();
    const [valueIndex, setValueIndex] = useState(null);
    const isMount = useIsMount();

    useEffect(()=> {
        data.map((item, index) => {
            Object.keys(item)[0] === value && setValueIndex(index);
        });
    }, []);

    useEffect(() => {
        if(!isMount) {
            setTimeout(()=>{
                const { offsetWidth, clientHeight, offsetLeft } = nav.current.childNodes[valueIndex];
                slide.current.style.width = (offsetWidth + 20) + 'px';
                slide.current.style.height = clientHeight + 'px';
                slide.current.style.left = (offsetLeft - 20) + 'px';
            }, 100)
        }
    }, [valueIndex]);

    const handleClick = (e, href) => {
        const { clientWidth, clientHeight, offsetLeft } = e.currentTarget;

        nav.current.childNodes.forEach(item => {
            if (item.className === 'active') item.className = '';
        })

        e.currentTarget.className = "active";

        slide.current.style.width = (clientWidth + 20) + 'px';
        slide.current.style.height = clientHeight + 'px';
        slide.current.style.left = (offsetLeft - 20) + 'px';
        handleChangeNav(href);
    }

    return (
         <div className={cn('filter-slider', {'disabled': disabled})}>
             <ul className="nav" ref={nav}>
                {data.map((item, index) => (
                    Object.keys(item).map((objId) => (
                        <li
                            className={cn({'active': index === valueIndex})}
                            key={objId}
                            onClick={(e) => {
                                !disabled && handleClick(e, objId)}
                            }
                        >
                            <a>{item[objId]}</a>
                        </li>
                    ))
                ))}
                <li className="slide1" ref={slide} />
            </ul>
        </div>
    )
}


export default SlideNav;
