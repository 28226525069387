import authService from "./auth";
import { IntegrationModel } from "../models/integration";
import _ from "underscore";

class IntegrationService {
    #sources = [];

    get sources() {
        return this.#sources;
    }

    set sources(sources) {
        this.#sources = sources;
    }

    getList = () => {
        return authService.get(
            `/branches/adplatforms`,
        )
            .then((result) => result.map(item => new IntegrationModel(item)))
            .catch(e => e);
    }

    getBranch = () => {
        return authService
            .get(`/branches`)
            .then((data) => data);
    }

    getSources = () => {
        if (this.sources.length > 0) {
            return Promise.resolve(this.sources);
        } else {
            return authService.get(
                `/extreview/integrations`,
            ).then((result) => {
                this.sources = result;
                return this.sources;
            });
        }
    }

    parseFilterToApi(filter) {
        //это поле парсим если в нем только одно значение.
        const BINARY_FIELD = 'answer';

        let newObj = { };

        _.mapObject(filter, (val, key) => {
            if (_.isArray(val) && val.length > 0 && key !== BINARY_FIELD) {
                newObj[key] = filter[key].map(val=> val.value).join(',');
            }
            if (key === BINARY_FIELD && val.length === 1) {
                newObj[key] = filter[key].map(val=> val.value).join(',');
            }
        });

        return newObj;
    }

    exportXls = (filter) => {
        return authService.post(
            '/branches/adplatforms/export',
            this.parseFilterToApi(filter)
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }
}

const integrationService = new IntegrationService();
export default integrationService;

