import cn from "classnames";
import React from "react";

import kub from '../../../images/dashboard/kub.png';
import plus from '../../../images/dashboard/ico_plus.png';
import hand_down from '../../../images/dashboard/ico_hand_down.png';
import ico_face_neutral from '../../../images/dashboard/ico_face_neutral.png';
import ico_face_pasitive from '../../../images/dashboard/ico_face_pasitive.png'
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

const RatingBlock = ({data}) => {
    const positiveFeedback = (total = 0, good = 0) => !!total ? (good/total*100).toFixed(0)+'%' : 0+'%';
    return (
        <>
            <Link to={'/analytics/applications'}>
            {!isMobile ?
                <div className={cn('row', 'rating')}>
                    <div className={cn('col', 'col__w50', 'col__w50_min-width')}>
                    <fieldset className={'ml_0'}>
                        <legend>Рейтинг филиалов</legend>
                        {!!data.winners.branches.length ?
                            <>
                                {data.winners.branches.map((item, key) => {
                                    return (
                                        <div className={cn('row', 'row__md_10')} key={key}>
                                            <div className={cn('col', 'col__w68', 'col__w70_min-width', 'col__ovh')}>
                                                <span className={cn('ico', 'ico__kubok')}>
                                                    <img src={kub} alt="" width={17} height={17}/>
                                                </span>
                                                {item.branch.title}
                                            </div>
                                            <div className={cn('col', 'col__w10', 'col__w10_min-width', 'col__percent')}>
                                                <span className={cn('ico')}>
                                                    <img src={ico_face_pasitive} alt="" width={17} height={17}/>
                                                </span>
                                                {positiveFeedback(item.reviews.total, item.reviews.good)}
                                            </div>
                                            <div className={cn('col', 'col__w10', 'col__w10_min-width')}>
                                            <span className={cn('ico')}>
                                                <img src={plus} alt="" width={17} height={17}/>
                                            </span>
                                                {item.applications.total}
                                            </div>
                                            <div className={cn('col', 'col__w10', 'col__w10_min-width')}>
                                            <span className={cn('ico')}>
                                                <img src={ico_face_neutral} alt="" width={17} height={17}/>
                                            </span>
                                                {item.rates.nps}
                                            </div>
                                        </div>
                                    )
                                })}
                                {data.losers.branches.map((item, key) => {
                                    return (
                                        <div className={cn('row', 'row__md_10')} key={key}>
                                            <div className={cn('col', 'col__w68', 'col__w70_min-width', 'col__ovh')}>
                                                <span className={cn('ico', 'ico__hand_down')}>
                                                    <img src={hand_down} alt="" width={17} height={17}/>
                                                </span>
                                                {item.branch.title}
                                            </div>
                                            <div className={cn('col', 'col__w10', 'col__w10_min-width', 'col__percent')}>
                                                <span className={cn('ico')}>
                                                    <img src={ico_face_pasitive} alt="" width={17} height={17}/>
                                                </span>
                                                {positiveFeedback(item.reviews.total, item.reviews.good)}
                                            </div>
                                            <div className={cn('col', 'col__w10', 'col__w10_min-width')}>
                                                <span className={cn('ico')}>
                                                    <img src={plus} alt="" width={17} height={17}/>
                                                </span>
                                                {item.applications.total}
                                            </div>
                                            <div className={cn('col', 'col__w10', 'col__w10_min-width')}>
                                                <span className={cn('ico')}>
                                                    <img src={ico_face_neutral} alt="" width={17} height={17}/>
                                                </span>
                                                {item.rates.nps}
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                            :
                            <div className={cn('empty_data')}>нет данных</div>
                        }
                    </fieldset>
                </div>
                    <div className={cn('col', 'col__w50', 'col__w50_min-width')}>
                    <fieldset className={'mr_0'}>
                        <legend>Рейтинг сотрудников</legend>
                        {!!data.winners.managers.length ?
                            <>
                                {data.winners.managers.map((item, key) => {
                                    return (
                                        <div className={cn('row', 'row__md_10')} key={key}>
                                            <div className={cn('col', 'col__w68', 'col__w70_min-width', 'col__ovh')}>
                                                <span className={cn('ico', 'ico__kubok')}>
                                                    <img src={kub} alt="" width={17} height={17}/>
                                                </span>
                                                {item.manager.name}
                                            </div>
                                            <div className={cn('col', 'col__w10', 'col__w10_min-width', 'col__percent')}>
                                                <span className={cn('ico')}>
                                                    <img src={ico_face_pasitive} alt="" width={17} height={17}/>
                                                </span>
                                                {positiveFeedback(item.reviews.total, item.reviews.good)}
                                            </div>
                                            <div className={cn('col', 'col__w10', 'col__w10_min-width')}>
                                                <span className={cn('ico')}>
                                                    <img src={plus} alt="" width={17} height={17}/>
                                                </span>
                                                {item.applications.total}
                                            </div>
                                            <div className={cn('col', 'col__w10', 'col__w10_min-width')}>
                                                <span className={cn('ico')}>
                                                    <img src={ico_face_neutral} alt="" width={17} height={17}/>
                                                </span>
                                                {item.rates.nps}
                                            </div>
                                        </div>
                                    )
                                })}
                                {data.losers.managers.map((item, key) => {
                                    return (
                                        <div className={cn('row', 'row__md_10')} key={key}>
                                            <div className={cn('col', 'col__w68', 'col__w70_min-width', 'col__ovh')}>
                                                <span className={cn('ico', 'ico__hand_down')}>
                                                    <img src={hand_down} alt="" width={17} height={17}/>
                                                </span>
                                                {item.manager.name}
                                            </div>
                                            <div className={cn('col', 'col__w10', 'col__w10_min-width', 'col__percent')}>
                                                <span className={cn('ico')}>
                                                    <img src={ico_face_pasitive} alt="" width={17} height={17}/>
                                                </span>
                                                {positiveFeedback(item.reviews.total, item.reviews.good)}
                                            </div>
                                            <div className={cn('col', 'col__w10', 'col__w10_min-width')}>
                                                <span className={cn('ico')}>
                                                    <img src={plus} alt="" width={17} height={17}/>
                                                </span>
                                                {item.applications.total}
                                            </div>
                                            <div className={cn('col', 'col__w10', 'col__w10_min-width')}>
                                                <span className={cn('ico')}>
                                                    <img src={ico_face_neutral} alt="" width={17} height={17}/>
                                                </span>
                                                {item.rates.nps}
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                            :
                            <div className={cn('empty_data')}>нет данных</div>
                        }
                    </fieldset>
                </div>
                </div> :
                <>
                    <div className={cn('row', 'rating', 'row__md_5')}>
                        <div className={cn('col')}>
                            <fieldset>
                                <legend>Рейтинг филиалов</legend>
                                {!!data.winners.branches.length ?
                                    <>
                                        {data.winners.branches.map((item, key) => {
                                            return (
                                                <div className={cn('row', 'row__md_5')} key={key}>
                                                    <div className={cn('col', 'col__ovh')}>
                                                        <span className={cn('ico', 'ico__kubok')}>
                                                            <img src={kub} alt="" />
                                                        </span>
                                                        {item.branch.title}
                                                    </div>

                                                    <div className={cn('col', 'col__percent')}>
                                                        <span className={cn('ico')}>
                                                           <img src={ico_face_pasitive} alt="" />
                                                        </span>
                                                        {positiveFeedback(item.reviews.total, item.reviews.good)}
                                                    </div>
                                                    <div className={cn('col')}>
                                                        <span className={cn('ico')}>
                                                            <img src={plus} alt=""/>
                                                        </span>
                                                        {item.applications.total}
                                                    </div>
                                                    <div className={cn('col')}>
                                                        <span className={cn('ico')}>
                                                            <img src={ico_face_neutral} alt="" />
                                                        </span>
                                                        {item.rates.nps}
                                                    </div>
                                                    <br/>
                                                </div>
                                            )
                                        })}
                                        {data.losers.branches.map((item, key) => {
                                            return (
                                                <div className={cn('row', 'row__md_5')} key={key}>
                                                    <div className={cn('col', 'col__ovh')}>
                                                        <span className={cn('ico', 'ico__hand_down')}>
                                                            <img src={hand_down} alt=""/>
                                                        </span>
                                                        {item.branch.title}
                                                    </div>
                                                    <div className={cn('col', 'col__percent')}>
                                                        <span className={cn('ico')}>
                                                            <img src={ico_face_pasitive} alt="" />
                                                        </span>
                                                        {positiveFeedback(item.reviews.total, item.reviews.good)}
                                                    </div>
                                                    <div className={cn('col')}>
                                                        <span className={cn('ico')}>
                                                            <img src={plus} alt=""/>
                                                        </span>
                                                        {item.applications.total}
                                                    </div>
                                                    <div className={cn('col')}>
                                                        <span className={cn('ico')}>
                                                            <img src={ico_face_neutral} alt="" />
                                                        </span>
                                                        {item.rates.nps}
                                                    </div>
                                                    <br/>
                                                </div>
                                            )
                                        })}
                                    </>
                                    :
                                    <div className={cn('empty_data')}>нет данных</div>
                                }
                            </fieldset>
                        </div>
                    </div>
                    <div className={cn('row', 'rating')}>
                        <div className={cn('col')}>
                            <fieldset>
                                <legend>Рейтинг сотрудников</legend>
                                {!!data.winners.managers.length ?
                                    <>

                                        {data.winners.managers.map((item, key) => {
                                            return (
                                                <div className={cn('row', 'row__md_5')} key={key}>
                                                    <div className={cn('col', 'col__ovh')}>
                                                        <span className={cn('ico', 'ico__kubok')}>
                                                            <img src={kub} alt=""/>
                                                        </span>
                                                        {item.manager.name}
                                                    </div>
                                                    <div className={cn('col', 'col__percent')}>
                                                        <span className={cn('ico')}>
                                                            <img src={ico_face_pasitive} alt=""/>
                                                        </span>
                                                        {positiveFeedback(item.reviews.total, item.reviews.good)}
                                                    </div>
                                                    <div className={cn('col')}>
                                                        <span className={cn('ico')}>
                                                            <img src={plus} alt=""/>
                                                        </span>
                                                        {item.applications.total}
                                                    </div>
                                                    <div className={cn('col')}>
                                                        <span className={cn('ico')}>
                                                            <img src={ico_face_neutral} alt=""/>
                                                        </span>
                                                        {item.rates.nps}
                                                    </div>
                                                    <br/>
                                                </div>
                                            )
                                        })}

                                        {data.losers.managers.map((item, key) => {
                                            return (
                                                <div className={cn('row', 'row__md_10')} key={key}>
                                                    <div className={cn('col', 'col__ovh')}>
                                                        <span className={cn('ico', 'ico__hand_down')}>
                                                            <img src={hand_down} alt=""/>
                                                        </span>
                                                        {item.manager.name}
                                                    </div>

                                                    <div className={cn('col', 'col__percent')}>
                                                        <span className={cn('ico')}>
                                                            <img src={ico_face_pasitive} alt="" />
                                                        </span>
                                                        {positiveFeedback(item.reviews.total, item.reviews.good)}
                                                    </div>
                                                    <div className={cn('col')}>
                                                        <span className={cn('ico')}>
                                                            <img src={plus} alt=""/>
                                                        </span>
                                                        {item.applications.total}
                                                    </div>
                                                    <div className={cn('col')}>
                                                        <span className={cn('ico')}>
                                                            <img src={ico_face_neutral} alt=""/>
                                                        </span>
                                                        {item.rates.nps}
                                                    </div>
                                                    <br/>

                                                </div>
                                            )
                                        })}
                                    </>
                                    :
                                    <div className={cn('empty_data')}>нет данных</div>
                                }

                            </fieldset>
                        </div>
                    </div>
                </>}
            </Link>
        </>
    )
}

export default RatingBlock;
