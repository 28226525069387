import moment from "moment";
import AdPlatform from "./ad-platform";
import Branch from "./branch";
import Author from "./author";
import Claim from "./claim";
import Tag from "./tag";
import ReviewAnswerParams from "./review-answer-params";

/**
 * @typedef {string} ExternalReviewType
 **/

/**
 * @readonly
 * @enum {ExternalReviewType}
 */
export const EXTERNAL_REVIEW_TYPE = {
    REVIEW: 'review',
    APPLICATION: 'application',
};
/**
 *
 */
export default class ExternalReview {
    constructor(review) {

        this._id  = review.id || null;
        this._type  = review.type || null;
        this._adplatform = (review.adplatform) ? new AdPlatform(review.adplatform) : {};
        this._url = review.url || null;
        this._subsourceCode = review.subsource_code || null;
        this._matchingApp = review.matchingApp || null;
        this._branches = (review.branches) ? review.branches.map(b => new Branch(b)) : [];
        this._attributes = review._attributes || [];
        this._tags = (review.tags) ? review.tags.map(t => new Tag(t)) : [];
        this._autoTags = (review.autotags) ?  review.autotags.map(t => new Tag(t)) : [];
        this._author = (review.author) ? new Author(review.author) : {};
        this._score = review.score || null;
        this._text = review.text || null;
        this._attaches = review.attaches || [];
        this._hasAnswerVariants = review.hasAnswerVariants || false;
        this._answer = (review.answer) ? new ReviewAnswerParams(review.answer) : {};
        this._claim = (review.claim) ? new Claim(review.claim) : {};
        this._published = review.published || false;
        this._unrated = review.unrated || false;
        this._sentiment = review.sentiment || '';
        this._postedAt = review.postedAt || null;
        this._createdAt = review.createdAt || null;
        this._updatedAt = review.updatedAt || null;
        this._deletedAt = review.deletedAt || null;
    }

    //_attributes

    mockAutoTag() {
        function getRandomInt(max) {
            return Math.floor(Math.random() * max);
        }

        const list = [
            {id:1, title: 'автотег 1'},
            {id:2, title: 'автотег 2'},
            {id:3, title: 'автотег 3'},
            {id:4, title: 'автотег 4'},
            {id:5, title: 'автотег 5'},
        ];

        return list.map(item=>{
            if(getRandomInt(5)%2) {
                return item
            }
        }).filter(item => item !== undefined);
    }

    /**
     * @return {String}
     */

    get sentiment() {
        return this._sentiment;
    }

    /**
     * @return {boolean}
     */
    get hasAnswerVariants() {
        return this._hasAnswerVariants;
    }

    /**
     * @return {int|null}
     */
    get id() {
        return this._id;
    }

    /**
     * @return {ExternalReviewType|null}
     */
    get type() {
        return this._type;
    }

    /**
     * @return {AdPlatform}
     */
    get adplatform() {
        return this._adplatform;
    }

    /**
     * @return {Array<Branch>}
     */
    get branches() {
        return this._branches;
    }

    /**
     *
     * @returns {[]}
     */
    get attributes() {
        return this._attributes;
    }

    /**
     *
      * @returns {Array<Tag>}
     */
    get tags() {
        return this._tags;
    }

    /**
     *
     * @returns {Array<Tag>}
     */
    get autoTags() {
        return this._autoTags;
    }

    /**
     * @return {Author}
     */
    get author() {
        return this._author;
    }

    /**
     * @return {int|null}
     */
    get score() {
        return this._score;
    }

    /**
     * @return {string|null}
     */
    get text() {
        return this._text;
    }

    /**
     * @return {Array<string>}
     */
    get attaches() {
        return this._attaches;
    }

    /**
     * @return {ReviewAnswerParams}
     */
    get answer() {
        return this._answer;
    }

    /**
     *
     * @returns {Claim|{}}
     */
    get claim() {
        return this._claim;
    }


    /**
     * @return {boolean}
     */
    get published() {
        return this._published;
    }

    /**
     * @return {boolean}
     */
    get unrated() {
        return this._unrated;
    }


    /**
     * @return {string}
     */
    get postedAt() {
        return this._postedAt ? moment(this._postedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }
    get postedAtRaw() {
        return this._postedAt;
    }

    /**
     *
     * @returns {string|string}
     */
    get createdAt() {
        return this._createdAt ? moment(this._createdAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     *
     * @returns {string|string}
     */
    get createdAtRaw() {
        return this._createdAt;
    }


    /**
     * @return {string}
     */
    get updatedAt() {
        return this._updatedAt ? moment(this._updatedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     *
     * @returns {string|null}
     */
    get deletedAt() {
        return this._deletedAt;
    }



    /**
     * @returns {string|null}
     */
    get url() {
// если нет основного url показываем url альтернативной платформы
        let url = (this._url) ?  this._url : this.adplatform.url;
// если тип отзыва 'application' (добавлен с сайта okreview) в url ставим ссылку на детальную странцу заявки
        (this.type === 'application') && (url = `/application/detail/${this.id}`);

        return url || null;
    }

    /**
     * @returns {string|null}
     */
    get subsourceCode() {
        return this._subsourceCode;
    }

    /**
     * @returns {string|null}
     */
    get matchingApp() {
        return this._matchingApp;
    }

    get isShortBranchesBlock () {
        return !(this.answer.official || this.review?.type === 'application');
    }
}
