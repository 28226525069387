import React, { useState} from "react";
import cn from "classnames";
import {ContactEmailSingle} from "./ContactEmail";

const ContactInfo = () => {

    const [contactPhoneSingleValue, setContactPhoneSingleValue] = useState('');

    const ContactPhoneOnChange = (value) => {
        //setContactPhoneSingleValue(value[0])
    }

    return (
        <>
            <div className={cn('block')}>
                <div className={cn('block__title')}>
                    <h3>Электронная почта</h3>
                </div>
                <div className={cn('block__form')}>

                    <ContactEmailSingle
                        fieldName={'emails'}
                    />

{/*
                    <div className={cn("form__row")}>
                        <div className={cn('title-field')}>
                            <h4>Настройка площадок</h4>
                        </div>
                    </div>

                    <div className={cn("form__row")}>
                        <Setup
                            //disabled={!contactPhoneSingleValue.length}
                            fields={
                                [
                                    new TopFormFields({
                                        fieldName: 'email',
                                        code: 'emails',
                                        fieldLabel: 'Email адрес',
                                        fieldType: 'dynamic-text',
                                        fieldValue: '',
                                        required: false
                                    }),

                                ]
                            }
                            code={"contactInfoSetup"}
                        />
                    </div>
*/}


                </div>
            </div>
        </>
    )
}

export {
    ContactInfo,
};