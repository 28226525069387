import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import './StatusTag.scss';

/**
 *
 * @param status текущий статус
 * @param statusesList список доступных статусов
 * @param changeHandler обработчик изменения
 * @returns {JSX.Element}
 * @constructor
 */
const StatusTag = ({status = {}, statusesList = [], changeHandler = () => {}}) => {

    const buttonRef = useRef();
    const buttonRef2 = useRef();

    const [openDropdown, setOpenDropdown] = useState(false);

    const clickOutsideHandler = (event) => {
        if (!buttonRef.current?.contains(event.target) && !buttonRef2.current?.contains(event.target)) {
            setOpenDropdown(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', clickOutsideHandler, false);
        return () => {
            document.removeEventListener('click', clickOutsideHandler, false);
        }
    }, []);

    return (
        <div className={cn('status')}>
            <span className={cn('p-tag', 'application-tag', {'application-tag__button': !!statusesList.length})}
                  style={{
                      'color': status.foreground,
                      'backgroundColor': status.background
                  }}
                  ref={buttonRef2}
                  onClick={() => {
                      setOpenDropdown(openDropdown => !openDropdown);
                  }}
            >
                {status.title}
            </span>
            {!!statusesList.length && <>
                <span
                    className={cn('status__button')}
                    ref={buttonRef}
                    onClick={() => {
                        setOpenDropdown(openDropdown => !openDropdown);
                    }}
                >
                    <i className="pi pi-angle-down" />
                </span>

                {openDropdown && <div className={cn('status__dropdown')}>
                    <ul>
                        {statusesList.map((item, key)=>{
                            return (
                                <li key={key} className={cn('status__item')}>
                                    <span className={cn('p-tag', 'application-tag')}
                                          style={{
                                              'color': item.foreground,
                                              'backgroundColor': item.background
                                          }}
                                          onClick={() => {
                                              changeHandler(item);
                                          }}
                                    >{item.title}</span>
                                </li>
                            )
                        })}
                    </ul>
                </div>}
            </>}
        </div>
    )
}


export default StatusTag;
