import React from "react";
import {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {useParams} from "react-router";
import cn from "classnames";
import ReviewItem from "./ReviewItem";
import {Link} from "react-router-dom";

const ReviewDetail = ({reviewsStore, uiStore}) => {

    const {id} = useParams();
    const [review, setReview] = useState(null)
    const {isLoading} = reviewsStore;

    useEffect(() => {
        reviewsStore.loadReview(id)
            .then((result) => {
                setReview(result)
            })
            .catch((error)=>{
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.error.type,
                        detail: error.error.description,
                    }
                )
            });
    }, [])

    return (
        <>
            <div className={cn('page__review-detail', 'page')}>
                <div className={cn('title')}>
                    <div className={cn('form-btn__wrapper')}>
                        <Link to='/monitoring/list' className="p-button p-component back-btn" >
                            <i className="pi pi-angle-left"></i>
                        </Link>
                    </div>
                    <h1>Отзыв {id}</h1>
                </div>
                <div className={cn('review-list')} id="js-review-detail">
                    <div className={cn('review-list__table')}>
                        {isLoading && <div className={'loader'}/>}
                        <div className={cn('review-list__wrapper')}>
                            {review && <ReviewItem
                                data={review}
                                single={true}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default inject('reviewsStore', 'uiStore')(observer(ReviewDetail));
