import { AD_PLATFORM } from "../models/ad-platform";
import { Modal } from "antd";

/**
 * Plural forms for russian words
 *
 * @param {int} count quantity for word
 * @param {Array} words Array of words. Example: ['депутат', 'депутата', 'депутатов'], ['коментарий', 'коментария', 'комментариев']
 * @param {boolean} addCount
 * @return {String} Count + plural form for word
 * @author https://github.com/znechai
 */
const pluralize = (count, words, addCount = true) => {
    const cases = [2, 0, 1, 1, 1, 2];

    return (addCount ? count + ' ' : '') + words[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[Math.min(count % 10, 5)]];
};


const serialize = function (obj, prefix) {
    let str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                serialize(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}

export {
    pluralize,
    serialize
}

export const getDetailDataByPlatform = (type) => {
    const result = AD_PLATFORM.find(platform => platform.value === type);

    if(result) {
        return  result
    }else {
        return {
            "value": "",
            "name": "",
            "shortName": "",
            "ico": "",
            "color": "",
            "lightColor": ""
        }
    }
}
// для совместимости, потом уберу
export const sources = [...AD_PLATFORM];

export const sourcesForGeo = () => {
    return AD_PLATFORM.filter((item) => {
        return item.value === 'yandex' || item.value === 'google' || item.value === '2gis';
    })
};

/**
 *
 * пустой статус
 *     review_checking
 *     review_prepared
 *     review_posted
 *
 * опубликован
 *     review_published
 *
 * ошибки
 *     все остальное
 *
 * @param check_status статус побликации на авито
 * @returns {{title: string|null, avitopub_status: string|null}}
 */
export const getStatusAvitoPub = (check_status) => {
    const PUBLISHED_STATUSES = ['review_published'];
    const PROCESS_STATUSES = ['review_checking', 'review_prepared', 'review_posted'];

//TODO сократить до одного find
    if (PROCESS_STATUSES.find(item => item === check_status) && 'process' || !check_status) {
        return {
            avitopub_status: null,
            title: null,
        }
    } else if (PUBLISHED_STATUSES.find(item => item === check_status) && 'published') {
        return {
            avitopub_status: 'published',
            title: 'опубликован',
        }
    } else {
        return {
            avitopub_status: 'error',
            title: 'ошибка',
        }
    }
}

/**
 * Сравниваем две даты с округлением до часа >= 30min
 * @param date1
 * @param date2
 * @returns {{hours: *, months: *, minutes: *, days: *, years: *}}
 */
export const dateDiff = (date1, date2) => {

    let years = date1.diff(date2, 'year');
    date2.add(years, 'years');

    let months = date1.diff(date2, 'months');
    date2.add(months, 'months');

    let days = date1.diff(date2, 'days');
    date2.add(days, 'days');

    let hours = date1.diff(date2, 'hours');
    date2.add(hours, 'hours');

    let minutes = date1.diff(date2, 'minutes');

    if (Math.abs(minutes) >= 30) {
        hours -= 1
        minutes = 0
    }

    date2.add(minutes, 'minutes');

    return { years, months, days, hours, minutes };
}

/**
 * функция для поиска расхождений в массивах обьектов
 * @param {Array} arr1 что ищем
 * @param {Array} arr2 где ищем
 * @param {string} field
 * @returns {*[]}
 */
export const getUniqueObjectFromArray = (arr1 = [], arr2 = [], field = '') => {
    if (!field) return [];
    return arr1.filter((obj) => !arr2.some((obj2) => obj[field] === obj2[field]));
}

/**
 * удаляет обьект из массива обьектов
 * @param {Array} src
 * @param {Object} obj
 * @param {string} field
 * @returns {*[]}
 */
export const removeObjectFromArray = (src = [], obj = {}, field) => {
    if (!field) return [];
    let srcCopy = [...src];
    const indexRemoveTag = srcCopy.findIndex(tag => tag[field] === obj[field])
    srcCopy.splice(indexRemoveTag, 1);
    return srcCopy;
}

export const onConfirm = (props) => {
    const { confirm } = Modal;

    return new Promise((resolve, reject) => {
        confirm({
            ...props,
            onOk: () => {
                resolve(true)
            },
            onCancel: () => {
                reject(true)
            }
        })
    })
}
