import moment from "moment";

class FilterService {
    debounceMs = 1000;
    periods = {};

    constructor() {
        this.periods = {
            'today': this.getToday(),
            'yesterday': this.getYesterday(),
            'week': this.getCurrentWeek(),
            'month': this.getCurrentMonth(),
        };

    }

    getYesterday = () => {
        return [
            moment().subtract(1, 'day').toString(),
            moment().subtract(1, 'day').toString(),
        ];
    }

    getToday = () => {
        return [
            moment().toString(),
            moment().toString(),
        ];
    }

    getCurrentWeek = () => {
        return [
            moment().subtract(7, 'day').toString(),
            moment().toString(),
        ];
    }

    getCurrentMonth = () => {
        return [
            moment().subtract(1, 'month').toString(),
            moment().toString(),
        ];

    }

    /**
     *
     * @param arrObjects
     * @param key
     * @returns {*}
     */
    uniqObjectFromArray = (arrObjects, key) => {
        let tmp = [];

        const check = (i) => {
            if (tmp.indexOf(i[key]) === -1) {
                tmp.push(i[key]);
                return true;
            }
            return false;
        }

        return arrObjects.filter((i) => {
            return check(i);
        });
    }

}

const filterService = new FilterService();
export default filterService;
