import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";
import './PagerSizeSelector.scss';

const PagerSizeSelector = ({ setCount =()=>{}, options = [], value= 10 }) => {


    const [toggleDropdown, setToggleDropdown] = useState(false);
    const buttonRef = useRef();

    useEffect(() => {
        document.addEventListener('click', clickOutsideHandler, false);
        return () => {
            document.removeEventListener('click', clickOutsideHandler, false);
        }
    }, []);

    const clickOutsideHandler = (event) => {
        if (!buttonRef.current?.contains(event.target)) {
            setToggleDropdown(false);
        }
    }

    return (
        <div className={cn('pager-size')}>
            <div
                className={cn('pager-size__title')}
                onClick={() => setToggleDropdown(prev => !prev)}
                ref={buttonRef}
            >
                <span>показывать по {value}</span>
                <i className="pi pi-chevron-down"></i>
            </div>
            <div className={cn('pager-size__dropdown', {'active': toggleDropdown})}>
                <ul>
                    {options.map((item, key) => (
                        <li
                            key={key+item}
                            onClick={() => {
                                setCount(item)
                                setToggleDropdown(false)
                            }}
                        >{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default PagerSizeSelector;
