import authService from './auth';
import { action } from "mobx";
import { serialize } from '../helpers/index';
import {FilterModel, FilterDashboardModel} from "models/filter"
import ErrorService from "./error";

class ApplicationService {

    _applications = [];
    dashboard = {};

    get applications() {
        return this._applications;
    }

    set applications(applications) {
        this._applications = applications;
    }

    saveApplication = (data) => {
        return authService.post(
            '/applications',
            data
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(new ErrorService(e).getError())
            });
    }

    changeStatus = (applId, data) => {
        return authService.put(
            `/applications/${applId}/statuses/next`,
            data
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });

    }

    exportXls = (filter) => {
        return authService.post(
            '/applications/export',
            filter
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });

    }

    getPossibleStatuses = (applId) => {
        return authService.get(
            `/applications/${applId}/statuses/next`,
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }

    lentSendMessage = (applId, data) => {
        return authService.post(
            `/applications/${applId}/comments`,
            data
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(new ErrorService(e).getError())
            });
    }


    getEvents = (applId) => {
        return authService.get(
            `/applications/${applId}/events`,
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });

    }

    getApplications = (id) => {
        return authService.get(
            `/applications/${id}`,
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }

    @action getApplicationsList = (filter) => {
        const queryString = serialize(new FilterModel(filter).getToAPI());

        return authService.get(
            `/applications?${queryString}`,
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }

    @action getDashboard = (filter) => {
        const queryString = serialize(new FilterDashboardModel(filter).getToAPI());
        return authService.get(
            `/applications/dashboard?${queryString}`,
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }

    getNewNeNativeCounter = () => {
        return authService.get(
            `/applications/counters/new-negative`,
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }

    getNewNegativeList = (filter) => {
        return Promise.all([
            this.getDashboard(filter),
            this.getApplicationsList(filter),
        ]).then(([dashboard,applList]) => {
            return Promise.resolve({
                dashboard,
                applList
            });
        })
            .catch((e) => Promise.reject(e))

    }


}

const applicationService = new ApplicationService();
export default applicationService;
