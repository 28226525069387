import cn from "classnames";
import { ContactPhoneSingle } from "./ContactPhone";
import Setup from "../Setup";
import { TopFormFields } from "../../../../../stores/forms/topForm.store";
import React from "react";

const PhoneInfo = () => {

    const ContactPhoneOnChange = (value) => {
        //setContactPhoneSingleValue(value[0])
    }

    return (
        <>
            <div className={cn('block')}>
                <div className={cn('block__title')}>
                    <h3>Телефон</h3>
                </div>
                <div className={cn('block__form')}>

                    <ContactPhoneSingle
                        /*fieldName={'phones'}*/
                        fieldName={'phones'}
                        onChange={ContactPhoneOnChange}
                    />

                    <div className={cn("form__row")}>
                        <div className={cn('title-field')}>
                            <h4>Настройка площадок</h4>
                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <Setup
                            //disabled={!contactPhoneSingleValue.length}
                            fields={
                                [
                                    new TopFormFields({
                                        fieldName: 'phone',
                                        code: 'phones',
                                        fieldLabel: 'Номер телефона',
                                        fieldType: 'dynamic-text',
                                        fieldValue: '',
                                        required: false
                                    }),
                                ]
                            }
                            code={"phoneInfoSetup"}
                        />
                    </div>
                </div>
            </div>
        </>
    )

}


export {
    PhoneInfo,
};