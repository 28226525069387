export class IntegrationModel {
    id;
    title;
    qrid;
    adplatforms;

    constructor(object) {
        this.id = object.id || null;
        this.title = object.title || '';
        this.qrid = object.qrid || '';
        this.adplatforms = object.adplatforms.map(i => new AdplatformModel(i || {})) || [];
    }
}

class AdplatformModel {
    id;
    title;
    type;
    accountUrl;
    connections;

    constructor(object) {
        this.id = object.id || null
        this.title = object.title || ''
        this.type = new TypeModel(object.type || {});
        this.accountUrl = object.accountUrl || '';
        this.connections = new ConnectionsModel(object.connections || {})
    }
}

class TypeModel {
    code;
    name;

    constructor(object) {
        this.code = object.code || ''
        this.name = object.name || ''
    }
}

class ConnectionsModel {
    collector;
    parser;
    publisher;
    needAuth;
    notConnected;

    constructor(object) {
        this.collector = object.collector ? new CollectorModel(object.collector) : null;
        this.parser = object.parser ? new CollectorModel(object.parser) : null;
        this.publisher = object.publisher ? new CollectorModel(object.publisher) : null;
        this.needAuth = this.setNeedAuth();
        this.notConnected = this.setNotConnected();
    }

    setNotConnected() {
        return !!((!this.parser && !this.needAuth) && (!this.publisher || !this.collector));
    }

    setNeedAuth() {
        return !!(!this.parser && this.collector);
    }

}

class CollectorModel {
    enabled;
    auth;
    error;
    updatedAt;
    status;

    constructor(object) {
        this.enabled = object.enabled || null;
        this.auth = object.auth || null;
        this.error = object.error || '';
        this.updatedAt = object.updatedAt || null;
        this.status = object.status || '';
    }
}
