import cn from "classnames";
import React from "react";
import { toNumber } from "@amcharts/amcharts4/.internal/core/utils/Type";

const RatingBlock = ({rating, className = '', suf= ''}) => {

    const colorClass =  toNumber(rating) >= 0 ? 'color_green' : 'color_red';

    if(toNumber(rating) > 0) {
        rating = `+${rating}`;
    }
    return (
        <span className={cn(colorClass, className)}>{rating} {suf}</span>
    )
}


export default RatingBlock;
