import cn from "classnames";
import React from "react";

const DiffAwgTimeReply = ({ data }) => {
    let diffAwgTimeReply = 0;
    let diffAwgTimeReplyString = '';

    if (data.period.avgTimeReply) {
        diffAwgTimeReply = Math.round(data.period.avgTimeReply / 60 / 60);
        if (diffAwgTimeReply <= 48) {
            diffAwgTimeReplyString = <span className={cn('diff__up')}>({diffAwgTimeReply} ч)</span>
        } else if (data.period.avgTimeReply === 0) {
            diffAwgTimeReplyString = <span className={cn('text-color--gray')}>({diffAwgTimeReply} ч)</span>
        } else {
            diffAwgTimeReplyString = <span className={cn('diff__down')}>({diffAwgTimeReply} ч)</span>
        }
    }

    return (
        <>
            <span
                className={cn({ 'text-color--gray': !Math.round(data.current.avgTimeReply / 60 / 60) })}>{Math.round(data.current.avgTimeReply / 60 / 60)} ч</span> {diffAwgTimeReplyString}
        </>
    )
}

export default DiffAwgTimeReply;