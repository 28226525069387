import authService from "./auth";
import {ApplicationStatusModel} from "../models/application-status";

class ApplicationStatusService {

    _applicationStatuses = []

    get applicationStatuses() {
        return this._applicationStatuses;
    }

    set applicationStatuses(applicationStatuses) {
        this._applicationStatuses = applicationStatuses;
    }

    getApplicationStatus = () => {

        if (this.applicationStatuses.length > 0) {
            return Promise.resolve(this.applicationStatuses);
        } else {
            return authService.get(
                `/applications/statuses`,
            ).then((result) => {
                this.applicationStatuses = result.map(applicationStatus => {
                    return new ApplicationStatusModel(applicationStatus);
                })
                return this.applicationStatuses;
            });
        }

    }
}

const applicationStatusService = new ApplicationStatusService();
export default applicationStatusService;