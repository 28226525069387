import cn from "classnames";
import ico2w from "../../../images/dashboard/dashboard_ico_new2-w.svg";
import CountUp from "react-countup";
import ico5w from "../../../images/dashboard/dashboard_ico_new5-w.svg";
import ico3 from "../../../images/dashboard/dashboard_ico_new3.svg";
import React from "react";
import { Link } from "react-router-dom";

const QrTransitionDashboard = ({ value }) => {

    return (
        <>
            {value && <div className={cn('filter-content__dashboard')}>
                <>
                    <ul className={cn('block', 'block__block4')}>
                        <li style={{ width: '260px' }}>
                            <span>Всего перехвачено негатива</span>
                            <span>
                                <img src={ico2w} alt='Всего перехвачено негатива' title='Всего перехвачено негатива' width={25} height={25}/>
                                <CountUp end={value.reviews?.total} separator=" " duration={.5} className={'CountUp__counter'}/>
                            </span>
                        </li>
                    </ul>
                    <ul className={cn('block', 'block__block3')}>
                        <li style={{ width: '260px' }}>
                            <span>Перехвачено негатива за период</span>
                            <span>
                                <img src={ico5w} alt='Перехвачено негатива за период' title='Перехвачено негатива за период' width={25}
                                     height={25}/>
                                <CountUp end={value.reviews?.period} separator=" " duration={.5} className={'CountUp__counter'}/>
                            </span>
                        </li>
                    </ul>
                    <ul className={cn('block', 'block__block5')}>
                        <li style={{ width: '220px' }}>
                            <span>Все переходы</span>
                            <span>
                                <img src={ico3} alt='Все переходы' title='Все переходы' width={25}
                                     height={25}/>
                                <CountUp end={value.traffic?.total} separator=" " duration={.5} className={'CountUp__counter'}/>
                            </span>
                        </li>
                    </ul>
                    <Link to={'/analytics/qr'} className={cn('more')}>подробнее <span><i className="pi pi-arrow-right"></i></span></Link>
                </>
            </div>}
        </>
    )
}

export default QrTransitionDashboard;