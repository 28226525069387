import authService from "../services/auth";
import { serialize } from "../helpers";
import Tag from "../models/tag";

class TagService {
    /**
     * Список тегов
     * @returns {Promise<{pager: {}, sorting: {}, items: [Tag]}>}
     */
    getList(filter = {}) {
        const queryString = serialize(filter);
        return authService
            .get(`/extreview/tags?${queryString}`)
            .then((data) => {
                return {
                    items: data.items.map(item => new Tag(item)),
                    sorting: data.sorting,
                    pager: data.pager,
                };
            })
            .catch(e => {
                return Promise.reject(e);
            });
    }

    getAutoTagListForFilter(filter = {}) {
        const queryString = serialize(filter);
        return authService
            .get(`/extreview/autotags/filter?${queryString}`)
            .then((data) => {
                return {
                    items: data.items.map(item => new Tag(item)),
                    sorting: data.sorting,
                    pager: data.pager,
                };
            })
            .catch(e => {
                return Promise.reject(e);
            });

    }


    getListForFilter(filter = {}) {
        const queryString = serialize(filter);
        return authService
            .get(`/extreview/tags/filter?${queryString}`)
            .then((data) => {
                return {
                    items: data.items.map(item => new Tag(item)),
                    sorting: data.sorting,
                    pager: data.pager,
                };
            })
            .catch(e => {
                return Promise.reject(e);
            });

    }
    /**
     * Добавление тега
     * @param {String} title название тега
     * @returns {Promise<* | undefined>|Promise<never>}
     */
    addItem(title = '') {
        if (title.length <= 0) return Promise.reject('пустой тег');

        return authService
            .post(`/extreview/tags`, { title: title })
            .then((data) => {
                return data;
            })
            .catch(e => {
                return Promise.reject(e);
            });
    }

    /**
     * Список тегов отзыва
     * @param {number} id ид отзыва
     * @returns {Promise<{pager: {}, sorting: {}, items: [Tag]}>}
     */
    getListFromReview(id) {
        return authService
            .get(`/extreview/reviews/${id}/tags`)
            .then((data) => {
                return {
                    items: data.items.map(item => new Tag(item)),
                    sorting: data.sorting,
                    pager: data.pager,
                };
            })
            .catch(e => {
                return Promise.reject(e);
            });

    }

    /**
     * Установление связи тега и отзыва
     * @param {string} tagId
     * @param {number} reviewId
     * @returns {Promise<* | undefined>}
     */
    linkItem(tagId, reviewId) {
        return authService
            .post(`/extreview/tags/${tagId}/reviews/${reviewId}`)
            .then((data) => {
                return data;
            })
            .catch(e => {
                return Promise.reject(e);
            });
    }

    /**
     * Разрыв связи тега и отзыва
     * @param {string} tagId
     * @param {number} reviewId
     * @returns {Promise<* | undefined>}
     */
    removeLinkItem(tagId, reviewId) {
        return authService
            .delete(`/extreview/tags/${tagId}/reviews/${reviewId}`)
            .then((data) => {
                return data;
            })
            .catch(e => {
                return Promise.reject(e);
            });
    }

    /**
     * Массовое удаление тегов
     * @param {Array} ids
     * @returns {Promise<* | undefined>}
     */
    delList(ids = []) {
        return authService
            .post(`/extreview/tags/delete`, { ids })
            .then((data) => {
                return data;
            })
            .catch(e => {
                return Promise.reject(e);
            });
    }

    /**
     * Редактирование тега
     * @param {Number} id
     * @param {String} title
     * @returns {Promise<never>|Promise<* | undefined>}
     */
    editTag(id = null, title = '') {
        if (title.length <= 0) return Promise.reject('пустой тег');

        return authService
            .put(`/extreview/tags/${id}`, { title: title })
            .then((data) => {
                return data;
            })
            .catch(e => {
                return Promise.reject(e);
            });
    }

    /**
     * Удаление тега
     * @param {Number} tagId
     * @returns {Promise<* | undefined>}
     */
    delItem(tagId = null) {
        return authService
            .delete(`/extreview/tags/${tagId}`)
            .then((data) => {
                return data;
            })
            .catch(e => {
                return Promise.reject(e);
            });

    }

    /**
     * Слияние тегов
     * @param {String} targetId
     * @param {Array} ids
     * @returns {Promise<* | undefined>}
     */
    mergeItem(targetId, ids) {
        return authService
            .post(`/extreview/tags/${targetId}/merge`, { ids: ids })
            .then((data) => {
                return data;
            })
            .catch(e => {
                return Promise.reject(e);
            });

    }



//Карточка тега
    getItem() {

    }
}

export default new TagService()