import React, {useEffect, useRef, useState} from "react";
import cn from "classnames";
import "./OkSplitButton.scss";
import {Link} from "react-router-dom";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 * TODO добавить в пропсы
 * иконку, позицию иконки, стиль кнопки
 * модель для выпадающего меню (должно быть динамическое)
 */
const OkSplitButton = ({model = []}) => {

    const buttonRef = useRef();
    const dropdownPlaceholder = useRef();

    const [openDropdown, setOpenDropdown] = useState(false);

    useEffect(() => {
        document.addEventListener("click", clickOutsideHandler, false);

        return () => {
            document.removeEventListener("click", clickOutsideHandler, false);
        }
    }, []);

    const clickOutsideHandler = (event) => {
        if (!buttonRef.current?.contains(event.target)) {
            setOpenDropdown(false);
        }
    }

    return (
        <>
            <div className={cn("split-button__wrapper")} ref={buttonRef}>
                <div className={cn("split-button")}
                     onClick={() => {
                         setOpenDropdown(openDropdown => !openDropdown);
                     }}
                >
                    <span className={cn("label")}>Удалить</span>
                    <span className={cn("button-icon-right", "pi pi-angle-down")}></span>
                </div>
                <div
                    className={cn("split-button__dropdown", {"active": openDropdown})}
                    ref={dropdownPlaceholder}>
                    <ul>
                        {model.map((item, key) => {
                            return <li
                                key={key}
                                onClick={(e) => {
                                    item.command(e)
                                    setOpenDropdown(false)
                                }}
                            >{item.name}</li>
                        })}
                    </ul>
                </div>

            </div>
        </>
    )
}

export default OkSplitButton;