import { serialize } from "helpers";
import authService from "../services/auth";
import ErrorService from "./error";

class DashboardService {

    getRatingBlockData = (filter) => {
        const queryString = serialize(filter);
        return authService.get(
            `/applications/report/honor-roll?${queryString}`
        )
            .then((result) => result)
            .catch(e => e);
    }

    getRequestBlockData = (filter) => {
        const queryString = serialize(filter);
        return authService.get(
            `/applications/report/total-dynamic?${queryString}`
        )
            .then((result) => result)
            .catch(e => e);
    }

    getIntegrationBlockData = (filter) => {
        const queryString = serialize(filter);
        return authService.get(
            `/branches/adplatforms/total?${queryString}`
        )
            .then((result) => result)
            .catch(e => e);
    }

    getReputationBlockData = (filter) => {
        const queryString = serialize(filter);
        return authService.get(
            `/extreview/reviews/report/branches-rating-counter?${queryString}`
        )
            .then((result) => result)
            .catch(e => e);
    }

    /**
     *
     * @param year
     * @param month
     * @returns {Promise<unknown>}
     */
    exportPdf = ({ year, month }) => {

        return authService.post(
            `/applications/report/month`,
            { year, month }
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(new ErrorService(e).getError('Внимание', e.error.description))
            });
    }
}

const dashboardService = new DashboardService();
export default dashboardService;
