import { action, observable } from "mobx";

class DevelopersTagStore {
    @observable isDeveloper;

    /**
     *
     * @param {Boolean} isDeveloper
     */
    @action setDevAccess(isDeveloper) {
        this.isDeveloper = isDeveloper;
    }
}
const developersTagStore = new DevelopersTagStore();
export default developersTagStore;
