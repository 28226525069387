import cn from "classnames";
import React from "react";

const DiffPercentString = ({ data }) => {
    let diffPercent = 0;
    let diffPercentString = '';
    if (data.diff.reviews) {
        diffPercent = (data.diff.responded / data.diff.reviews * 100).toFixed();
        if (diffPercent >= 75) {
            diffPercentString = <span className={cn('diff__up')}>({diffPercent}%)</span>
        } else if (!diffPercent) {
            diffPercentString = <span className={cn('text-color--gray')}>({diffPercent}%)</span>
        } else {
            diffPercentString = <span className={cn('diff__down')}>({diffPercent}%)</span>
        }
    }

    return (
        <>
            {data.close.reviews ? <span>{((data.close.responded / data.close.reviews) * 100).toFixed()}%</span> : <span className={cn('text-color--gray')}>0%</span>}&nbsp;{diffPercentString}
        </>
    );
}
export default DiffPercentString;