import { inject, observer } from "mobx-react";
import cn from "classnames";
import React from "react";

const Column = inject('qrTransitionStore')(observer(({ className, field, header, sortable, ico, qrTransitionStore })=>{
    const defaultSort = 'pi-sort-alt';

    return (
        <>
            {sortable ?
                <th className={cn('p-sortable-column', className)} aria-sort="none" onClick={() => {

                    const newSortOrder = (qrTransitionStore.filter.sort.order === 'pi-sort-alt') ? 'asc' : (qrTransitionStore.filter.sort.order === 'asc') ? 'desc' : 'asc';

                    qrTransitionStore.setFieldValue('sort', {
                        column: field, order: newSortOrder
                    });

                }}
                >
                    <span className="p-column-title">{header}</span>
                    <span
                        className={cn("p-sortable-column-icon", "pi", "pi-fw", (field === qrTransitionStore.filter.sort.column) ?
                            qrTransitionStore.filter.sort.order :
                            defaultSort
                        )}
                    />

                </th>
                :
                <th className={cn('p-sortable-column', className)} aria-sort="none">
                    {ico ?
                        <div className={cn('ico__wrapper')}>
                            <div className={cn('comments-count__ico')}>
                                <img src={ico} width={52} height={22} alt="Заметки" title="Заметки"/>
                            </div>
                        </div>
                        :
                        <span className="p-column-title">{header}</span>
                    }
                </th>
            }

        </>
    );
}));

export default Column;