export default class Tag {
    #id;
    #title;
    #count;

    constructor(tag) {
        this.#id = tag.id || null;
        this.#title = tag.title || '';
        this.#count = tag.count || 0;
    }

    /**
     * @returns {number|null}
     */
    get id() {
        return this.#id;
    }

    /**
     *
     * @returns {string}
     */
    get title() {
        return this.#title;
    }

    /**
     * @returns {number}
     */
    get count() {
        return this.#count;
    }


}