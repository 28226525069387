import tagService from "../services/tag";
import { action, observable } from "mobx";


class TagStore {
    #tagService;

    @observable tags;

    @observable tagsListForFilter;

    @observable tagsListAutoForFilter;

    @observable filter = {
        query: '',
        sort: {
            column: 'title',
            order: 'desc'
        },
        page: {
            size: 100,
            number: 1
        }
    }

    constructor(tagService) {
        this.#tagService = tagService;
    }

    @action setFieldValue = (fieldName, fieldValue) => {
        if (this.filter.hasOwnProperty(fieldName)) {
            this.filter[fieldName] = fieldValue;
        }
    }

    @action setTagsListForFilter(tags = []) {
        this.tagsListForFilter = tags;
    }

    @action setTagsAutoListForFilter(tags= []) {
        this.tagsListAutoForFilter = tags;
    }

    @action setTags(tags = []) {
        this.tags = tags;
    }

    @action setAutoTags(tags = []) {
        this.autoTags = tags;
    }

    getTagList() {
        return this.getList()
            .then(result => {
                this.setTags(result.items);
                return result
            })
            .catch(e => {
                return Promise.reject(e);
            })
    }

    getAutoTagList() {
        return this.#tagService.getAutoTagListForFilter({
            ...this.filter,
            ...{attached: 1}
        })
            .then(result => {
                this.setTagsAutoListForFilter(result.items.map(tag => {
                    return { value: tag.id, name: tag.title }
                }))

                return result;
            })
            .catch(e => {
                return Promise.reject(e);
            })

    }

    getTagsForFilter() {
        return this.#tagService.getListForFilter({
            ...this.filter,
            ...{attached: 1}
        })
            .then(result => {
                this.setTagsListForFilter(result.items.map(tag => {
                    return { value: tag.id, name: tag.title }
                }))
                return result;
            })
            .catch(e => {
                return Promise.reject(e);
            })
    }

    /**
     * Список тегов
     * @returns {Promise<{pager: {}, sorting: {}, items: [Tag]}>}
     */
    getList() {
        return this.#tagService.getList(this.filter)
            .then(result => {
                return result;
            })
            .catch(e => {
                return Promise.reject(e);
            })
    }

    /**
     * добавление тега
     * @param {String} title
     */
    addItem(title = '') {
        return this.#tagService.addItem(title)
            .then(result => {
                return result;
            })
            .catch(e => {
                return Promise.reject(e);
            })
    }

    /**
     * Установление связи тега и отзыва
     * @param tagId
     * @param reviewId
     * @returns {Promise<T>}
     */
    linkItem(tagId, reviewId) {
        return this.#tagService.linkItem(tagId, reviewId)
            .then(result => {
                return result;
            })
            .catch(e => {
                return Promise.reject(e);
            })
    }

    /**
     * Разрыв связи тега и отзыва
     * @param tagId
     * @param reviewId
     * @returns {Promise<T>}
     */
    removeLinkItem(tagId, reviewId) {
        return this.#tagService.removeLinkItem(tagId, reviewId)
            .then(result => {
                return result;
            })
            .catch(e => {
                return Promise.reject(e);
            })
    }

    /**
     * Удаление тегов
     * @param {Array} ids
     * @returns {Promise<T>}
     */
    delList(ids) {
        return this.#tagService.delList(ids)
            .then(result => {
                return result;
            })
            .catch(e => {
                return Promise.reject(e);
            })
    }

    /**
     * Редактирование тега
     * @param {Number} id
     * @param {String} title
     * @returns {Promise<T>}
     */
    editTag(id, title) {
        return this.#tagService.editTag(id, title)
            .then(result => {
                return result;
            })
            .catch(e => {
                return Promise.reject(e);
            })
    }

    /**
     * Удаление тега
     * @param {Number} id
     * @returns {Promise<T>}
     */
    delItem(id) {
        return this.#tagService.delItem(id)
            .then(result => {
                return result;
            })
            .catch(e => {
                return Promise.reject(e);
            })
    }

    /**
     * Слияние тегов
     * @param {String} targetId
     * @param {Array} idx
     * @returns {Promise<T>}
     */
    mergeItem(targetId, idx) {
        return this.#tagService.mergeItem(targetId, idx)
            .then(result => {
                return result;
            })
            .catch(e => {
                return Promise.reject(e);
            })
    }
}


const tagStore = new TagStore(tagService);
export default tagStore;