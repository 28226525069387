import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom';
import { inject, observer } from "mobx-react";

const ExternalAuth = ({userStore}) => {
    const { auth_code } = useParams();
    const history = useHistory();

    useEffect(() => {
        userStore.authAccessToken(auth_code).then(()=>{
            history.push('/dashboard');
        })
    }, [])

    return (
        <></>
    )
}

export default inject('userStore')(observer(ExternalAuth));