import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import Tabs from "../Tabs";
import { FilterReview } from "../Filter";
import SourcePlate from "../SourcePlate"
import PrepareNumber from "../../../../components/common/PrepareNumber";

const ReviewsMobile = ({ analyticsStore }) => {

    const { filter } = analyticsStore;
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        analyticsStore.getReviewsList()
            .then(result => {
                setItems(result);
                setLoading(false);
            });
    }, [JSON.stringify(filter)]);


    return (
        <>
            <div className={cn('page__analytics-mobile', 'page')}>
                <div className={cn('title')}>
                    <h1>Аналитика</h1>
                </div>
                <div className={cn('analytics-tabs')}>
                    <Tabs />
                </div>
                <div className={cn('analytics-filter')}>
                    <FilterReview
                        mobile={true}
                        loading={loading}
                    />
                </div>

                <div className={cn('analytics-list')}>
                    <div className={cn('analytics-list__table')}>
                        <div className={cn('analytics-list__wrapper')}>
                            {loading && <div className={'loader'} />}

                            {items?.total && <FirstRow
                                data={items?.total}
                            />}

                            {items.items?.length > 0 && items.items.map((item, key) => (
                                <Row
                                    data={item}
                                    key={key + '_' + item.id}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

/**
 *
 * @param {Object} data
 * @returns {JSX.Element}
 * @constructor
 */
const FirstRow = ({ data }) => {

    let diffPercent = 0;
    let diffPercentString = '';
    if(data.diff.reviews) {
        diffPercent = (data.diff.responded / data.diff.reviews * 100).toFixed();
        if(diffPercent >= 75) {
            diffPercentString = <span className={cn('diff__up')}>{diffPercent}%</span>
        }
        else {
            diffPercentString = <span className={cn('diff__down')}>{diffPercent}%</span>
        }
    }

    return (
        <div className={cn('analytics-list__item', 'item')}>
            <div className={cn('item__wrapper')}>
                <div className={cn('name')}>
                    <div className={cn('ico', 'ico__bag')} />
                    <div>Все филиалы</div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col')}>Рейтинг</div>
                    <div className={cn('col', 'align__right')}>
                        <span className={cn('rating')}>
                            <span className={cn('pi-star pi')} />
                            <span className={cn('value')}>
                                {Number(data.close.rating.toFixed(1)).toLocaleString('ru-RU')}
                            </span>
                        </span>
                    </div>
                    <div className={cn(
                        'col',
                        'diff',
                        { 'diff__up': Number(data.diff.rating) >= 0 },
                        { 'diff__down': Number(data.diff.rating) < 0 }
                    )}
                    >
                        <PrepareNumber value={data.diff.rating} />
                    </div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col')}>Оценки</div>
                    <div className={cn('col', 'align__right')}>
                        {Number(`${data.close.scores}`).toLocaleString('ru-RU')}
                    </div>
                    <div className={cn(
                        'col',
                        'diff',
                        { 'diff__up': Number(data.diff.scores) >= 0 },
                        { 'diff__down': Number(data.diff.scores) < 0 }
                    )}
                    >
                        <PrepareNumber value={data.diff.scores} />
                    </div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col')}>Отзывы</div>
                    <div className={cn('col', 'align__right')}>
                        {Number(`${data.close.reviews}`).toLocaleString('ru-RU')}
                    </div>
                    <div className={cn(
                        'col',
                        'diff',
                        { 'diff__up': Number(data.diff.reviews) >= 0 },
                        { 'diff__down': Number(data.diff.reviews) < 0 }
                    )}
                    >
                        <PrepareNumber value={data.diff.reviews} />
                    </div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col')}>С ответом</div>
                    <div className={cn('col', 'align__right')}>
                        {data.close.reviews ? ((data.close.responded / data.close.reviews) * 100).toFixed() : <>0</>}%
                    </div>
                    <div className={cn('col')}>
                        {diffPercentString}
                    </div>
                </div>
{/*
                <div className={cn('row')}>
                    <div className={cn('col')}>Время ответа</div>
                    <div className={cn('col', 'align__right')}>
                        3ч
                    </div>
                    <div className={cn('col')}>&nbsp;</div>
                </div>
*/}
            </div>
        </div>
    );
}
/**
 *
 * @param {Object} data
 * @returns {JSX.Element}
 * @constructor
 */
const Row = ({ data }) => {

    let diffPercent = 0;
    let diffPercentString = '';
    if(data.diff.reviews) {
        diffPercent = (data.diff.responded / data.diff.reviews * 100).toFixed();
        if(diffPercent >= 75) {
            diffPercentString = <span className={cn('diff__up')}>{diffPercent}%</span>
        }
        else {
            diffPercentString = <span className={cn('diff__down')}>{diffPercent}%</span>
        }
    }


    return (
        <div className={cn('analytics-list__item', 'item')}>
            <div className={cn('item__wrapper')}>
                <div className={cn('name')}>
                    <div className={cn('ico', 'ico__bag')} />
                    <div title={data.branch.title}>{data.branch.title}</div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col', 'align__bottom')}>Источник</div>
                    <div className={cn('col', 'align__right')}>
                        <SourcePlate
                            adplatforms={data.adplatforms}
                            sources={data.sources}
                            isMobile={true}
                        />
                    </div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col')}>Рейтинг</div>
                    <div className={cn('col', 'align__right')}>
                        <span className={cn('rating')}>
                            <span className={cn('pi-star pi')} />
                            <span className={cn('value')}>
                                {Number(data.close.rating.toFixed(1)).toLocaleString('ru-RU')}
                            </span>
                        </span>
                    </div>
                    <div className={cn(
                        'col',
                        'diff',
                        { 'diff__up': Number(data.diff.rating) >= 0 },
                        { 'diff__down': Number(data.diff.rating) < 0 }
                    )}
                    >
                        <PrepareNumber value={data.diff.rating} />
                    </div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col')}>Оценки</div>
                    <div className={cn('col', 'align__right')}>
                        {Number(`${data.close.scores}`).toLocaleString('ru-RU')}
                    </div>
                    <div className={cn(
                        'col',
                        'diff',
                        { 'diff__up': Number(data.diff.scores) >= 0 },
                        { 'diff__down': Number(data.diff.scores) < 0 }
                    )}
                    >
                        <PrepareNumber value={data.diff.scores} />
                    </div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col')}>Отзывы</div>
                    <div className={cn('col', 'align__right')}>
                        {Number(`${data.close.reviews}`).toLocaleString('ru-RU')}
                    </div>
                    <div className={cn(
                        'col',
                        'diff',
                        { 'diff__up': Number(data.diff.reviews) >= 0 },
                        { 'diff__down': Number(data.diff.reviews) < 0 }
                    )}
                    >
                        <PrepareNumber value={data.diff.reviews} />
                    </div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col')}>С ответом</div>
                    <div className={cn('col', 'align__right')}>
                        {data.close.reviews ? ((data.close.responded / data.close.reviews) * 100).toFixed() : <>0</>}%
                    </div>
                    <div className={cn('col')}>{diffPercentString}</div>
                </div>
{/*
                <div className={cn('row')}>
                    <div className={cn('col')}>Время ответа</div>
                    <div className={cn('col', 'align__right')}>
                        3ч
                    </div>
                    <div className={cn('col')}>&nbsp;</div>
                </div>
*/}

            </div>
        </div>
    );
}

export default inject('analyticsStore')(observer(ReviewsMobile));
