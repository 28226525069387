import OkTags from "../../../../common/OkTags";
import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { getUniqueObjectFromArray, removeObjectFromArray } from "../../../../../helpers";
import { reaction } from "mobx";
import OkTagsAuto from "../../../../common/OkTagsAuto";

const convertTagList = (tagList = []) => {
    return tagList.map(tag => {
        return {
            value: tag.title,
            label: tag.title,
            key: tag.id
        }
    })
}

const FooterTag = ({ tagStore, uiStore, reviewFilterStore, defaultValue, reviewId, review }) => {

// существующие теги
    const [options, setOptions] = useState([]);

// назначенные теги этому отзыву
    const [assignedTags, setAssignedTags] = useState(convertTagList(defaultValue));

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setOptions(convertTagList(tagStore.tags));

        const disposers = [
            reaction(
                () => tagStore.tags,
                () => {
                    setOptions(
                        convertTagList(tagStore.tags)
                    );
                }
            )
        ];
        return () => {
            disposers.map(func => func());
        }
    }, []);

    const createTag = (tag = '') => {
        tagStore.addItem(tag.value)
            .then(result => {
                if (result.id) {
                    setAssignedTags(prev => {
                        return [
                            ...prev,
                            {
                                key: result.id,
                                value: tag.value,
                                label: tag.value,
                            }
                        ]
                    })
                    return tagStore.linkItem(result.id, reviewId)
                } else {
                    return Promise.reject('ошибка')
                }
            })
            .then(() => {
                tagStore.getTagsForFilter()
                return tagStore.getTagList()
            })
            .catch(e => {
                console.error(e)
            })
    }

    const linkTag = (tag) => {
        tagStore.linkItem(tag.key, reviewId)
            .then(() => {
                setAssignedTags(prev => {
                    return [
                        ...prev,
                        tag
                    ]
                })
                tagStore.getTagsForFilter()
            })
            .catch(e => {
                console.error(e)
            })
    }

    const delLinkTag = (tag) => {


        tagStore.removeLinkItem(tag.key, reviewId)
            .then((_) => {

                tagStore.getTagList().then(_ => {
//TODO вделить в отдельную функцию
                    const countReview = tagStore.tags.find(item => item.id === tag.key).count
                    if (countReview === 0) {
                        let copy = [...reviewFilterStore.filter.tags]
                        const tagIndex = reviewFilterStore.filter.tags.findIndex((item) => item.value === tag.key)
                        copy.splice(tagIndex, 1)
                        reviewFilterStore.setFieldValue('tags', copy)
                    }
                })
                tagStore.getTagsForFilter()
            })
            .catch(e => {
                console.error(e)
            })
    }

    const onChangeTag = (values) => {

        if (assignedTags.length < values.length) {
            const newTag = getUniqueObjectFromArray(values, assignedTags, 'value')[0]
            if (newTag) {
                if (!newTag.key) {
                    createTag(newTag);
                } else {
                    linkTag(newTag)
                }
            }
        } else {
            const removeTag = getUniqueObjectFromArray(assignedTags, values, 'value')[0]
            if (removeTag) {
                const newAssignedTags = removeObjectFromArray(assignedTags, removeTag, 'value')
                setAssignedTags(newAssignedTags)

                if (removeTag.key) {
                    delLinkTag(removeTag)
                }
            }
        }
    }

    return (
        <>
            <OkTags
                options={options}
                defaultValue={assignedTags}
                onChange={onChangeTag}
                loading={loading}
            />
            {!!review.autoTags.length && <OkTagsAuto
                list={review.autoTags}
            />}

        </>
    );
}
export default inject('tagStore', 'reviewFilterStore', 'uiStore')(observer(FooterTag));