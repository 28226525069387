import React, {Component} from "react";
import {InputText} from "primereact/inputtext";
import cn from "classnames";
import EyeIco from "../../images/login_eye.svg";

class InputPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showValue: false,
        }
    }

    toggleTypeField = () => {
        this.setState({showValue: !this.state.showValue});
    }

    render() {
        const {
            id,
            name,
            handleChange,
            handleBlur,
            values,
            className,
        } = this.props;
        const {showValue} = this.state;

        return (
            <React.Fragment>
                <InputText
                    id={id}
                    type={showValue === false ? 'password' : 'text'}
                    name={name}
                    className={cn(className, 'wrapper_ico_eye')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values}
                />
                <i
                    className={cn('ico_eye')}
                    onClick={this.toggleTypeField}
                >
                    <img src={EyeIco} alt=""/>
                </i>
            </React.Fragment>

        );
    }
}

export default InputPassword;