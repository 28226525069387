import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import "./ReviewListMobile.scss";
import Filter from "../../../../../components/pages/review/filter/Filter";
import Charts from "../../../../../components/pages/review/charts/Charts";
import NavigateBtnUp from "../../../../../components/pages/application/common/NavigateBtnUp";
import { Dropdown } from "primereact/dropdown";
import ReviewItemMobile from "./ReviewItemMobile";
import Download from "../../list/common/Download";
import { useIsMount } from "../../../../../hooks";

/**
 * @param {String} type
 */

const ReviewListMobile = ({ reviewsStore, reviewFilterStore, uiStore, userStore, tagStore }) => {
    const  [showButtonUp, setShowButtonUp] = useState(true);
    const { items, isLoading } = reviewsStore;
    const { filter, setPager } = reviewFilterStore;
    const [reviews, setReviews] = useState([]);
    const isMount = useIsMount();

    const sortFields = [
        { value: { column: 'posted', order: 'desc' }, label: 'По дате: сначала новые' },
        { value: { column: 'source', order: 'desc' }, label: 'По источнику' },
        { value: { column: 'score', order: 'desc' }, label: 'По оценке: сначала высокие' },
        { value: { column: 'score', order: 'asc' }, label: 'По оценке: сначала низкие' }
    ]

    const scrollFunction = () => {
        let docBody = document.body,
            docElement = document.documentElement,
            scrollTop = (window.pageYOffset || document.documentElement.scrollTop),
            winHeight = docElement.clientHeight,
            docHeight = Math.max(docBody.scrollHeight, docBody.offsetHeight, winHeight, docElement.scrollHeight, docElement.offsetHeight);

// кнопка up
        if (scrollTop >= 700) {
            setShowButtonUp(true);
        } else {
            setShowButtonUp(false);
        }

// догрузка контента
        if (scrollTop >= (docHeight - winHeight - 200)) {
            loadMore();
        }
    }

    const loadMore = () => {
        const pageNum = (filter.pager.number + 1);

        if (pageNum <= filter.pager.pageCount) {
            unBindScrollListener();

            setPager({
                number: pageNum,
            });

            reviewsStore.loadReviews2(
                reviewFilterStore.parseFilterToApi()
            ).then(() => {

                bindScrollListener();
            });
        }
    }

    const bindScrollListener = () => {
        window.addEventListener('scroll', scrollFunction);
    }

    const unBindScrollListener = () => {
        window.removeEventListener('scroll', scrollFunction);
    }

    useEffect(() => {
        if (isMount) {
            tagStore.getTagList();
            tagStore.getAutoTagList();
        }

        bindScrollListener();
    },[]);

    useEffect(() => {
        setPager({
            number: 1,
        });

        reviewsStore.loadReviews2(
            reviewFilterStore.parseFilterToApi()
        );

    }, [
        JSON.stringify(filter.date),
        JSON.stringify(filter.branches),
        JSON.stringify(filter.sources),
        JSON.stringify(filter.scores),
        JSON.stringify(filter.tags),
        JSON.stringify(filter.autotags),
        JSON.stringify(filter.claim),
        JSON.stringify(filter.attention),
        JSON.stringify(filter.answer),
        JSON.stringify(filter.sorting),
    ]);

    useEffect(() => {
        setReviews(reviews => {
            if (filter.pager.number !== 1) {
                return [
                    ...reviews,
                    ...items,
                ];
            }
            else {
                return items;
            }
        });

    }, [items]);

    return (
        <>
            <div className={cn('page__review-list-mobile', 'page')}>
                <div className={cn('title')}>
                    <h1>Мониторинг</h1>
                    <Download
                        filter={filter}
                        isMobile={true}
                        uiStore={uiStore}
                        userStore={userStore}
                        dest={'review'}
                    />

                </div>

                <div className={cn('review-filter')}>
                    <Filter
                        loading={isLoading}
                        shortFormat
                    />
                </div>

                <div className={cn('review-charts')}>
                    <div className={cn('review-charts__wrapper')}>
                        <Charts />
                    </div>

                </div>

                <div className={cn('review-list')}>
                    <div className={cn('review-list__table')}>
                        {isLoading && <div className={'loader'} />}
                        <div className={cn('review-list__header')}>
                            <h2>Отзывы</h2>
                            <span className={cn('count')}>{filter.pager.itemCount}</span>
                        </div>
                        <div className={cn('sort')}>
                            <div className={cn('sort__wrapper')}>
                                <Dropdown inputId="sort"
                                          name="sort"
                                          options={sortFields}
                                          value={filter.sorting}
                                          onChange={(e) => {
                                              reviewFilterStore.setSorting({
                                                  column: e.target.value.column,
                                                  order: e.target.value.order,
                                              });
                                          }}
                                          placeholder={'По дате: сначала новые'}
                                          optionLabel="label"
                                />
                            </div>
                        </div>

                        <div className={cn('review-list__wrapper')}>
                            {reviews.length ? reviews.map((item, key) => (
                                <ReviewItemMobile
                                    data={item}
                                    key={key+'_'+item.id}
                                />
                            )) : 'нет отзывов'}
                            {showButtonUp && <NavigateBtnUp />}
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}

export default inject('reviewsStore', 'reviewFilterStore', 'uiStore', 'userStore', 'tagStore')(observer(ReviewListMobile));
