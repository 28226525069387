import integrationService from '../services/integration';
import { sources } from "../helpers";

import { action, observable } from "mobx";
import _, { isNull } from "underscore";

class IntegrationStore {
    #integrationService;

    @observable branches = [];
    @observable items = [];
    @observable qrActiveBranch = '';
    sources = sources;

    filterSources = [];

    constructor(integrationService) {
        this.#integrationService = integrationService;
    }

    @action setQrActiveBranch(branch) {
        this.qrActiveBranch = branch;
    }

    getList() {
        let removeItemFilter = [];
        return this.#integrationService.getList()
            .then(result => {
//TODO убрать от сюда, причесать.
                result.map(item => {
                    item.adplatforms.map((value) => {
                        if (isNull(value.connections.collector)
                            && isNull(value.connections.parser)
                            && isNull(value.connections.publisher)
                        ) {
                            removeItemFilter.push(value.type.code);
                        }
                    })
                })
                let tmp = this.sources.filter(source => {
                    let i = 0;
                    return !!removeItemFilter.find((value) => {
                        if (value === source.value) {
                            i++;
                            if (i === result.length) {
                                return true;
                            }
                        }
                    })
                })
                this.filterSources = _.difference(this.sources, tmp);

                this.setItems(this.sortNotConnected(result));

                return this.items;
            })
            .catch(e => e);
    }

    sortNotConnected(data) {

        let finalData = [];

        data.map((item, key) => {
            const resultNotConnect = item
                .adplatforms.filter((value) => !!value.connections.notConnected)
                .sort((a, b) => a.type.name.toLowerCase().localeCompare(b.type.name.toLowerCase()));

            const resultConnect = item
                .adplatforms.filter((value) => !value.connections.notConnected)
                .sort((a, b) => a.type.name.toLowerCase().localeCompare(b.type.name.toLowerCase()));

            finalData[key] = {
                ...item,
                ...{
                    adplatforms: [
                        ...resultConnect,
                        ...resultNotConnect,
                    ],
                },
            };

        });

        return finalData;
    }

    @action setItems(items) {
        this.items = items;
    }

    @action getBranch() {
        return this.#integrationService.getBranch()
            .then(result => {
                this.branches = result.map(item => {
                    return {
                        value: item.id,
                        name: item.title,
                    }
                })
            })
            .catch(e => e);
    }

    getSources() {
        return Promise.resolve(this.sources);
    }


    getAttention = () => {
        const pattern = ['off', 'on', 'pending'];
        let countAttention = 0;
        let attentionList = [];
        this.items.forEach((item, key) => {

            const result = item.adplatforms.filter((plat) => {
                return (
                    (!!plat.connections.collector && _.indexOf(pattern, plat.connections.collector?.status) === -1) ||
                    (!!plat.connections.parser && _.indexOf(pattern, plat.connections.parser?.status) === -1) ||
                    (!!plat.connections.publisher && _.indexOf(pattern, plat.connections.publisher?.status) === -1)
                );
            });
            if (result.length !== 0) {
                countAttention = countAttention + result.length;
                attentionList[key] = {
                    ...item,
                    ...{
                        adplatforms: result
                    }
                }
            }
        });

        return {
            'countAttention': countAttention,
            'data': attentionList
        }
    }
}

const integrationStore = new IntegrationStore(integrationService);
export default integrationStore;
