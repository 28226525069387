import React, { useState } from "react";
import { Button } from "primereact/button";
import cn from "classnames";
import { Modal } from "antd";
import { InputText } from "primereact/inputtext";

const CreateTagButton = ({ onCreateTag }) => {

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [title, setTitle] = useState('')

    const handleCancel = () => {
        setIsModalOpen(false)
        setTitle('')
    }

    const handleOk = () => {
        onCreateTag(title)
        setIsModalOpen(false)
        setTitle('')
    }

    return (
        <>
            <Button
                label='Добавить'
                className={cn('p-button-outlined')}
                onClick={() => {
                    setIsModalOpen(true)
                }}
            />
            <Modal
                title="Создание тега"
                cancelText={'Отменить'}
                okText={'Добавить'}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ disabled: !title.length }}
            >
                <div className={'text-field'}>
                    <div className="p-float-label">
                        <InputText
                            id='title'
                            type="text"
                            name='title'
                            maxLength={50}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            value={title}
                        />
                        <label htmlFor='title'>Название<span style={{ color: 'red' }}>*</span></label>
                    </div>
                </div>

            </Modal>
        </>
    )
}

export default CreateTagButton;