import React, { useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import arrow_up from '../../../images/dashboard/ico_arrow_up.png';
import arrow_down from '../../../images/dashboard/ico_arrow_down.png';
import { getDetailDataByPlatform } from "../../../helpers/index";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

//TODO переписать на HOC компонент!!!
const MonitoringReviewsAnswer = ({ data, sources = []}) => {

    const [selected, setSelected] = useState([]);

    const [reviews, setReviews] = useState(null);

    useEffect(() => {
        if(!!sources.length && !!data.items.length) {
            setReviews(parseReviews(data.items));
            setSelected([
                { value: sources[0]?.value, code: sources[0]?.code },
                { value: sources[1]?.value, code: sources[1]?.code },
            ]);
        }
    }, []);

    const parseReviews = (data = []) => {
        let result = [];

        data.map((item) => {
            result.push({
                code: item.adplatform.code,
                title: getDetailDataByPlatform(item.adplatform.code)?.name ?? 'none',
                percent: item.close.reviews > 0 ? (item.close.responded / item.close.reviews * 100).toFixed(1) : 0,
                arrow: item.diff.responded > 0 ? <img src={arrow_up} alt="" height={22} /> :
                    <img src={arrow_down} alt="" height={22} />,
            })
        });
        return result;
    }

    const handleChange = (data) => {
        if (!!reviews?.length) {
            setSelected(data)
        }
    }

    useEffect(() => {
        let tmpArr = [];
         selected.map(adplatform => {
             const adplatformElement = data.items.find(item => item.adplatform.code === adplatform.code)
             adplatformElement && tmpArr.push(adplatformElement)
        });
        setReviews(parseReviews(tmpArr));

    }, [selected])

    return (
        <fieldset className={cn({'ml_0': !isMobile})}>
            <legend>Отзывы с ответом</legend>
            {!!sources?.length && !!reviews?.length ?
                <Link to={'/monitoring/list'}>
                    <div className={cn('double-block')}>
                        <ActionButton
                            selected={selected}
                            handleChange={handleChange}
                        />
                        <div className={cn('left')}>
                            {reviews[0] && <>
                                <span className={'name'}>{reviews[0].title}</span>
                                <span className={'value'}>{reviews[0].percent} <i>%</i></span>
                                <span className={cn('ico_arrow')}>{reviews[0].arrow}</span>
                            </>}
                        </div>
                        <div className={cn('right')}>
                            {reviews[1] && <>
                                <span className={'name'}>{reviews[1].title}</span>
                                <span className={'value'}>{reviews[1].percent} <i>%</i></span>
                                <span className={cn('ico_arrow')}>{reviews[1].arrow}</span>
                            </>}
                        </div>
                    </div>
                </Link>
            :
                <div className={cn('empty_data')}>нет данных</div>
            }
        </fieldset>
    )
}

const MonitoringReviewsClaims = ({ data, sources = [] }) => {

    const [selected, setSelected] = useState([]);
    const [reviews, setReviews] = useState(null);

    useEffect(() => {

        if(!!sources.length && !!data.items.length) {
            setReviews(parseReviews(data.items));
            setSelected([
                { value: sources[0]?.value, code: sources[0]?.code },
                { value: sources[1]?.value, code: sources[1]?.code },
            ])
        }
    }, []);

    const parseReviews = (data = []) => {
        let result = [];
        data.map((item) => {
            result.push({
                code: item.adplatform.code,
                title: getDetailDataByPlatform(item.adplatform.code).name ?? 'none',
                //percent: (item.close.claimed / item.close.reviews * 100).toFixed(1),
                percent: item.close.reviews > 0 ? (item.close.claimed / item.close.reviews * 100).toFixed(1) : 0,
                arrow: item.diff.claimed > 0 ? <img src={arrow_up} alt="" height={22} /> :
                    <img src={arrow_down} alt="" height={22} />,
            })
        });
        return result;
    }

    const handleChange = (data) => {
        if (!!reviews?.length) {
            setSelected(data)
        }
    }

    useEffect(() => {
        let tmpArr = [];
        selected.map(adplatform => {
            const adplatformElement = data.items.find(item => item.adplatform.code === adplatform.code)
            adplatformElement && tmpArr.push(adplatformElement)
        });
        setReviews(parseReviews(tmpArr));

    }, [selected])

    return (
        <fieldset className={cn({'mr_0': !isMobile})}>
            <legend>Отзывы с жалобами</legend>
            {!!sources?.length && !!reviews?.length ?
                <Link to={'/monitoring/list'}>
                    <div className={cn('double-block')}>
                        <ActionButton
                            selected={selected}
                            handleChange={handleChange}
                        />

                        <div className={cn('left')}>
                            {reviews[0] && <>
                                <span className={'name'}>{reviews[0].title}</span>
                                <span className={'value'}>{reviews[0].percent} <i>%</i></span>
                                <span className={cn('ico_arrow')}>{reviews[0].arrow}</span>
                            </>}
                        </div>
                        <div className={cn('right')}>
                            {reviews[1] && <>
                                <span className={'name'}>{reviews[1].title}</span>
                                <span className={'value'}>{reviews[1].percent} <i>%</i></span>
                                <span className={cn('ico_arrow')}>{reviews[1].arrow}</span>
                            </>}
                        </div>
                    </div>
                </Link>
            :
                <div className={cn('empty_data')}>нет данных</div>}
        </fieldset>
    );
};


const ActionButton = inject('dashboardStore')(observer(
    ({ dashboardStore, selected = [], handleChange }) => {

        const buttonRef = useRef();

        const { sources } = dashboardStore;
        const [openDropdown, setOpenDropdown] = useState(false);
        const [selectedItems, setSelectedItems] = useState(selected);

        useEffect(() => {
            document.addEventListener('click', clickOutsideHandler, false);
            return () => {
                document.removeEventListener('click', clickOutsideHandler, false);
            }
        }, []);

        useEffect(() => {
            setSelectedItems(selected)
        }, [selected])

        useEffect(() => {
            handleChange(selectedItems);
        }, [selectedItems])

        const clickOutsideHandler = (event) => {
            if (!buttonRef.current?.contains(event.target)) {
                setOpenDropdown(false);
            }
        }

        const addItem = (item) => {

            if (selectedItems.length >= 2) {
                setSelectedItems(selectedItems => {
                    selectedItems.shift();
                    return [
                        ...selectedItems,
                        {
                            value: item.value,
                            code: item.code,
                        }
                    ];
                });
            }
        }

        return (
            <div
                className={cn('double-block__filter')}
                ref={buttonRef}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setOpenDropdown(openDropdown => !openDropdown);
                }}
            >
                {openDropdown && <ul>
                    {sources.map((item, key) => {
                        const isSelect = !!selectedItems.filter(select => select.value === item.value).length

                        return (
                            <li key={key} onClick={(e) => {
                                !isSelect && addItem(item);
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            >
                                {isSelect && <i className="pi pi-check" />}
                                {item.name}
                            </li>
                        );
                    })}
                </ul>}

            </div>

        );
    }
))
export {
    MonitoringReviewsAnswer,
    MonitoringReviewsClaims,
}

