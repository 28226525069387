import React, {useEffect, useRef, useState} from "react";
import cn from "classnames";
import './AnswerList.scss';
import { ProgressSpinner } from "primereact/progressspinner";
import { TextToolTip } from "../../../common/ToolTip";
import DateCompare from "../../../common/DateCompare";
import { Button } from "primereact/button";
import { inject, observer } from "mobx-react";
import {onConfirm} from "../../../../helpers";

const AnswerList = ({ items, reviewCreatedAtDate, reviewPostedAtDate, reloadAnswer }) => {



    return (
        <>
            <div className={cn('replay__list')}>
                {!items.length && <div className={cn('replay__loader')}>
                    <ProgressSpinner/>
                </div>}
                {items.map((item, key) => (
                    <Answer
                        item={item}
                        key={key}
                        reviewCreatedAtDate={reviewCreatedAtDate}
                        reviewPostedAtDate={reviewPostedAtDate}
                        reloadAnswer={reloadAnswer}
                    />
                ))}
            </div>
        </>
    );
}

const Answer = inject('reviewsStore', 'uiStore')(observer(
    ({ reviewsStore, uiStore, item, reviewCreatedAtDate, reviewPostedAtDate, reloadAnswer }) => {

        const handlerRepostAnswer = (data) => {
            reviewsStore.repostAnswer(item.id, item.reviewId, data)
                .then(result => {
                    if(result) {
                        reloadAnswer(false, item.id)
                    }
                })
                .catch(e => {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: 'Внимание',
                            detail: e.error.description,
                        }
                    )
                })
        }

        const getAuthorName = (createdBy) => {
            if (createdBy.id) {
                return `${createdBy.lastname || ''} ${createdBy.firstname || ''} `
            }
            else {
                return  `____`;
            }
        }

        return (
            <>
                <div className={cn('replay', { 'official': item.official })}>
                    <div className={cn('replay__wrapper')}>
                        <div className={cn('replay__header')}>
                            <div className={cn('replay__author')}>
                                {/*TODO строку имен в отдельный компонент*/}
                                {item.official
                                    ?
                                    <span
                                        className={cn('replay__author_official', {'author_official__underline': item.okdAnswered})}>
                                        {item.createdBy.id && <TextToolTip content={`Ответ создан пользователем: ${getAuthorName(item.createdBy)}`}>Официальный ответ</TextToolTip>}
                                        {!item.createdBy.id && `Официальный ответ`}
                                    </span>
                                    :
                                    <span>{item.author.name}</span>
                                }
                                {/*TODO строку статусов в отдельный компонент*/}
                                {!item.published && <TextToolTip content={`Дата создания ответа: ${item.createdAt}`}><AnswerState state={item.state}/></TextToolTip>}
                                {item.published  && <TextToolTip content={`Дата создания ответа: ${item.createdAt}`}>{item.postedAt}</TextToolTip>}
                                {item.hideable && <span className={cn('close')}>
                                    <i className="pi pi-times"
                                       onClick={()=>{
                                           onConfirm(
                                               {
                                                   centered: true,
                                                   title: 'ВНИМАНИЕ!',
                                                   content: `Неопубликованный ответ будет скрыт навсегда.`,
                                                   cancelText: 'Отмена',
                                                   okText: 'Скрыть',
                                                   okType: 'danger',
                                               }
                                           ).then(() => {
                                               reviewsStore.delAnswer(item.id, item.reviewId)
                                                   .then(result => {
                                                       if(result === "OK") {
                                                           reloadAnswer(true, item.id)
                                                       }
                                                   })
                                                   .catch(e => {
                                                       uiStore.setMessage(
                                                           {
                                                               severity: 'error',
                                                               summary: 'Внимание',
                                                               detail: e.error.description,
                                                           }
                                                       )
                                                   })

                                           }).catch((_) => {
                                           })
                                       }}
                                    ></i>
                                </span>}
                            </div>

                            {item.published && item.official && <div className={cn('replay__date')}>
                                {item.okdAnswered ?
                                    <DateCompare startDate={reviewCreatedAtDate} endDate={item.createdAtRaw}/>
                                    :
                                    <DateCompare startDate={reviewPostedAtDate} endDate={item.postedAtRaw}/>
                                }
                            </div>}
                        </div>
                        <div className={cn('replay__text')}>
                            <PrepareText text={item.text} repostAnswer={handlerRepostAnswer} repostable={item.repostable}/>
                        </div>
                    </div>
                </div>

            </>
        )
    }
))

const AnswerState = ({state}) => {

    const stats = {
        pending: {
            title: 'Ожидает публикации',
            color: '#9C9C9C',
            view: true,
        },
        approving: {
            title: 'Ожидает публикации',
            color: '#9C9C9C',
            view: true,
        },
        published: {
            title: 'Опубликован',
            color: '#9C9C9C',
            view: true,
        },
        error: {
            title: 'Ошибка публикации',
            color: '#EB5757',
            view: true,
        },
        unpublished: {
            title: 'Снят с публикации',
            color: '#EB5757',
            view: true,
        },
        declined: {
            title: 'Ответ отклонён площадкой',
            color: '#EB5757',
            view: true,
        }
    }

    return <>
        {stats[state].view && <span style={{color:stats[state].color}}>{stats[state].title}</span>}
    </>
}

const PrepareText = ({ text, repostAnswer, repostable }) => {
    const prepareText = (rawText) => rawText
        .split('\n')
        .map((item, key) => (<span key={key}>{item}<br/></span>));

    const [editable, setEditable] = useState(false);
    const [newText, setNewText] = useState(text)
    const textareaRef = useRef(null)

    useEffect(() => {
        if(textareaRef.current) {
            textareaRef.current.style.height = "inherit";

            const heightTextarea = Math.max(
                textareaRef.current.scrollHeight,
                80
            );

            if(heightTextarea <= 120) {
                textareaRef.current.style.height = `${heightTextarea}px`;
            }
            else {
                textareaRef.current.style.height = `120px`;
            }
        }
    }, [newText])


    return (
        <>
            {!editable ?
                <>
                    <div className={cn('replay__text-wrapper')}>
                        {prepareText(text)}
                    </div>
                    {repostable && <div className={cn('replay__btn-publish')}>
                        <Button
                            className={cn('replay__btn', 'publish')}
                            onClick={() => {
                                setEditable(prev => !prev)
                            }}
                            icon="pi pi-replay"
                        />
                    </div>}
                </>
                :
                <>
                    <div className={cn('replay__body', 'repost')}>
                        <div className={cn('replay__form')}>
                            <div className={cn('replay__textarea_wrapper')}>
                                <textarea
                                    value={newText}
                                    onChange={(e) => {
                                        setNewText(e.target.value)
                                    }}
                                />
                            </div>
                            <div className={cn('replay__btn_wrapper')}>
                                <Button
                                    className={cn('replay__btn')}
                                    style={{ 'marginRight': '5px' }}
                                    onClick={() => {
                                        repostAnswer(newText)
                                        setEditable(false)
                                    }}
                                    icon="pi pi-save"
                                />
                                <Button
                                    className={cn('replay__btn')}
                                    onClick={() => {
                                        setEditable(false)
                                    }}
                                    icon="pi pi-times"
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default AnswerList;
