import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { inject, observer } from "mobx-react";
import { Button } from "primereact/button";
import { Checkbox } from 'antd';
import okAnalyticsService from "../../../services/okAnalytics";
import cn from "classnames";
import "./Tags.scss";
import { onConfirm } from "../../../helpers";
import InlineEditInput from "./common/InlineEditInput";
import CreateTagButton from "./common/CreateTagButton";
import MergeButtonDrop from "./common/MergeButtonDrop";


const TagsList = () => {
    useEffect(() => {
        okAnalyticsService.addEntity({
            page: 'tags_list',
            target: 'page',
            event: 'open',
        });
    }, []);

    const getClientWidth = () => {
        return document.documentElement.clientWidth;
    }

    return (
        <>
            {(isMobile && getClientWidth() <= 1024) ? <TagsListDesktop/> : <TagsListDesktop/>}
        </>
    );
}

const TagsListDesktop = inject('tagStore', 'uiStore')(observer(({ tagStore, uiStore }) => {
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        onLoadTagList();
    }, [])

    useEffect(() => {
        const menuItemsRaw = selectedItems.map(item => {
            return {
                label: item.title,
                key: item.id,
            }
        })

        setMenuItems(menuItemsRaw);

    }, [selectedItems])

    const onLoadTagList = () => {
        setLoading(true);
        tagStore.getTagList()
            .then(result => {
                setItems(result.items)
            })
            .catch(e => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: e.error.description,
                        detail: e.error.message,
                    }
                )
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const onChecked = (e) => {
        const object = e.target.value;

        if (e.target.checked) {
            setSelectedItems(prev => {
                return [
                    ...prev,
                    ...[object],
                ]
            })
        } else {
            let copy = [
                ...selectedItems
            ];

            const index = copy.findIndex(item => {
                return item.id === object.id;
            })

            copy.splice(index, 1);
            setSelectedItems(copy)
        }
    }

    const onAllChecked = (e) => {
        if (e.target.checked) {
            setSelectedItems(items);
        } else {
            setSelectedItems([]);
        }
    }

    const checkAllStatus = () => {
        if (selectedItems.length <= 0) {
            return false;
        } else if (selectedItems.length === items.length) {
            return true;
        }
    };

    const indeterminateAllStatus = () => {
        return selectedItems.length > 0 && selectedItems.length !== items.length;
    };

    const checkStatus = (object) => {
        return !!selectedItems.find(item => item.id === object.id);
    }

    const onEditTag = (id, value) => {
        setItems([])
        tagStore.editTag(id, value)
            .then(result => {
                if (result === 'OK') {
                    uiStore.setMessage(
                        {
                            severity: 'success',
                            summary: '',
                            detail: 'Тег успешно сохранен',
                            sticky: false,
                        }
                    );
//                    setSelectedItems([]);

                    const copy = selectedItems.map((item) => {
                        if(item.id === id){
                            return {
                                id: item.id,
                                title: value,
                            }
                        }
                        else {
                            return  {
                                id: item.id,
                                title: item.title
                            }
                        }
                    })

                    setSelectedItems(copy)
                }
            })
            .catch(e => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: e.error.description,
                        detail: e.error.message,
                    }
                )
            })
            .finally(() => {
                onLoadTagList()
            })
    }

    const onMergeTags = ({ key }) => {
        const selectedTag = selectedItems.find(tag => tag.id === key);
        const ids = selectedItems.map(tag => tag.id);

        onConfirm(
            {
                centered: true,
                title: 'ВНИМАНИЕ!',
                content: `Выбранные теги будут объединены в один: «${selectedTag.title}». Все отзывы, отмеченные выбранными тегами, будут отмечены новым установленным тегом`,
                cancelText: 'Отменить',
                okText: 'Объединить',
                okType: 'danger',
            }
        ).then(() => {

            setItems([])
            tagStore.mergeItem(key, ids)
                .then(result => {
                    if (result === 'OK') {
                        uiStore.setMessage(
                            {
                                severity: 'success',
                                summary: '',
                                detail: 'Слияние тегов выполнено',
                                sticky: false,
                            }
                        );
                        setSelectedItems([]);
                    }

                })
                .catch(e => {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: e.error.description,
                            detail: e.error.message,
                        }
                    )
                })
                .finally(() => {
                    onLoadTagList()
                })

        }).catch((_) => {

        })
    }

    const onDeleteTags = () => {
        const ids = selectedItems.map(tag => tag.id);

        const content = [
            `Выбранный тег будет удален навсегда. Тег будет удален у всех отзывов отмеченных этим тегом`,
            'Выбранные теги будут удалены навсегда. Теги будут удалены у всех отзывов отмеченных этими тегами',
        ];

        onConfirm(
            {
                centered: true,
                title: 'ВНИМАНИЕ!',
                content: ids.length === 1 ? content[0] : content[1],
                cancelText: 'Отменить',
                okText: 'Удалить',
                okType: 'danger',
            }
        ).then(() => {
            tagStore.delList(ids)
                .then((result) => {
                    if (result === 'OK') {
                        uiStore.setMessage(
                            {
                                severity: 'success',
                                summary: '',
                                detail: 'Общий список тегов удален',
                                sticky: false,
                            }
                        );
                        setSelectedItems([]);
                    }
                })
                .catch(e => {
                    e.error.data.map(item => {
                        uiStore.setMessage(
                            {
                                severity: 'error',
                                summary: e.error.description,
                                detail: item.message,
                            }
                        )
                    });
                })
                .finally(() => {
                    onLoadTagList()
                })

        }).catch((_) => {

        })
    }

    const onDelete = (tag) => {
        onConfirm(
            {
                centered: true,
                title: 'ВНИМАНИЕ!',
                content: `Выбранный тег: «${tag.title}» будет удален навсегда. Тег будет удален у всех отзывов отмеченных этим тегом`,
                cancelText: 'Отменить',
                okText: 'Удалить',
                okType: 'danger',
            }
        ).then(() => {
            tagStore.delItem(tag.id)
                .then(result => {
                    if (result === 'OK') {
                        uiStore.setMessage(
                            {
                                severity: 'success',
                                summary: '',
                                detail: 'Тег успешно удален',
                                sticky: false,
                            }
                        );
                        setSelectedItems([]);
                    }
                })
                .catch(e => {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: e.error.description,
                            detail: e.error.message,
                        }
                    )
                })
                .finally(() => {
                    onLoadTagList()
                })
        }).catch((_) => {

        })
    }

    const onCreateTag = (title) => {
        tagStore.addItem(title)
            .then(result => {
                if (result.id) {
                    uiStore.setMessage(
                        {
                            severity: 'success',
                            summary: '',
                            detail: 'Тег успешно создан',
                            sticky: false,
                        }
                    );
                }
            })
            .catch(e => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: e.error.description,
                        detail: e.error.message,
                    }
                )
            })
            .finally(() => {
                onLoadTagList()
            })
    }

    return (
        <>
            <div className={cn('page__settings', 'page', 'tag-table')}>
                <div className={cn('title')}>
                    <h1>Управление тегами</h1>
                </div>
                <div className={cn('analytics-table', 'table')}>
                    <div className={cn('table__wrapper')}>
                        {loading && <div className={'loader'}/>}
                        <div className={cn('button__wrapper')}>
                            <CreateTagButton
                                onCreateTag={onCreateTag}
                            />

                            <MergeButtonDrop
                                items={menuItems}
                                onClick={onMergeTags}
                                label={'Объеденить'}
                                disabled={selectedItems.length < 2}
                            />

                            <Button
                                label='Удалить'
                                disabled={!selectedItems.length}
                                className={cn('p-button-outlined', 'p-button-danger')}
                                onClick={() => {
                                    onDeleteTags()
                                }}
                            />
                        </div>
                        <div className="p-datatable p-component">
                            <div className="p-datatable-wrapper">
                                <table role="grid">
                                    <thead className="p-datatable-thead">
                                    <tr>
                                        <th className={cn('checkbox')}>
                                            <Checkbox
                                                onChange={onAllChecked}
                                                indeterminate={indeterminateAllStatus()}
                                                checked={checkAllStatus()}
                                            />
                                        </th>
                                        <th>Список тегов:</th>
                                        <th>&nbsp;</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {items.map((item, _) => {
                                        return (
                                            <tr key={item.id}>
                                                <td className={cn('checkbox')}>
                                                    <Checkbox
                                                        onChange={onChecked}
                                                        checked={checkStatus(item)}
                                                        value={item}/>
                                                </td>
                                                <td>
                                                    <InlineEditInput
                                                        item={item}
                                                        onSave={onEditTag}
                                                        onUnique={[]}
                                                    />
                                                </td>
                                                <td className={cn('count')}>
                                                    {item.count}
                                                </td>
                                                <td className={cn('action')}>
                                                    <div className={cn('delete-btn')} onClick={() => {
                                                        onDelete(item);
                                                    }}>
                                                        <i className="pi pi-trash"></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}));

export default TagsList