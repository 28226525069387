import moment from "moment";

export class FilterQRModel {
    #branches;
    #statuses;
    #date;
    #sort;
    #page;

    constructor(filter = {}) {
        this.#date = new FilterDateModel(filter.date);
        this.#branches = filter.branches || [];
        this.#statuses = filter.statuses.map(i => i) || [];
        this.#sort = new FilterSortModel(filter.sort);
        this.#page = new FilterPageModel(filter.page);
    }

    get date() {
        return this.#date;
    }

    get branches() {
        return this.#branches.map(val => val.value).join(',');
    }

    get statuses() {
        return this.#statuses.map(val => val.value).join(',');
    }

    get sort() {
        return this.#sort;
    }

    get page() {
        return this.#page;
    }

    getToAPI () {
        return {
            ...(this.branches !== '0') && {
                'branches': this.branches,
            },
            ...{
                'date': this.date,
                'statuses': this.statuses,
                'sort': this.sort,
                'page': this.page,
            }
        }
    }

}

export class FilterModel {
    #branch_id;
    #manager_id;
    #status_id;
    #scores;

    constructor(filter = {}) {
        this.date = new FilterDateModel(filter.date);
        this.#branch_id = filter.branch_id || [];
        this.#manager_id = filter.manager_id || [];
        this.#status_id = filter.status_id.map(i => i) || [];
        this.#scores = filter.scores.map(i => i) || '';
        this.query = filter.query || '';
        this.sort = new FilterSortModel(filter.sort);
        this.page = new FilterPageModel(filter.page);
    }

    get branch_id() {
        return this.#branch_id.map(val => val.value).join(',');
    }

    get manager_id() {
        return this.#manager_id.map(val => val.value).join(',');
    }

    get status_id() {
        return this.#status_id.map(val => val.value).join(',');
    }

    get scores() {
        return this.#scores.map(val => val.value).join(',');
    }

    getToAPI () {
        return {
            ...(this.branch_id !== '0') && {
                'branch_id': this.branch_id,
            },
            ...(this.manager_id !== '0') && {
                'manager_id': this.manager_id,
            },
            ...{
                'date': this.date,
                'status_id': this.status_id,
                'scores': this.scores,
                'query': this.query,
                'sort': this.sort,
                'page': this.page,
            }
        }
    }
}

export class FilterQRDashboardModel {
    #branches;
    #date;

    constructor(filter = {}) {
        this.#branches = filter.branches || [];
        this.#date = new FilterDateModel(filter.date);
    }

    get branches() {
        return this.#branches.map(val => val.value).join(',');
    }

    get date() {
        return this.#date;
    }

    getToAPI () {
        return {
            ...(this.branches !== '0') && {
                'branches': this.branches,
            },
            ...{
                'date': this.date,
            },
        }
    }

}

export class FilterDashboardModel {
    #branch_id;
    #manager_id;

    constructor(filter = {}) {
        this.#branch_id = filter.branch_id || [];
        this.date = new FilterDateModel(filter.date);
        this.#manager_id = filter.manager_id || [];
    }

    get branch_id() {
        return this.#branch_id.map(val => val.value).join(',');
    }

    get manager_id() {
        return this.#manager_id.map(val => val.value).join(',');
    }

    getToAPI () {
        return {
            ...(this.branch_id !== '0') && {
                'branch_id': this.branch_id,
            },
            ...(this.manager_id !== '0') && {
                'manager_id': this.manager_id,
            },
            ...{
                'date': this.date,
            },

        }
    }

}

class FilterDateModel {
    //TODO убрать лишние new Date();
    constructor(date = {}) {
        this.from = moment(new Date(date.from)).format(process.env.REACT_APP_datePickerFormat).toString()
            || moment(new Date()).format(process.env.REACT_APP_datePickerFormat).toString();

        this.to = moment(new Date(date.to)).format(process.env.REACT_APP_datePickerFormat).toString()
            || moment(new Date()).format(process.env.REACT_APP_datePickerFormat).toString();
    }
}

class FilterSortModel {
    constructor(sort = {}) {
        this.column = sort.column || 'time';
        this.order = sort.order || 'desc';
    }
}

class FilterPageModel {
    constructor(page = {}) {
        this.size = page.size || 10;
        this.number = page.number || 1;
    }
}
