import React from 'react';
import { Toast } from 'primereact/toast';
import { reaction, toJS } from "mobx";
import cn  from 'classnames';
import uiStore from "../../stores/ui";
import './Message.scss';

const toast = React.createRef();
/**
 *
 * @param {boolean} isMobile
 * @returns {JSX.Element}
 * @constructor
 */
const Message = ({isMobile= false}) => {
    return (
        <div>
            <Toast
                ref={toast}
                className={cn({'mobile': isMobile})}
                onRemove={(e)=>{
                    uiStore.message.onRemove(e);
                }}
            />
        </div>
    );
}

/**
 * @param message
 */
const showMessage = (message) => {
    toast.current.show(
        {
            severity: message.severity,
            summary: message.summary,
            detail: message.detail,
            sticky: message.sticky,
            life: message.life,
            closable: message.closable,
        }
    );
}

reaction(
    () => toJS(uiStore.message),
    (message) => {
        showMessage(message);
    }
);


export default Message;
