import React, {useEffect, useRef} from "react";
import cn from "classnames";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_ru_RU from "@amcharts/amcharts5/locales/ru_RU";

const Chart4 = ({data}) => {
    let root = null;
    let container = useRef(null);

    const destroyChart = () => {
        root && root.dispose();
    }

    const initChart = ( data = [], totalHeight = 1200, settings = []) => {

        am5.ready(function () {

            root = am5.Root.new("chart4");
            root.locale = am5locales_ru_RU;

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                paddingLeft: 10,
                paddingRight: 10,
                //paddingBottom: -4,
                layout: root.verticalLayout
            }));

            let xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 30,
                minorGridEnabled: true,
                strokeOpacity: 0.1
            });

            xRenderer.labels.template.setAll({
                paddingTop: 5,
                visible: true,
                fontSize: "11px"
            });

            xRenderer.grid.template.setAll({
                visible: false,
                location: 1
            })

            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: "month",
                renderer: xRenderer,
            }));

            xAxis.data.setAll(data);

            let yRenderer = am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0
            })

            yRenderer.labels.template.setAll({
                visible: false,
            });

            yRenderer.grid.template.setAll({
                visible: false,
                minorGridEnabled: false,
            })

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                min: 0,
                // макс высота столбца (нужно чтобы влез total)
                max: totalHeight, //надо устанавливать динамически от пришедших данных
                strictMinMax: true,
                calculateTotals: true,
                renderer: yRenderer,
            }));

            function makeSeries(name, fieldName, color, data, totals = false) {
                let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                    name: name,
                    stacked: true,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: fieldName,
                    //valueYShow: "valueYTotalPercent",
                    categoryXField: "month",
                }));



                if (!totals) {
                    series.bullets.push(function () {

                        let label = am5.Label.new(root, {
                            text: "{valueY}",
                            fontSize: '11px',
                            fill: '#373947',
                            centerY: am5.p50,
                            centerX: am5.p50,
                            populateText: true
                        })

                        label.adapters.add("text", function(text, target) {
                            const valueY = target.dataItem.get('valueY')
                            return valueY !== 0 ? valueY : '';
                        });

                        return am5.Bullet.new(root, {
                            sprite: label
                        });
                    });
                } else {
                    series.bullets.push(function () {
                        return am5.Bullet.new(root, {
                            sprite: am5.Label.new(root, {
                                text: "{valueYTotal}",
                                fontSize: '11px',
                                fill: '#373947',
                                centerY: 23,
                                centerX: am5.p50,
                                populateText: true
                            })
                        });
                    });
                }


                series.columns.template.setAll({
                    maxWidth: 45,
                    fill: color,
                    stroke: color,
                });

                series.data.setAll(data);

                container.current = root;
            }


            settings.map((item, _) => {
                makeSeries(item.code.toString(), item.code.toString(), item.color.toString(), data);
            })
            makeSeries("none", "none", 'transparent', data, true);

        })

    }

    useEffect(() => {
        initChart(data.data, (data.total*1.1), data.legend)
        return () => destroyChart();
    }, [data])

    return (
        <>
            <div className={cn('chart')} style={{minHeight: "350px"}}>
                <div className={cn('chart__title')}>Распределение переходов по площадкам</div>
                <div className={cn('chart__content')} id={'chart4'}></div>
                <div className={cn('chart__legend')}>
                    <ul>
                        {data.legend?.length && data.legend.map((item, key) => {
                            return <li className={cn('color__c0')} key={key} style={{'--bg-color': item.color}}>{item.title}</li>
                        })}
                    </ul>
                </div>

            </div>

        </>
    )
}

export default Chart4;