import React, { useEffect, useState } from 'react';
import cn from "classnames";
import { sourcesForGeo } from "../../../../../helpers";
import { Sidebar } from "primereact/sidebar";
import {
    phone_icon,
    telegram_icon,
    processing_icon,
    okreview_icon
} from "../../../../../helpers/icons";
import { inject, observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const DataSynchronizationInfo = ({ geoStore }) => {

    const [sources, setSources] = useState([])
    const [visibleCompareSideBar, setVisibleCompareSideBar] = useState(false)

    useEffect(() => {
        setSources(sourcesForGeo())
    }, [])

    const findSourceByCode = (code) => {
        return sources.find(source => source.value === code)
    }

    return (
        <>
            <div className={cn('block', 'synchronization_info')}>
                <div className={cn('block__title')}>
                    <h3>Синхронизация данных</h3>
                </div>
                {sources.length > 0 &&
                    <div className={cn('block__content')}>
                        <div className={cn('content')}>
                            <div className={cn('content__title', 'yandex_map')}>
                                <img src={findSourceByCode('yandex').ico} alt="" width={15} height={15}
                                     title={findSourceByCode('yandex').name}/>
                                {findSourceByCode('yandex').name}
                            </div>
                            <div className={cn('content__status', 'success')}>Опубликован на Яндекс Картах</div>

                            <div className={cn('content__info', 'info__title')}>Последняя проверка:</div>
                            <div className={cn('content__info')}>20:00, 03 июля</div>
                            <div
                                className={cn('content__info', 'warning')}
                                style={{ cursor: 'pointer' }}
                                onClick={() => setVisibleCompareSideBar(true)}
                            >
                                <span className={cn('pi', 'pi-times')}></span>
                                2 расхождения
                                <span className={cn('pi', 'pi-arrow-right')}></span>
                            </div>
                            <CompareSideBar
                                visibleCompareSideBar={visibleCompareSideBar}
                                setVisibleCompareSideBar={setVisibleCompareSideBar}
                                findSourceByCode={findSourceByCode}
                            />

                        </div>
                        <div className={cn('content')}>
                            <div className={cn('content__title', 'google_map')}>
                                <img src={findSourceByCode('google').ico} alt="" width={15} height={15}
                                     title={findSourceByCode('google').name}/>
                                {findSourceByCode('google').name}
                            </div>
                            <div className={cn('content__status', 'success')}>Опубликован на Яндекс Картах</div>

                            <div className={cn('content__info', 'info__title')}>Последняя проверка:</div>
                            <div className={cn('content__info')}>20:00, 03 июля</div>
                            <div className={cn('content__info', 'success')}><span
                                className={cn('pi', 'pi-check')}></span>Расхождений нет
                            </div>
                        </div>
                        <div className={cn('content')}>
                            <div className={cn('content__title', 'soon')}>
                                <img src={findSourceByCode('2gis').ico} alt="" width={15} height={15}
                                     title={findSourceByCode('2gis').name}/>
                                {findSourceByCode('2gis').name}
                            </div>
                            <div className={cn('content__status', 'soon')}>Данные скоро появятся</div>

                            <div className={cn('content__info', 'info__title')}>Последняя проверка:</div>
                            <div className={cn('content__info')}>20:00, 03 июля</div>
                            <div className={cn('content__info', 'warning')}>
                                <span className={cn('pi', 'pi-times')}></span>
                                10 расхождения
                                <span className={cn('pi', 'pi-arrow-right')}></span>
                            </div>

                        </div>
                        <div className={cn('content')}>
                            <div className={cn('content__title', 'soon')}>
                                ZOON
                            </div>
                            <div className={cn('content__status', 'soon')}>Данные скоро появятся</div>
                        </div>
                        <div className={cn('content')}>
                            <div className={cn('content__title', 'not_published')}>
                                OTZOVIK
                            </div>
                            <div className={cn('content__status', 'not_published')}>Не опубликован</div>

                        </div>
                    </div>}
            </div>
        </>
    )
}

const CompareSideBar = inject('geoStore')(observer(({
                                                        visibleCompareSideBar,
                                                        setVisibleCompareSideBar,
                                                        findSourceByCode,
                                                        geoStore
                                                    }) => {

    const [data, setData] = useState(null)
    const [categories, setCategories] = useState([])
    const [workTime, setWorkTime] = useState([])
    const [isNotDiff, setIsNotDiff] = useState(false)
    const [isWork5Week, setIsWork5Week] = useState(false)

    useEffect(() => {
        setData(geoStore.form)
    }, [])

    useEffect(() => {
        if (data) {
            setCategories(getCategoryBySource('yandex'))
        }

        if (geoStore.dataModel.items.length > 0) {
            setWorkTime(geoStore.dataModel.items.find(dm => dm.code === 'work-time').value)
        }
    }, [data])


    useEffect(() => {
        if (workTime.length > 0) {
            if (workTime.length === 7) {
                const diff = workTime.every((item, index, ar) => {
                    return JSON.stringify(item.work) === JSON.stringify(ar[0].work)
                })
                setIsNotDiff(diff)
            }

            if (workTime.length === 5) {
                const work5Week = workTime.map((item,index, ar) => {
                    if([1,2,3,4,5].includes(item.day)) {
                        if(JSON.stringify(item.work) === JSON.stringify(ar[0].work)) {
                            return true
                        }
                    }
                })

                if([...new Set(work5Week)].length === 1 && [...new Set(work5Week)][0] === true) {
                    //console.log([...new Set(work5Week)])
                    setIsWork5Week(true)
                }
            }
        }
    }, [workTime])


    const getCategoryBySource = (source = 'yandex') => {
        const sp = data.setupPlace.find(sp => {
            return sp.code === 'legalInfoSetup'
        })

        const block = sp.blocks.find(s => s.source === source)
        return block.fields[0].fieldValue || []
    }

    const weekDay = {
        "1": "Пн",
        "2": "Вт",
        "3": "Ср",
        "4": "Чт",
        "5": "Пт",
        "6": "Сб",
        "7": "Вс"
    }

    return (

        <Sidebar
            visible={visibleCompareSideBar}
            position="bottom"
            onHide={() => setVisibleCompareSideBar(false)}
            className={cn('side-bar', 'compare-side-bar')}
            blockScroll={true}
            icons={() => (
                <button
                    className={cn('p-link', 'close-text')}
                    onClick={() => setVisibleCompareSideBar(false)}
                >
                    Закрыть
                </button>
            )}

        >
            <div className={cn('compare-side-bar__wrapper')}>
                {data?.title && <div className={cn('compare-side-bar__header')}>
                    <div className={cn('compare-side-bar__title')}>
                        <b>{data.title.value}</b><br/><span
                        style={{ fontSize: '12px' }}>{data.address.value.postcode} {data.address.value.city} {data.address.value.street} {data.address.value.building}</span>
                    </div>
                    <div className={cn('compare-side-bar__subtitle')}>
                        <div className={cn('compare-side-bar__subtitle-text')}>5 расхождений</div>
                        <div className={cn('compare-side-bar__subtitle-date')}>
                            <img src={processing_icon} width={20} height={15} alt=""/>
                            20:00, 03 июля 2024
                        </div>
                    </div>
                </div>}
                <div className={cn('table', 'data-compare-table', 'company-data-compare-table')}>
                    <div className={cn('table__wrapper')}>
                        <div className={cn('table')}>
                            <div className={cn('table__header')}>
                                <div className={cn('table__row')} style={{ "--my-repeat": 8 }}>
                                    <div className={cn('table__cell')}>Источник</div>
                                    <div className={cn('table__cell')}>Название</div>
                                    <div className={cn('table__cell')}>Адрес</div>
                                    <div className={cn('table__cell')}>Категории</div>
                                    <div className={cn('table__cell')}>Телефоны, мессенджеры, эл.почта</div>
                                    <div className={cn('table__cell')}>Сайт и соцсети</div>
                                    <div className={cn('table__cell')}>График работы</div>
                                    <div className={cn('table__cell')}>Способ оплаты</div>
                                </div>
                            </div>
                            {data?.title && <div className={cn('table__body')}>
                                <div className={cn('table__row')} style={{ "--my-repeat": 8 }}>
                                    <div className={cn('table__cell', 'cell')}>
                                        <img src={findSourceByCode('yandex').ico} alt="" width={14} height={14}
                                             title={findSourceByCode('yandex').name}/>Яндекс
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>Аванта-Запад</div>
                                    <div className={cn('table__cell', 'cell')}>Московская область,
                                        Москва, пр-т
                                        Ленина, 26
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>1841000031 Магазин
                                        розничной торговли
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <ul className={cn('phone-list')}>
                                            <li>
                                                <img src={phone_icon} width={14} height={14} alt={''}/>
                                                <span>+7 000 000-00-00 (горячая линия и заказ доставки)</span>
                                            </li>
                                            <li>
                                                <img src={phone_icon} width={14} height={14} alt={''}/>
                                                <span className={cn('color__red')}>+7 111 000-00-00 (горячая линия и заказ доставки)</span>
                                            </li>
                                            <li>
                                                <img src={telegram_icon} width={10} height={10} alt={''}/>
                                                <span>auto-mgn.ru</span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className={cn('table__cell', 'cell')}>https://www.auto-mgn.ru</div>
                                    <div className={cn('table__cell', 'cell')}>
                                        ежедневно<br/>8:00-20:00
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        Наличные<br/>Карта
                                    </div>
                                </div>
                                <div className={cn('table__row')} style={{ "--my-repeat": 8 }}>
                                    <div className={cn('table__cell', 'cell')}>
                                        <img src={okreview_icon} width={14} height={14} alt=''/>
                                        OK Review
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>{data.title.value}</div>
                                    <div className={cn('table__cell', 'cell')}>
                                        {data.address.value.region},&nbsp;
                                        <nobr>{data.address.value.city},</nobr>
                                        <br/>
                                        <nobr>{data.address.value.street},</nobr>
                                        <nobr>{data.address.value.building}</nobr>
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        {categories.length > 0 && categories.map((item, index) => {
                                            return (
                                                <span className={'category'} key={index}>
                                                    {item.id}&nbsp;{item.title}
                                                    {index !== categories.length - 1 && ', '}
                                                </span>
                                            )
                                        })}
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <ul className={cn('phone-list')}>
                                            {data.phones?.value.length > 0 && data.phones.value.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon icon={['solid', 'mobile-screen']} fixedWidth /></span>{item.number}
                                                    </li>
                                                )
                                            })}

                                            {data.emails?.value.length > 0 && data.emails.value.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon icon={['regular', 'envelope']} fixedWidth/></span>{item}
                                                    </li>
                                                )
                                            })}

                                            {data.whatsapp_phone?.value && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon icon={['brands', 'whatsapp']} fixedWidth /></span>{data.whatsapp_phone.value}
                                            </li>}
                                            {data.tg_link?.value && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon icon={['brands', 'telegram']} fixedWidth /></span>{data.tg_link.value}
                                            </li>}
                                            {data.viber_phone?.value && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon icon={['brands', 'viber']} fixedWidth/></span>
                                                {data.viber_phone.value}
                                            </li>}
                                        </ul>
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        <ul className={cn('phone-list')}>
                                            {data.websites.value.length > 0 && data.websites.value.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <span className={cn('phone-list__icon-wrapper')}>
                                                            <FontAwesomeIcon icon={['solid', 'earth-europe']} fixedWidth /></span>
                                                        {item}
                                                    </li>
                                                )
                                            })}
                                            {data.vk_url?.value && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon icon={['brands', 'vk']} fixedWidth /></span>{data.vk_url?.value}</li>}
                                            {data.youtube_link?.value && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon icon={['brands', 'youtube']} fixedWidth /></span>{data.youtube_link?.value}</li>}
                                            {data.ok_link?.value && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon icon={['brands', 'odnoklassniki']} fixedWidth /></span>{data.ok_link?.value}</li>}
                                            {data.twitter_link?.value && <li>
                                                <span className={cn('phone-list__icon-wrapper')}><FontAwesomeIcon icon={['brands', 'x-twitter']} fixedWidth /></span>{data.twitter_link?.value}</li>}
                                        </ul>
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        {/*TODO В отдельный компонент*/}
                                        {workTime.length > 0 && isNotDiff ?
                                            <>ежедневно<br/>{workTime[0].work.start}-{workTime[0].work.finish}</>
                                            : isWork5Week ?
                                                <>
                                                    Пн-Пт:
                                                    <br/>{workTime[0].work.start}-{workTime[0].work.finish}
                                                    <br/>
                                                    Сб-Вс:
                                                    <br/> выходной
                                                </>
                                                : <ul>
                                                    {workTime.map((item, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <span style={{'width': '22px', 'display': 'inline-block'}}>{weekDay[item.day]}:</span>
                                                                <nobr>{item.work.start}-{item.work.finish}</nobr>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                        }
                                    </div>
                                    <div className={cn('table__cell', 'cell')}>
                                        ????
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

        </Sidebar>
    )

}))


export default inject('geoStore')(observer(DataSynchronizationInfo));