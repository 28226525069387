import { useEffect, useRef } from "react";

/**
 *
 * @example
 * const isMount = useIsMount();
 * useEffect(() => {
 *     if (isMount) {
 *         console.log('First Render');
 *     } else {
 *         console.log('Subsequent Render');
 *     }
 * });
 *
 * @returns {boolean}
 */
const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
};

export {
    useIsMount
};

