export class ManagerModel {
    constructor(manager = {}) {
        this._id = manager.id || null;
        this._fullname = manager.fullname || ''
        this._phone = manager.phone || '';
        this._email = manager.email || '';
        this._services = manager.services || [];
    }

    get id() {
        return this._id;
    }

    get fullname() {
        return this._fullname;
    }

    get phone() {
        return this._phone;
    }

    get email() {
        return this._email;
    }

    get services() {
        return this._services;
    }

    get firstname() {
        return this._fullname.split(' ')[1];
    }

    get lastname() {
        return this._fullname.split(' ')[0];

    }

    get fullName() {
        return this._fullname;
    }
}
