import cn from "classnames";
import React from "react";

const ColoringText = ({value= 0, af}) => {
    return (
        <span className={cn(
            { 'text-color--gray': Number(value) === 0 },
        )}>
            {Number(`${value}`).toLocaleString('ru-RU')}{af}
        </span>
    )
}
export default ColoringText;