import React from "react";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import MessengerService from 'services/telegram';
import { Button } from 'primereact/button';
import './TelegramLoginButton.scss';

const TelegramLogin = inject('uiStore')(observer(({uiStore}) => {

    return (
        <>
            <div className={cn('page__analytics', 'page')}>
                <div className={cn('title')}>
                    <h1>Настройка уведомлений</h1>
                </div>
                <div>
                    <div className="card">
                        <div className={'telegram__wrapper'}>
                            <Button
                                label={'Подключиться'}
                                onClick={()=>{
                                    MessengerService.connectTelegram().then(result => {
                                        let link = document.createElement("a")
                                        link.href = result.link
                                        link.target = "_blank"
                                        link.click()
                                    });

                                }}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )

}));

export default TelegramLogin;
