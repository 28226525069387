import { ServiceModel } from "./service";

export class ProfileModel {
    constructor(user = {}, client = {}, roles = [], allowed = []) {

        this._user = new UserModel(user) || {};
        this._client = new ClientModel(client) || {};
        //this._roles = roles.map(i => new RoleModel(i)) || [];
        this._roles = (roles) ? roles : [];
        this._allowed = allowed || [];

    }

    get user() {
        return this._user;
    }

    get client() {
        return this._client;
    }

    get roles() {
        return this._roles;
    }

    get allowed() {
        return this._allowed;
    }
}

export class UserModel {

    constructor(user = {}) {
        this._id = user.id || null;
        this._firstname = user.firstname || '';
        this._lastname = user.lastname || '';
        this._phone = user.phone || '';
        this._email = user.email || '';
        this._notifyEmail = user.notifyEmail || '';
        this._createdAt = user.createdAt || null;
    }

    get id() {
        return this._id;
    }

    get firstname() {
        return this._firstname;
    }

    get lastname() {
        return this._lastname;
    }

    get phone() {
        return this._phone;
    }

    get email() {
        return this._email;
    }

    get notifyEmail() {
        return this._notifyEmail;
    }

    get createdAt() {
        return this._createdAt;
    }

}

export class ClientModel {
    constructor(client = {}) {
        this._id = client.id || null;
        this._title = client.title;
        this._reviewCollectorTemplate = client.reviewCollectorTemplate || null;
        this._createdAt = client.createdAt || null;

    }

    get id() {
        return this._id;
    }

    get title() {
        return this._title;
    }

    get reviewCollectorTemplate() {
        return this._reviewCollectorTemplate;
    }


    get createdAt() {
        return this._createdAt;
    }
}

export class RoleModel {

    constructor(roles = {}) {
        this._branches = [];
        this._type = roles.type;

        roles.branches.forEach(service => {
            this._branches.push(new ServiceModel(service));
        })
    }

    get type() {
        return this._type;
    }

    get services() {
        return this._services;
    }
}

