import { getDetailDataByPlatform, getStatusAvitoPub } from "../../../../helpers";
import React from "react";
import cn from "classnames";
import {AD_PLATFORM_TYPE} from "../../../../models/ad-platform"
import avito_custom_icon from "../../../../images/platforms_icon_final/06_0.png";
import "./SourceList.scss"



const SourceList = ({sourceList = [], avitopub = ''}) => {
    const {avitopub_status, title} = getStatusAvitoPub(avitopub);

    if(avitopub_status) {
        sourceList = sourceList.filter(item => item !== AD_PLATFORM_TYPE.AVITO)
    }

    return (
        <ul className={'source-list'}>
            {avitopub_status &&
                <SourceItemWithError
                    avitopub_status={avitopub_status}
                    title={title}
                />
            }
            {sourceList.map((item, key) => {
                return <SourceItem item={item} key={key + item} avitopub={avitopub}/>
            })}
        </ul>
    )
};

const SourceItemWithError = ({avitopub_status = '', title = ''}) => {
    const { name } = getDetailDataByPlatform(AD_PLATFORM_TYPE.AVITO);
    return (
        <li className={cn('type_status', `${avitopub_status}`)}>
            <img src={avito_custom_icon} alt="" width={20} height={20} title={name}/>
            <span>{title}</span>
        </li>
    )
}

const SourceItem = ({item = ''}) => {
    const { name, ico} = getDetailDataByPlatform(item);

    return (
        <>
            <li>
                <img src={ico} alt="" width={20} height={20} title={name}/>
            </li>
        </>
    )
}

export default SourceList;