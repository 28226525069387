import React from "react";
import cn from "classnames";

const Empty = ({title}) => {
    return (
        <>
            <div className={cn('chart')} style={{minHeight: "150px"}}>
                <div className={cn('chart__title')}>{title}</div>
                <div className={cn('chart__content')}>
                    <div className={cn('empty__message')}>Нет данных</div>
                </div>
            </div>
        </>
    )
}

export default Empty;