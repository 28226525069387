export default class Claim {
    #count;
    #status;
    #allowed;
    #okd;

    constructor(claim) {
        this.#count = claim.count || null;
        this.#status = claim.status || null;
        this.#allowed = claim.allowed || null;
        this.#okd = claim.okd || null;
    }

    /**
     * Количество жалоб на отзыв
     * @returns {number}
     */
    get count() {
        return this.#count;
    }

    /**
     * Статус активной жалобы (всегда одна)
     * @returns {string|null}
     */
    get status() {
        return this.#status;
    }

    /**
     * Признак возможности обжаловать отзыв
     * @returns {boolean}
     */
    get allowed() {
        return this.#allowed;
    }

    /**
     * Признак обжалования с OkReview
     * @returns {boolean}
     */
    get okd() {
        return this.#okd;
    }

}
