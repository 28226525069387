import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import _ from 'underscore';
import cn from "classnames";
import { getDetailDataByPlatform } from '../../../helpers/index';
import './Integration.scss';

import { FilterButton, RequiresAttentionButton } from "../../common/FilterButton";
import { inject, observer } from "mobx-react";
import { ToolTip } from "../../common/ToolTip";

import QrCode from "../../../images/qr_ico.png";
import {Link} from "react-router-dom";
import Download from "../review/list/common/Download";

const Integration = ({ integrationStore, uiStore, userStore }) => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [immutableItems, setImmutableItems] = useState([]);
    const { branches, filterSources } = integrationStore;

    const [branch, setBranch] = useState([]);
    const [source, setSource] = useState([]);
    const [countAttention, setCountAttention] = useState(0);
    const [attentionList, setAttentionList] = useState([]);
    const [disabledFilter, setDisabledFilter] = useState(false);
    const [visibleAttentionList, setVisibleAttentionList] = useState(false);

    const {attention} = useParams();

//Фильтрация данных
    useEffect(() => {

        const arrBranchId = parseBranchFilter();
        const arrSourceId = parseSourceFilter();

        let filteredByBranch = [];
        filteredByBranch = immutableItems.filter((val) => {
            return _.indexOf(arrBranchId, val.id) !== -1;
        })

        if (filteredByBranch.length === 0) {
            filteredByBranch = [
                ...filteredByBranch,
                ...immutableItems,
            ]
        }

        let filteredBySource = []

        filteredByBranch.forEach((item, key) => {
            const result = item.adplatforms.filter((val) => {
                return _.indexOf(arrSourceId, val.type.code) !== -1;
            });

            if (result.length !== 0) {

                filteredBySource[key] = {
                    ...item,
                    ...{
                        adplatforms: result
                    }
                };

            } else {
                filteredBySource = [
                    ...filteredByBranch
                ]
            }
        });

        !!filteredBySource.length ? setItems(filteredBySource) : setItems(immutableItems);

    }, [branch, source])

    useEffect(() => {
        setItems(immutableItems)
    }, [immutableItems])

    useEffect(() => {
        setLoading(true);
        integrationStore.getList().then(result => {
            setImmutableItems(result)
            integrationStore.getBranch();
            integrationStore.getSources();
            setCountAttention(integrationStore.getAttention().countAttention);
            setAttentionList(integrationStore.getAttention().data)
            if(!!attention) {
                setItems(attentionList)
                setVisibleAttentionList(true);
                setDisabledFilter(true);
            }

            setLoading(false);
        })
    }, []);

    useEffect(() => {
        visibleAttentionList === true ? setItems(attentionList) : setItems(immutableItems);
    },[visibleAttentionList])

    const getAttentionList = () => {
        setVisibleAttentionList(prev => !prev);
        setDisabledFilter(prev => !prev);
        setSource([]);
        setBranch([]);
    }

    const resetFilter = () => {
        setItems(immutableItems);
        setDisabledFilter(false);
        setSource([]);
        setBranch([]);
    }

    const parseBranchFilter = () => {
        let arrBranchId = [];
        if (!!branch.length) {
            branch.forEach((val) => {
                arrBranchId.push(val.value)
            });
        }
        return arrBranchId;
    }

    const parseSourceFilter = () => {
        let arrSourceId = []
        if (!!source.length) {
            source.forEach((val) => {
                arrSourceId.push(val.value)
            });
        }
        return arrSourceId;
    }

    const parseFilterForXls = () => {
        return {branches: branch, adplatforms: source, attention: visibleAttentionList ? 1: 0 };
    }

    return (
        <>
            <div className={cn('page__integration-list', 'page')}>
                <div className={'integration-list'}>
                    <>
                        <div className={'title'}>
                            <h1>Интеграции</h1>
                            <Download
                                filter={parseFilterForXls()}
                                isMobile={false}
                                uiStore={uiStore}
                                userStore={userStore}
                                dest={'integration'}
                            />
                        </div>
                        {loading && <div className={cn('form-loading')} />}
                        <div className={cn('integration-list-filter')}>
                            <div className={cn('filter__wrapper')}>
                                <div className={cn('filter__item')}>
                                    <FilterButton
                                        name='Филиал'
                                        list={branches}
                                        onChange={(e) => {
                                            setBranch(e);
                                        }}
                                        onClear={() => {
                                            setBranch([]);
                                        }}
                                        filter={branch}
                                        loading={loading}
                                        search={true}
                                        disabled={disabledFilter}
                                    />
                                </div>
                                <div className={cn('filter__item')}>
                                    <FilterButton
                                        name='Источник'
                                        list={filterSources}
                                        onChange={(e) => {
                                            setSource(e);
                                        }}
                                        onClear={() => {
                                            setSource([])
                                        }}
                                        filter={source}
                                        loading={loading}
                                        search={true}
                                        disabled={disabledFilter}
                                    />
                                </div>
                                <div className={cn('filter__item')}>
                                    <RequiresAttentionButton
                                        name='Требует внимания'
                                        handlerClick={getAttentionList}
                                        selectedItems={[]}
                                        count={countAttention}
                                        active={disabledFilter}
                                    />
                                </div>
                                <div
                                    className={'filter__clear-btn'}
                                    onClick={resetFilter}
                                >Очистить все
                                </div>
                            </div>
                        </div>
                        <div className={cn('integration-list__table')}>
                            <div className={cn('integration-list__wrapper')}>
                                <div className="p-datatable p-component">
                                    <div className="p-datatable-wrapper">
                                        <table role="grid">
                                            <thead className="p-datatable-thead">
                                            <tr>
                                                <th className={cn('header-title')}>
                                                    Филиал <span>{items.length}</span>
                                                </th>
                                                <th className={cn('header-source')}>Источник</th>
                                                <th className={cn('header-status')}>
                                                    <div>
                                                        <span>Проактивный сбор отзывов</span>
                                                        <div className={'tooltip__wrapper'}>
                                                            <ToolTip>
                                                                Мы собираем положительные отзывы и способствуем их публикации для выбранных вами площадок. Результаты можно отслеживать в разделе списка заявок
                                                            </ToolTip>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className={cn('header-monitoring')}>
                                                    <div>
                                                        <span>Мониторинг отзывов</span>
                                                        <div className={'tooltip__wrapper'}>
                                                            <ToolTip>
                                                                Мониторинг оптимизирует вашу работу и значительно сократит время на взаимодействие с отзывами ваших клиентов в онлайне
                                                            </ToolTip>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className={cn('header-qrcode', 'text-align-right')}>
                                                    <span>QR-коды</span>
                                                </th>

                                            </tr>
                                            </thead>
                                            <tbody className={cn('p-datatable-tbody')}>
                                            <>
                                                {items.map((item, key) => (
                                                    <React.Fragment key={`${key}_${item.id}`}>
                                                        <tr className={cn('p-datatable-row')}>
                                                            <td colSpan={4} className={cn('branch-name')}>
                                                                <span>{item.title}</span>
                                                            </td>
                                                            <td>
                                                                <Link
                                                                    to={`/booklet/${item.id}`}
                                                                    className={cn('qr-btn')}
                                                                >
                                                                    <img src={QrCode} alt="Печать QR" width={12} height={12}/>
                                                                    <span>Печать QR</span>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                        {item.adplatforms.map((platform, p_key) => (
                                                            <tr className={cn('p-datatable-row')}
                                                                key={`${p_key}_${platform.id}`}
                                                            >
                                                                <td>&nbsp;</td>
                                                                <td>
                                                                <span className={cn('name', {'disabled': platform.connections.notConnected})}>
                                                                    {!platform.connections.notConnected && <a href={platform.accountUrl} target={'_blank'}>
                                                                        <img src={getDetailDataByPlatform(platform.type.code)?.ico} alt="" width={21} height={21}/>
                                                                        {platform.type.name}
                                                                    </a>}
                                                                    {platform.connections.notConnected && <span>
                                                                        <img src={getDetailDataByPlatform(platform.type.code)?.ico} alt="" width={21} height={21}/>
                                                                        {platform.type.name}
                                                                    </span>}
                                                                </span>
                                                                </td>
                                                                <td>
                                                                    {(platform.connections.publisher || platform.connections.collector) && <>
                                                                        {platform.connections.collector?.status === 'on' &&
                                                                            <span className={cn('p-tag', 'status-tag')}
                                                                            >Подключен</span>}
                                                                        {platform.connections.collector?.status === 'off' &&
                                                                            <span
                                                                                className={cn('p-tag', 'status-tag', 'grey')}
                                                                            >Выключен</span>}
                                                                        {platform.connections.publisher?.status === 'auth' &&
                                                                            <span
                                                                                className={cn('p-tag', 'status-tag', 'red')}
                                                                            >Ошибка авторизации</span>}
                                                                        {platform.connections.publisher?.status === 'error' &&
                                                                            <span
                                                                                className={cn('p-tag', 'status-tag', 'red')}
                                                                            >Ошибка</span>}
                                                                        {platform.connections.publisher?.status === 'pending' &&
                                                                            <span
                                                                                className={cn('p-tag', 'status-tag', 'yellow')}
                                                                            >Загрузка данных...</span>}

                                                                    </>}
                                                                </td>
                                                                <td>
                                                                    {platform.connections.parser && <>
                                                                        {platform.connections.parser.status === 'on' &&
                                                                            <span className={cn('p-tag', 'status-tag')}
                                                                            >Подключен</span>}
                                                                        {platform.connections.parser.status === 'off' &&
                                                                            <span
                                                                                className={cn('p-tag', 'status-tag', 'grey')}
                                                                            >Выключен</span>}
                                                                        {platform.connections.parser.status === 'auth' &&
                                                                            <span
                                                                                className={cn('p-tag', 'status-tag', 'red')}
                                                                            >Ошибка авторизации</span>}
                                                                        {platform.connections.parser.status === 'error' &&
                                                                            <span
                                                                                className={cn('p-tag', 'status-tag', 'red')}
                                                                            >Ошибка</span>}

                                                                        {platform.connections.parser.status === 'pending' &&
                                                                            <span
                                                                                className={cn('p-tag', 'status-tag', 'yellow')}
                                                                            >Загрузка данных...</span>}

                                                                    </>}
                                                                    {platform.connections.needAuth && <><span
                                                                        className={cn('p-tag', 'status-tag', 'grey')}
                                                                    >Требуется авторизация</span></>}
                                                                </td>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                        ))}
                                                    </React.Fragment>
                                                ))}
                                            </>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                </div>
            </div>
        </>
    );
};

export default inject('integrationStore', 'uiStore', 'userStore')(observer(Integration));

