class NpsModel {
    constructor(nps= {}) {
        this._score = nps.score || null;
        this._subdivision = nps.subdivision || '';
        this._answer = nps.answer || '';
    }

    get score() {
        return this._score;
    }

    get subdivision() {
        return this._subdivision;
    }
    get answer() {
        return this._answer;
    }
}

export default NpsModel;
