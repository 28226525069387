import React, { useEffect } from "react";

import ReviewsMobile from "./ReviewsMobile";
import ReviewsDesktop from "./ReviewsDesktop";
import okAnalyticsService from "../../../../services/okAnalytics";
import '../Analytics.scss';

import {
    isMobile
} from "react-device-detect";


const ReviewsCommon = () => {

    useEffect(() => {
        okAnalyticsService.addEntity({
            page: 'analytics_reviews',
            target: 'page',
            event: 'open',
        });
    }, []);

    const getClientWidth = () => {
        return document.documentElement.clientWidth;
    }

    return (
        <>
            {(isMobile && getClientWidth() <= 1024) ? <ReviewsMobile /> : <ReviewsDesktop />}
        </>
    );

}

export default ReviewsCommon;
