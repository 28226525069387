import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faXTwitter,
    faWhatsapp,
    faTelegram,
    faViber,
    faVk,
    faYoutube,
    faOdnoklassniki
} from '@fortawesome/free-brands-svg-icons';

import {
    faMobileScreen,
    faEarthEurope,
} from '@fortawesome/free-solid-svg-icons';
//<FontAwesomeIcon icon="fa-regular fa-earth-europe" />
import {
    faEnvelope,
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faXTwitter,
    faWhatsapp,
    faTelegram,
    faViber,
    faVk,
    faYoutube,
    faOdnoklassniki,
    faEnvelope,
    faMobileScreen,
    faEarthEurope
);