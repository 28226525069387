export default class Branch {
    constructor(branch) {
        this._id  = branch.id || null;
        this._title  = branch.title || '';
    }

    /**
     * @return {int|null}
     */
    get id() {
        return this._id;
    }

    /**
     * @return {string|null}
     */
    get title() {
        return this._title;
    }

}
