class PersonModel {
    constructor(person = {}) {
        this._lastname = person.lastname || '';
        this._firstname = person.firstname || '';
        this._phone = person.phone || '';
        this._email = person.email || '';
    }

    get lastname() {
        return this._lastname;
    }

    get firstname() {
        return this._firstname;
    }

    get phone() {
        return this._phone;
    }

    get email() {
        return this._email;
    }
}


export default PersonModel;