import React from 'react';
import 'react-dates/initialize';

import "../fontawesome.js"
import moment from "moment-timezone";
// eslint-disable-next-line no-unused-vars
import ru from "moment/locale/ru";
import { observer, inject } from "mobx-react";
import { isMobile } from "react-device-detect";
import External from "./layouts/External";
import Internal from "./layouts/Internal";
import authService from "../services/auth";


import YandexMetrika from "./common/YandexMetrika";
import Message from "./common/Message";
import './App.scss';
import { ConfigProvider } from "antd";

moment.tz.setDefault("Europe/Moscow");
moment.locale('ru');

const App = ({ userStore }) => {

    if (!userStore.isAuthorized) {
        authService.setPrevUrl(window.location.pathname)
    }
    return (
        <>
            <ConfigProvider
                theme={
                    {
                        token: {
                            activeShadow: 0,
                            borderRadius: '2px',
                            fontFamily: "'Stolzl Book', 'Stolzl', Arial, Helvetica, sans-serif",
                        },
                        components: {
                            Select: {
                                colorBorder: '#343546',
                                colorPrimaryHover: '#343546',
                            }
                        }
                    }
                }
            >
                {<YandexMetrika/>}
                {userStore.isAuthorized !== null ? <React.Fragment>
                    {userStore.isAuthorized ? <Internal/> : <External/>}
                </React.Fragment> : ''}
                <Message isMobile={isMobile}/>
            </ConfigProvider>
        </>

    );
}

export default inject('userStore')(observer(App));

