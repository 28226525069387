import PersonModel from "./person";
import moment from "moment-timezone";

class ReviewModel {
    constructor(review = {}) {
        this._author = new PersonModel(review.author || {});
        this._rating = review.rating || null;
        this._text = review.text || '';
        this._time = review.time || null;
        this._adplatform = review.adplatform || {};
        this._avitopub = review.avitopub || '';
    }

    get author() {
        return this._author;
    }

    get rating() {
        return this._rating;
    }

    get text() {
        return this._text;
    }

    get adplatform() {
        return this._adplatform;
    }

    get avitopub() {
        return this._avitopub;
    }



    get time() {
        return moment
            .utc(this._time)
            .tz(process.env.REACT_APP_timeZone)
            .format(process.env.REACT_APP_dateTimeDisplayFormat)
            .toString()
    }

}

export default ReviewModel;
