//TODO если не понадобится, перенесу в сервис.
class OkAnalyticsStore {
    #data;

    constructor() {
        this.data = {
            events: [],
            userId: null,
        };
    }

    get data() {
        return this.#data;
    }

    set data(data) {
        this.#data = data;
    }

    clearData() {
        this.#data = {
            events: [],
            userId: null,
        };
    }
}



const okAnalyticsStore = new OkAnalyticsStore();
export default okAnalyticsStore;
