import {InputMask} from "primereact/inputmask";
import {InputText} from "primereact/inputtext";
import React from "react";
import ReactDOM from "react-dom";


class OkInputMask extends InputMask {
    errorClass = 'error';

    className = [];

    constructor(props) {
        super(props);

        if(this.props.className) {
            this.className = this.props.className.split(' ');
        }
    }

    init() {
        if (this.props.mask) {
            this.tests = [];
            this.partialPosition = this.props.mask.length;
            this.len = this.props.mask.length;
            this.firstNonMaskPos = null;
            this.defs = {
                '9': '[0-9]',
                'n': '[1234569]',
                'a': '[A-Za-z]',
                '*': '[A-Za-z0-9]'
            };

            this.androidChrome = /chrome/i.test(navigator.userAgent) && /android/i.test(navigator.userAgent);

            let maskTokens = this.props.mask.split('');
            for (let i = 0; i < maskTokens.length; i++) {
                let c = maskTokens[i];
                if (c === '?') {
                    this.len--;
                    this.partialPosition = i;
                } else if (this.defs[c]) {
                    this.tests.push(new RegExp(this.defs[c]));
                    if (this.firstNonMaskPos === null) {
                        this.firstNonMaskPos = this.tests.length - 1;
                    }
                    if (i < this.partialPosition) {
                        this.lastRequiredNonMaskPos = this.tests.length - 1;
                    }
                } else {
                    this.tests.push(null);
                }
            }

            this.buffer = [];
            for (let i = 0; i < maskTokens.length; i++) {
                let c = maskTokens[i];
                if (c !== '?') {
                    if (this.defs[c])
                        this.buffer.push(this.getPlaceholder(i));
                    else
                        this.buffer.push(c);
                }
            }
            this.defaultBuffer = this.buffer.join('');
        }
    }

    onBlur(e) {
        super.onBlur(e)
        // всю валидацию можно сделать при вызове компонента
        // но для примера временно сделаем так
        if(this.props.required) {
            if(this.props.value === '') {
                this.className.push('p-invalid');
            }
            else {
                this.className = this.className.filter(c => c !== 'p-invalid');
            }
        }

    }


    render() {
        return (
            <InputText
                id={this.props.id}
                ref={(el) => this.input = ReactDOM.findDOMNode(el)}
                type={this.props.type}
                name={this.props.name}
                style={this.props.style}
                className={this.className.join(' ')}
                placeholder={this.props.placeholder}
                size={this.props.size}
                maxLength={this.props.maxlength}
                tabIndex={this.props.tabindex}
                disabled={this.props.disabled}
                readOnly={this.props.readonly}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onKeyDown={this.onKeyDown}
                onKeyPress={this.onKeyPress}
                onInput={this.onInput}
                onPaste={this.handleInputChange}
                required={this.props.required}/>
        );
    }
}

export default OkInputMask;
