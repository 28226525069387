import React, {useEffect} from "react";
import {YMInitializer} from 'react-yandex-metrika';
import {useLocation} from "react-router-dom";

const yaId = process.env.REACT_APP_yaMetrikaId;
const yaExtreviewsId = process.env.REACT_APP_yaMetrikaId_extreviews;
const yaMetrikaId_OK1330 = process.env.REACT_APP_yaMetrikaId_OK1330

const hit = (pathname) => {
    if (yaId) {
        setTimeout(()=>{
            try {
                window[`yaCounter${yaId}`].hit(pathname);
            } catch (e) {}
        }, 500)
    }

    if (yaExtreviewsId) {
        if (/^(\/review)|^(\/analytics)/.test(pathname)) {
            setTimeout(()=>{
                try {
                    window[`yaCounter${yaExtreviewsId}`].hit(pathname);
                } catch (e) {}
            }, 500)
        }
    }
};

const usePageViews = () => {
    let location = useLocation();

    useEffect(
        () => {
            hit(location.pathname);
        },
        [location]
    );
};

const YandexMetrika = () => {
    usePageViews();

    return (
        <>
            {yaId && <div
                style={{'position': 'absolute', 'left': '-9999px'}}
            >
                <YMInitializer
                    accounts={[yaId, yaExtreviewsId, yaMetrikaId_OK1330]}
                    options={
                        {
                            clickmap: true,
                            trackLinks: true,
                            accurateTrackBounce: true,
                            webvisor: false,
                        }
                    }
                />
            </div>}
        </>
    );
}

export default YandexMetrika;
