import moment from "moment-timezone";

import ReviewModel from "./review";
import NpsModel from "./nps";

class CommentModel {
    #id;
    #author;
    #text;
    #createdAt;
    #updatedAt;

    constructor(comment) {
        this.#id = comment.id || null;
        this.#author = comment.author || '';
        this.#text = comment.text || '';
        this.#createdAt = comment.createdAt || null;
        this.#updatedAt = comment.updatedAt || null;
    }

    get id() {
        return this.#id;
    }

    get author() {
        return this.#author;
    }

    get text() {
        return this.#text;
    }

    get createdAt() {
        return this.#createdAt;
    }

    get updatedAt() {
        return this.#updatedAt;
    }

}

class EventModel {
    constructor(event = {}) {
        this._id = event.id || null;
        this._time = event.time || null;
        this._event = event.event || '';
        this._nps = new NpsModel(event.nps || {});
        this._review = new ReviewModel(event.review || {});
        this._comment = new CommentModel(event.comment || {})
    }

    get id() {
        return this._id;
    }

    get time() {
        return moment
            .utc(this._time)
            .tz(process.env.REACT_APP_timeZone)
            .format(process.env.REACT_APP_dateTimeDisplayFormat)
            .toString()
    }

    get event() {
        return this._event;
    }

    get nps() {
        return this._nps;
    }

    get review() {
        return this._review;
    }

    get comment() {
        return this._comment;
    }

}



export default EventModel;
