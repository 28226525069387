export default class Author {
    constructor(author) {
        this._name = author.name || null;
        this._anonymous = author.anonymous || false;
    }

    /**
     * @return {string|null}
     */
    get name() {
        return this._name;
    }

    /**
     * @return {boolean}
     */
    get anonymous() {
        return this._anonymous;
    }
}
