import moment from "moment-timezone";
import CarModel from "./car-model";

export default class PurchaseModel {
    constructor(purchase = {}) {
        this._date = purchase.date;
        this._car = new CarModel(purchase.car || {});
        this._type = new TypeBranch(purchase.type || {});
    }

    get car() {
        return this._car;
    }

    get date() {
        return this._date;
    }

    get type() {
        return this._type;
    }

    get dateFormatted() {
        return moment
            .utc(this._date)
            .tz(process.env.REACT_APP_timeZone)
            .format(process.env.REACT_APP_dateDisplayFormat)
            .toString()
    }

    toApiObject() {
        return {
            date: this.date,
            car: this.car.toApiObject(),
        };
    }

}


class TypeBranch {
    #code;
    #name

    constructor(type = {}) {
        this.#code = type.code || '';
        this.#name = type.name || '';
    }

    get code() {
        return this.#code;
    }

    get name() {
        return this.#name;
    }
}
