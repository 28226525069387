import authService from '../services/auth';
import { getDetailDataByPlatform, serialize } from '../helpers'
import moment from "moment";

const CHARTS_URL = {
    rating: {
        url: 'rating',
    },
    dynamics: {
        url: 'score-dynamic',
    },
    pie: {
        url: 'source-type',
    },
    grade: {
        url: 'review-distribution',
    }
}

class ChartService {

    getData = (filter, chartType) => {
        const queryString = serialize(filter);
        const url = CHARTS_URL[chartType].url;

        return authService.get(
            `/extreview/reviews/chart/${url}?${queryString}`,
        ).then((result) => {
            if (chartType === 'rating') {
                return this.parseRating(result);
            }
            if (chartType === 'dynamics') {
                return this.parseDynamics(result);
            }
            if (chartType === 'pie') {
                return this.parsePie(result);
            }
            if (chartType === 'grade') {
                return this.parseGrade(result);
            }

        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }

    parseGrade = (result) => {
        const data = result.series.map((item) => {
            return {
                'rating': item.score,
                'published': item.diff.published <= 0 ? `${item.diff.published}` : `+${item.diff.published}`,
                'added': item.diff.added > 0 ? `+${item.diff.added}` : `${item.diff.added}`,
                'deleted': item.diff.deleted > 0 ? `-${item.diff.deleted}` : `${item.diff.deleted}`,
            }
        })

        return {
            data: data.slice(1)
        }

    }

    parsePie = (result) => {
        const tmp = {
            'total': result.summary.close.total.toLocaleString('ru-RU'),
        }

        const data = result.series.map((item) => {
            let change;
            let textColor;

            const total = item.diff.total.toLocaleString('ru-RU');
//TODO не нашел как в am4charts получить значение переменной из json. вставил формат сюда.
            if (item.diff.total > 0) {
                change = `+${total}`;
                textColor = '#6FCF97';
            } else if (item.diff.total === 0) {
                change = `${total}`;
                textColor = '#6FCF97';
            } else {
                change = `${total}`;
                textColor = '#EB5757';
            }

            return {
                "source": getDetailDataByPlatform(item.source).shortName || getDetailDataByPlatform('none').shortName,
                "count": item.close.total.toLocaleString('ru-RU'),
                "change": change,
                "textColor": textColor,
                "color": getDetailDataByPlatform(item.source).color || getDetailDataByPlatform('none').color,
            }
        });

        return {
            summary: tmp,
            data
        }
    }

    parseRating = (result) => {
        const tmp = {
            'total': Number(result.summary.close.rating).toLocaleString('ru-RU'),
            'rating': Number(result.summary.diff.rating).toLocaleString('ru-RU'),
            'total_rating': Number(result.summary.close.total),
            'total_reviews': Number(result.summary.close.total_reviews),
        }

        const data = result.series.map((item) => {
            return {
                'date': moment(item.timestamp).format("YYYY-MM-DD").toString(),
                'value': item.rating,
                'tooltipDate': moment(item.timestamp).format("DD.MM.YYYY").toString(),
            }
        })

        return {
            summary: tmp,
            data
        }
    }

    parseDynamics = (result) => {
        const tmp = {
            'total': Number(result.summary.close.total),
            'diff': Number(result.summary.diff.total).toLocaleString('ru-RU'),
        }

        const data = result.series.map((item) => {
            return {
                'date': moment(item.open.timestamp).format("DD.MM.YYYY").toString(),
                'tooltip': (item.diff.days === 1) ? null : (item.diff.days > 7) ? 'Месяц с\n' : 'Неделя с\n',
                'value': item.diff.total.toLocaleString('ru-RU'),
            }
        })

        return {
            summary: tmp,
            data: data
        }
    }

}

export default new ChartService();
