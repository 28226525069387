import authService from "services/auth";
import { ChannelModel } from 'models/channel';


class MessengerService {

    getChannel() {
        return authService.get(`/notifications/channels`)
            .then((result) => {
                return result.items.map(item => {
                    return new ChannelModel(item);
                })
            })
            .catch(error => error);
    }


    checkTelegramChannel() {
        const TELEGRAM_CODE = 'telegram'

        return this.getChannel().then(result => {
            return !!!result.find(item => item.code === TELEGRAM_CODE)
        })
    }

    connectTelegram(data) {
        return authService.get(
            `/telegram/link`,
            data,
        ).then((result) => {
            return result;
        })
            .catch(error => error);
    }
}

const messengerService = new MessengerService();
export default messengerService;

