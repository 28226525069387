import React from "react";
import cn from "classnames";
import Za from '../../../images/dashboard/za.png';
import Ot from '../../../images/dashboard/ot.png';
import Kon from '../../../images/dashboard/kon.png';
import Nps from '../../../images/dashboard/nps.png';
import ico_positive from '../../../images/dashboard/ico_face_pasitive.png';
import ico_negative from '../../../images/dashboard/ico_face_negative.png';
import RatingBlock from "components/pages/review/charts/RatingBlock";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

const RequestsBlock = ({data}) => {

    return (
        <>
            <Link to={'/application/list'}>
            {!isMobile ?
                <div className={cn('row', 'requests')}>
                    <div className={cn('col', 'col__w25', 'col__w25_min-width')}>
                        <fieldset className={'ml_0'}>
                            <legend>Заявки</legend>
                            <span>
                                <img src={Za} alt="" width={40} height={40} />
                            </span>
                            <span className={cn('value')}>{data.close.applications.total.toLocaleString('ru-RU')}</span>
                            <span className={cn('sub_value')}>
                                <RatingBlock
                                    rating={data.diff.applications.total}
                                />
                            </span>
                        </fieldset>
                    </div>
                    <div className={cn('col', 'col__w25', 'col__w25_min-width')}>
                        <fieldset>
                            <legend>Отзывы</legend>
                            <span>
                                <img src={Ot} alt="" width={40} height={40} />
                            </span>
                            <span className={cn('value')}>{data.close.reviews.total.toLocaleString('ru-RU')}</span>
                            <span className={cn('sub_value')}>
                                <RatingBlock
                                    rating={data.diff.reviews.total}
                                />
                            </span>
                        </fieldset>
                    </div>
                    <div className={cn('col', 'col__w25', 'col__w25_min-width')}>
                        <fieldset>
                            <legend>Конверсия в отзыв</legend>
                            <span>
                                <img src={Kon} alt="" width={40} height={40} />
                            </span>
                            <span className={cn('value')}>{data.close.conversion}%</span>
                            <span className={cn('sub_value')}>
                                <RatingBlock
                                    rating={data.close.conversion - data.open.conversion}
                                    suf={'п.п.'}
                                />
                            </span>
                        </fieldset>
                    </div>
                    <div className={cn('col', 'col__w25', 'col__w25_min-width')}>
                        <fieldset className={'mr_0'}>
                            <legend>NPS</legend>
                            <span>
                                <img src={Nps} alt="" width={40} height={40} />
                            </span>
                            <span className={cn('value')}>{data.close.nps.value}</span>
                            <span className={cn('sub_value')}>
                                <RatingBlock
                                    rating={data.diff.nps.value}
                                />
                            </span>
                            <div className={cn('right_value')}>
                                <span className={cn('right_value__positive')}>
                                    <img src={ico_positive} alt="" width={16} height={16} />
                                    {data.close.nps.promoters.toLocaleString('ru-RU')} <i>
                                        <RatingBlock
                                            rating={data.diff.nps.promoters}
                                        />
                                    </i></span>
                                <span className={cn('right_value__negative')}>
                                    <img src={ico_negative} alt="" width={16} height={16} />
                                    {data.close.nps.detractors} <i>
                                        <RatingBlock
                                            rating={data.diff.nps.detractors}
                                        />
                                    </i></span>
                            </div>
                        </fieldset>
                    </div>
                </div> :
                <>
                    <div className={cn('row', 'requests', 'row__md_5')}>
                        <div className={cn('col', 'col__w50')}>
                            <fieldset>
                                <legend>Заявки</legend>
                                <div className={cn('row', 'row__md_0')}>
                                    <span><img src={Za} alt=""/></span>
                                    <span className={cn('value')}>{data.close.applications.total.toLocaleString('ru-RU')}</span>
                                    <span className={cn('sub_value')}>
                                        <RatingBlock
                                            rating={data.diff.applications.total}
                                        />
                                    </span>
                                </div>
                            </fieldset>
                        </div>
                        <div className={cn('col', 'col__w50')}>
                            <fieldset>
                                <legend>Отзывы</legend>
                                <div className={cn('row', 'row__md_0')}>
                                    <span><img src={Ot} alt="" /></span>
                                    <span className={cn('value')}>{data.close.reviews.total.toLocaleString('ru-RU')}</span>
                                    <span className={cn('sub_value')}>
                                        <RatingBlock
                                            rating={data.diff.reviews.total}
                                        />
                                    </span>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div className={cn('row', 'requests', 'row__md_5')}>
                        <div className={cn('col', 'col__w50')}>
                            <fieldset>
                                <legend>Конверсия в отзыв</legend>
                                <div className={cn('row', 'row__md_0')}>
                                <span><img src={Kon} alt="" /></span>
                                <span className={cn('value')}>{data.close.conversion}%</span>
                                <span className={cn('sub_value')}>
                                    <RatingBlock
                                        rating={data.open.conversion - data.close.conversion}
                                    />п.п.
                                </span>
                                </div>
                            </fieldset>
                        </div>
                        <div className={cn('col', 'col__w50')}>
                            <fieldset>
                                <legend>NPS</legend>
                                <div className={cn('row', 'row__md_0')}>
                                    <span><img src={Nps} alt="" /></span>
                                    <span className={cn('value')}>{data.close.nps.value}</span>
                                    <div className={cn('right_value')}>
                                        <span className={cn('right_value__positive')}>
                                            <img src={ico_positive} alt="" />
                                            {data.close.nps.promoters.toLocaleString('ru-RU')} <i>
                                                <RatingBlock
                                                    rating={data.diff.nps.promoters}
                                                />
                                            </i></span>
                                            <span className={cn('right_value__negative')}>
                                            <img src={ico_negative} alt="" />
                                                {data.close.nps.detractors} <i>
                                                <RatingBlock
                                                    rating={data.diff.nps.detractors}
                                                />
                                            </i></span>
                                        </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </>
            }
            </Link>
        </>
    )
}

export default RequestsBlock;
