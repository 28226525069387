import chartService from '../services/chart';

class ChartStore {

    /**
     * @type {ChartService}
     * @private
     */
    _chartService

    constructor(chartService) {
        this._chartService = chartService;
    }

    getData = (filter = {}, chartType) => {
        return this._chartService.getData(filter, chartType)
            .then(result => result)
    }

}

const chartStore = new ChartStore(chartService);

export default chartStore

