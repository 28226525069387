import cn from "classnames";
import React from "react";
import Nps0 from '../../../../images/nps0_ico.png'
import Nps1 from '../../../../images/nps1_ico.png'
import Nps2 from '../../../../images/nps2_ico.png'
import Nps3 from '../../../../images/nps3_ico.png'


/**
 *
 * @param {Object} nps
 * @returns {JSX.Element}
 * @constructor
 */
const NpsBlock = ({ nps }) => {

    const arrValue = [
        { type: "detractor", ico: Nps1 },
        { type: "passive", ico: Nps2 },
        { type: "promoter", ico: Nps3 },
    ];

    const curNps = arrValue.find(item => item.type === nps.subdivision);
    return (
        <>
            <div className={cn('nps')}>
                {curNps ?
                    <img src={curNps?.ico} title={nps.score} alt={nps.score} width="17" height="17"/> :
                    <img src={Nps0}  width="17" height="17" alt="none" title="none"/>
                }
                </div>
        </>
    )
}


export default NpsBlock;
