import React, { useEffect, useState } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { reaction } from "mobx";

import applicationService from '../../../../../services/application'
import managersService from "../../../../../services/managers";
import applicationStatusService from "../../../../../services/application-status";
import filterService from '../../../../../services/filter';
import autoServicesService from '../../../../../services/services';

import '../../../../App.scss';
import './../ApplicationList.scss';
import './ApplicationListMobile.scss';

import _ from "underscore";
import ApplicationListDashboard from "../../common/ApplicatonListDashboard";
import NavigateBtnUp from "../../common/NavigateBtnUp";
import RatingField from "../../common/RatingField";
import NpsBlock from "../../common/NpsBlock";
import ApplicationModel from "../../../../../models/application";

import { useHistory } from "react-router";
import OkDateRangePicker from "../../../../common/OkDateRangePicker";
import SlideNav from "../../../analytics/SlideNav";
import { FilterButton, FilterButtonSimple } from "components/common/FilterButton";
import { useIsMount } from "hooks";
import { InputText } from "primereact/inputtext";
import icoList from "../../../../../images/ico_list.png";
import Download from "../../../review/list/common/Download";
import SourceList from "../../common/SourceList";

const ApplicationListMobile = inject('filterStore', 'uiStore', 'userStore')(
    observer(({ filterStore, uiStore, userStore }) => {
        const [loading, setLoading] = useState(false);
        const [loadingTable, setLoadingTable] = useState(false);
        const [loadingDash, ] = useState(false);
        const [applications, _setApplications] = useState([]);
        const [disabledFilter, setDisabledFilter] = useState(false);
        const [visibleNewNegative, setVisibleNewNegative] = useState(false);
        const [newNegativeCounter, setNewNegativeCounter] = useState(null);

        const newNegativeId = [];

        const applicationsRef = React.useRef(applications);
        const setApplications = data => {
            applicationsRef.current = data;
            _setApplications(data);
        };

        const [showButtonUp, setShowButtonUp] = useState(false);
        const [appStatuses, setAppStatuses] = useState([]);

        const [dashboard, setDashboard] = useState(null);
        const [pager, _setPager] = useState({});

        const pagerRef = React.useRef(pager);
        const setPager = data => {
            pagerRef.current = data;
            _setPager(data);
        };

        const [dashboardSticky, setDashboardSticky] = useState(false);
        const { filter, setFieldValue, isNewNegativeOn, setNewNegativeOn } = filterStore;
        const { setMessage } = uiStore;

        const [appServices, setAppServices] = useState([
            { value: 0, name: "Все филиалы" }
        ]);

        const [appManagers, setAppManagers] = useState([
            { value: 0, name: "Все менеджеры" }
        ]);

        const listScores = [
            { value: 1, name: "1" },
            { value: 2, name: "2" },
            { value: 3, name: "3" },
            { value: 4, name: "4" },
            { value: 5, name: "5" },
        ];

        const [datePeriod, setDatePeriod] = useState({
            'from': filter.date.from,
            'to': filter.date.to,
        });
        const isMount = useIsMount();

//TODO убрать эту хрень
        let classNameForm = 'application-list';
        if (loading) {
            classNameForm += ' application-list__bg-transparent';
        }

// первоначальная загрузка данных
        useEffect(() => {
            Promise.all([
                autoServicesService.getServices(),
                managersService.getManagers(),
                applicationStatusService.getApplicationStatus(),
                applicationService.getDashboard(filter),
                applicationService.getApplicationsList(filter),
                applicationService.getNewNeNativeCounter(),
            ]).then((
                [
                    servicesData,
                    managersData,
                    applStatusData,
                    dashboardData,
                    applListData,
                    newNegativeData,
                ]
            ) => {
// получаем список сервисов для фильтра
                if (servicesData) {
                    const services = servicesData.map((service) => ({
                        value: service.id,
                        name: service.title,
                    }));
                    setAppServices((prev) => {
                        return [
                            ...prev,
                            ...services,
                        ]
                    });
                }

// получаем список манагеров для фильтра
                if (managersData) {
                    const managers = managersData.map((manager) => {
                        return { value: manager.id, name: manager.fullName };
                    });

                    setAppManagers((prev) => {
                        return [
                            ...prev,
                            ...managers,
                        ]
                    });
                }

// получаем список статусов для фильтра
                if (applStatusData) {
                    const statuses = applStatusData.map((status) => {
                        if (!!status.negative_filter === true) {
                            newNegativeId.push({value: status.id});
                        }
                        return  { value: status.id, name: status.title, sortorder: status.sortorder };
                    });

                    setAppStatuses(statuses);
                }

// получаем дашбоард
                if (dashboardData) {
                    setDashboard(dashboardData);
                }

// получаем список заявок
                if (applListData) {
                    setLoadingTable(false);
                    setLoading(false);
                    const applications = applListData.applications.map(item => {
                        return new ApplicationModel(item);
                    });
                    setApplications(applications);
                    setPager(applListData.pager);

                    bindScrollListener();
                }

// кол-во заявок со статусом "Негатив перехвачен"
                if(newNegativeData) {
                    setNewNegativeCounter(newNegativeData)
                }

                if(isNewNegativeOn && !!newNegativeData) {
                    setVisibleNewNegative(true);
                    setDisabledFilter(true);
                }
                if(isNewNegativeOn && !newNegativeData) {
                    filterStore.resetOldFilter();
                    setVisibleNewNegative(false);
                    setDisabledFilter(false);
                    setNewNegativeOn(false)
                }

            })
                .catch(error => {
                    if (error.error) {
                        error.error.data.map(item => {
                            setMessage(
                                {
                                    severity: 'error',
                                    summary: error.error.description,
                                    detail: item.message,
                                }
                            )
                        });
                    }
                });
            return () => unBindScrollListener();
        }, []);

// следим за измениниями строки поиска, сортировки, филиала (используя mobx reaction)
        useEffect(() => {
            const disposers = [
                reaction(
                    () => filterStore.isNewNegativeOn,
                    (value) => {
                        if(value) {
                            filterStore.saveFilter();
                            filterStore.getNewNegative(newNegativeId, userStore.profile.client.createdAt)
                        }
                        else {
                            filterStore.resetOldFilter();
                        }
                    }
                ),
                reaction(
                    () => filter.query,
                    (value) => {
                        getDebounceApplicationsList(value)
                    }
                ),
                reaction(
                    () => filter.sort,
                    () => {
                        getApplicationsList();
                    }
                ),
                reaction(
                    () => filter.branch_id,
                    branch_id => {
                        getManagers(branch_id[0].value);
                        filterStore.setDefaultManager(0);
                    }
                ),
            ];
            return () => {
                disposers.map(func => func());
            }
        }, []);

// Следим за фильтрами для дашбоарда и списка
        useEffect(() => {

            if (!isMount) {
                setLoading(true)
                setLoadingTable(true);
                Promise.all([
                    applicationService.getDashboard(filter),
                    applicationService.getApplicationsList(filter),
                ]).then((
                    [
                        dashboardData,
                        applListData,
                    ]
                ) => {
                    setLoading(false);

// получаем дашбоард
                    if (dashboardData) {
                        setDashboard(dashboardData);
                    }

// получаем список заявок
                    if (applListData) {
                        setLoadingTable(false);
                        setLoading(false);
                        const applications = applListData.applications.map(item => {
                            return new ApplicationModel(item);
                        });
                        setApplications(applications);
                        setPager(applListData.pager);
                    }

                });

            }
        }, [
            JSON.stringify(filter.date),
            JSON.stringify(filter.branch_id),
            JSON.stringify(filter.manager_id),
        ])

// Следим за фильтрами для списка
        useEffect(() => {
            if (!isMount) {
                getApplicationsList()
            }

        }, [
            JSON.stringify(filter.status_id),
            JSON.stringify(filter.scores),
        ]);


        const unBindScrollListener = () => {
            if (scrollFunction) {
                window.removeEventListener('scroll', scrollFunction);
            }
        }

        let scrollFunction;
        const bindScrollListener = () => {
            scrollFunction = () => {
                let docBody = document.body,
                    docElement = document.documentElement,
                    scrollTop = (window.pageYOffset || document.documentElement.scrollTop),
                    winHeight = docElement.clientHeight,
                    docHeight = Math.max(docBody.scrollHeight, docBody.offsetHeight, winHeight, docElement.scrollHeight, docElement.offsetHeight);

// sticky dashboard
                if (scrollTop >= 400) {
                    setDashboardSticky(true);
                } else {
                    setDashboardSticky(false);
                }

// кнопка up
                if (scrollTop >= 700) {
                    setShowButtonUp(true);
                } else {
                    setShowButtonUp(false);
                }
// догрузка контента
                if (scrollTop >= (docHeight - winHeight - 200)) {
                    loadMore();
                }
            }
            window.addEventListener('scroll', scrollFunction);
        }

        const loadMore = () => {
            const pager = pagerRef.current;
            let pageNum = (pager.number + 1);
            if (pageNum <= pager.pageCount) {

                setFieldValue('page', { size: filter.page.size, number: pageNum });

                setLoadingTable(true)

                unBindScrollListener();

                const applPrev = applicationsRef.current;
                setTimeout(() => {
                    applicationService.getApplicationsList(filter)
                        .then(applListData => {
                            const applications = applListData.applications.map(item => {
                                return new ApplicationModel(item);
                            });
                            setApplications([
                                ...applPrev,
                                ...applications,
                            ]);
                            setPager(applListData.pager);
                            setLoadingTable(false);
                            setLoading(false);
                            bindScrollListener();
                        })

                }, 500)
            }
        }

        const debounceFunc = () => {
            //e.target.value.length === 2 || e.target.value.trim().match(/[\wа-яА-ЯёЁ\d]/) !== null
            getApplicationsList();
        }

        const getDebounceApplicationsList = _.debounce(debounceFunc, 800);

        const getApplicationsList = () => {
            setLoading(true)
            setLoadingTable(true);
            applicationService.getApplicationsList(filter).then((applListData) => {
                const applications = applListData.applications.map(item => {
                    return new ApplicationModel(item);
                });
                setApplications(applications);
                setPager(applListData.pager);
                setLoadingTable(false);
                setLoading(false);
            });
        }

        const getManagers = (branch_id) => {

            setLoadingTable(true);

            setAppManagers([
                { value: 0, name: "Все менеджеры" }
            ]);

            managersService.getManagers(branch_id).then(data => {

                const managers = data.map((manager) => {
                    return { value: manager.id, name: manager.fullName };
                });
                setAppManagers((prev) => {
                    return [
                        ...prev,
                        ...managers,
                    ]
                });

                setLoadingTable(false);
            })

        }

        /**
         * передаем период в календарь
         * @param date
         */
        const handleChangePeriod = (date) => {
            setDatePeriod({
                'from': filterService.periods[date][0],
                'to': filterService.periods[date][1]
            });
        };

        /**
         * ловим изменение даты в календаре
         * @param {Object} date
         */
        const onChangeRangeDate = (date) => {
            setFieldValue('date', {
                from: date.startDate,
                to: date.endDate,
            });
        };

        /**
         * ловим изменения фильтра
         * @param {String} field
         * @param {String} value
         */
        const handlerChangeFilter = (field, value) => {
            if (!isMount) {
                setFieldValue(field, value);
            }
        }
        return (
            <React.Fragment>
                <div className={cn('page__application-list', 'page__application-list-mobile', 'page')}>
                    <div className={classNameForm}>
                        {loading && <div className={cn('form-loading')} />}
                        <React.Fragment>
                            <div className={'title'}>
                                <h1>Список заявок</h1>
                                <Download
                                    filter={filter}
                                    isMobile={true}
                                    uiStore={uiStore}
                                    userStore={userStore}
                                    dest={'appl'}
                                />
                            </div>
                            <div className={cn('application-list-filter')}>
                                <div className={cn('filter__wrapper')}>
                                    <div className={cn('filter__item')}>
                                        <SlideNav
                                            handleChangeNav={handleChangePeriod}
                                            data={[
                                                { 'today': 'Сегодня' },
                                                { 'yesterday': 'Вчера' },
                                                { 'week': 'Неделя' },
                                                { 'month': 'Месяц' },
                                            ]}
                                            value={'month'}
                                            disabled={disabledFilter}
                                        />
                                    </div>
                                    <div className={cn('filter__item')}>
                                        <OkDateRangePicker
                                            onChangeDateHandler={onChangeRangeDate}
                                            orientation={'vertical'}
                                            dateRange={{
                                                'from': datePeriod.from,
                                                'to': datePeriod.to,
                                            }}
                                            disabled={disabledFilter}
                                        />
                                    </div>
                                    {appServices.length > 1 && <div className={cn('filter__item')}>
                                        <FilterButtonSimple
                                            list={appServices}
                                            onChange={(value) => handlerChangeFilter('branch_id', value)}
                                            onClear={() => setFieldValue('branch_id', [{value:0}])}
                                            filter={filter.branch_id}
                                            loading={loading}
                                            search={true}
                                            defaultValue={{ value: 0 }}
                                        />
                                    </div>}
                                    {appManagers.length > 1 && <div className={cn('filter__item')}>
                                        <FilterButtonSimple
                                            list={appManagers}
                                            onChange={(value) => handlerChangeFilter('manager_id', value)}
                                            onClear={() => setFieldValue('manager_id', [{value:0}])}
                                            filter={filter.manager_id}
                                            loading={loading}
                                            search={true}
                                            defaultValue={{ value: 0 }}
                                        />
                                    </div>}
                                </div>
                            </div>

                            <div
                                className={cn('dashboard', { dashboard__loading: loadingDash }, { dashboard__sticky: dashboardSticky })}
                            >
                                <ApplicationListDashboard value={dashboard} />
                            </div>

                            <div className={cn('application-list-filter', 'position__bottom')}>
                                <div className={cn('filter__wrapper')}>
                                    <div className={cn('filter__item')}>
                                        <FilterButton
                                            name='Статус'
                                            list={appStatuses}
                                            onChange={(value) => handlerChangeFilter('status_id', value)}
                                            onClear={() => setFieldValue('status_id', [])}
                                            filter={filter.status_id}
                                            loading={loading}
                                            search={true}
                                            disabled={disabledFilter}
                                        />
                                    </div>
                                    <div className={cn('filter__item')}>
                                        <FilterButton
                                            name='Оценка'
                                            list={listScores}
                                            onChange={(value) => handlerChangeFilter('scores', value)}
                                            onClear={() => setFieldValue('scores', [])}
                                            filter={filter.scores}
                                            loading={loading}
                                            disabled={disabledFilter}
                                        />
                                    </div>
                                    <div className={cn('filter__item')}>
                                        <div className={cn(
                                            "filter-button",
                                            "warning",
                                            {'disabled' : !newNegativeCounter}
                                        )}
                                             onClick={() => {
                                                 if(newNegativeCounter) {
                                                     setVisibleNewNegative(prev => !prev);
                                                     setDisabledFilter(prev => !prev);
                                                     setNewNegativeOn(!isNewNegativeOn)
                                                 }
                                             }}>
                                            <div className={
                                                cn("filter-button__title",
                                                    {'active' : visibleNewNegative},
                                                )
                                            }>
                                                <span>Негатив перехвачен</span>
                                                {!!newNegativeCounter && <span className="badge">{newNegativeCounter}</span>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={cn('filter__item', 'word-field')}>
                                        <div className={'text-field'}>
                                                <span className="p-input-icon-left">
                                                    <i className="pi pi-search" />
                                                    <InputText
                                                        id="query"
                                                        name="query"
                                                        value={filter.query}
                                                        onChange={(e) => {
                                                            setFieldValue('query', e.target.value)
                                                        }}
                                                        placeholder="Поиск по клиенту или товару / услуге"
                                                    />
                                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <React.Fragment>
                            <div
                                className={cn('application-list__plate', { application__loading: loadingTable })}
                            >
                                {applications.map((item, key) => {
                                    return (
                                        <ApplicationPlateItem
                                            item={item}
                                            key={key}
                                        />
                                    );
                                })}
                                {showButtonUp && <NavigateBtnUp />}

                            </div>
                        </React.Fragment>


                        </React.Fragment>
                    </div>
                </div>
            </React.Fragment>
        );
    }));

const ApplicationPlateItem = (props) => {
    const { item } = props;
    const history = useHistory();
    const handleClick = () => {
        !window.getSelection().toString().length && history.push(`/application/detail/${item.id}`);
    }

    return (
        <div className={cn('application-plate__item')} onClick={handleClick}>
            <div className={cn('date')}>{item.time}</div>
            <div className={cn('status')}>
                <span className={`p-tag application-tag mobile-tag`}
                      style={{
                          'color': item.status.foreground,
                          'backgroundColor': item.status.background
                      }}
                >
                    {item.status.title.toUpperCase()}
                </span>
                <SourceList
                    sourceList={item.counters.adlinks}
                    avitopub={item.review.avitopub}
                />
            </div>
            <div className={cn('descr')}>
                <div className={cn('row')}>
                    <div className={cn('col-title')}>№ заявки</div>
                    <div className={cn('col-text')}>{item.number}</div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col-title')}>Оценка</div>
                    <div className={cn('col-text')}>
                        <RatingField
                            review={item.review}
                        />
                    </div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col-title')}>NPS</div>
                    <div className={cn('col-text')}>
                        <NpsBlock
                            nps={item.nps}
                        />
                    </div>
                </div>

                <div className={cn('row')}>
                    <div className={cn('col-title')}>Филиал</div>
                    <div className={cn('col-text')}>
                        {item.branch.title}
                    </div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col-title')}>Клиент</div>
                    <div className={cn('col-text')}>
                        {item.customer.lastname} {item.customer.firstname}
                    </div>
                </div>
                {item.purchase.type.code === 'vehicle' && <div className={cn('row')}>
                    <div className={cn('col-title')}>VIN</div>
                    <div className={cn('col-text')}>{item.purchase.car.vin}</div>
                </div>}
                <div className={cn('row')}>
                    <div className={cn('col-title')}>Товар / Услуга</div>
                    <div className={cn('col-text')}>
                        {item.purchase.type.code === 'vehicle' && <>
                            {item.purchase.car.brand} {item.purchase.car.model}, {item.purchase.car.year}
                        </>}
                        {item.purchase.type.code === 'generic' && <>
                            &#8212;
                        </>}
                    </div>
                </div>
                <div className={cn('row')}>
                    <div className={cn('col-title')}>Комменты / Чаты</div>
                    <div className={cn('col-text')}>
                        {!!item.counters?.comments && <div className={cn('comments-count')}>
                            <div className={cn('comments-count__ico')}>
                                <img src={icoList} alt="" width={20} height={17} />
                            </div>
                            <div className={cn('comments-count__text')}>
                                <span>{item.counters?.comments}</span>
                            </div>
                        </div>}
                    </div>
                </div>

{/*
                <td className="chat-col">
                    {!!item.counters?.comments && <div className={cn('comments-count')}>
                        <div className={cn('comments-count__ico')}>
                            <img src={icoList} alt="" width={20} height={17} />
                        </div>
                        <div className={cn('comments-count__text')}>
                            <span>{item.counters?.comments}</span>
                        </div>
                    </div>}
                </td>
*/}

            </div>
        </div>
    )
}

export default ApplicationListMobile;
