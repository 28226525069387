import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import StatusTag from "../application/common/StatusTag";
import { inject, observer } from "mobx-react";
import okAnalyticsService from "../../../services/okAnalytics";
import { toNumber } from "@amcharts/amcharts4/.internal/core/utils/Type";

import "../application/detail/ApplicationDetail.scss";
import SlideNav from "../analytics/SlideNav";
import Lent from "../application/common/Lent";
import Chat from "../application/common/Chat";
import { scroller } from "react-scroll";
import EventModel from "../../../models/event";
import qrTransitionStore from "../../../stores/QrTransitionStore";
import { useParams } from "react-router";
import moment from "moment-timezone";


const HiddenPhoneNumber = inject('userStore')(observer(({ phone, userStore, application_id }) => {
    const [showPhone, setShowPhone] = useState(false);

    return (
        <>
            <span className={cn({ 'hidden-phone__wrapper': !showPhone })}
                  onClick={() => {
                      setShowPhone(true)
                      okAnalyticsService.addEntity({
                          page: 'application_detail',
                          target: 'form',
                          event: 'show_phone',
                          info: {
                              user_id: userStore.profile.user.id,
                              application_id: toNumber(application_id)
                          }
                      });

                  }}
            >{showPhone ? phone : 'Показать номер'}</span>
        </>
    )
}))

const QrTransitionDetail = inject('qrTransitionStore', 'uiStore')(observer(({ qrTransitionStore, uiStore }) => {

    const scrollToEnd = () => {
        scroller.scrollTo('end-cont', {
            duration: 0,
            delay: 0,
            smooth: 'easeInOutQuart',
            containerId: 'scroll-container'
        })
    }

    const { id } = useParams();

    const [events, setEvents] = useState([]);
    const [item, setItem] = useState({})
    const [statuses, setStatuses] = useState([]);
    const [status, setStatus] = useState({});
    const [rightComponent, setRightComponent] = useState('lent');

    useEffect(() => {

        qrTransitionStore.getPossibleStatuses(id)
            .then(result => {
                setStatuses(result)
            })
            .catch(e => e);

        qrTransitionStore.getItem(id)
            .then(result => {
                setItem(result)
                setStatus(result.status);
            })
            .catch(e => e)

        qrTransitionStore.getEventsList(id)
            .then(result => {
                setEvents(result);
                scrollToEnd();
            })
            .catch(e => e)
    }, []);

    const changeStatus = (data) => {
        qrTransitionStore.changeStatus(id, { status_id: data.id })
            .then(result => {
                if(result === 'OK') {
                    Promise.all(
                        [
                            qrTransitionStore.getItem(id),
                            qrTransitionStore.getEventsList(id),
                            qrTransitionStore.getPossibleStatuses(id)
                        ]
                    ).then(([result1, result2, result3]) => {
                        setStatus(result1.status);
                        setEvents(result2);
                        setStatuses(result3)
                        scrollToEnd();

                        uiStore.setMessage(
                            {
                                severity: 'success',
                                sticky: false,
                                summary: '',
                                detail: 'Статус изменен',
                            }
                        );

                    })
                }
            })
            .catch(e => e);
    }

    const lentSendMessage = (data) => {
        qrTransitionStore.lentSendMessage(id, {comment: data})
            .then(() => {
                qrTransitionStore.getEventsList(id)
                    .then(result => {
                        setEvents(result)
                        scrollToEnd();
                        uiStore.setMessage(
                            {
                                severity: 'success',
                                sticky: false,
                                summary: '',
                                detail: 'Сообщение отправленно',
                            }
                        );

                    })
                    .catch(e => e)
            })
    }

    const chatSendMessage = () => {}

    return (
        <>
            <div className={cn('page__application-detail', 'page')}>
                <div className={'application-detail'}>
                    <>
                        <div className={cn('application-detail__cart')}>
                            <div className={cn('cart__wrapper')}>
                                <div className={cn('cart__header')}>
                                    <div className={cn('form-btn__wrapper')}>
                                        <Link to='/qr/list/' className="p-button p-component back-btn">
                                            <i className="pi pi-angle-left"></i>
                                        </Link>
                                    </div>
                                    <h1>QR-код {item.number}</h1>
                                </div>

                                <div className={cn('service')}>{item.branch?.title}</div>
                                <div className={cn('status')}>
                                    <StatusTag
                                        status={status}
                                        statusesList={statuses}
                                        changeHandler={(data)=> {
                                            changeStatus(data)
                                        }}
                                    />
                                </div>
                                <div className={cn('field-box')} style={{ paddingTop: '25px' }}>
                                    <div className={cn('field')}>
                                        <span className={cn('field__label')}>Телефон</span>
                                        <div className={cn('field__text')}>
                                            <HiddenPhoneNumber
                                                phone={item.review?.phone}
                                                application_id={item.review?.id}
                                            />
                                        </div>
                                    </div>
                                    <div className={cn('field')}>
                                        <span className={cn('field__label')}>Дата отзыва</span>
                                        <div
                                            className={cn('field__text')}
                                            title={''}
                                        >
                                            {moment
                                                .utc(item.time)
                                                .tz(process.env.REACT_APP_timeZone)
                                                .format('DD.MM.YYYY')
                                                .toString()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={cn('application-detail__lent')}>
                            <div className={cn('slider')}>
                                <div className={cn('slider__wrapper')}>
                                    <SlideNav
                                        handleChangeNav={(data)=>{
                                            setRightComponent(data);
                                        }}
                                        data={[
                                            { 'lent': 'Лента событий' },
                                            { 'chat': 'Чат с клиентом' }
                                        ]}
                                        value={'lent'}
                                    />
                                </div>
                            </div>
                            <div className={cn('lent__wrapper')}>
                                {rightComponent === 'lent' && <Lent
                                    events={events}
                                    sendMessage={lentSendMessage}
                                    scrollToEnd={scrollToEnd}
                                />}
                                {rightComponent === 'chat' && <Chat
                                    sendMessage={chatSendMessage}
                                />}
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </>
    )
}))

export default QrTransitionDetail;