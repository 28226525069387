import React, { useEffect } from "react";

import TrafficMobile from "./TrafficMobile";
import TrafficDesktop from "./TrafficDesktop";
import okAnalyticsService from "../../../../services/okAnalytics";
import '../Traffic.scss';

import {
    isMobile
} from "react-device-detect";


const TrafficCommon = () => {

    useEffect(() => {
        okAnalyticsService.addEntity({
            page: 'analytics_traffic',
            target: 'page',
            event: 'open',
        });
    }, []);

    const getClientWidth = () => {
        return document.documentElement.clientWidth;
    }

    return (
        <>
            {(isMobile && getClientWidth() <= 1024) ? <TrafficMobile /> : <TrafficDesktop />}
        </>
    );

}

export default TrafficCommon;
