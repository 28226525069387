import { Tag } from "antd";
import React from "react";

const scoresTagRender = (props) => {
    const { label, value, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <>
            <Tag
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
            >
                {label}{value !== 0 && <span className="pi-star pi"></span>}
            </Tag>
        </>
    )
}

export default scoresTagRender;