import React, {useEffect, useState} from "react";
import cn from "classnames";
import Tabs from "./Tabs";
import './Settings.scss';
import { Select, Space, Switch } from "antd";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {inject, observer} from "mobx-react";
import scoresTagRender from "./ScoresTagRender";

const PERIODICITY_DEFAULT = 'once-hour';

const SettingsEmail = inject('notificationStore', 'uiStore')(observer(({notificationStore, uiStore}) => {

    const [email, setEmail] = useState('');

    const [emailError, setEmailError] = useState([]);

    const [periodicity, setPeriodicity] = useState(PERIODICITY_DEFAULT);
    const [rating, setRating] = useState([]);
    const [branches, setBranches] = useState([]);
    const [sources, setSources] = useState([]);
    const [emailNotificationObject, setEmailNotificationObject] = useState(null);
    const [allowNotify, setAllowNotify] = useState([]);
    const {  addValue, typesNotification, periodicityList } = notificationStore;

    useEffect(() => {
        notificationStore.getBranchList()
            .then(result => {
                setBranches(result);
            })
            .catch(e => e);

        notificationStore.getSourcesList()
            .then(result => {
                setSources(result)
            })
            .catch(e => e);

        notificationStore.getScore()
            .then(result => {
                setRating(result)
            })
            .catch(e => e);

        notificationStore.getEmailNotificationSetting()
            .then((result) => {
                setEmailNotificationObject(result);

                typesNotification.map(t => {
                    if(notificationStore.emailNotification.policy.types.includes(t.value)) {
                        setAllowNotify(prev => [...prev,t.value])
                    }
                })
            })
            .catch(e => e);
    }, [])

    useEffect(() => {
        setEmail(emailNotificationObject?.accounts.join(','))

        if(emailNotificationObject?.schedule.period) {
            setPeriodicity(emailNotificationObject?.schedule.period)
        }
        else {
            addValue('periodicity', PERIODICITY_DEFAULT);
        }

    }, [emailNotificationObject])

    useEffect(() => {
        addValue('types', allowNotify);
    }, [allowNotify])

    const checkAllowNotifyTypes = (item) => {
        return notificationStore.emailNotification.policy.types.includes(item.value);
    }

    const onChangTypes = (checked, type) => {
        if(checked) {
            setAllowNotify(prev => [...prev, type])
        }
        else {
            let copy  = [...allowNotify];
            const index = copy.findIndex(t => t === type)
            copy.splice(index, 1);
            setAllowNotify(copy)
        }
    }

    const onSave = () => {
        if (validate()) {
            notificationStore.saveNotificationSetting('email')
                .then(_ => {
                    uiStore.setMessage(
                        {
                            severity: 'success',
                            summary: '',
                            detail: 'Данные сохранены',
                            sticky: false,
                        }
                    );
                })
                .catch(e => {
                    e.error.data.map((m) => {
                        uiStore.setMessage(
                            {
                                severity: 'error',
                                summary: e.error.description,
                                detail: m.message,
                            }
                        )
                    });
                })
        } else {
            return;
        }

    }

    const placeholder = (text) => {
        return <span style={{position:"relative", top:'2px'}}>&nbsp;{text}</span>
    }

    const validate = () => {
        let message = []
        email
            .replace(/\s/g, '')
            .split(',')
            .filter(e => e !== '')
            .map(e => {
            if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(e)) {
                message.push({
                    value: e,
                    text: 'текст ошибки.'
                })
            }
        })

        if(message.length) {
            setEmailError(message);
        }
        else {
            setEmailError([]);
        }

        return !message.length;
    }

    return (
        <>
            <div className={cn('page__settings', 'page')}>
                <div className={cn('title')}>
                    <h1>Настройки уведомлений</h1>
                </div>
                <div className={cn('settings-tabs')}>
                    <Tabs/>
                </div>
                {emailNotificationObject && <div className={'form'}>
                    <div className={cn('block')}>
                        <h3>Разрешить следующие уведомления</h3>
                        <div className={cn('content')}>
                            <ul className={cn('content__switches')}>
                                {typesNotification.map(((item, key) => (
                                    <li key={key}>
                                        <Switch
                                            defaultChecked={checkAllowNotifyTypes(item)}
                                            size="small"
                                            onChange={(checked) => {
                                                onChangTypes(checked, item.value)
                                            }}
                                            className={cn('okSwitch')}/>
                                        {item.title}
                                    </li>
                                )))}
                            </ul>
                        </div>
                    </div>
                    <div className={cn('block')}>
                        <h3>Получение уведомлений о новых отзывах</h3>
                        <div className={cn('content')}>
                            <div className={cn('content__complete')}>
                                <div className={cn('text-field', 'content__text-field')}>
                                    <label htmlFor="email" title="Укажите один или несколько E-mail для уведомлений (через запятую)">Укажите один или несколько E-mail для уведомлений (через запятую)</label>
                                    <InputText
                                        id='email'
                                        type="text"
                                        name='email'
                                        className={cn({'p-error': !!emailError.length})}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                        }}
                                        onMouseLeave={(e)=>{
                                            addValue('email', e.target.value)
                                        }}
                                        onBlur={(e) => {
                                            addValue('email', e.target.value)
                                        }}
                                        value={email || ''}
                                    />
                                    {!!emailError.length &&
                                        <div className={cn('message-block', 'message-block__error')}>
                                            Эти электронные адреса некорректны:
                                            {emailError.map((m, key) => {
                                                return <span key={key}>&nbsp;{m.value}</span>
                                            })}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={cn('content__complete')} style={{marginBottom:'7px'}}>
                                {periodicity && <div className={cn('text-field', 'content__text-field', 'small')}>
                                    <label htmlFor="periodicity" title="periodicity">Периодичность уведомлений</label>
                                        <Select
                                            className={cn('okSelect')}
                                            style={{
                                                width: '100%',
                                            }}
                                            value={periodicity}
                                            defaultValue={periodicity}
                                            onChange={(value) => {
                                                setPeriodicity(value)
                                                addValue('periodicity', value)
                                            }}
                                            options={periodicityList}
                                        />

                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className={cn('block')}>
                        <h3>Фильтрация отзывов</h3>
                        <div className={cn('content')}>
                            <div className={cn('content__complete')}>
                                <label htmlFor="sources" title="Источники">Источники</label>
                                <Select
                                    className={cn('okSelect')}
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder={placeholder('Выберите источники')}
                                    defaultValue={emailNotificationObject.policy.sources}
                                    onChange={(values) => {
                                        addValue('sources', values)
                                    }}
                                    filter="true"
                                    optionFilterProp={'label'}
                                    options={sources}
                                />
                            </div>
                            <div className={cn('content__complete')}>
                                <label htmlFor="rating" title="Рейтинг">Рейтинг</label>
                                <Select
                                    className={cn('okSelect')}
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    tagRender={scoresTagRender}
                                    placeholder={placeholder('Выберите рейтинг')}
                                    defaultValue={emailNotificationObject.policy.scores}
                                    onChange={(values) => {
                                        addValue('rating', values)
                                    }}
                                    options={rating}
                                    optionRender={(rating) => (
                                            <Space>{rating.label}{rating.value !== 0 && <span className="pi-star pi"></span>}</Space>
                                        )
                                    }
                                    filter="true"
                                    optionFilterProp={'label'}
                                />

                            </div>
                            <div className={cn('content__complete')}>
                                <label htmlFor="branches" title="Филиалы">Филиалы</label>
                                <Select
                                    className={cn('okSelect')}
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder={placeholder('Выберите филиалы')}
                                    defaultValue={emailNotificationObject.policy.branches}
                                    onChange={(values) => {
                                        addValue('branches', values)
                                    }}
                                    filter="true"
                                    optionFilterProp={'label'}
                                    options={branches}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={cn('block', 'button__wrapper')}>
                        <Button label="Сохранить"
                                type="submit"
                                onClick={onSave}
                                className={cn('submit-btn')}
                        />
                    </div>
                    <br/>
                    <div className={cn('block')}>
                        <h3>&nbsp;</h3>
                    </div>
                </div>}
            </div>
        </>
    )
} ))


export default SettingsEmail;