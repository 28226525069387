import { action, observable } from "mobx";
import moment from "moment/moment";
import qrTransitionService from "../services/QrTransition";
import { ServiceModel } from "../models/service";

class QrTransitionStore {
    #qrTransitionService;

    @observable branches = [];

    @observable dashboard = {}

    @observable item = {};

    @observable eventList = [];

    @observable visibleNewNegative = false;

    @observable isNewNegativeOn = false;

    statusList = [];
    qrFeedbackList = [];

    possibleStatuses = [];

    @observable oldFilter = {};

    @observable filter = {
        branches: [
            { value: 0 }
        ],
        date: {
            from: moment().subtract(1, 'month').toString(),
            to: moment().toString(),
        },
        statuses: [],
        sort: {
            column: 'time',
            order: 'desc'
        },
        page: {
            size: 10,
            number: 1
        }
    }

    constructor(qrTransitionService) {
        this.#qrTransitionService = qrTransitionService;
    }


    @action setNewNegativeOn = (state) => {
        this.isNewNegativeOn = state;
    }

    @action resetOldFilter = () => {
        this.filter = Object.assign({}, this.oldFilter);
    }

    @action saveFilter = () => {
        this.oldFilter = Object.assign({}, this.filter);
    }

    @action getNewNegative = (statusId = [], dateFrom = null) => {
        this.filter = {
            branches: [
                { value: 0 }
            ],
            date: {
                'from': moment(dateFrom).toString(),
                'to': moment().toString()
            },
            statuses: statusId,
            sort: {
                column: 'time',
                order: 'desc'
            },
            page: {
                size: 10,
                number: 1
            }
        };
    }


    @action setFieldValue = (fieldName, fieldValue) => {
        if (this.filter.hasOwnProperty(fieldName)) {
            this.filter[fieldName] = fieldValue;
        }
    }

    @action setDashboard = (dashboard) => {
        this.dashboard = dashboard;
    }

    @action setBranches = (branches) => {
        this.branches = branches;
    }

    @action setPageSize = (size) => {
        this.filter.page.size = size;
    }

    @action setPage = (number) => {
        this.filter.page.number = number;
    }

    @action setItem = (item) => {
        this.item = item;
    }

    @action setEventsList = (eventList) => {
        this.eventList = eventList;
    }

    parseFilterToApi() {

    }

    exportXls() {
        return this.#qrTransitionService.exportXls(this.filter)
            .then(result => {
                this.setDashboard(result);
                return result
            })
            .catch((e) => {
                return Promise.reject(e)
            });

    }

    getDashboard() {
        const filter = {
            date: this.filter.date,
            branches: this.filter.branches,
        };

        return this.#qrTransitionService.getDashboard(filter)
            .then(result => {
                this.setDashboard(result);
                return result
            }).catch(e => e);
    }

    getBranchList() {
        return (this.branches?.length > 0)
            ?
            Promise.resolve(this.branches)
            :
            this.#qrTransitionService.getBranchList()
                .then(result => {
                    const branches = result.map(branch => {
                        return new ServiceModel(branch);
                    });

                    this.setBranches(branches);
                    return branches;
                }).catch(e => e);
    }

    getStatusList() {
        return (this.statusList?.length > 0)
            ?
            Promise.resolve(this.statusList)
            :
            this.#qrTransitionService.getStatusList()
                .then(result => {
                    this.statusList = result;
                    return result
                }).catch(e => e);
    }

    getQrFeedbackList() {
        const filter = {
            date: this.filter.date,
            branches: this.filter.branches,
            statuses: this.filter.statuses,
            sort: this.filter.sort,
            page: this.filter.page,
        };

        return this.#qrTransitionService.getQrFeedbackList(filter)
            .then(result => {
                this.qrFeedbackList = result;
                return result
            }).catch(e => e);


    }

    getItem(id) {
        return this.#qrTransitionService.getItem(id)
            .then(result => {
                this.setItem(result);
                return result
            }).catch(e => e);
    }

    getEventsList(id) {
        return this.#qrTransitionService.getEventsList(id)
            .then(result => {
                const result2 = result.items.map(item => {
                    return {
                        ...item,
                        ...{'time': moment.utc(item.time).tz(process.env.REACT_APP_timeZone).format('DD.MM.YYYY HH:mm').toString()}
                    }
                })
                this.setEventsList(result2);
                return result2
            }).catch(e => e);
    }

    getPossibleStatuses(id) {
        return (this.possibleStatuses?.length > 0)
            ?
            Promise.resolve(this.possibleStatuses)
            :
            this.#qrTransitionService.getPossibleStatuses(id)
                .then(result => {
                    return result;
                }).catch(e => e);
    }

    lentSendMessage(id, data) {
        return this.#qrTransitionService.lentSendMessage(id, data)
            .then(result => {
                return result;
            }).catch(e => e);
    }

    changeStatus(id, data) {
        return this.#qrTransitionService.changeStatus(id, data)
            .then(result => {
                return result;
            }).catch(e => e);
    }

    newNegative() {
        return this.#qrTransitionService.newNegative()
            .then(result => {
                return result;
            }).catch(e => e);

    }

}

const qrTransitionStore = new QrTransitionStore(qrTransitionService);
export default qrTransitionStore;