import React, { useEffect } from "react";
import '../Analytics.scss';
import { isMobile } from "react-device-detect";
import ApplicationsMobile from "./ApplicationsMobile";
import ApplicationsDesktop from "./ApplicationsDesktop";
import okAnalyticsService from "../../../../services/okAnalytics";

const ApplicationsCommon = () => {

    useEffect(() => {
        okAnalyticsService.addEntity({
            page: 'analytics_applications',
            target: 'page',
            event: 'open',
        });

    }, []);


    const getClientWidth = () => {
        return document.documentElement.clientWidth;
    }

    return (
        <>
            {(isMobile && getClientWidth() <= 1024) ? <ApplicationsMobile /> : <ApplicationsDesktop />}
        </>
    );

}

export default ApplicationsCommon;
