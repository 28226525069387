import { useHistory } from "react-router";
import cn from "classnames";
import icoList from "../../../images/ico_list.png";
import React from "react";

const Row = ({ item }) => {
    const history = useHistory();
    const handleClick = () => {
        !window.getSelection().toString().length && history.push(`/qr/detail/${item.id}`);
    }
    const [data, time] = item.time.replaceAll(/\s/g, "\n").split('\n');

    return <>
        <tr className="p-datatable-row" onClick={handleClick}>
            <td className="date-col">
                <div>{data}</div>
                <div>{time}</div>
                <div className={cn('number-col')}>
                    <span>
                        № {item.number}
                        <span className={cn('chain_ico')}></span>
                    </span>
                </div>
            </td>
            <td className="status-col">
                <span className="p-tag application-tag" style={
                    {
                        color: item.status.foreground,
                        backgroundColor: item.status.background
                    }
                }
                >
                    {item.status.title}
                </span>
            </td>
            <td className="review-col" style={{width:'20%', whiteSpace:'normal'}}>
                <div style={{marginRight:'40px', overflow: 'hidden'}}>{item.branch.title}</div>
            </td>
            <td className="review-col" style={{whiteSpace:'normal', width:'50%'}}>
                {item.review.text}
            </td>
            <td className="chat-col">
                {!!item.counters?.comments && <div className={cn('comments-count')}>
                    <div className={cn('comments-count__ico')}>
                        <img src={icoList} alt="" width={20} height={17}/>
                    </div>
                    <div className={cn('comments-count__text')}>
                        <span>{item.counters?.comments}</span>
                    </div>
                </div>}
            </td>
        </tr>
    </>
}

export default Row;