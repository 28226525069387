import Author from "./author";
import moment from "moment";

export default class Answer {
    constructor(answer) {
        this._id = answer.id || null;
        this._reviewId = answer.reviewId || null;
        this._parentId = answer.parentId || null;
        this._depth = answer.depth || 0;
        this._author = (answer.author) ? new Author(answer.author) : {};
        this._text = answer.text || '';
        this._answerable = answer.answerable || false;
        this._official = answer.official || false;
        this._okdAnswered = answer.okdAnswered || false;
        this._published = answer.published || false;
        this._state = answer.state || '';
        this._hideable = answer.hideable || false;
        this._repostable = answer.repostable || false;
        this._postedAt = answer.postedAt || null;
        this._createdAt = answer.createdAt || null;
        this._updatedAt = answer.updatedAt || null;
        this._createdBy = answer.createdBy || {};
    }

    getRandomStatus() {
        const stats = [
            "pending",
            "approving",
            "error",
            "unpublished",
            "declined",
        ];
        function getRandomInt(max) {
            return Math.floor(Math.random() * max);
        }
        return stats[getRandomInt(5)];
    }


    get createdBy() {
        return this._createdBy;
    }

    /**
     * @return {int|null}
     */
    get id() {
        return this._id;
    }

    /**
     * @return {int|null}
     */
    get reviewId() {
        return this._reviewId;
    }

    /**
     * @return {int|null}
     */
    get parentId() {
        return this._parentId;
    }

    /**
     * @return {int}
     */
    get depth() {
        return this._depth;
    }

    /**
     * @return {Author}
     */
    get author() {
        return this._author;
    }

    /**
     * @return {string}
     */
    get text() {
        return this._text;
    }

    /**
     * @return {boolean}
     */
    get answerable() {
        return this._answerable;
    }

    /**
     * @return {boolean}
     */
    get official() {
        return this._official;
    }

    /**
     * @return {boolean}
     */
    get okdAnswered() {
        return this._okdAnswered;
    }

    /**
     * @return {boolean}
     */
    get published() {
        return this._published;
    }

    /**
     * @return {string}
     */
    get state() {
        return this._state;
    }

    get hideable() {
        return this._hideable;
    }

    get repostable() {
        return this._repostable;
    }

    /**
     * @return {string}
     */
    get postedAt() {
        return this._postedAt ? moment(this._postedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    get postedAtRaw() {
        return this._postedAt;
    }

    /**
     *
     * @returns {string|string}
     */
    get createdAt() {
        return this._createdAt ? moment(this._createdAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

    /**
     *
     * @returns {string|string}
     */
    get createdAtRaw() {
        return this._createdAt
    }

    /**
     * @return {string}
     */
    get updatedAt() {
        return this._updatedAt ? moment(this._updatedAt)
            .format('DD.MM.YYYY HH:mm')
            .toString() : '—';
    }

}
