import React, { useEffect, useState } from "react";
import analyticsChartService from "../../../../services/analyticsChartService";
import "./Charts.scss";
import { inject, observer } from "mobx-react";
import Chart1 from "./Charts/Chart1";
import Chart2 from "./Charts/Chart2";
import Chart3 from "./Charts/Chart3";
import Chart4 from "./Charts/Chart4";
import Empty from "./Charts/Empty";

const Charts = ({ analyticsStore }) => {
    const { filter } = analyticsStore;

    const [chart1, setChart1] = useState({})
    const [chart2, setChart2] = useState({})
    const [chart3, setChart3] = useState({})
    const [chart4, setChart4] = useState({})

    useEffect(() => {

        const promises = [
            analyticsChartService.getDataForChartConversionReview(filter.date),
            analyticsChartService.getDataForChartDistributionStatus(filter.date),
            analyticsChartService.getDataForChartConversionClick(filter.date),
            analyticsChartService.getDataForChartDistributionSource(filter.date)
        ]

        Promise.all(promises).then(([dataConversionReview, dataDistributionStatus, dataConversionClick, dataDistributionSource]) => {
            setChart1(dataConversionReview);
            setChart2(dataDistributionStatus)
            setChart3(dataConversionClick);
            setChart4(dataDistributionSource);
        })

    }, [JSON.stringify(filter)])

    return (
        <div className={'charts__wrapper'}>
            {!chart1.empty > 0 ? <Chart1 data={chart1} /> : <Empty title={'Кол-во заявок и конверсия в отзыв'}/>}

            {chart2.legend?.length > 0 ? <Chart2 data={chart2} /> : <Empty title={'Распределение заявок по статусам'}/>}

            {!chart3.empty > 0 ? <Chart3 data={chart3}  /> : <Empty title={'Конверсия в переход'}/>}

            {chart4.legend?.length > 0 ? <Chart4 data={chart4} /> : <Empty title={'Распределение переходов по площадкам'}/>}

        </div>
    )
}

export default inject('analyticsStore')(observer(Charts));
