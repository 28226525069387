import {
    isMobile
} from "react-device-detect";
import React, { useEffect } from "react";
import okAnalyticsService from "../../../../services/okAnalytics";
import ApplicationDetailDesktop from "./ApplicationDetailDesktop";
import ApplicationDetailMobile from "./ApplicationDetailMobile";
import { useParams } from "react-router";


const ApplicationDetailCommon = () => {

    const { id } = useParams();

    useEffect(() => {
        okAnalyticsService.addEntity({
            page: 'application_detail',
            target: 'page',
            event: 'open',
        });
    }, []);

    const getClientWidth = () => {
        return document.documentElement.clientWidth;
    }

    return (
        <>
            {(isMobile && getClientWidth() <= 1024) ? <ApplicationDetailMobile idApplication={id}/> : <ApplicationDetailDesktop idApplication={id}/>}
        </>
    );

}

export default ApplicationDetailCommon;
