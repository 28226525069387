import authService from "../auth";
import { serialize } from "../../helpers";

class GeoData {
    getRubricsCompound = (keyword) => {
        return authService.get(
            `/geodata/rubrics/compound?${serialize({ query: keyword })}`,
        ).then((result) => {
            return result;
        });
    }

    getRubricsAdplatform = (keyword, code) => {
        return authService.get(
            `/geodata/rubrics/adplatform/${code}?${serialize({ query: keyword })}`,
        ).then((result) => {
            return result;
        });
    }

    getCompanyList = () => {
        return authService.get(
            `/geodata/companies`,
        ).then((result) => {
            return result;
        });
    }

    getCompanyData = (id) => {
        return authService.get(
            `/geodata/companies/${id}/data`,
        ).then((result) => {
            return result;
        });

    }

    saveCompanyData = (id, data) => {
        return authService
            .patch(`/geodata/companies/${id}/data`, data)
            .then((data) => {
                return data;
            })
            .catch(e => {
                return Promise.reject(e);
            });
    }

    saveCompanyInfo = () => {}

    getChildrenRubrics = (id) => {
        return authService.get(
            `/geodata/rubrics/compound/${id}`,
        ).then((result) => {
            return result;
        });

    }

    getCompanyInfo = (id) => {
        return authService.get(
            `/geodata/companies/${id}`,
        ).then((result) => {
            return result;
        });

    }

    getAddressSuggestion = (query = '') => {
        let url = "http://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";

        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + process.env.REACT_APP_DADATA_TOKEN
            },
            body: JSON.stringify({query: query})
        }

        return fetch(url, options)
            .then(response => response.text())
            .then(result => result)
            .catch(error => error);
    }

    getAddress = (coords = {lat: 55.878, lon: 37.653}) => {
        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address";

        let query = { lat: coords.lat, lon: coords.lon };

        let options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + process.env.REACT_APP_DADATA_TOKEN
            },
            body: JSON.stringify(query)
        }

        return fetch(url, options)
            .then(response => response.text())
            .then(result => result)
            .catch(error => error);
    }

    copyData = (companyId, data = {source: '', merge:false}) => {
        return authService
            .post(`/geodata/companies/${companyId}/data/copy`, data)
            .then((result) => {
                return result;
            })
            .catch(e => {
                return Promise.reject(e);
            });
    }

    syncData = (companyId, source = null) => {
        return authService
            .post(`/geodata/companies/${companyId}/data/sync/${source}`, {})
            .then((result) => {
                return result;
            })
            .catch(e => {
                return Promise.reject(e);
            });
    }
}

const geoDataService = new GeoData();
export default geoDataService;