import cn from "classnames";
import { Button } from "primereact/button";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import './MessageForm.scss';

const CHARS_LIMIT = 1;

const MessageForm = ({ messageHandler }) => {

    const [comment, setComment] = useState('');

    const sendMessage = () => {
        messageHandler(comment);
        setComment('')
    }

    return (
        <div className={cn('replay__form')}>
            <div className={cn('replay__textarea_wrapper')}>
                <textarea
                    placeholder='Написать заметку (видно только вам и вашим коллегам)'
                    value={comment}
                    onChange={(e) => {
                        setComment(e.target.value);
                    }}
                    onKeyDown={(e)=> {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                comment.trim().length >= CHARS_LIMIT && sendMessage();
                                e.preventDefault()
                                e.stopPropagation()
                            }
                        }
                    }

                />
                <div className={cn('replay__right_side')}></div>
            </div>
            {!isMobile && <div className={cn('replay__annotation')}>Enter → отправить. Enter+shift → новый абзац</div>}
            <div className={cn('replay__btn_wrapper')}>
                <Button
                    className={cn('replay__btn', { active: comment.trim().length >= CHARS_LIMIT })}
                    onClick={() => {
                        sendMessage();
                    }}
                    disabled={comment.trim().length < CHARS_LIMIT}
                    label='→'
                />
            </div>
        </div>

    )
}

export default MessageForm;
