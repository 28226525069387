import React from "react";
import cn from "classnames";
import { pluralize } from "../../../../../helpers";
const AnswerCount = ({review, showQuestionsListBlock, setShowQuestionsListBlock}) => {

    return (
        <>
            {review.answer.count > 0 && <>
                <a
                    href="#"
                    onClick={(e) => {
                        setShowQuestionsListBlock((showQuestionsListBlock) => !showQuestionsListBlock);
                        return e.preventDefault();
                    }}
                    className={cn({'error': review.answer.errors})}
                >

                    {!showQuestionsListBlock && <>
                        {!review.answer.errors && <>
                            <span className={cn('badge')}>{review.answer.count}</span>
                            {pluralize(review.answer.count, ['ответ', 'ответа', 'ответов'], false)}

                            {!review.answer.official ? ' (нет официальных)'
                            :
                            ` (${pluralize(review.answer.official, ['официальный', 'официальных', 'официальных'])})`}
                        </>
                        }
                        {review.answer.errors > 0 &&
                            <>
                                <span className={cn('badge')} style={{'backgroundColor': '#EB5757'}}>{review.answer.count}</span>
                                {pluralize(review.answer.count, ['ответ', 'ответа', 'ответов'], false)}&nbsp;(ошибка)
                                {!review.answer.official ? ' нет официальных'
                                :
                                ` ${pluralize(review.answer.official, ['официальный', 'официальных', 'официальных'])}`}

                            </>
                        }
                    </>}
                    {showQuestionsListBlock && <>
                        <span className={cn('hide-answers')}>
                            {review.answer.count > 1 && 'Скрыть ответы'}
                            {review.answer.count < 2 && 'Скрыть ответ'}
                        </span>
                    </>}
                </a>
            </>}
        </>
    )
}

export default AnswerCount;
