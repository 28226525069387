import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import cn from "classnames";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

const WebsiteMulti = inject('geoStore')(observer((props) => {
    const {geoStore, field, code, source} = props;

    const [valueList, setValueList] = useState(geoStore.getSetupFieldValueFromStore(code, source, field.fieldName));

    useEffect(()=>{
        setValueList(geoStore.getSetupFieldValueFromStore(code, source, field.fieldName))
    }, [])


    const saveFieldList = (fieldValue) => {
        geoStore.setSetupFieldValue(code, source, field.fieldName, fieldValue);
    }

    const multiProps = {
        ...props,
        ...{
            valueList,
            saveFieldList,
            geoStore
        },
    }

    return(
        <Website {...multiProps}/>
    )


}))
const WebsiteSingle = inject('geoStore')(observer((props) => {
    const {geoStore, fieldName, onChange} = props;

    const [valueList, setValueList] = useState(props.geoStore.form[fieldName].value);

    const saveFieldList = (fieldValue) => {
        onChange(fieldValue);
        geoStore.setFormFieldValue(fieldName, fieldValue);
    }

    const singleProps = {
        ...props,
        ...{
            valueList,
            saveFieldList,
            geoStore
        },
    }
    return(
        <Website {...singleProps}/>
    )
}));

const Website = (props) => {
    const {fieldName, saveFieldList, valueList} = props;
    const [websiteUrlList, setWebsiteUrlList] = useState(valueList);

    useEffect(() => {
        //назначаем значения для мульти полей
        props.field && setWebsiteUrlList(valueList);
    }, [])

    useEffect(()=>{
        saveFieldList(websiteUrlList);
    }, [websiteUrlList])

    const addItem = (key, value) => {
        setWebsiteUrlList(prev => {
            let copy = [...prev];
            copy[key] = value;
            return copy
        })
    }

    const onDeleteItem = (key) => {

        let copy = [
            ...[],
            ...websiteUrlList
        ];

        if (key > -1) {
            copy.splice(key, 1);
        }
        setWebsiteUrlList(copy);
    }

    const showAddButton = (endItem = false) => {
        return websiteUrlList.length < 3 && endItem === true;
    }


    return (
        <>
            {websiteUrlList?.length > 0 && websiteUrlList.map((item, key)=>(
                <div className={cn("form__row")} key={key}>
                    <div className={cn('text-field')}>
                        <div className={cn("p-float-label")}>
                            <InputText
                                id={`${fieldName}_${key}`}
                                type="text"
                                name={`${fieldName}_${key}`}
                                value={websiteUrlList[key]}
                                onChange={(e) => {
                                    addItem(key, e.target.value)
                                }}
                            />
                            <label htmlFor={`${fieldName}_${key}`}>Ссылка на сайт</label>
                        </div>
                    </div>
                    {showAddButton(key === websiteUrlList.length-1) &&
                        <div className={cn('description-field')} style={{marginRight: '20px'}}>
                            <Button
                                label='Добавить'
                                icon="pi pi-plus"
                                iconPos="left"
                                className={cn('p-button-outlined')}
                                onClick={() => {
                                    const list = [
                                        ...websiteUrlList,
                                        ...['']
                                    ];
                                    setWebsiteUrlList(list);
                                }}/>
                        </div>
                    }

                    {key > 0 && <div className={cn('description-field')}>
                        <Button
                            label='Удалить'
                            className={cn('p-button-danger', 'p-button-outlined')}
                            onClick={() => {
                                onDeleteItem(key)
                            }}
                        />
                    </div>}
                </div>
            ))}

            {websiteUrlList.length === 0  && <div className={cn("form__row")}>
                <div className={cn("btn-field")}>
                    <Button
                        label='Добавить'
                        icon="pi pi-plus"
                        iconPos="left"
                        className={cn('p-button-outlined')}
                        onClick={() => {
                            const list = [
                                ...websiteUrlList,
                                ...['']
                            ];
                            setWebsiteUrlList(list);
                        }}/>
                </div>
            </div>}
        </>
    );
}

export {
    WebsiteMulti,
    WebsiteSingle
}