import React from "react";
import cn from "classnames";
import CountUp from 'react-countup';

import ico1 from "../../../../images/dashboard/dashboard_ico_new1.svg";
import ico2 from "../../../../images/dashboard/dashboard_ico_new2.svg";
import ico3 from "../../../../images/dashboard/dashboard_ico_new3.svg";
import ico4 from "../../../../images/dashboard/dashboard_ico_new4.svg";
import ico5 from "../../../../images/dashboard/dashboard_ico_new5.svg";
import ico6 from "../../../../images/dashboard/dashboard_ico_new6.svg";
import ico7 from "../../../../images/dashboard/dashboard_ico_new7.svg";


import './ApplicationListDashboard.scss';


const ApplicationListDashboard = ({value}) => {
    return (<>
        <div  className={cn('filter-content__dashboard')}>
            <>
                <ul className={cn('block', 'block__block1')}>
                    <li>
                        <span>Все заявки</span>
                        <span>
                        <img src={ico1} alt="Все заявки" title='Все заявки' width={25} height={25}/>
                            {value ? <CountUp end={value?.applications.total} separator=" " duration={.5} className={'CountUp__counter'}/> : 0}
                            {/*{value?.applications.total.toLocaleString('ru-RU') || 0}*/}
                    </span>
                    </li>
                    <li>
                        <span>Все отзывы</span>
                        <span>
                        <img src={ico2} alt='Все отзывы' title='Все отзывы' width={25} height={25}/>
                            {value ? <CountUp end={value?.reviews.total} separator=" " duration={.5} className={'CountUp__counter'}/> : 0}
                            {/*{value?.reviews.total.toLocaleString('ru-RU') || 0}*/}
                    </span>
                    </li>
                    <li>
                        <span>Все переходы</span>
                        <span>
                        <img src={ico3} alt='Все переходы' title='Все переходы' width={25} height={25}/>
                            {value ? <CountUp end={value?.traffic.total} separator=" " duration={.5} className={'CountUp__counter'}/> : 0}
{/*                            {value?.traffic.total.toLocaleString('ru-RU') || 0}*/}
                    </span>
                    </li>
                </ul>
                <ul className={cn('block', 'block__block2')}>
                    <li>
                        <span>Заявки за период</span>
                        <span>
                        <img src={ico4} alt="Заявки за период" title='Заявки за период' width={25} height={25}/>
                            {value ? <CountUp end={value?.applications.period} separator=" " duration={.5} className={'CountUp__counter'}/> : 0}
                            {/*{value?.applications.period.toLocaleString('ru-RU') || 0}*/}
                    </span>
                    </li>
                    <li>
                        <span>Отзывы за период</span>
                        <span>
                        <img src={ico5} alt='Отзывы за период' title='Отзывы за период' width={25} height={25}/>
                            {value ? <CountUp end={value?.reviews.period} separator=" " duration={.5} className={'CountUp__counter'}/> : 0}
{/*                            {value?.reviews.period.toLocaleString('ru-RU') || 0}*/}
                    </span>
                    </li>
                    <li>
                        <span>Переходы за период</span>
                        <span>
                        <img src={ico6} alt='Переходы за период' title='Переходы за период' width={25} height={25}/>
                            {value ? <CountUp end={value?.traffic.period} separator=" " duration={.5} className={'CountUp__counter'}/> : 0}
{/*                            {value?.traffic.period.toLocaleString('ru-RU') || 0}*/}
                    </span>
                    </li>
                </ul>
                <ul className={cn('block', 'block__block3')}>
                    <li>
                        <span>Заявки в работе</span>
                        <span>
                        <img src={ico7} alt="Заявки в работе" title='' width={25} height={25}/>
                            {value ? <CountUp end={value?.applications.progress} separator=" " duration={.5} className={'CountUp__counter'}/> : 0}
                            {/*{value?.applications.progress.toLocaleString('ru-RU') || 0}*/}
                    </span>
                    </li>
                </ul>
            </>
        </div>
    </>)
}

export default ApplicationListDashboard;
