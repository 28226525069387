import React, {useEffect, useRef} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";
import * as am4charts from "@amcharts/amcharts4/charts";
import cn from "classnames";

const PERIOD_LIST = {
    day: 'день',
    week: 'неделя',
    month: 'месяц',
    year: 'год',
}


const AdditionalChartTraffic = ({source= null, data = {}, period = ''}) => {
    am4core.useTheme(am4themes_animated);
    const chart = useRef(null);

    let x = null;

    const initChart = (data = []) => {
        x = am4core.create("chart-source2", am4charts.XYChart);
        x.language.locale = am4lang_ru_RU;
        x.padding(10, 20, 5, 10);
        x.numberFormatter.numberFormat = "#.a";
        let dateAxis = x.xAxes.push(new am4charts.DateAxis());

        dateAxis.dateFormats.setKey("day", "dd.MM");
        dateAxis.periodChangeDateFormats.setKey("day", "dd.MM");

        dateAxis.dateFormats.setKey("week", "dd.MM");
        dateAxis.periodChangeDateFormats.setKey("week", "dd.MM");

        dateAxis.dateFormats.setKey("month", "MM.YYYY");
        dateAxis.periodChangeDateFormats.setKey("month", "MM.YYYY");

        dateAxis.dateFormats.setKey("year", "YYYY");
        dateAxis.periodChangeDateFormats.setKey("year", "YYYY");

        let valueAxis = x.yAxes.push(new am4charts.ValueAxis());

        valueAxis.renderer.labels.template.disabled = false;
        valueAxis.renderer.grid.template.disabled = true;

// Create series
        let series = x.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "phoneNumberTraffic";
        series.dataFields.dateX = "date";
        series.name = "Показ номера телефона";
        series.stacked = true;

        let columnTemplate = series.columns.template;
        columnTemplate.tooltip = new am4core.Tooltip();
        columnTemplate.tooltip.label.fontSize = 12;

        columnTemplate.tooltip.pointerOrientation = 'left';
        columnTemplate.tooltip.getFillFromObject = false;
        columnTemplate.tooltip.label.fill = am4core.color("#7A7A7A")

        columnTemplate.tooltipX = am4core.percent(50);
        columnTemplate.tooltipY = am4core.percent(0);

        columnTemplate.tooltipText = "{tooltip}{date.formatDate('dd.MM.YYYY')}\n{name}: {phoneNumberTraffic}"
        columnTemplate.fillOpacity = 1;
        columnTemplate.strokeWidth = 0;
        columnTemplate.strokeOpacity = 1;
        columnTemplate.fill = am4core.color("#fbdb55");


        let series2 = x.series.push(new am4charts.ColumnSeries());
        series2.dataFields.valueY = "routeTraffic";
        series2.dataFields.dateX = "date";
        series2.name = "Построение маршрута";
        series2.stacked = true;


        let columnTemplate2 = series2.columns.template;
        columnTemplate2.tooltip = new am4core.Tooltip();
        columnTemplate2.tooltip.label.fontSize = 12;

        columnTemplate2.tooltip.pointerOrientation = 'left';
        columnTemplate2.tooltip.getFillFromObject = false;
        columnTemplate2.tooltip.label.fill = am4core.color("#7A7A7A")

        columnTemplate2.tooltipX = am4core.percent(50);
        columnTemplate2.tooltipY = am4core.percent(0);

        columnTemplate2.tooltipText = "{tooltip}{date.formatDate('dd.MM.YYYY')}\n{name}: {routeTraffic}"
        columnTemplate2.fillOpacity = 1;
        columnTemplate2.strokeWidth = 0;
        columnTemplate2.strokeOpacity = 1;
        columnTemplate2.fill = am4core.color("#61c156");


        let series3 = x.series.push(new am4charts.ColumnSeries());
        series3.dataFields.valueY = "conversionSiteTraffic";
        series3.dataFields.dateX = "date";
        series3.name = "Переходы на сайт";
        series3.stacked = true;


        let columnTemplate3 = series3.columns.template;
        columnTemplate3.tooltip = new am4core.Tooltip();
        columnTemplate3.tooltip.label.fontSize = 12;

        columnTemplate3.tooltip.pointerOrientation = 'left';
        columnTemplate3.tooltip.getFillFromObject = false;
        columnTemplate3.tooltip.label.fill = am4core.color("#7A7A7A")

        columnTemplate3.tooltipX = am4core.percent(50);
        columnTemplate3.tooltipY = am4core.percent(0);

        columnTemplate3.tooltipText = "{tooltip}{date.formatDate('dd.MM.YYYY')}\n{name}: {conversionSiteTraffic}"
        columnTemplate3.fillOpacity = 1;
        columnTemplate3.strokeWidth = 0;
        columnTemplate3.strokeOpacity = 1;
        columnTemplate3.fill = am4core.color("#da6154");

        let legend = new am4charts.Legend();
        legend.markers.template.width = 20;
        legend.markers.template.height = 20;
        legend.labels.template.fontSize = 12;

        x.legend = legend;
        x.data = data;
        chart.current = x;
    }

    const destroyChart = () => {
        x && x.dispose();
    }

    useEffect(() => {
        initChart(data);
        return () => destroyChart();
    }, [data]);

    return (
        <>
            <div className={cn('chart')}>
                <div className={cn('chart__header')}>
                    <div className={cn('header__title')}>
                        <div className={cn('position__left')}>
                            <h3>{source?.name}</h3>
                        </div>
                        <span className={cn('sub_text', 'position__right')}>Период: {PERIOD_LIST[period]}</span>
                    </div>
                </div>

                <div id='chart-source2' className={cn('chart__body')} style={{ width: '100%', height: '100%' }} />
            </div>
        </>
    )
}

export default AdditionalChartTraffic;