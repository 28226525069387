import React, { useEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { inject, observer } from "mobx-react";

import chartService from "./chartsService";

const Pie = ({ chartStore, reviewFilterStore, filter=null }) => {

    am4core.useTheme(am4themes_animated);
    const chart = useRef(null);
    const chartsFilter = filter ?? reviewFilterStore.parseFilterToChart();
    let x = null;

    const destroyChart = () => {
        x && x.dispose();
    }

    useEffect(() => {
        chartStore.getData(chartsFilter, 'pie')
            .then((data) => {
                chartService.initPieChart(data.data, data.summary, x, 'chart-source')
                    .then(result => {
                        chart.current = result
                    });
            });
        return () => destroyChart();
    }, [JSON.stringify(chartsFilter)]);

    return (
        <fieldset>
            <legend>Источники</legend>
            <div id='chart-source' style={{ width: '100%', height: '100%' }} />
        </fieldset>
    );
}

export default inject('chartStore', 'reviewFilterStore')(observer(Pie));
