import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import './profile.scss';
import { Link } from "react-router-dom";


const OkProfile = (model = [
    {
        name: "Интеграции",
        link: "/integration",
        command: () => {},
    },
    {
        name: "Настройки уведомлений",
        link: "/settings",
        command: () => {},
    },
    {
        name: "Геосервис",
        link: "/company",
        command: () => {},
    },
    {
        name: "Выход",
        link: "",
        command: () => {
            //logout()
        },
    },

]) => {


    const profileRef = useRef();
    const dropdownPlaceholder = useRef();

    const [openDropdown, setOpenDropdown] = useState(false);

    useEffect(() => {
        document.addEventListener("click", clickOutsideHandler, false);

        return () => {
            document.removeEventListener("click", clickOutsideHandler, false);
        }
    }, []);

    const clickOutsideHandler = (event) => {
        if (!profileRef.current?.contains(event.target)) {
            setOpenDropdown(false);
        }
    }

    return (
        <>
            <div className={cn("profile__wrapper")} ref={profileRef}>
                <div className={cn("profile__title")}
                     onClick={() => {
                         setOpenDropdown(openDropdown => !openDropdown);
                     }}
                >
                    <span className={cn("label")}>user name</span>
                </div>
                <div
                    className={cn("profile__dropdown", {"active": openDropdown})}
                    ref={dropdownPlaceholder}>
                    <ul>
                        {model.map((item, key) => {
                            return <li
                                key={key}
                                onClick={(e) => {
                                    item.command(e)
                                    setOpenDropdown(false)
                                }}
                            >{item.name}</li>
                        })}
                    </ul>
                </div>

            </div>
        </>
    );
}

const Profile = ({ userStore, developersTagStore, handleOnClick }) => {

    const domNode = useRef();
    const [openPlate, setOpenPlate] = useState(false);
    const { isDeveloper } = developersTagStore;

    useEffect(() => {
        document.addEventListener('click', clickOutsideHandler);
        return () => {
            document.removeEventListener('click', clickOutsideHandler);
        }
    }, [])

    const clickOutsideHandler = (event) => {
        if (!domNode.current?.contains(event.target)) {
            setOpenPlate(false)
        }
    }

    return (
        <div
            className={cn('profile', { active: openPlate })}
            ref={domNode}
        >
            <div className={cn('user-block__wrapper')}>
                <div
                    className={cn('profile__user-block')}
                    onClick={() => setOpenPlate(!openPlate)}
                >
                {isDeveloper ?
                    <i className={cn('profile__ico', 'pi', 'pi-cog')} /> :
                    <div className={cn('profile__ico', 'avatar', 'avatar__black', 'ico')} />}

                    <div className={cn('profile__user-name')}>{userStore.userFullName}</div>
                </div>
                <div className={cn('profile__client-title')} title={userStore.clientTitle}>{userStore.clientTitle}</div>
            </div>
            <ul
                onClick={() => setOpenPlate(false)}
            >
                <li>
                    <Link to={'/integration'}>Интеграции</Link>
                </li>
                <li>
                    <Link to={'/settings/storage-files'}>Хранилище файлов</Link>
                </li>

                <li>
                    <Link to={'/settings/notifications/email'}>Настройки уведомлений</Link>
                </li>
                <li>
                    <Link to={'/tags/list'}>Управление тегами</Link>
                </li>
{/*
                <li
                >
                    <Link to={'/company'}>Геосервис</Link>
                </li>
*/}
{/*
                <li>
                    <Link to={'/company-list'}>Мои компании</Link>
                </li>
*/}

                <li
                    data-tag='logout'
                    onClick={handleOnClick}
                >
                    Выход
                </li>
            </ul>
        </div>
    );
}

export default inject('userStore', 'developersTagStore')(observer(Profile));
