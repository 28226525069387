import React from "react";
import cn from "classnames";

const PrepareNumber = ({value = 0, af = ''}) => {
    return (
        <span className={cn(
            'diff',
            { 'diff__up': Number(value) > 0 },
            { 'diff__down': Number(value) < 0 },
            { 'text-color--gray': Number(value) === 0 },
        )}
        >
            {value > 0
                ?
                `+${Number(value).toLocaleString('ru-RU')}${af}`
                :
                `${Number(value).toLocaleString('ru-RU')}${af}`
            }

        </span>

    );
}

export default PrepareNumber;
