import React, { useEffect } from "react";

import BranchMobile from "./BranchMobile";
import BranchDesktop from "./BranchDesktop";
import okAnalyticsService from "../../../../services/okAnalytics";
import '../Analytics.scss';

import {
    isMobile
} from "react-device-detect";



const BranchCommon = () => {
    useEffect(() => {
        okAnalyticsService.addEntity({
            page: 'analytics_branch',
            target: 'page',
            event: 'open',
        });

    }, []);

    const getClientWidth = () => {
        return document.documentElement.clientWidth;
    }

    return (
        <>
            {(isMobile && getClientWidth() <= 1024) ? <BranchMobile /> : <BranchDesktop />}
        </>
    );

}

export default BranchCommon;
