import cn from "classnames";
import _ from "underscore";
import React, { useEffect, useState } from "react";
import './Sortable.scss';
import { inject, observer } from "mobx-react";

const sortState = {
    default: {
        order: 'default',
        ico: 'pi-sort-alt'
    },
    asc: {
        order: 'asc',
        ico: 'pi-sort-amount-up-alt',
    },
    desc: {
        order: 'desc',
        ico: 'pi-sort-amount-down',
    }
};

/**
 *
 * @param children
 * @param sort {{column: string, order: string}}
 * @param column {string}
 * @returns {JSX.Element}
 * @constructor
 */
const Sortable = ({ name, sort, column, reviewFilterStore }) => {

    const [sorting, setSorting] = useState(sortState.default);


    useEffect(() => {
        if (sort.column === column) {
            sort.order === 'asc' ?
                setSorting(sortState.asc)
                :
                setSorting(sortState.desc);
        } else {
            setSorting(sortState.default);
        }
    }, [sort])

    const handlerSort = () => {
        switch (sorting.order) {
            case 'asc':
                setSorting(() => {

                    reviewFilterStore.setSorting({
                        column: column,
                        order: sortState.desc.order,
                    });

                    return sortState.desc;
                });
                break;
            case 'desc':
            case 'default':
                setSorting(() => {

                    reviewFilterStore.setSorting({
                        column: column,
                        order: sortState.asc.order,
                    });

                    return sortState.asc;
                });
        }
    }

    const handlerSortDebounce = _.debounce(handlerSort, 500);

    return (
        <div
            className={cn('sortable')}
            onClick={handlerSortDebounce}
        >
            {name}
            <span
                className={cn('pi', sorting.ico)}
            />
        </div>
    )
}
export default inject('reviewFilterStore')(observer(Sortable));

