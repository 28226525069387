import authService from "./auth";


const KEY_NAME = 'notification';
class NotificationService {
    #notification = { count: 0, now: true};

    constructor() {

        this.notification = JSON.parse(this.getCountFromStore());
    }

    get notification() {
        return this.#notification;
    }

    set notification(notification) {
        this.#notification = notification;
    }

    markCountInStore() {
        this.notification = {
            count: this.notification.count,
            now: false,
        };
        this.setCountInStore();
    }

    getCountNotification() {
        return authService.get(`/notifications/unread`)
            .then((result) => {
                if(this.notification?.count !== result){
                    this.notification = {
                        count: result,
                        now: true,
                    };
                    this.setCountInStore();
                }
                return this.notification;
            })
            .catch(e => e);
    }

    saveEmailNotificationSetting(data) {
        return authService.post(
            '/notifications/channels/email',
            data
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }

    getEmailNotificationSetting() {
        return authService.get(`/notifications/channels/email`)
            .then(result => result)
            .catch(e => e);
    }

    saveTelegramNotificationSetting(data) {
        return authService.post(
            '/notifications/channels/telegram',
            data
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }

    getTelegramNotificationSetting() {
        return authService.get(`/notifications/channels/telegram`)
            .then(result => result)
            .catch(e => e);
    }


    getCountFromStore() {
        return window.localStorage.getItem(KEY_NAME);
    }

    setCountInStore() {
        window.localStorage.setItem(KEY_NAME, JSON.stringify(this.notification));
    }

}

const notificationService = new NotificationService();
export default notificationService;
