import authService from "./auth";
import { ServiceModel } from "../models/service";

class AutoServicesService {
    _services = [];

    get services() {
        return this._services;
    }

    set services(services) {
        this._services = services;
    }

//FIXME два метода делают почти одно и тоже
    getBranches = () => {
        return authService.get(
            `/branches`,
        ).then((result) => {
            this.services = result
            return this.services;
        });

/*

        if (this.services.length > 0) {
            return Promise.resolve(this.services);
        } else {
            return authService.get(
                `/branches`,
            ).then((result) => {
                //FIXME не понял зачем мапить?
                this.services = result.map(service => service);
                return this.services;
            });
        }
*/
    }

//FIXME два метода делают почти одно и тоже (этот убрать)
    getServices = () => {
        if (this.services.length > 0) {
            return Promise.resolve(this.services);
        } else {
            return authService.get(
                `/branches`,
            ).then((result) => {
                this.services = result.map(service => {
                    //FIXME эта модель уже не актуальна
                    return new ServiceModel(service);
                })
                return this.services;
            });
        }
    }

    clear = () => {
        this.services = [];
    }

}

const autoServicesService = new AutoServicesService();
export default autoServicesService;
