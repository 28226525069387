import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { SelectButton } from "primereact/selectbutton";
import cn from "classnames";
import developersTagService from "../../services/developersTag";

const Develop = ({ developersTagStore }) => {

    const options = [
        { name: 'Выключено', value: false },
        { name: 'Включено', value: true },
    ];

    useEffect(() => {
        developersTagStore.isDeveloper ? developersTagService.setTag() : developersTagService.removeTag()
    }, [developersTagStore.isDeveloper])

    const onChange = (e) => {
        developersTagStore.setDevAccess(!e.value ?? false);
    }

    return (
        <>
            <div className={cn('page__analytics', 'page')}>
                <div className={cn('title')}>
                    <h1>Разработка</h1>
                </div>

                <div className={cn('analytics-filter')}>
                    <div className="card">
                        <h5>Сбор статистики</h5>
                        <SelectButton
                            value={!developersTagStore.isDeveloper ?? false}
                            options={options}
                            onChange={(e) => onChange(e)}
                            optionLabel="name"
                        />
                    </div>
                </div>

            </div>
        </>
    );
}

export default inject('developersTagStore')(observer(Develop));
