export default class CustomerModel {
    constructor(customer = {}) {
        this._lastname = customer.lastname;
        this._firstname = customer.firstname;
        this._phone = customer.phone;
        this._email = customer.email;
    }

    get lastname() {
        return this._lastname;
    }

    get firstname() {
        return this._firstname;
    }

    get phone() {
        return this._phone;
    }

    get email() {
        return this._email;
    }

    toApiObject() {
        return {
            lastname: this.lastname,
            firstname: this.firstname,
            phone: this.phone,
            email: this.email,
        }
    }
}
