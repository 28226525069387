import React, { useEffect, useState } from "react";
import cn from "classnames";
import "../CompanyList.scss";
import check_ico from '../../../../images/check_ico.png';
import { getDetailDataByPlatform } from "../../../../helpers";
import { useHistory } from "react-router";
import { inject, observer } from "mobx-react";

const CompanyList = ({ geoStore, geoDataStore }) => {


    const [companyList, setCompanyList] = useState([]);

    useEffect(() => {
        geoDataStore.getCompanyList().then(result => {
            setCompanyList(result.items);
        })
    }, [])


    return (
        <>
            <div className={cn('page__company-list', 'page')}>
                <div className={'company-list'}>
                    <div className={cn('title')}>
                        <h1>Мои компании</h1>
                    </div>

                    <div className={cn('company-table', 'application-list', 'table')}>
                        <div className={cn('table__wrapper')}>

                            <div className="p-datatable p-component">
                                <div className="p-datatable-wrapper">
                                    <table role="grid">
                                        <thead className="p-datatable-thead">
                                        <tr>
                                            <th>Филиал</th>
                                            <th className={cn('status-col')}></th>
                                            <th>Адрес</th>
                                            <th className={cn('platform-col')}>Площадка</th>
                                            <th className={cn('progress-col')}>Заполненность</th>
                                        </tr>
                                        </thead>
                                        <tbody className={cn('p-datatable-tbody')}>
                                        {companyList.map((company, key)=>{
                                            return <Row item={company} key={key}/>
                                        })}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const Row = ({item}) => {

    const history = useHistory();

    const handleClick = () => {
        !window.getSelection().toString().length && history.push(`/company/${item.id}`);
    }

    return (<tr className="p-datatable-row" onClick={handleClick}>
        <td className={cn('branch')}>
            <div>{item.title}</div>
        </td>
        <td>
            <span  className={cn('p-tag', 'status-tag', `${item.type.code}`)} >{item.type.title}</span>
        </td>
        <td>{item.address}</td>
        <td className={cn('platform')}>
            {item.adplatforms.length > 0 && <ul>
                {
                    item.adplatforms.map((platform, key) => {
                        const { ico } = getDetailDataByPlatform(platform.type);
                        return (
                            <li key={key}>
                                <img src={ico} width={18} height={18}/>
                                <span className={cn('rating')}>
                                    <span className="pi-star pi"></span>
                                    <span className="value">{platform.rating}</span>
                                </span>
                                {platform.type === 'yandex' &&
                                    <span className={cn('check')}>
                                        <img src={check_ico} width={18} height={18}/>
                                    </span>
                                }
                            </li>
                        )
                    })
                }
            </ul>}
        </td>
        <td>
            {item.adplatforms.length > 0 && <ul>
                {
                    item.adplatforms.map((code, key) => {
                        const countColor = Math.round(code.completeness/10)

                        return (
                            <li key={key}>
                                <div className={cn('progress')}>
                                    <ProgressUl count={countColor}/>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>}
        </td>
    </tr>)}

const ProgressUl = ({count}) => {
    return (
        <ul className={cn('scale')}>
            {[1,2,3,4,5,6,7,8,9,10].map((item, key) => {
                if(item<=count) {
                    return (<li className={cn({'red': count<=5}, {'green': count > 5})} key={key}></li>)
                }
                else {
                    return (<li key={key}></li>)
                }
            })}
        </ul>

    )
}

export default inject('geoStore', 'geoDataStore')(observer(CompanyList));