import cn from "classnames";
import {InputSwitch} from "primereact/inputswitch";
import {Button} from "primereact/button";
import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import {Calendar} from "primereact/calendar";
import {Checkbox} from "primereact/checkbox";

const WorkTime = inject('geoStore')(observer(({geoStore}) => {

        const [weekInfo, setWeekInfo] = useState([]);

        useEffect(() => {
            setWeekInfo(geoStore.week)
        }, []);

        useEffect(() => {
            geoStore.setFormFieldValue('workTime', weekInfo)
        }, [weekInfo])

        const onChange = (result) => {

            let copy = [...weekInfo];
            copy.map((day, i) => {
                if (day.index === result.index) {
                    copy[i] = result;
                }
            })
            setWeekInfo(copy)
        }

        return (
            <>
                <div className={cn('block')}>
                    <div className={cn('block__title')}>
                        <h3>Время работы и временные изменения в режиме</h3>
                    </div>
                    <div className={cn('block__form')}>
                        {weekInfo.map((day, key) => (
                            <WorkDay key={key} day={day} onChange={onChange}/>
                        ))}
                    </div>
                </div>
            </>
        )
    }
))

const WorkDay = ({day, onChange}) => {
    const modelRange = {from: '', to: ''};

    const [statusSwitch, setStatusSwitch] = useState(false);
    const [twentyFour, setTwentyFour] = useState(false);

    const [item, setItem] = useState(day);

    useEffect(() => {
        setStatusSwitch(item.open)
    }, [])

    useEffect(() => {

        onChange(item)
    }, [item])

    useEffect(() => {
        let copy = {...item};
        copy.twentyFour = twentyFour;
        setItem(copy);
    }, [twentyFour])


    useEffect(() => {
        let copy = {...item};
        copy.open = statusSwitch;
        setItem(copy);
    }, [statusSwitch])

    const changeTime = (value, source) => {

        if (source === undefined) return;

        let copy = {...item}
        copy.time[source] = value;
        setItem(copy)

    }
    const changeRecess = (value, source) => {
        if (source === undefined) return;

        let copy = {...item}
        copy.recess[source] = value;
        setItem(copy)

    }

    const addRecess = () => {
        let copy = {...item}
        copy.recess = modelRange;
        setItem(copy)
    }

    const removeRecess = () => {
        let copy = {...item}
        copy.recess = null;
        setItem(copy)
    }

    return (
        <>
            <div className={cn("form__row")}>
                <div className={cn("input-switch")}>
                    <InputSwitch
                        checked={statusSwitch}
                        onChange={() => {
                            setStatusSwitch(!statusSwitch)
                        }}
                    />
                </div>
                <div className={cn("day-field")}>
                    <span className={cn("day-field__name")}>{item.name}</span>
                    <span className={cn("day-field__open")}>{statusSwitch ? 'Открыто' : 'Закрыто'}</span>
                </div>
                {statusSwitch && <>
                    <div className={cn("time-range-field")}>
                        <div className={cn("input-double-text")}>
                            <Calendar
                                timeOnly
                                disabled={twentyFour}
                                required={true}
                                showTime
                                hourFormat="24"
                                className={cn({'p-invalid': (!item.time.from || !item.time.to)})}
                                id={`day_from_${item.index}`}
                                name={`day_from_${item.index}`}
                                value={item.time.from}
                                onChange={(e) => {
                                    changeTime(e.target.value, 'from')
                                }}>
                            </Calendar>
                            <Calendar
                                timeOnly
                                disabled={twentyFour}
                                required={true}
                                showTime
                                hourFormat="24"
                                className={cn({'p-invalid': (!item.time.from || !item.time.to)})}
                                id={`day_to_${item.index}`}
                                name={`day_to_${item.index}`}
                                value={item.time.to}
                                onChange={(e) => {
                                    changeTime(e.target.value, 'to')
                                }}>
                            </Calendar>
                            <span className={cn("input-double-text__title")}>Время работы</span>
                            <div className={cn('checkbox-wrapper')}>
                                <Checkbox
                                    id={`twentyFour_${item.index}`}
                                    onChange={() => {
                                        setTwentyFour(!twentyFour)
                                    }}
                                    checked={twentyFour}
                                >
                                </Checkbox>
                                <label htmlFor={`twentyFour_${item.index}`} className="p-checkbox-label">Круглосуточно</label>
                            </div>
                        </div>
                    </div>
                    {item.recess === null ? <>
                            <div className={cn("btn-field")}>
                                <Button
                                    label='Добавить перерев'
                                    icon="pi pi-plus"
                                    iconPos="left"
                                    className={cn('p-button-outlined')}
                                    onClick={() => {
                                        addRecess()
                                    }}
                                />
                            </div>
                        </>
                        :
                        <>
                            <div className={cn("time-range-field")}>
                                <div className={cn("input-double-text")}>
                                    <Calendar
                                        timeOnly
                                        showTime
                                        hourFormat="24"
                                        id={`recess_from_${item.index}`}
                                        name={`recess_from_${item.index}`}
                                        value={item.recess.from}
                                        onChange={(e) => {
                                            changeRecess(e.target.value, 'from')
                                        }}>
                                    </Calendar>
                                    <Calendar
                                        timeOnly
                                        showTime
                                        hourFormat="24"
                                        id={`recess_to_${item.index}`}
                                        name={`recess_to_${item.index}`}
                                        value={item.recess.to}
                                        onChange={(e) => {
                                            changeRecess(e.target.value, 'to')
                                        }}>
                                    </Calendar>
                                    <span className={cn("input-double-text__title")}>Перерыв</span>
                                </div>
                            </div>
                            <div className={cn("btn-field")}>
                                <Button
                                    label='Удалить перерыв'
                                    className={cn('p-button-danger', 'p-button-outlined')}
                                    onClick={() => {
                                        removeRecess()
                                    }}
                                />
                            </div>
                        </>
                    }
                </>}


            </div>
        </>
    )
}


export default WorkTime;