import React, { useEffect, useState } from 'react';
import cn from "classnames";
import moment from "moment";
import { DateRangePicker } from 'react-dates';
import { useIsMount } from "../../hooks";
import 'react-dates/lib/css/_datepicker.css';
import './OkDateRangePicker.scss';

const OkDateRangePicker = ({
         dateRange,
         orientation = 'horizontal',
         onChangeDateHandler = () => {},
         disabled= false,
     }) => {
        const [startDate, setStartDate] = useState(moment(dateRange.from));
        const [endDate, setEndDate] = useState(moment(dateRange.to));
        const [focusedInput, setFocusedInput] = useState(null);
        const isMount = useIsMount();

        useEffect(() => {

            if (!isMount && startDate && endDate) {
                onChangeDateHandler({ startDate, endDate })
            }

        }, [startDate, endDate]);

        useEffect(() => {
            if (!isMount) {
                setStartDate(moment(dateRange.from));
                setEndDate(moment(dateRange.to));
            }
        }, [JSON.stringify(dateRange)])

        return (
            <>
                <div className={cn('data-text__wrapper')}>
                    <div className={cn({'disabled': disabled})}>
                        <DateRangePicker
                            startDatePlaceholderText="Начало"
                            endDatePlaceholderText="Конец"
                            isOutsideRange={() => false}
                            readOnly
                            disabled={disabled}
                            orientation={orientation}
                            startDateId="startDate"
                            endDateId="endDate"
                            startDate={startDate}
                            endDate={endDate}
                            onDatesChange={({ startDate, endDate }) => {
                                setStartDate(startDate);
                                setEndDate(endDate);
                            }}
                            focusedInput={focusedInput}
                            onFocusChange={(focusedInput) => {
                                setFocusedInput(focusedInput);
                            }}
                        />
                    </div>
                </div>
            </>
        );

    };

export default OkDateRangePicker;
