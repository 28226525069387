class ChannelModel {
    #code;
    #accountId;
    #createdAt;
    #enabled;

    constructor(channel = {}) {
        this.#code = channel.code || '';
        this.#accountId = channel.accountId || '';
        this.#createdAt = channel.createdAt || '';
        this.#enabled = channel.enabled || false;
    }

    /**
     * @return {string}
     */
    get code() {
        return this.#code;
    }

    /**
     * @return {string}
     */
    get accountId() {
        return this.#accountId;
    }

    /**
     * @return {string}
     */
    get createdAt() {
        return this.#createdAt;
    }

    /**
     * @return {boolean}
     */
    get enabled() {
        return this.#enabled;
    }

}


class TelegramUserModel {
    #id;
    #first_name;
    #last_name;
    #auth_date;
    #hash;
    #username;
    #photo_url;


    constructor(user = {}) {
        this.#id = user.id || null;
        this.#first_name = user.first_name || '';
        this.#last_name = user.last_name || '';
        this.#auth_date = user.auth_date || '';
        this.#hash = user.hash || '';
        this.#username = user.username || '';
        this.#photo_url = user.photo_url || '';
    }

    /**
     * @return {int|null}
     */
    get id() {
        return this.#id;
    }

    /**
     * @return {string}
     */
    get first_name() {
        return this.#first_name;
    }

    /**
     * @return {string}
     */
    get last_name() {
        return this.#last_name;
    }

    /**
     * @return {string}
     */
    get auth_date() {
        return this.#auth_date;
    }

    /**
     * @return {string}
     */
    get hash() {
        return this.#hash;
    }

    /**
     * @return {string}
     */
    get username() {
        return this.#username;
    }

    /**
     * @return {string}
     */
    get photo_url() {
        return this.#photo_url;
    }

}


export  { ChannelModel, TelegramUserModel };
