import { action, observable } from "mobx";
import reviewsService from "../services/review";
import answerService from "../services/answer";
import ErrorService from "../services/error";
import Answer from "../models/answer";
import reviewFilterStore from "../stores/reviewFilter";

class ReviewsStore {
    /**
     * @type {ReviewService}
     * @private
     */
    _reviewsService;

    /**
     * @type {AnswerService}
     * @private
     */
    _answerService;

    /**
     * @type {Array<ExternalReview>}
     */
    @observable items = [];

    @observable isLoading = false;

    @observable answers = {};

    sentiments = [
        {
            code: 'positive',
            value: 'Позитивный'
        },
        {
            code: 'negative',
            value: 'Негативный'
        },
        {
            code: 'neutral',
            value: 'Нейтральный'
        },

    ]

    constructor(reviewsService, answerService) {
        this._reviewsService = reviewsService;
        this._answerService = answerService;
    }

    getSentiments = (code = '') => {
        if(code === '') return ;

        const result = this.sentiments.find(sentiment => {
            return sentiment.code === code
        })

        return result ?? ''
    }

    @action setLoading = (loading) => {
        this.isLoading = loading;
    }

    /**
     * @param {Array<ExternalAnswer>} listAnswers
     */
    @action setAnswers = (listAnswers) => {
        this.listAnswers = listAnswers || [];
    }

    /**
     * @param {Array<ExternalReview>} items
     */
    @action setItems = (items) => {
        this.items = items || [];
    }

// TODO обязательно отрефактить.
    loadReviews2 = (filter = {}) => {
        this.setLoading(true);
        return this
            ._reviewsService
            .getList(filter)
            .then((result) => {
                this.setItems(result?.items);
                reviewFilterStore.setPager(result?.pager);
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => this.setLoading(false));
    }

    loadReviews = (filter = {}) => {
        this.setLoading(true);
        return this
            ._reviewsService
            .getList(filter)
            .then((result) => {
                reviewFilterStore.setPager(result?.pager);
                return result
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => this.setLoading(false));
    }

    loadReview = (id) => {
        return this
            ._reviewsService
            .getItem(id)
            .then((result) => result)
            .catch(err => {
                return Promise.reject(err);
            })
            .finally(() => this.setLoading(false));
    }


    /**
     * @param {int} reviewId
     */
    loadAnswers = (reviewId) => {
        return this
            ._answerService
            .getList(reviewId)
            .then((result) => {
                return result.items;
            });
    }

    loadAnswer = (reviewId, id) => {
        return this
            ._answerService
            .getItem(reviewId, id)
            .then((result) => result)
            .catch(err => console.log(err));
    }

    repostAnswer = (id, reviewId, text) => {
        return this._answerService
            .repostAnswer(id, reviewId, text)
            .then(result => result.id)
            .catch(e => Promise.reject(e));
    }

    delAnswer = (id, reviewId) => {
        return this._answerService
            .delAnswer(id, reviewId)
            .then(result => result)
            .catch(e => Promise.reject(e));
    }


    getAnswerVariants(reviewId) {
        return this._answerService
            .getAnswerVariants(reviewId)
            .then(result => result)
            .catch(e => Promise.reject(e));
    }

    saveAnswer = (reviewId, text) => {
        return this._answerService
            .saveAnswer(reviewId, text)
            .then(result => result.id)
            .catch(e => Promise.reject(
                new ErrorService(e).getError("Ошибка отправки ответа", "Произошла ошибка отправки ответа. Сервис временно недоступен")
            ));
    }

    /**
     *
     * @param {int} id
     * @param {int} reviewId
     * @param {string} text
     * @returns {Answer}
     */
    addNewCommentInList = (id, reviewId, text) => {

        const tplData = {
            "id": id,
            "reviewId": reviewId,
            "parentId": null,
            "depth": 0,
            "author": {
                "name": null,
                "anonymous": true
            },
            "text": text,
            "answerable": true,
            "official": true,
            "okdAnswered": false,
            "published": false,
            "state": "pending",
            "hideable": false,
            "repostable": false,
            "postedAt": new Date(),
            "updatedAt": new Date(),
            "isNewComment": true,
        }

        return new Answer(tplData);
    }
}

const reviewsStore = new ReviewsStore(
    reviewsService,
    answerService,
);

export default reviewsStore;
