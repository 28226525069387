import React, { useEffect } from "react";
import cn from "classnames";
import Setup from "../Setup";
import {TopFormFields} from "../../../../../stores/forms/topForm.store";
import {inject, observer} from "mobx-react";
import OkInputText from "../../../../common/OkInputText";

const NameCompany = ({geoStore}) => {

    const {form} = geoStore;

    return (
        <>
            <div className={cn('block')}>
                <div className={cn('block__title')}>
                    <h3>Название</h3>
                </div>
                <div className={cn('block__form')}>
                    <div className={cn("form__row")}>
                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <OkInputText
                                    id='title'
                                    type="text"
                                    name='title'
                                    required={form.title.required}
                                    value={form.title.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('title', e.target.value)
                                    }}
                                />
                                <label htmlFor='title'>
                                    {form.title.title}
                                    {form.title.required && <span>*</span>}
                                </label>
                            </div>
                        </div>

                        <div className={cn('text-field')}>
                            <div className={cn("p-float-label")}>
                                <OkInputText
                                    id='short_title'
                                    type="text"
                                    name='short_title'
                                    required={form.short_title.required}
                                    value={form.short_title.value}
                                    onChange={(e) => {
                                        geoStore.setFormFieldValue('short_title', e.target.value)
                                    }}
                                />
                                <label htmlFor='short_title'>
                                    {form.short_title.required && <span>*</span>}
                                    {form.short_title.title}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <div className={cn('title-field')}>
                            <h4>Настройка площадок</h4>
                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <Setup
                            disabled={!form.title.value.length}
                            fields={
                                [
                                    new TopFormFields({
                                        fieldName: 'name',
                                        code: 'title',
                                        fieldLabel: 'Название компании',
                                        fieldValue: form.title.value,
                                        fieldType: 'text',
                                        required: false
                                    }),
                                    new TopFormFields({
                                        fieldName: 'name2',
                                        code: 'short_title',
                                        fieldLabel: 'Короткое название компании',
                                        fieldValue: '',
                                        fieldType: 'text',
                                        required: false
                                    }),
                                ]
                            }
                            code={"firstSetup"}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default inject('geoStore')(observer(NameCompany));