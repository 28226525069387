import React from "react";
import okAnalyticsService from "../../services/okAnalytics";

const OkAnalytics = (Component) => {

    return class extends React.Component {
        #okAnalyticsService;

        constructor(props) {
            super(props);
            this.#okAnalyticsService = okAnalyticsService;
        }

        componentDidMount() {
            okAnalyticsService.startEventListeners();
        }

        componentWillUnmount() {
            okAnalyticsService.removeEventListeners();
        }

        render() {
            return <Component {...this.props} />;
        }
    }
}
export default OkAnalytics;
