import {observable, action} from "mobx";
import authService from "../services/auth";
import profileService from '../services/profile';
import {ProfileModel} from "models/profile";

class UserStore {

    @observable profile = new ProfileModel();
//TODO инициализировать в false. придумать что-то вроде isLoading, чтобы не мигал index при запросе новых токенов
    @observable isAuthorized = null;

    constructor() {
//TODO когда будет проверка авторизации в каждом запросе, буду думать как переделать
        authService.loadFromAppStorage().then(() => {
            return profileService.getProfile();
        })
            .then((data) => {
                this.setProfile(data);
            })
            .catch(() => {
            })
            .finally(() => {
                this.autorize();
            });
    }

    get firstService() {
        return this.profile.roles[0].services[0];
    }

    get userFullName() {
        return `${this.profile.user.lastname} ${this.profile.user.firstname}`
    }

    get clientTitle() {
        return this.profile.client.title;
    }

    @action setProfile = (data) => {
        this.profile = new ProfileModel(
            data?.user,
            data?.client,
            data?.roles,
            data?.allowed,
        );
    }

    @action autorize = () => {
        this.isAuthorized = authService.isAuthorized;
    }

    @action authAccessToken = (auth_code) => {
        return authService.authAccessToken(auth_code)
            .then(() => {
                return profileService.getProfile();
            })
            .then((data) => {
                this.setProfile(data);
                this.autorize();
            })
            .catch(e => {
                return e;
            })
    }

    @action login = (email, password, remember) => {
        return authService.auth({username: email, password: password}, remember)
            .then(() => {
                return profileService.getProfile();
            })
            .then((data) => {
                this.setProfile(data);
                this.autorize();
            })
            .catch(e => {
                return e;
            })
    }

    @action logout = () => {
        return authService.logout()
            .then(() => {
                this.autorize();
            })
            .catch(e => {
                return e;
            })
    }



}

export default new UserStore();
