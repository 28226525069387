import React, { useEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import RatingBlock from "./RatingBlock";
import { pluralize } from "../../../../helpers/index";

import chartService from "./chartsService";

const Dynamics = ({ chartStore, reviewFilterStore, filter=null }) => {

    am4core.useTheme(am4themes_animated);
    const chart = useRef(null);

    const chartsFilter = filter ?? reviewFilterStore.parseFilterToChart();
    let x = null;
    const [summary, setSummary] = useState({ rating: 0, diff: 0 });
    const [isLoader, setLoader] = useState(true);

    const destroyChart = () => {
        x && x.dispose();
    }

    useEffect(() => {
        chartStore.getData(chartsFilter, 'dynamics')
            .then((data) => {
                setSummary(data.summary);
                chartService.initDynamicsChart(data.data, x, 'chart-source3')
                    .then(result => {
                        chart.current = result
                        setLoader(false);
                    });

            });
        return () => destroyChart();
    }, [JSON.stringify(chartsFilter)]);

    return (
        <fieldset>
            <legend>Динамика оценок</legend>
            {!isLoader && <div className={cn('chart__header', 'header')}>
                <div className={'header__left-side'}>
                    <div className={'number-top'}>
                        <div>{summary.total.toLocaleString('ru-RU')}</div>
                        <span>{pluralize(summary.total, ['оценка', 'оценки', 'оценок'], false)}</span>
                    </div>
                    <div>
                        <RatingBlock
                            rating={summary.diff}
                            className={'number-bottom'}/>
                    </div>
                </div>
            </div>}
            <div className={cn('chart__body')}>
                <div id='chart-source3' style={{ width: '100%', height: '100%' }} />
            </div>
        </fieldset>
    )
}

export default inject('chartStore', 'reviewFilterStore')(observer(Dynamics));
