import React from "react";
import cn from 'classnames';
import HeaderLogo from './../images/header_logo.png';
import './Footer.scss';

const Footer = () => {
    return (
        <div className={'footer'}>
            <div className={'footer__wrapper'}>
                <div className={cn('footer__logo')}>
                    <img src={HeaderLogo} alt="okreview" width={157} height={37}/>
                </div>
                <ul className={cn('footer__terms-of-use')}>
                    <li><a href={"/files/OKReview_terms_of_use.pdf"} target="_blank" rel="noreferrer">Пользовательское соглашение</a></li>
                    <li><a href={"/files/OKReview_privacy_policy.pdf"} target="_blank" rel="noreferrer">Политика конфиденциальности</a></li>
                </ul>

                <div className={cn('footer__contact-us')}>
                    <a href="mailto:info@okreview.ru">info@okreview.ru</a>
                    <a href="tel:+74993026962">+7 (499) 302-69-62</a>
                </div>
            </div>
        </div>
    );
}

export default Footer;
