import { DayPickerSingleDateController } from 'react-dates';
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import cn from "classnames";
import { InputText } from "primereact/inputtext";
import './OkDatePicker.scss';
import { useField, useFormikContext } from "formik";

const OkDatePicker = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    const [date, setDate] = useState();
    const [focused, setFocused] = useState(false);
    const [show, setShow] = useState(false);
    const datePicker = useRef();

    const dateString = field.value && moment(field.value).format('DD.MM.YYYY');


    useEffect(() => {
        if (show) {
            if (datePicker.current) {
                setTimeout(() => {
                    if (document.documentElement.clientHeight < datePicker.current.getBoundingClientRect().bottom) {
                        datePicker.current.style.top = 'auto';
                        datePicker.current.style.bottom = '55px';
                    }
                }, 1);
            }
        }
    }, [show])


    const onDateChange = (date) => {
        setFieldValue(field.name, moment(date).format());
        setDate(date);
        setShow(false)
    }

    const onFocusChange = () => {
        setFocused(true);
    }

    const onOutsideClick = () => {
        setShow(false)
    }

    const isDayBlocked = (day) => moment().isBefore(day, 'day');

    return (
        <div>
            <div className={cn("p-float-label", "p-input-icon-right", "DatePickerWrapper")}>
                <i className="pi pi-calendar" />
                <InputText
                    {...field}
                    {...props}
                    value={dateString || ''}
                    readOnly
                    onClick={() => setShow((show) => !show)}
                />
                <label htmlFor="purchaseDate">
                    Дата сделки <span style={{ color: 'red' }}>*</span>
                </label>
                {show && <div className={'pickerPanelWrapper'} ref={datePicker}>
                    <DayPickerSingleDateController
                        isDayBlocked={isDayBlocked}
                        onOutsideClick={onOutsideClick}
                        onDateChange={onDateChange}
                        onFocusChange={onFocusChange}
                        focused={focused}
                        date={date}
                    />
                </div>}
            </div>
        </div>
    );
}


export default OkDatePicker;
