import moment from "moment-timezone";

export default class QRFeedbackModel {

    #id;
    #number;
    #time;
    #status;
    #branch;
    #review;
    #counters;

    constructor(object={}) {
        this.#id = object.id || '';
        this.#number = object.number || null;
        this.#time = object.time || '';
        this.#status = object.status || {};
        this.#branch = object.branch || {};
        this.#review = object.review || {};
        this.#counters = object.counters || {};

    }


    get id() {
        return this.#id;
    }

    get number() {
        return this.#number;
    }

    get time() {
        return this.#time;
    }

    get status() {
        return this.#status;
    }

    get branch() {
        return this.#branch;
    }

    get review() {
        return this.#review;
    }

    get counters() {
        return this.#counters;
    }

    get time() {
        return moment
            .utc(this.#time)
            .tz(process.env.REACT_APP_timeZone)
            .format(process.env.REACT_APP_dateTimeDisplayFormat)
            .toString()
    }
}