import React, { useEffect, useState } from "react";
import { dateDiff, pluralize } from "../../helpers";
import moment from "moment/moment";

const DateCompare = ({ startDate = null, endDate = null }) => {

    const [diff, setDiff] = useState(null)

    useEffect(() => {
        let { days, hours, minutes } = dateDiff(moment(startDate), moment(endDate))
        let message = 'Время ответа: ';
        if(days !== 0){
            message += `${pluralize(Math.abs(days), ['день', 'дня', 'дней'])} `;
        }
        if(hours !== 0) {
            message += `${pluralize(Math.abs(hours), ['час', 'часа', 'часов'])} `;
        }

        if(minutes !== 0) {
            message += `${pluralize(Math.abs(minutes), ['минута', 'минуты', 'минут'])} `;
        }

        setDiff(message)
    }, []);

    return (
        <>
            {diff}
        </>
    )
}

export default DateCompare;