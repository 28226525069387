export default class BranchModel {
    #id;
    #type;
    #title;
    #city;
    #address;
    #phone;
    #email;
    #website;

    constructor(branch = {}) {
        this.#id = branch.id || null;
        this.#type = new BranchType(branch.type || {});
        this.#title = branch.title || '';
        this.#city = branch.city || '';
        this.#address = branch.address || '';
        this.#phone = branch.phone || '';
        this.#email = branch.email || '';
        this.#website = branch.website || '';

    }

    /**
     *
     * @returns {Number}
     */
    get id() {
        return this.#id;
    }

    /**
     *
     * @returns {Object}
     */
    get type() {
        return this.#type;
    }

    /**
     *
     * @returns {String}
     */
    get title() {
        return this.#title;
    }

    /**
     *
     * @returns {String}
     */
    get city() {
        return this.#city;
    }

    /**
     *
     * @returns {String}
     */
    get address() {
        return this.#address;
    }

    /**
     *
     * @returns {String}
     */
    get phone() {
        return this.#phone;
    }

    /**
     *
     * @returns {String}
     */
    get email() {
        return this.#email;
    }

    /**
     *
     * @returns {String}
     */
    get website() {
        return this.#website;
    }

}

class BranchType {
    #id;
    #title;
    #defaultPurchase;
    #defaultApplication;

    constructor(branchType) {
        this.#id = branchType.id || null;
        this.#title = branchType.title || '';
        this.#defaultPurchase = branchType.defaultPurchase || {};
        this.#defaultApplication = branchType.defaultApplication || {};
    }

    get id() {
        return this.#id;
    }

    get title() {
        return this.#title;
    }

    get defaultPurchase() {
        return this.#defaultPurchase;
    }

    get defaultApplication() {
        return this.#defaultApplication;
    }
}
