import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import OkAnalytics from "../common/OkAnalytics";

import Header from "../Header";
import Footer from "../Footer";
import ApplicationFormAdd from "../pages/application/form/ApplicationFormAdd";
import ApplicationList from "../pages/application/list/ApplicationList";
import Develop from "../pages/Develop";
import ApplicationDetailCommon from "../pages/application/detail/ApplicationDetail";
import ReviewsCommon from "../pages/analytics/reviews/Reviews";
import ReviewDetail from "../pages/review/list/ReviewDetail";
import BranchCommon from "../pages/analytics/branch/Branch";
import DataCompare from "../pages/analytics/data-compare/DataCompare";
import ApplicationsCommon from "../pages/analytics/applications/Applications";
import ReviewListCommon from "../pages/review/ReviewListCommon"
import TrafficCommon from '../pages/analytics/traffic/Traffic';
import Integration from "../pages/integration/Integration";
import Dashboard from "../pages/dashboard/Dashboard";
import TelegramLogin from "components/pages/telegram/TelegramLogin";
import CompanyList from "../pages/company/list/CompanyList";
import ExternalAuth from "../pages/externalauth/ExternalAuth";
import Company from "../pages/company/edit/Company";
import Booklet from "../pages/integration/Booklet";
import QRCodeCommon from "../pages/analytics/qrcode/QRCode";
import Payload from "../pages/payload/Payload";
import TagsList from "../pages/tags/TagList";
import QrTransitionList from "../pages/qrtransition/QrTransitionList";
import QrTransitionDetail from "../pages/qrtransition/QrTransitionDetail";

import SettingsEmail from "../pages/settings/SettingsEmail";
import SettingsTelegram from "../pages/settings/SettingsTelegram";
import StorageDownloadFiles from "../pages/storage-files/StorageDownloadFiles";

const DEVELOP_EMAIL = 'test-user3@example.com';

//Заготовка под глобальный прелоадер.
const Preloader = ({ children }) => {
    return (
        <div className={'content'}>
            {children}
        </div>
    );
};

//review
const Internal = inject('userStore')(observer(({userStore}) => {
    return (
        <>
            <Header />
            <Preloader>
                <Switch>
                    <Route exact path='/' component={Dashboard} />
                    <Route path='/dashboard' component={Dashboard} />}
                    {userStore.profile.allowed.createApplication &&
                        <Route path='/application/add' component={ApplicationFormAdd} />}
                    {userStore.profile.allowed.listApplications &&
                        <Route path='/application/list' component={ApplicationList} />}
                    {userStore.profile.allowed.listApplications &&
                        <Route exact path='/application/detail/:id' component={ApplicationDetailCommon} />}
                    {userStore.profile.allowed.listReviews &&
                        <Route path='/monitoring/list' component={ReviewListCommon} />}
                    {userStore.profile.allowed.listReviews &&
                        <Route path='/monitoring/detail/:id' component={ReviewDetail} />}
                    {userStore.profile.allowed.analytics &&
                        <Route path='/analytics/applications' component={ApplicationsCommon} />}
                    {userStore.profile.allowed.analytics &&
                        <Route path='/analytics/rating' component={ReviewsCommon} />}
                    {userStore.profile.allowed.analytics &&
                        <Route path='/analytics/qr' component={QRCodeCommon} />}

                    {userStore.profile.allowed.analytics &&
                        <Route exact path='/analytics/branch' component={BranchCommon} />}
                    {userStore.profile.allowed.analytics &&
                        <Route exact path='/analytics/branch/:id' component={BranchCommon} />}
                    {userStore.profile.allowed.analytics &&
                        <Route exact path='/integration' component={Integration} />}
                    {userStore.profile.allowed.analytics &&
                        <Route exact path='/integration/:attention' component={Integration} />}
                    {userStore.profile.allowed.analytics &&
                        <Route path='/analytics/traffic' component={TrafficCommon} />}
                    {userStore.profile.allowed.analytics &&
                        <Route path='/analytics/data-compare' component={DataCompare} />}




                    <Route path='/qr/list' component={QrTransitionList} />}
                    <Route path='/qr/detail/:id' component={QrTransitionDetail} />}

                    <Route path='/settings/notifications/email' component={SettingsEmail} />
                    <Route path='/settings/notifications/telegram' component={SettingsTelegram} />
                    <Route path='/settings/storage-files' component={StorageDownloadFiles} />


                    <Route path='/tags/list' component={TagsList} />}

                    <Route path='/booklet/:id' component={Booklet} />
                    <Route path='/booklet/:id/:title' component={Booklet} />

                    <Route path='/settings' component={TelegramLogin} />

                    <Route path='/company-list' component={CompanyList} />
                    <Route path='/company/:id' component={Company} />

                    <Route path='/settings' component={TelegramLogin} />

                    <Route path='/externalauth/:auth_code' component={ExternalAuth} />}

                    <Route path='/payload' component={Payload} />}

                    {userStore.profile.user.email === DEVELOP_EMAIL &&
                        <Route path='/dev' component={Develop} />}

                    <Redirect to='/' />
                </Switch>
            </Preloader>
            <Footer />
        </>

    )
}));

export default withRouter(OkAnalytics(Internal));


