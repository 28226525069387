import { Button } from "primereact/button";
import cn from "classnames";
import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

const ClaimButton = ({ uiStore, item, setVisibleRight }) => {

    const showMessage = (subsource_code, allowed, publshed) => {
        if(publshed) {
            if(subsource_code === 'flamp' && allowed) {
                return false
            }
            if(subsource_code === 'flamp' && !allowed) {
                return true
            }

        }
    }

    return (
        <>

            {(item.published && item.subsourceCode === 'flamp') && <Button
                label='Обжаловать отзыв'
                className={cn('p-button-outlined')}
                onClick={() => {
                    if(showMessage(item.subsourceCode, item.claim.allowed, item.published)) {
                        uiStore.setMessage(
                            {
                                severity: 'warn',
                                summary: 'ВНИМАНИЕ!',
                                detail: 'Для того, чтобы обжаловать отзывы, размещенные на Flamp, необходимо настроить доступ для сервиса OKReview. Свяжитесь с Вашим менеджером - он поможет быстро и просто подключить этот функционал.',
                            }
                        )
                    }
                    else {
                        setVisibleRight(true)
                    }

                }}
            />}

            {(item.published && item.subsourceCode !== 'flamp' && item.claim.allowed === true) && <Button
                label='Обжаловать отзыв'
                className={cn('p-button-outlined')}
                onClick={() => {
                    setVisibleRight(true)
                }}
            />}
        </>

    )
}

export default inject('uiStore')(observer(ClaimButton))