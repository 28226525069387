import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import cn from "classnames";
import NpsBlock from "../common/NpsBlock";
import RatingField from "../common/RatingField";
import MessageForm from "../common/MessageForm";
import './Lent.scss';

const Lent = ({ events, sendMessage, scrollToEnd }) => {

    useEffect(() => {
        scrollToEnd()
    }, [])

    const send = (data) => {
        sendMessage(data);
    }

    return (
        <>
            {!isMobile ? <div className={cn('scroll-container')} id='scroll-container'>
                <ul>
                    {events.map((item, key) => (
                        <Event
                            item={item}
                            key={key}
                        />
                    ))}
                </ul>
                <span id='end-cont' className={cn('end-cont')}/>
            </div> : <>
                <ul>
                    {events.map((item, key) => (
                        <Event
                            item={item}
                            key={key}
                        />
                    ))}
                </ul>
            </>
            }

            <div className={cn('message-form')}>
                <div className={cn('message-form__wrapper')}>
                    <MessageForm
                        messageHandler={send}
                    />
                </div>
            </div>

        </>
    )
}
const Event = ({ item }) => {
    return (
        <li>
            {item && <>
                <div>
                    <div className={cn('date')}>
                        {item.time}
                    </div>
                    <div className={cn('text')}>{item.event} {item.nps?.score && <NpsBlock nps={item.nps || {}}/>}</div>
                </div>

                {!!item.comment?.text.length && <Comment
                    item={item.comment}
                />}
                {!!item.review?.text.length && <Review
                    item={item.review}
                />}
            </>}
        </li>
    )
}

const Comment = ({ item }) => {
    return (
        <div className={cn('review')}>
            <div className={cn('review__header')}>
                <span className={cn('name', 'briefcase')}>{item.author}</span>
            </div>

            <div className={cn('review__body')}>
                {item?.text}
            </div>
        </div>
    )
}

const Review = ({ item }) => {

    return (
        <div className={cn('review')}>
            <div className={cn('review__header')}>
                <span className={cn('name')}>
                    {item.author ? item.author?.lastname+' '+item.author?.firstname : 'Автор'}
                </span>
                {item.rating && <span className={cn('rating')}>
                    <RatingField
                        review={
                            { rating: item.rating }
                        }
                    />
               </span>}
            </div>
            <div className={cn('review__body')}>
                {item.text}
            </div>
        </div>
    )
}

export default Lent;
