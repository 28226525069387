import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { inject, observer } from "mobx-react";
import RatingBlock from "./RatingBlock";
import { pluralize } from "../../../../helpers/index";

import chartService from "./chartsService";

const Rating = ({ chartStore, reviewFilterStore, filter= null }) => {

    am4core.useTheme(am4themes_animated);
    const chart = useRef(null);
    const chartsFilter = filter ?? reviewFilterStore.parseFilterToChart();
    let x = null;

    const [summary, setSummary] = useState({ rating: 0, diff: 0 });
    const [isLoader, setLoader] = useState(true);

    const destroyChart = () => {
        x && x.dispose();
        chart.current = null;
    }

    useEffect(() => {
        chartStore.getData(chartsFilter, 'rating')
            .then((data) => {
                setSummary(data.summary);
                 chartService.initRatingChart(data.data, x, 'chart-source2')
                     .then(result => {
                         chart.current = result
                         setLoader(false);
                     });
            });
        return () => destroyChart();
    }, [JSON.stringify(chartsFilter)]);

    return (
        <fieldset className={cn('ml_0')}>
            <legend>Рейтинг</legend>
            {!isLoader && <div className={cn('chart__header', 'header')}>
                <div className={'header__left-side'}>
                    <div className={'number-top'}>
                        <div>{summary.total}</div>
                        <div className={cn('star')}>
                            <div className={cn('pi-star', 'pi')}/>
                        </div>

                    </div>

                    <div className={cn('number-bottom')}>
                        <RatingBlock
                            rating={summary.rating}
                        />
                    </div>
                </div>
                <div className={'header__right-side'}>
                    <div>{summary.total_rating.toLocaleString('ru-RU')} {pluralize(summary.total_rating, ['оценка', 'оценки', 'оценок'], false)}</div>
                    <div>{summary.total_reviews.toLocaleString('ru-RU')} {pluralize(summary.total_reviews, ['отзыв', 'отзыва', 'отзывов'], false)}</div>
                </div>
            </div>}
            <div className={cn('chart__body')}>
                <div id='chart-source2' style={{ width: '100%', height: '100%' }} />
            </div>
        </fieldset>
    );
}

export default inject('chartStore', 'reviewFilterStore')(observer(Rating));
