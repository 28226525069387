import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Select, Space, Switch, Tag } from "antd";
import Tabs from "./Tabs";

import './Settings.scss';
import { Button } from "primereact/button";
import { inject, observer } from "mobx-react";
import MessengerService from "../../../services/telegram";
import scoresTagRender from "./ScoresTagRender";


const SettingsTelegram = inject('notificationStore', 'uiStore')(observer(({notificationStore, uiStore }) => {
    const [rating, setRating] = useState([]);
    const [branches, setBranches] = useState([]);
    const [sources, setSources] = useState([]);
    const [emailNotificationObject, setEmailNotificationObject] = useState(null);
    const [allowNotify, setAllowNotify] = useState([]);
    const { addValue, typesNotification } = notificationStore;

    useEffect(() => {
        notificationStore.getBranchList()
            .then(result => {
                setBranches(result);
            })
            .catch(e => e);

        notificationStore.getSourcesList()
            .then(result => {
                setSources(result)
            })
            .catch(e => e);

        notificationStore.getScore()
            .then(result => {
                setRating(result)
            })
            .catch(e => e);

        notificationStore.getTelegramNotificationSetting()
            .then((result) => {
                setEmailNotificationObject(result);
                typesNotification.map(t => {
                    if(notificationStore.emailNotification.policy.types.includes(t.value)) {
                        setAllowNotify(prev => [...prev,t.value])
                    }
                })

            })
            .catch(e => e);
    }, [])

    useEffect(() => {
        addValue('types', allowNotify);
    }, [allowNotify])

    const checkAllowNotifyTypes = (item) => {
        return notificationStore.emailNotification.policy.types.includes(item.value);
    }

    const onChangTypes = (checked, type) => {
        if(checked) {
            setAllowNotify(prev => [...prev, type])
        }
        else {
            let copy  = [...allowNotify];
            const index = copy.findIndex(t => t === type)
            copy.splice(index, 1);
            setAllowNotify(copy)
        }
    }

    const placeholder = (text) => {
        return <span style={{position:"relative", top:'2px'}}>&nbsp;{text}</span>
    }

    const onSave = () => {

        notificationStore.saveNotificationSetting('telegram')
            .then(_ => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: '',
                        detail: 'Данные сохранены',
                        sticky: false,
                    }
                );
            })
            .catch(e => {
                e.error.data.map((m) => {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: e.error.description,
                            detail: m.message,
                        }
                    )
                });
            })
    }

    return (
        <>
            <div className={cn('page__settings', 'page')}>
                <div className={cn('title')}>
                    <h1>Настройки уведомлений</h1>
                </div>
                <div className={cn('settings-tabs')}>
                    <Tabs/>
                </div>
                {emailNotificationObject && <div className={'form'}>
                    <div className={cn('block')}>
                        <h3>Разрешить следующие уведомления</h3>
                        <div className={cn('content')}>
                            <ul className={cn('content__switches')}>
                                {typesNotification.map(((item, key) => (
                                    <li key={key}>
                                        <Switch
                                            defaultChecked={checkAllowNotifyTypes(item)}
                                            size="small"
                                            onChange={(checked) => {
                                                onChangTypes(checked, item.value)
                                            }}
                                            className={cn('okSwitch')}/>
                                        {item.title}
                                    </li>
                                )))}
                            </ul>
                        </div>
                    </div>
                    <div className={cn('block')}>
                        <h3>Фильтрация отзывов</h3>
                        <div className={cn('content')}>
                            <div className={cn('content__complete')}>
                                <label htmlFor="sources" title="Источники">Источники</label>
                                <Select
                                    className={cn('okSelect')}
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder={placeholder('Выберите источники')}
                                    defaultValue={emailNotificationObject.policy.sources}
                                    onChange={(values) => {
                                        addValue('sources', values)
                                    }}
                                    filter="true"
                                    optionFilterProp={'label'}
                                    options={sources}
                                />
                            </div>

                            <div className={cn('content__complete')}>
                                <label htmlFor="rating" title="Рейтинг">Рейтинг</label>
                                <Select
                                    className={cn('okSelect')}
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    tagRender={scoresTagRender}
                                    placeholder={placeholder('Выберите рейтинг')}
                                    defaultValue={emailNotificationObject.policy.scores}
                                    onChange={(values) => {
                                        addValue('rating', values)
                                    }}
                                    optionRender={(rating) => (
                                            <Space>{rating.label}{rating.value !== 0 && <span className="pi-star pi"></span>}</Space>
                                        )
                                    }
                                    filter="true"
                                    optionFilterProp={'label'}
                                    options={rating}
                                />
                            </div>

                            <div className={cn('content__complete')}>
                                <label htmlFor="branches" title="Филиалы">Филиалы</label>
                                <Select
                                    className={cn('okSelect')}
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder={placeholder('Выберите филиалы')}
                                    defaultValue={emailNotificationObject.policy.branches}
                                    onChange={(values) => {
                                        addValue('branches', values)
                                    }}
                                    filter="true"
                                    optionFilterProp={'label'}
                                    options={branches}
                                />
                            </div>

                        </div>
                    </div>
                    <div className={cn('block', 'button__wrapper')}>
                        <div className={cn('button')}>
                            <Button label="Сохранить"
                                    type="submit"
                                    onClick={onSave}
                                    className={cn('submit-btn')}
                            />
                        </div>

                        <div className={cn('button')}>
                            <Button label="Присоединится к каналу"
                                    type="submit"
                                    onClick={()=>{
                                        MessengerService.connectTelegram().then(result => {
                                            let link = document.createElement("a")
                                            link.href = result.link
                                            link.target = "_blank"
                                            link.click()
                                        });
                                    }}
                                    className={cn('submit-btn')}
                            />
                        </div>
                    </div>

                    <br/>
                    <div className={cn('block')}>
                        <h3>&nbsp;</h3>
                    </div>

                </div>}

            </div>
        </>
    )
}))

const tagRender = (props) => {
    const { label, value, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <>
            <Tag
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
            >
                {value !== 0 && <span className="pi-star pi"></span>}{label}
            </Tag>
        </>
    )
}



export default SettingsTelegram;