import authService from "./auth";
import {ManagerModel} from "../models/manager";

class ManagersService {

    _managers = []

    get managers() {
        return this._managers;
    }

    set managers(managers) {
        this._managers = managers;
    }

    getManagers = (branch_id) => {
        const url = (!branch_id) ? `/managers` :  `/managers?branch_id=${branch_id}`;
        return authService.get(
            url,
        ).then((result) => {
            this.managers = result.map(manager => {
                return new ManagerModel(manager);
            })
            return this.managers;
        });
    }

    getServiceTitle = (managers) => {
        return managers.map(item => {
            return {
                value: item.id,
                label: item.service.title,
            }
        });
    }

    clear = () => {
        this.managers = [];
    }

}

const managersService = new ManagersService();
export default managersService;
