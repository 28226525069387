import PurchaseModel from "./purchase-model";
import CustomerModel from "./customer-model";

class AppRequestCommonModel {
    #branch_id;
    #customer;

    constructor(commonModel = {}) {
        this.#branch_id = commonModel.branch_id || null;
        this.#customer = new CustomerModel(commonModel.customer || {});
    }

    get branch_id() {
        return this.#branch_id;
    }

    get customer() {
        return this.#customer;
    }

    toApiObject() {
        return {
            branch_id: this.branch_id,
            customer: this.customer.toApiObject(),
        };
    }
}

class AppRequestGenericModel extends AppRequestCommonModel {
    //TODO придумать что-то с датой
    #purchase = {
        date: new Date(),
    };

    constructor(genericModel) {
        super(genericModel);
        this.#purchase.date = genericModel.purchase.date;
    }

    get purchase() {
        return this.#purchase;
    }

    toApiObject() {
        return {
            branch_id: this.branch_id,
            purchase: this.purchase,
            customer: this.customer.toApiObject(),
        };
    }
}

class AppRequestVehicleModel extends AppRequestCommonModel {
    #purchase;

    constructor(vehicleModel = {}) {
        super(vehicleModel);
        this.#purchase = new PurchaseModel(vehicleModel.purchase || {});
    }

    get purchase() {
        return this.#purchase;
    }

    toApiObject() {
        return {
            branch_id: this.branch_id,
            purchase: this.purchase.toApiObject(),
            customer: this.customer.toApiObject(),
        };
    }

}

export {
    AppRequestGenericModel,
    AppRequestVehicleModel,
}
