import authService from "./auth";
import { serialize } from "../helpers";

class StorageFilesService {

    getFileList(filter) {
        const queryString = serialize(filter);
        return authService
            .get(`/job-management/jobs?${queryString}`)
            .then((data) => {
                return data;
            });
    }

    downloadFile(id){
        return authService
            .download(`/job-management/jobs/${id}/download`, {})
            .then((data) => {
                return data;
            })
            .catch(e => {
                return Promise.reject(e);
            });
    }

}

const storageFilesService =  new StorageFilesService();
export default storageFilesService;


