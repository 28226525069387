import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { Button } from "primereact/button";
import { inject, observer } from "mobx-react";
import okAnalyticsService from "../../../../../services/okAnalytics";
import { Prompt } from 'react-router-dom'
import { onConfirm } from "../../../../../helpers";
import ai_ico from "../../../../../images/ai_ico.png";
import aire_ico from "../../../../../images/aire_ico.png";


const CHARS_LIMIT = 5;

const AnswerAdd = ({ reviewsStore, uiStore, reviewId, review, addAnswer, setShowAnswerBlock }) => {

    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [variants, setVariants] = useState([]);
    const textareaRef = useRef(null);


    useEffect(() => {

        textareaRef.current.style.height = "inherit";

        const heightTextarea = Math.max(
            textareaRef.current.scrollHeight,
            80
        );

        if (heightTextarea <= 150) {
            textareaRef.current.style.height = `${heightTextarea}px`;
        } else {
            textareaRef.current.style.height = `150px`;
        }

        const onbeforeunloadHandler = (event) => {
            event.preventDefault();
            event.returnValue = "";
        }
        if (comment.length > 0) {
            window.addEventListener("beforeunload", onbeforeunloadHandler);
            return () => {
                window.removeEventListener("beforeunload", onbeforeunloadHandler);
            }
        }
        return () => {
        };
    }, [comment]);

    useEffect(() => {
        if (review.hasAnswerVariants) {
            reviewsStore.getAnswerVariants(review.id)
                .then(result => {
                    setVariants(result.items)
                })
        }
    }, [])

    const saveAnswer = () => {
        setLoading(true);

        reviewsStore.saveAnswer(reviewId, comment)
            .then(data => {
                data && setComment('');

                addAnswer({
                    id: data,
                    text: comment,
                    reviewId
                });

                okAnalyticsService.addEntity({
                    page: 'review_list',
                    target: 'answer_form',
                    event: 'send_answer',
                    info: {
                        review_id: reviewId,
                    }
                });
            })
            .catch(error => {
                error.error.data.map(item => {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: error.error.description,
                            detail: item.message,
                        }
                    )
                });
            });
    }

    const disabledButton = comment.length <= CHARS_LIMIT || loading;

    return (
        <div className={cn('replay__form', { 'active': comment })}>
            <Prompt
                when={comment.length > 0}
                message="Внимание! Если Вы закроете эту страницу, то введенные данные не сохранятся"/>

            <div className={cn('close-ico')}
                 onClick={() => {
                     if (comment.length > 0) {
                         onConfirm(
                             {
                                 centered: true,
                                 title: 'ВНИМАНИЕ!',
                                 content: `Если закрыть окно ответа, то введенный вами текст исчезнет.`,
                                 cancelText: 'Отменить',
                                 okText: 'Закрыть',
                                 okType: 'danger',
                             }
                         ).then(() => {
                             setShowAnswerBlock(prev => !prev)
                         }).catch((_) => {
                         })
                     } else {
                         setShowAnswerBlock(prev => !prev)
                     }
                 }}
            >
                <i className="pi pi-times"></i>
            </div>
            <textarea
                ref={textareaRef}
                placeholder='Ваш комментарий'
                value={comment}
                onChange={(e) => {
                    setComment(e.target.value);
                }}
            />
            <div className={cn('replay__footer')}>

                {variants.length > 0 && <GeneratingResponses
                    handlerSetComment={setComment}
                    variants={variants}
                />}

                <div className={cn('replay__btn_wrapper')}>
                    <Button
                        className={cn('replay__btn', { active: comment.length >= CHARS_LIMIT })}
                        onClick={saveAnswer}
                        disabled={disabledButton}
                        label='→'
                    />
                </div>
            </div>
        </div>
    );
}

const GeneratingResponses = ({handlerSetComment, variants}) => {
    const [indexVariant, setIndexVariant] = useState(null);
    const [cycle, setCycle] = useState(false);

    const startVariant = () => {
        setIndexVariant(0)
    }

    const changeVariant = () => {
        if (indexVariant < variants.length - 1) {
            setIndexVariant(prev => prev + 1)
        }

        if (indexVariant === variants.length - 1) {
            setCycle(true)
            setIndexVariant(0);
        }
    }

    useEffect(() => {
        if (indexVariant !== null) {
            handlerSetComment(variants[indexVariant])
        }
    }, [indexVariant])

    return (<>

        {variants && <span className={'author'}>
            {indexVariant === null ?
                <span onClick={startVariant}><img src={ai_ico} width={16} height={16} alt={'Сгенерировать ответ'} />&nbsp;Сгенерировать ответ</span>
                :
                <span onClick={changeVariant}>
                    {cycle ?
                        <><img src={aire_ico} width={18} height={16} alt={'Ваши ответы'}/>&nbsp;Ваши ответы</>
                        :
                        <><img src={ai_ico} width={16} height={16} alt={'Сгенерировать еще'}/>&nbsp;Сгенерировать еще</>
                    } {indexVariant + 1}/{variants?.length}
                </span>
            }
        </span>}

    </>)

}

export default inject('reviewsStore', 'uiStore')(observer(AnswerAdd));
