import React from "react";
import cn from "classnames";

const OkTagsAuto = ({list}) => {

    return (
        <>
            <ul className={cn('tag__auto')}>
                {list.map((item, key)=>{
                    return <li key={key} className={cn("OkItemTag")}>{item.title}</li>;
                })}
            </ul>
        </>
    )
}

export default OkTagsAuto;