import developersTagStore from "../stores/developersTag";

const TAG_NAME = 'N5ptuTgJ';

class DevelopersTagService {
    #developersTagStore;

    constructor(developersTagStore) {
        this.#developersTagStore = developersTagStore;
        this.checkIsDeveloper();
    }

    checkIsDeveloper () {
        this.#developersTagStore.setDevAccess(
            (this.getTag() === 1) ?? false
        );
    }

    setTag () {
        window.localStorage.setItem(TAG_NAME, '1');
    }

    getTag () {
        return Number(window.localStorage.getItem(TAG_NAME));
    }

    removeTag () {
        window.localStorage.removeItem(TAG_NAME);
    }
}
const developersTagService = new DevelopersTagService(developersTagStore);
export default developersTagService;
