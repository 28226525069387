import { NavLink } from "react-router-dom";
import React from "react";

const Tabs = () => {
    return (
        <>
            <ul>
                <li>
                    <NavLink to='/settings/notifications/email'>E-mail</NavLink>
                </li>
                <li>
                    <NavLink to='/settings/notifications/telegram'>Telegram</NavLink>
                </li>
            </ul>
        </>
    );
}

export default Tabs;