import React, { useEffect, useState } from "react";
import cn from "classnames";
import { onConfirm } from "../../../../helpers";

const InlineEditInput = ({ item, onSave }) => {

    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(item.title);
    }, [])

    const onChange = (e) => {
        setTitle(e.target.value)
    }

    const onKeyDown = (e) => {
        if (e.key === "Enter" || e.key === "Escape") {
            e.target.blur();
        }
    }

    return (
        <input
            type="text"
            className={cn('inline-edit-input')}
            value={title}
            maxLength={50}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={() => {
                if (title !== item.title) {
                    onConfirm({
                        centered: true,
                        title: 'Редактирование тега',
                        content: `Название тега «${item.title}» будет изменено на новое: «${title}»`,
                        cancelText: 'Отменить',
                        okText: 'Изменить',
                    }).then(() => {
                        onSave(item.id, title)
                    }).catch((_) => {
                        setTitle(item.title)
                    })
                }
            }}
        />

    )
}
export default InlineEditInput;


