import React from "react";
import chat_blank from "images/chat_blank.png";
import cn from "classnames";
import "./chat.scss";

const Chat = ({ sendMessage = () => {} }) => {
    return (
        <>
            <div className={cn('chat__wrapper')}>
                <div className={cn('chat__blank')}>
                    <img src={chat_blank} alt="Функционал в разработке" height={306}/>
                    <h2>Функционал в разработке</h2>
                </div>

            </div>
        </>
    );
}

export default Chat
