import authService from "../services/auth";
import ErrorService from "../services/error";

class ClaimsService {
    /**
     * @readonly
     * @type {Object}
     */
    CLAM_STATUS = {
        'submitted': 'Жалоба на рассмотрении',
        'accepted': 'Жалоба удовлетворена',
        'declined': 'Жалоба отклонена',
    };

    /**
     * Список возможных тем жалоб
     * @type {Array}
     */
    subject = [];

    /**
     *
     * @returns {Object}
     */
    getStatus = () => {
        return this.CLAM_STATUS;
    }

    /**
     *
     * @returns {Promise<Array>}
     */
    getSubject = () => {
        return (this.subject?.length > 0)
            ?
            Promise.resolve(this.subject)
            :
            authService.get(
                `/extreview/reviews/claims/subjects`,
            )
                .then((result) => {
                    this.subject = result.items.map(item => ({
                        value: item.code,
                        label: item.title,
                    }))
                    return this.subject;
                })
                .catch(e => e);
    }

    /**
     *
     * @param object
     * @returns {Promise<boolean>}
     */
    saveClaim = ({ subject, text, reviewId }) => {


        return authService.post(
                `/extreview/reviews/${reviewId}/claims`,
                { subject, text }
            ).then((result) => {
                return Promise.resolve(result);
            })
                .catch((e) => {
                    return Promise.reject(new ErrorService(e).getError())
                });
    }

}

const claimsService = new ClaimsService();
export default claimsService;

