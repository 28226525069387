import {action, observable} from "mobx";
import moment from "moment";
import analyticsService from "../services/analytics";
import reviewsService from  "../services/review";
import dashboardService from '../services/dashboard';
import _ from "underscore";

class DashboardStore {
    #analyticsService;
    #reviewsService;
    #dashboardService

    sources = [];

    @observable filter = {
        date: {
            from: moment().subtract(1, 'month').toString(),
            to: moment().toString(),
        },
        sources: [],
    };

    constructor(analyticsService, reviewsService, dashboardService) {
        this.#analyticsService = analyticsService;
        this.#reviewsService = reviewsService;
        this.#dashboardService = dashboardService;
    }

    @action resetFilter = () => {
        this.filter = {
            date: {
                from: moment().subtract(1, 'month').toString(),
                to: moment().toString(),
            },
            sources: [],
        };
    }

    @action setPeriod = (from = moment().toString(), to = moment().toString()) => {
        this.filter = {
            date: {
                from: from,
                to: to,
            },
        };

    }

    @action setFieldValue = (fieldName, fieldValue) => {
        if (this.filter.hasOwnProperty(fieldName)) {
            this.filter[fieldName] = fieldValue;
        }
    }

    parseFilterToApi = (filter = {}) => {
        let newObj = {
            date: {
                'from': moment(new Date(filter.date.from)).format("YYYY-MM-DD").toString(),
                'to': moment(new Date(filter.date.to)).format("YYYY-MM-DD").toString(),
            },
        };

        _.mapObject(filter, (val, key) => {
            if (_.isArray(val) && val.length > 0) {
                newObj[key] = filter[key].map(val => val.value).join(',');
            }
        });

        return newObj;
    }

    getIntegration = () => {
        return this.#dashboardService.getIntegrationBlockData(this.parseFilterToApi(this.filter))
            .then(result => result)
            .catch(e => e);
    }


    getReputation = () => {
        return this.#dashboardService.getReputationBlockData(this.parseFilterToApi(this.filter))
            .then(result => result)
            .catch(e => e);
    }

    getReviewsList = () => {

        const filter = {
            ...this.filter,
            ...{scores: [{value:1},{value:2},{value:3}] }
        }
        return this.#analyticsService.getReviewsByPlatformList(this.parseFilterToApi(filter))

            .then(result => result)
            .catch(e => e);
    }

    getRating = () => {
        return this.#dashboardService.getRatingBlockData(this.parseFilterToApi(this.filter))
            .then(result => result)
            .catch(e => e);
    }

    getRequest = () => {
        return this.#dashboardService.getRequestBlockData(this.parseFilterToApi(this.filter))
            .then(result => result)
            .catch(e => e);

    }

    getSources() {
        const REMOVE_BRANCH_CODE = 'okreview';
        return this.#reviewsService
            .getSources()
            .then(data => {

                this.sources = data.items?.filter(item => item.code !== REMOVE_BRANCH_CODE).map(item => {
                    return {
                        value: item.id,
                        name: item.title,
                        code: item.code,
                    }
                })

                this.filter['sources'] = data.items?.map(item => {
                    return {
                        value: item.id,
                    }
                })

                return this.sources;
            });
    }


}

const dashboardStore = new DashboardStore(analyticsService, reviewsService, dashboardService)
export default dashboardStore;
