import React, { useEffect, useState } from "react";
import cn from "classnames";
import Download from "../review/list/common/Download";
import Column from "./Column";
import QrTransitionDashboard from "./QrTransitionDashboard";
import { inject, observer } from "mobx-react";
import SlideNav from "../analytics/SlideNav";
import moment from "moment/moment";
import OkDateRangePicker from "../../common/OkDateRangePicker";
import { FilterButton, FilterButtonSimple } from "../../common/FilterButton";
import PagerSizeSelector from "../review/list/common/PagerSizeSelector";
import icoListBlack from "../../../images/ico_list_black.svg";
import { useIsMount } from "../../../hooks";
import filterService from "../../../services/filter";
import { Paginator } from "primereact/paginator";
import QRFeedbackModel from "../../../models/qrFeedbackModel";
import { reaction } from "mobx";
import Row from "./Row";
import { scroller } from "react-scroll";


const QrTransitionList = ({ uiStore, userStore, qrTransitionStore }) => {

    const { filter, setFieldValue, setPageSize, isNewNegativeOn, setNewNegativeOn } = qrTransitionStore;
    const [firstPage, setFirstPage] = useState(0);
    const [pager, setPager] = useState(null);

    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [dashboardIsIsLoading, setDashboardIsLoading] = useState(false);

    const [statuses, setStatuses] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [dashboard, setDashboard] = useState({});
    const [qrFeedbackList, setQrFeedbackList] = useState([]);

    const [newNegativeCounter, setNewNegativeCounter] = useState(null);
    const [disabledFilter, setDisabledFilter] = useState(false);
    const [visibleNewNegative, setVisibleNewNegative] = useState(false);

    const [datePeriod, setDatePeriod] = useState({
        'from': filter.date.from,
        'to': filter.date.to,
    });

    const [appServices, setAppServices] = useState([
        { value: 0, name: "Все филиалы" }
    ]);

    let newNegativeId = [];

    const isMount = useIsMount();

    useEffect(() => {

        const options = { delay: 100 };

        const disposers = [
            reaction(
                () => qrTransitionStore.isNewNegativeOn,
                (value) => {
                    if(value) {
                        qrTransitionStore.saveFilter();
                        qrTransitionStore.getNewNegative(newNegativeId, userStore.profile.client.createdAt)
                    }
                    else {
                        qrTransitionStore.resetOldFilter();
                    }
                }, options),

            reaction(() => qrTransitionStore.filter.date,
                () => {
                    getQrFeedbackList()
                    getDashboard()
                }, options),

            reaction(
                () => qrTransitionStore.filter.sort,
                () => {
                    getQrFeedbackList();
                }
            ),

            reaction(() => qrTransitionStore.filter.branches,
                () => {
                    getQrFeedbackList()
                    getDashboard()
                }, options),

            reaction(() => qrTransitionStore.filter.page.size,
                () => {
                    getQrFeedbackList()
                    getDashboard()
                }, options),

            reaction(() => qrTransitionStore.filter.page.number,
                () => {
                    getQrFeedbackList()
                    scroller.scrollTo('application-list', {
                        duration: 0,
                    });
                }, options),

            reaction(() => qrTransitionStore.filter.statuses,
                () => {
                    getQrFeedbackList()
                }, options),
        ];

        return () => {
            disposers.map(func => func());
        }
    }, []);

    useEffect(() => {

        setSelectedPeriod(getCurrentPeriod());

        qrTransitionStore.newNegative()
            .then(result => {
                setNewNegativeCounter(result)

                if(isNewNegativeOn && !!result) {
                    setVisibleNewNegative(true);
                    setDisabledFilter(true);
                }
                if(isNewNegativeOn && !result) {
                    qrTransitionStore.resetOldFilter();
                    setVisibleNewNegative(false);
                    setDisabledFilter(false);
                    setNewNegativeOn(false)
                }
            })

        qrTransitionStore.getBranchList()
            .then(result => {
                const branches = result.map((branch) => ({
                    value: branch.id,
                    name: branch.title,
                }));
                setAppServices(prev => [...prev, ...branches])
            })
            .catch(e => e)
            .finally();

        qrTransitionStore.getStatusList()
            .then(result => {

                let statuses = result.map((status) => {
                    if (!!status.negative_filter === true) {
                        newNegativeId.push({value: status.id});
                    }

                    return { value: status.id, name: status.title, sortorder: status.sortorder };
                });
                setStatuses(statuses);

            })
            .catch(e => e)
            .finally()

//список отзывов
        getQrFeedbackList();
//дашбоард
        getDashboard();
    }, [])

    const getQrFeedbackList = () => {

        setTableIsLoading(true);
        qrTransitionStore.getQrFeedbackList()
            .then(result => {
                const list = result.items.map(item => {
                    return new QRFeedbackModel(item);
                });

                setQrFeedbackList(list);
                setPager(result.pager);
                result.pager.number > 1 && setFirstPage((result.pager.number - 1) * result.pager.size);
                setTableIsLoading(false);
            })
            .catch(e => e)
            .finally()
    }

    const getDashboard = () => {

        setDashboardIsLoading(true);
        qrTransitionStore.getDashboard()
            .then(result => {
                setDashboard(result);
                setDashboardIsLoading(false);
            })
            .catch(e => e)
            .finally();
    }

    const handlerChangeFilter = (field, value) => {
        if (!isMount) {
            setFieldValue(field, value);
        }
    }

    const handleChangePeriod = (date) => {
        setFieldValue('date', {
            from: filterService.periods[date][0],
            to: filterService.periods[date][1],
        });
    };

    const onChangeRangeDate = (date) => {
        setFieldValue('date', {
            from: date.startDate,
            to: date.endDate,
        });
    };

    const setCountPagerSize = (count) => {
        setPageSize(count);
        setPage(0)
        setFirstPage(1)
    }

    const setPage = (pageNum) => {
        pageNum++;
        setFieldValue('page', { size: filter.page.size, number: pageNum });
    }

    const getCurrentPeriod = () => {
        // TODO это дело подружить с периодом в сервисе FilterService
        const periodList = {
            'today': {
                'from': moment().toString(),
                'to': moment().toString()
            },
            'yesterday': {
                'from': moment().subtract(1, 'day').toString(),
                'to': moment().subtract(1, 'day').toString()
            },
            'week': {
                'from': moment().subtract(7, 'day').toString(),
                'to': moment().toString()
            },
            'month': {
                'from': moment().subtract(30, 'day').toString(),
                'to': moment().toString()
            },
        }

        let period = 'month';

        Object.keys(periodList).find(key => {
            if (
                moment(periodList[key].from).isSame(datePeriod.from, 'day') &&
                moment(periodList[key].to).isSame(datePeriod.to, 'day')
            ) {
                period = key;
            }
        });

        return period;
    }

    return (
        <>
            <div className={cn('page__application-list', 'page')}>
                <div className={'application-list'}>
                    <div className={'title'}>
                        <h1>QR-коды</h1>
                        <Download
                            filter={filter}
                            isMobile={false}
                            uiStore={uiStore}
                            userStore={userStore}
                            dest={'qr'}
                        />
                    </div>

                    <div className={cn('application-list-filter')}>
                        <div className={cn('filter__wrapper')}>
                            <div className={cn('filter__item')}>
                                {selectedPeriod && <SlideNav
                                    handleChangeNav={handleChangePeriod}
                                    data={[
                                        { 'today': 'Сегодня' },
                                        { 'yesterday': 'Вчера' },
                                        { 'week': 'Неделя' },
                                        { 'month': 'Месяц' },
                                    ]}
                                    value={selectedPeriod}
                                    disabled={disabledFilter}
                                />}
                            </div>
                            <div className={cn('filter__item')}>
                                <OkDateRangePicker
                                    onChangeDateHandler={onChangeRangeDate}
                                    dateRange={{
                                        'from': filter.date.from,
                                        'to': filter.date.to,
                                    }}
                                    disabled={disabledFilter}
                                />
                            </div>
                            {appServices.length > 0 && <div className={cn('filter__item')}>
                                <FilterButtonSimple
                                    list={appServices}
                                    onChange={(value) => handlerChangeFilter('branches', value)}
                                    onClear={() => setFieldValue('branches', [{ value: 0 }])}
                                    filter={filter.branches}
                                    loading={false}
                                    search={true}
                                    defaultValue={{ value: 0 }}
                                    disabled={disabledFilter}
                                />
                            </div>}
                        </div>
                    </div>
                    <div className={cn('dashboard')}>
                        {dashboard && <QrTransitionDashboard value={dashboard}/>}
                    </div>
                    <div className={cn('application-list__table')}>
                        <div className={cn('application-list__wrapper')}>
                            <div className={cn('application-list-filter')}>
                                <div className={cn('filter__wrapper')}
                                     style={{ paddingBottom: '12px', paddingTop: '12px' }}>

                                    <div className={cn('filter__item')}>
                                        <FilterButton
                                            name='Статус'
                                            list={statuses}
                                            onChange={(value) => handlerChangeFilter('statuses', value)}
                                            onClear={() => setFieldValue('statuses', [])}
                                            filter={filter.statuses}
                                            loading={false}
                                            search={true}
                                            sort={false}
                                            disabled={disabledFilter}
                                        />
                                    </div>
                                    <div className={cn('filter__item')}>
                                        <div className={cn(
                                            "filter-button",
                                            "warning",
                                            {'disabled' : !newNegativeCounter}
                                        )}
                                             onClick={() => {
                                                 if(newNegativeCounter) {
                                                     setVisibleNewNegative(prev => !prev);
                                                     setDisabledFilter(prev => !prev);
                                                     setNewNegativeOn(!isNewNegativeOn)
                                                 }
                                             }}>
                                            <div className={
                                                cn("filter-button__title",
                                                    {'active' : visibleNewNegative},
                                                )
                                            }>
                                                <span>Негатив перехвачен</span>
                                                {!!newNegativeCounter && <span className="badge">{newNegativeCounter}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cn('filter__item', 'list-filter__pager-size')}>
                                        <PagerSizeSelector
                                            setCount={setCountPagerSize}
                                            value={filter.page.size}
                                            options={[
                                                10, 30, 50, 100
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-datatable p-component">
                                <div className="p-datatable-wrapper">
                                        <table role="grid" style={{tableLayout: 'auto'}}>
                                        <thead className="p-datatable-thead">
                                        <tr>
                                            <Column
                                                className={cn('date-col')}
                                                field="time"
                                                header="Дата"
                                                sortable
                                            />
                                            <Column
                                                className={cn('status-col')}
                                                field="status"
                                                header="Статус"
                                                sortable
                                            />
                                            <Column
                                                className={cn('manager-col')}
                                                field="branch"
                                                header="Филиал"
                                                sortable
                                            />
                                            <Column
                                                className={cn('review-col')}
                                                field="review"
                                                header="Текст отзыва"
                                            />
                                            <Column
                                                className={cn('chat-col')}
                                                header="&nbsp;"
                                                ico={icoListBlack}
                                            />
                                        </tr>
                                        </thead>
                                        <tbody className={'p-datatable-tbody'}>
                                        {qrFeedbackList.length > 0 && qrFeedbackList.map((item, _) => {
                                            return <Row item={item} key={item.id}/>
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <>
                                {pager && <Paginator
                                    rows={pager.size}
                                    totalRecords={pager.itemCount}
                                    first={firstPage}
                                    onPageChange={(e) => {
                                        setPage(e.page)
                                        setFirstPage(e.first)
                                    }}
                                />}
                            </>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default inject('filterStore', 'uiStore', 'userStore', 'qrTransitionStore')(observer(QrTransitionList))