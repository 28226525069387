import React, { useEffect } from 'react';
import cn from "classnames";
import "./payload.scss";

const HigherOrderComponent = (props) => {
    const { Component } = props

    const calculate = (x, y) => {
        return x + y;
    }

    return <>
        <Component {...props} calculate={calculate}/>
    </>
}
const HigherOrderComponent2 = (props) => {
    const { Component } = props

    const calculate = (x, y) => {
        return x * y;
    }

    return <>
        <Component {...props} calculate={calculate}/>
    </>
}


const myComponent = (props) => {
    const { calculate } = props;

    return <>
        myComponent
        <br/>
        {calculate(5,5)}
        <br/>
        {JSON.stringify(props)}
    </>
}

const Payload = () => {

    return (
        <>
            <div className={cn('page__application', 'page')}>
                <div className={cn('menu', 'menu__wrapper')}>
                    <HigherOrderComponent Component={myComponent} pr1={1} pr2={2} pr3={3}/>
                    <hr/>
                    <HigherOrderComponent2 Component={myComponent}/>
                </div>
            </div>

        </>
    );
}

export default Payload;
