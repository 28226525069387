import React from "react";
import cn from "classnames";
import { Button } from "primereact/button";
import { AD_PLATFORM_TYPE } from "../../../../../models/ad-platform";
import { inject, observer } from "mobx-react";

const AnswerButton = ({ uiStore, item, setShowAnswerBlock, showAnswerBlock }) => {

    const MESSAGE_TITLE = 'Внимание';
    const MESSAGE_DETAIL_FLAMP = 'Для того, чтобы отвечать на отзывы, размещенные на Flamp, необходимо настроить  доступ для сервиса OKReview. Свяжитесь с Вашим менеджером - он поможет быстро и просто подключить этот функционал.';
    const MESSAGE_DETAIL_ZOON = 'Для того, чтобы отвечать на отзывы, размещенные на Zoon, необходимо настроить  доступ для сервиса OKReview. Свяжитесь с Вашим менеджером - он поможет быстро и просто подключить этот функционал.';

/*
    subsource_code is not null and not answer.allowed and publshed -- показываем кнопку с сообщением (требуется авторизация)
    subsource_code is not null and answer.allowed and publshed -- показываем кнопку без сообщения (ответ разрешён)
    not publshed -- вообще кнопку не показываем
    subsource_code is null and answer.allowed and publshed -- показываем кнопку (ответ разрешён)
    subsource_code is null and not answer.allowed and publshed -- кнопка не показывается
*/

    const showAnswerBtn = (sourceCode = '') => {
        if (item.type === 'review') {

            if(item.unrated) {
                return { show: true, warning: '' };
            }

            if (item.published) {
                if (item.answer.allowed) { //subsource_code is null and answer.allowed and publshed -- показываем кнопку (ответ разрешён)
                    return { show: true, warning: '' };
                } else if (item.subsourceCode) { //subsource_code is not null and not answer.allowed and publshed -- показываем кнопку с сообщением (требуется авторизация)
                    if(item.subsourceCode === sourceCode) {
                        return { show: true, warning: sourceCode };
                    }
                    else {
                        return { show: false, warning: '' };
                    }
                } else { //subsource_code is null and not answer.allowed and publshed -- кнопка не показывается
                    return { show: false, warning: '' }
                }
            } else { //not publshed -- вообще кнопку не показываем
                return { show: false, warning: '' }
            }
        } else {
            return { show: false, warning: '' }
        }
    }

    return (

        <>

            {(showAnswerBtn('flamp').show && showAnswerBtn('flamp').warning === 'flamp') && <Button
                label='Ответить'
                className={cn('p-button-outlined')}
                onClick={() => {
                    uiStore.setMessage(
                        {
                            severity: 'warn',
                            summary: MESSAGE_TITLE,
                            detail: MESSAGE_DETAIL_FLAMP,
                        }
                    );
                }}
            />}

            {(showAnswerBtn('zoon').show && showAnswerBtn('zoon').warning === 'zoon') && <Button
                label='Ответить'
                className={cn('p-button-outlined')}
                onClick={() => {
                    uiStore.setMessage(
                        {
                            severity: 'warn',
                            summary: MESSAGE_TITLE,
                            detail: MESSAGE_DETAIL_ZOON,
                        }
                    );
                }}
            />}

            {(showAnswerBtn().show && !showAnswerBtn().warning) && <Button
                label='Ответить'
                disabled={item.adplatform.title === AD_PLATFORM_TYPE.AVITO && item.answer.count >= 0 || showAnswerBlock}
                className={cn('p-button-outlined')}
                onClick={() => {
                    setShowAnswerBlock(showAnswerBlock => !showAnswerBlock)
                }}
            />}
            {item.type === 'application' && <a href={item.url} target="_blank">
                <Button
                    label='См. заявку'
                    className={cn('p-button-outlined')}
                />
            </a>}

        </>
    )
}

export default inject('uiStore')(observer(AnswerButton));
