import authService from "./auth";

class ProfileService {
    getProfile = () => {
        return authService.get(
            '/auth/profile',
        ).then((result) => {
            return  result;
        });
    }
}

const profileService = new ProfileService();
export default profileService;