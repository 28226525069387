import React, { useEffect, useRef, useState } from "react";
import { getDetailDataByPlatform } from '../../../helpers/index';
import cn from "classnames";
import "./SourcePlate.scss";


/**
 *
 * @param {boolean} isMobile
 * @param {Array} sources Array [ "avito", "yandex" ]
 * @param {Array} adplatforms
 * @returns {JSX.Element}
 * @constructor
 */
const SourcePlate = ({ isMobile = false, sources = [], adplatforms = [] }) => {

    const sourcePlateRef = useRef();
    const [open, setOpen] = useState(false)

    const clickOutsideHandler = (event) => {
        if (!sourcePlateRef.current?.contains(event.target)) {
            setOpen(false);
        }
    }

    const handleOpen = () => {
        setOpen(open => !open);
    }

    useEffect(() => {
        document.addEventListener('click', clickOutsideHandler, false);
        return () => {
            document.removeEventListener('click', clickOutsideHandler, false);
        }
    }, []);

    return (
        <div
            ref={sourcePlateRef}
            className={cn('source__wrapper', 'source', { 'mobile': isMobile }, { 'open': open })}
        >
            <ul
                onClick={handleOpen}
            >
                {adplatforms.map((item, key) => {
                    const { ico } = getDetailDataByPlatform(item.code);
                    return (
                        <li key={key}>
                            <a href={item.url} target="_blank">
                                <img src={ico} alt={item.title} title={item.title} width={26} height={26} />
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default SourcePlate;
