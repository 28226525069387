import React, {useEffect, useState} from "react";
import cn from "classnames";
import {LegalInfoCategorySingle} from "./LegalInfoCategory";
import Setup from "../Setup";
import {TopFormFields} from "../../../../../stores/forms/topForm.store";
import {inject, observer} from "mobx-react";

const Category = ({geoStore}) => {
    const {form} = geoStore;

    const [categorySingleValue, setCategorySingleValue] = useState('');

    const CategoryOnChange = (value) => {
        setCategorySingleValue(value)
    }

    return (
        <>
            <div className={cn('block')}>
                <div className={cn('block__title')}>
                    <h3>Категории</h3>
                </div>
                <div className={cn('block__form')}>
                    <>
                        <LegalInfoCategorySingle
                            name={'category'}
                            onChange={CategoryOnChange}
                        />
                    </>
                    <div className={cn("form__row")}>
                        <div className={cn('title-field')}>
                            <h4>Настройка площадок</h4>
                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <Setup
                            disabled={!categorySingleValue.length}
                            showAddButton={false}
                            showRemoveButton={false}
                            fields={
                                [
                                    new TopFormFields({
                                        fieldName: 'categoryList',
                                        code: 'category',
                                        fieldLabel: 'Добавление категорий (начните вводить)',
                                        fieldType: 'dropdown',
                                        fieldValue: [],
                                        required: false
                                    }),
                                ]
                            }
                            code={"legalInfoSetup"}
                        />

                    </div>

                </div>
            </div>
        </>
    )
}

export default inject('geoStore')(observer(Category));