import React, { useEffect } from "react";
import ReviewListMobile from "./mobile/list/ReviewListMobile";
import ReviewList from "./list/ReviewList";
import okAnalyticsService from "../../../services/okAnalytics";
import {
    isMobile
} from "react-device-detect";

const ReviewListCommon = () => {

    useEffect(() => {
        okAnalyticsService.addEntity({
            page: 'review_list',
            target: 'page',
            event: 'open',
        });

    }, []);

    const getClientWidth = () => {
        return document.documentElement.clientWidth;
    }

    return (
        <>
            {(isMobile && getClientWidth() <= 1024) ? <ReviewListMobile/> : <ReviewList/>}
        </>
    );
}

export default ReviewListCommon;
