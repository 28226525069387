import React, {useEffect, useState} from "react";
import {inject, observer} from "mobx-react";
import cn from "classnames";
import {Button} from "primereact/button";
import OkInputText from "../../../../common/OkInputText";
import OkInputMask from "../../../application/common/OkInputMask";

const ContactPhoneMulti = inject('geoStore')(observer((props) => {
    const {geoStore, field, code, source} = props;

    const [valueList, setValueList] = useState(geoStore.getSetupFieldValueFromStore(code, source, field.fieldName));

    useEffect(()=>{
        //console.log(code, source, field.fieldName)
        setValueList(geoStore.getSetupFieldValueFromStore(code, source, field.fieldName))
    }, [])

    useEffect(()=>{
        //console.log('valueList', toJS(valueList))
    }, [valueList])

    const saveFieldList = (fieldValue) => {
        geoStore.setSetupFieldValue(code, source, field.fieldName, fieldValue);
    }

    const multiProps = {
        ...props,
        ...{
            saveFieldList,
            valueList,
            fieldName: field.fieldName,
            geoStore,
            required: true,
        },
    }

    return (
        <ContactPhone {...multiProps}/>
    )
}))

const ContactPhoneSingle = inject('geoStore')(observer((props) => {
    const {geoStore, fieldName, onChange} = props;

    const [valueList, setValueList] = useState(props.geoStore.form[fieldName].value);

    const saveFieldList = (fieldValue) => {
        onChange(fieldValue);
        geoStore.setFormFieldValue(fieldName, fieldValue);
    }

    const singleProps = {
        ...props,
        ...{
            saveFieldList,
            valueList,
            required: geoStore.form[fieldName].required,
        },
    }
    return (
        <ContactPhone {...singleProps}/>
    )
}));

const ContactPhone = (props) => {
    const {fieldName, saveFieldList, required, valueList} = props;
    const [contactPhoneList, setContactPhoneList] = useState(valueList);

    useEffect(() => {
        //назначаем значения для мульти полей
        props.field && setContactPhoneList(valueList);
    }, [])

    useEffect(() => {
        saveFieldList(contactPhoneList);
    }, [contactPhoneList])

    const addItem = (key, field, value) => {
        setContactPhoneList(prev => {
            let copy = [...prev];
            copy[key][field] = value;
            return copy
        })
    }

    const onDeleteItem = (key) => {

        let copy = [
            ...[],
            ...contactPhoneList
        ];

        if (key > -1) {
            copy.splice(key, 1);
        }
        setContactPhoneList(copy);
    }

    const showAddButton = (endItem = false) => {
        return contactPhoneList.length < 3 && endItem === true;
    }

    return (
        <>
            {contactPhoneList?.length > 0 && contactPhoneList.map((item, key) => (
                <div className={cn("form__row")} key={key}>
                    <div className={cn('text-field')}>
                        <div className={cn("p-float-label")}>
                            <OkInputMask
                                id={`${fieldName}_${key}_number`}
                                mask="+7 (n99) 999-99-99"
                                type="text"
                                name={`${fieldName}_${key}_number`}
                                value={contactPhoneList[key].number}
                                required={key === 0 && required}
                                errortext={'Поле обязательно для заполнения'}
                                onChange={(e) => {
                                    addItem(key, 'number', e.target.value)
                                }}
                            />
                            <label htmlFor={`${fieldName}_${key}_number`}>
                                Номер телефона {key === 0 && required && <span>*</span>}
                            </label>
                        </div>
                    </div>
                    <div className={cn('text-field')}>
                        <div className={cn("p-float-label")}>
                            <OkInputText
                                id={`${fieldName}_${key}_additional`}
                                type="text"
                                name={`${fieldName}_${key}_additional`}
                                value={contactPhoneList[key].additional}
                                onChange={(e) => {
                                    addItem(key, 'additional', e.target.value)
                                }}
                            />
                            <label htmlFor={`${fieldName}_${key}_additional`}>
                                Дополнительный номер телефона
                            </label>
                        </div>
                    </div>
                    <div className={cn('text-field')}>
                        <div className={cn("p-float-label")}>
                            <OkInputText
                                id={`${fieldName}_${key}_comment`}
                                type="text"
                                name={`${fieldName}_${key}_comment`}
                                value={contactPhoneList[key].comment}
                                onChange={(e) => {
                                    addItem(key, 'comment', e.target.value)
                                }}
                            />
                            <label htmlFor={`${fieldName}_${key}_comment`}>
                                Комментарий
                            </label>
                        </div>
                    </div>

                    {showAddButton(key === contactPhoneList.length-1) &&
                        <div className={cn('description-field')} style={{marginRight: '20px'}}>
                            <Button
                                //disabled={!contactPhoneList[0]?.length}
                                label='Добавить'
                                icon="pi pi-plus"
                                iconPos="left"
                                className={cn('p-button-outlined')}
                                onClick={() => {
                                    const list = [
                                        ...contactPhoneList,
                                        ...[{number: '', additional: '', comment: ''}]
                                    ];
                                    setContactPhoneList(list);
                                }}/>
                        </div>
                    }
                    {key > 0 && <div className={cn('description-field')}>
                        <Button
                            label='Удалить'
                            className={cn('p-button-danger', 'p-button-outlined')}
                            onClick={() => {
                                onDeleteItem(key)
                            }}
                        />
                    </div>}
                </div>
            ))}

            {contactPhoneList.length === 0  && <div className={cn("form__row")}>
                <div className={cn("btn-field")}>
                    <Button
                        //disabled={!contactPhoneList[0]?.length}
                        label='Добавить'
                        icon="pi pi-plus"
                        iconPos="left"
                        className={cn('p-button-outlined')}
                        onClick={() => {
                            const list = [
                                ...contactPhoneList,
                                ...[{number: '', additional: '', comment: ''}]
                            ];
                            setContactPhoneList(list);
                        }}/>
                </div>
            </div>}
        </>
    );


}

export {
    ContactPhoneSingle,
    ContactPhoneMulti,
}