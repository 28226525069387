import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import { scroller } from "react-scroll";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";

import "./ApplicationDetail.scss"
import StatusTag from "../common/StatusTag";
import Lent from "../common/Lent";
import Chat from "../common/Chat";
import SlideNav from "../../analytics/SlideNav";

import applicationService from "../../../../services/application";

import ApplicationModel from "../../../../models/application";
import EventModel from "../../../../models/event";
import { Link } from "react-router-dom";


@inject('filterStore', 'userStore', 'uiStore')
@observer
class ApplicationDetailMobile extends Component {

    idApplication;

    constructor(props) {
        super(props);

        this.idApplication =  props.idApplication;

        this.state = {
            error: false,
            isLoading: true,
            application: {},
            events: [],
            statuses: [],
            status: {},
            rightComponent: 'lent',
            tabSelected: 'cart',
        };

        scroller.scrollTo('root', {
            duration: 0,
        });

        applicationService.getPossibleStatuses(this.idApplication).then(result => {
            this.setState({
                statuses: result,
            });
        });

        applicationService.getApplications(this.idApplication)
            .then(data => {

                this.setState({
                    application: new ApplicationModel(data),
                    status: data.status,
                });


                return applicationService.getEvents(this.idApplication)
            })
            .then(data => {

                const events = [];

                data.forEach(i => {
                    events.push(new EventModel(i));
                });

                this.setState({
                    isLoading: false,
                    events: events
                });


            })
            .catch(() => {
                this.setState({
                    error: true
                });
            });

    }

    changeStatus = (data) => {
        this.setState({
            status: data,
        })

        applicationService.changeStatus(this.idApplication, { status_id: data.id }).then(result => {
            if (result === 'OK') {
                Promise.all([
                    this.reloadLent(),
                    applicationService.getPossibleStatuses(this.idApplication),
                ])
                    .then(([eventsResult, statusesResult]) => {
                        this.setState({
                            events: eventsResult,
                            statuses: statusesResult,
                        });

                        this.props.uiStore.setMessage(
                            {
                                severity: 'success',
                                summary: '',
                                detail: 'Статус изменен',
                            }
                        );
                    });
            }
        }).catch(error => {
            console.log(error)
        })

    }

    reloadLent = () => {
        return applicationService.getEvents(this.idApplication).then(result => {
            let events = [];
            result.forEach(i => {
                events.push(new EventModel(i));
            });

            return Promise.resolve(events);
        })
    }


    lentSendMessage = (data) => {
        applicationService.lentSendMessage(this.idApplication, { comment: data }).then(() => {
            this.reloadLent().then(result => {
                this.setState({
                    events: result,
                });
            });

            this.props.uiStore.setMessage(
                {
                    severity: 'success',
                    summary: '',
                    detail: 'Сообщение отправленно',
                }
            );
        })
    }

    chatSendMessage = () => {
        //console.log(data)
    }

    handleShowRightComponent = (data) => {
        this.setState({
            rightComponent: data,
        });
    }

    changeTabSelected = (data) => {
        this.setState({
            tabSelected: data,
        });
    }

    render() {
        const {
            isLoading,
            error,
            application,
            events,
            statuses,
            status,
            rightComponent,
            tabSelected,
        } = this.state;

        let classNameForm = 'application-detail';
        if (isLoading) {
            classNameForm += ' application-detail__bg-transparent';
        }

        return (
            <React.Fragment>
                <div className={cn('page__application-detail', 'page')}>
                    <div className={classNameForm}>
                        {error && <Dialog visible={error}
                                          className={cn('dialogError')}
                                          position={'center'}
                                          onHide={() => this.setState({ 'error': false })}
                        >
                            <div className={'blockError'}>
                                <div className={cn('wrapper', 'error')}>
                                    <div className={cn('text')}
                                         dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_errorTextSendForm }}
                                    />
                                </div>
                            </div>

                        </Dialog>}
                        {!error && isLoading && <div className={'loading'}><ProgressSpinner /></div>}
                        {!isLoading && <React.Fragment>
                            <div className={cn('application-detail__header')}>
                                <div className={cn('header__wrapper')}>

                                    <div className={cn('cart__wrapper')}>
                                        <div className={cn('cart__header')}>
                                            <Link to='/application/list' className="p-button p-component back-btn" >
                                                <i className="pi pi-angle-left"></i>
                                            </Link>
                                            <h1>Заявка {application.number}</h1>

                                        </div>
                                    </div>

                                    <div className={cn('status')}>
                                        <StatusTag
                                            status={status}
                                            statusesList={statuses}
                                            changeHandler={this.changeStatus}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={cn('application-detail__tab')}>
                                <div className={cn('tab__wrapper')}>
                                    <ul>
                                        <li>
                                            <a href='#'
                                               className={cn({'active': tabSelected === 'cart'})}
                                               onClick={()=>{
                                                   this.changeTabSelected('cart')
                                               }}
                                            >Информация</a>
                                        </li>

                                        <li>
                                            <a href='#'
                                               className={cn({'active': tabSelected === 'lent'})}
                                               onClick={()=>{
                                                   this.changeTabSelected('lent')
                                               }}
                                            >Лента / чат</a>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            {tabSelected === 'cart' && <div className={cn('application-detail__cart', {'visible': tabSelected === 'cart'})}>
                                <div className={cn('cart__wrapper')}>
                                    <div className={cn('service')}>{application.branch.title}</div>
                                    <div className={cn('descr')}>
                                        <div className={cn('row')}>
                                            <div className={cn('col-title')}>Дата заявки</div>
                                            <div
                                                className={cn('col-text')}
                                            >{application.time}</div>
                                        </div>
                                        <div className={cn('row')}>
                                            <div className={cn('col-title')}>Менеджер</div>
                                            <div className={cn('col-text')}>{application.manager.fullName}</div>
                                        </div>
                                    </div>

                                    {application.purchase?.type.code !== 'generic' && <div className={cn('field-box')}>

                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Марка</span>
                                            <div
                                                className={cn('field__text')}
                                            >{application.purchase.car.brand}</div>
                                        </div>

                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Модель</span>
                                            <div
                                                className={cn('field__text')}
                                            >{application.purchase.car.model}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Год</span>
                                            <div className={cn('field__text')}>{application.purchase.car.year}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Цвет</span>
                                            <div
                                                className={cn('field__text')}
                                            >{application.purchase.car.color}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>VIN</span>
                                            <div className={cn('field__text')}>{application.purchase.car.vin}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Телефон</span>
                                            <div className={cn('field__text')}>{application.customer.phone}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Имя клиента</span>
                                            <div
                                                className={cn('field__text')}
                                            >{application.customer.firstname}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Фамилия клиента</span>
                                            <div className={cn('field__text')}>{application.customer.lastname}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>E-mail клиента</span>
                                            <div className={cn('field__text')}>{application.customer.email}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Дата сделки</span>
                                            <div
                                                className={cn('field__text')}
                                            >{application.purchase.dateFormatted}</div>
                                        </div>


                                    </div>}
                                    {application.purchase?.type.code === 'generic' && <div className={cn('field-box')}>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Имя клиента</span>
                                            <div
                                                className={cn('field__text')}
                                            >{application.customer.firstname}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Фамилия клиента</span>
                                            <div className={cn('field__text')}>{application.customer.lastname}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Телефон</span>
                                            <div className={cn('field__text')}>{application.customer.phone}</div>
                                        </div>
                                        <div className={cn('field')}>
                                            <span className={cn('field__label')}>Дата сделки</span>
                                            <div
                                                className={cn('field__text')}
                                            >{application.purchase.dateFormatted}</div>
                                        </div>
                                    </div>}
                                </div>
                            </div>}
                            {tabSelected === 'lent' && <div className={cn('application-detail__lent', {'visible': tabSelected === 'lent'})}>
                                <div className={cn('slider')}>
                                    <div className={cn('slider__wrapper')}>
                                        <SlideNav
                                            handleChangeNav={this.handleShowRightComponent}
                                            data={[
                                                { 'lent': 'Лента событий' },
                                                { 'chat': 'Чат с клиентом' }
                                            ]}
                                            value={'lent'}
                                        />
                                    </div>
                                </div>

                                <div className={cn('lent__wrapper')}>
                                    {rightComponent === 'lent' && <Lent
                                        events={events}
                                        sendMessage={this.lentSendMessage}
                                    />}
                                    {rightComponent === 'chat' && <Chat
                                        sendMessage={this.chatSendMessage}
                                    />}
                                </div>
                            </div>}

                        </React.Fragment>}
                        {/* TODO вставить обертку для кнопки во всех формах */}
                        {/* TODO стили кнопки вынести в общий css */}
                    </div>
                </div>
            </React.Fragment>

        );
    }
}

export default ApplicationDetailMobile
