import AnalyticsEntityModel from '../models/analyticsEntity';
import okAnalyticsStore from '../stores/okAnalytics';
import developersTagStore from "../stores/developersTag";
import developersTagService from "../services/developersTag"
import userStore from '../stores/user';
import authService from "../services/auth";
import { v4 as uuidv4 } from 'uuid';

//TODO убрать в .env
const INTERVAL_MS = 1000*10; //интервал 10сек
const SESSION_TIMEOUT_MS = 1000 * 60 * 10; //таймаут активности 30мин
const START = true;
const DEBUG = false;
const EVENTS = [
    //'beforeunload',
];

/*{
    events: [
        {
            page: 'string', // analytics_branch, analytics_applications, analytics_branch, application_detail, application_add, application_list, login, review_list, site
            target: 'string', // page, application_add_form, login_form, answer_form, claim_form, page,
            event: 'string', // open, send_application, login, send_answer, send_claim, inactivity_timeout, close_page
            info: 'object',
            date: 'string',
            sessionId: 'string',
        }
    ],
        userId: 'string',
}*/

class OkAnalyticsService {

    #okAnalyticsStore;
    #developersTagStore;
    #userStore;
    #timer;
    #sessionTimer;
    #uniqueHash;

    constructor(okAnalyticsStore, developersTagStore, userStore) {

        this.#okAnalyticsStore = okAnalyticsStore;
        this.#developersTagStore = developersTagStore;
        this.#userStore = userStore;
        this.#uniqueHash = this.createSessionId();

        developersTagService.checkIsDeveloper()
        !this.#developersTagStore.isDeveloper && START && this.startSendTimer();
    }

    get uniqueHash() {
        return this.#uniqueHash;
    }

    startSessionTimer() {
        clearTimeout(this.#sessionTimer);
        this.#sessionTimer = setTimeout(() => {
            this.#okAnalyticsStore.data.events.push(
                new AnalyticsEntityModel(
                    {
                        ...{
                            page: 'site',
                            target: 'page',
                            event: 'inactivity_timeout',
                        },
                        ...{
                            sessionId: this.uniqueHash,
                            date: (new Date().getTime() / 1000).toFixed(),
                        }
                    }
                )
            );
        }, SESSION_TIMEOUT_MS);
    }

    startSendTimer() {
        this.#timer = setInterval(() => {
            //TODO пытаемся поставить в очередь на отправку. если ок. то чистим данные
            this.#okAnalyticsStore.data.events.length > 0 && this.sendData() && this.clearData();
        }, INTERVAL_MS);
    }

    stopSendTimer() {
        clearInterval(this.#timer);
    }

    startEventListeners() {
        EVENTS.map(event => window.addEventListener(event, this.listener));
    }

    removeEventListeners() {
        EVENTS.map(event => window.removeEventListener(event, this.listener));
    }

    listener = () => {
        okAnalyticsService.addEntity({
            page: 'site',
            target: 'page',
            event: 'close_page',
        });

        this.#okAnalyticsStore.data.events.length > 0 && this.sendData() && this.clearData();
    }

    createSessionId() {
        return uuidv4();
    }

    /**
     *
     * @param {AnalyticsEntityModel} object
     */
    addEntity(object = {}) {
        this.#okAnalyticsStore.data.events.push(
            new AnalyticsEntityModel(
                {
                    ...object,
                    ...{
                        sessionId: this.uniqueHash,
                        date: (new Date().getTime() / 1000).toFixed(),
                    }
                }
            )
        );
        this.startSessionTimer();
    }

    sendData() {
        this.#okAnalyticsStore.data.userId = authService.accessToken;
        DEBUG && console.log(JSON.stringify(this.#okAnalyticsStore.data));
        return authService.post('/webhooks/okreview/user-activity', JSON.stringify(this.#okAnalyticsStore.data)).then(() => {});
    }

    clearData() {
        this.#okAnalyticsStore.clearData();
        DEBUG && console.log('очистили this.#okAnalyticsStore.data');
    }


}

const okAnalyticsService = new OkAnalyticsService(okAnalyticsStore, developersTagStore, userStore);
export default okAnalyticsService;
