import React, {Component} from "react";
import Header from "../Header";
import Footer from "../Footer";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import LoginPage from "../pages/login/Login";
import ExternalAuth from "../pages/externalauth/ExternalAuth";
import {inject} from "mobx-react";


@inject('userStore')
class External extends Component{
    constructor(props) {
        super(props);
        this.userStore = props.userStore;
    }

    render() {
        return(
            <React.Fragment>
                <Header/>
                <React.Fragment>
                    <Switch>
                        <Route exact path='/'>
                            <LoginPage/>
                        </Route>
                        <Route path='/login'>
                            <LoginPage/>
                        </Route>
                        <Route path='/externalauth/:auth_code'>
                            <ExternalAuth/>
                        </Route>

                        <Redirect to='/'/>
                    </Switch>
                </React.Fragment>
                <Footer/>
            </React.Fragment>
        );
    }
}
export default withRouter(External);
