import cn from "classnames";
import Chain from "images/dashboard/ico_chain.png";
import React from "react";
import {
    isMobile
} from "react-device-detect";
import { Link } from "react-router-dom";

const SMIntegrationBlock = ({data}) => {
    return (
        <Link to={'/integration'}>
            <fieldset className={cn('mr_0')}>
                <legend>Интеграции: подключение</legend>
                <div className={cn('row', 'row__md_10')}>
                    <div className={cn('col', 'col__big')}>
                        <span>
                            <img src={Chain} alt="" width={40} height={40}/>
                        </span>
                        <span className={cn('value')}>{data.enabled}</span>
                    </div>
                    {isMobile ? <div className={cn('right_side__row')}>
                                    <div className={cn('col')}>
                                        <div className={cn('notification')}>
                                            <i className={cn("pi", "pi-bell")}></i>
                                            {!!data.problem && <span className={cn('badge', 'active')}>{data.problem}</span>}
                                        </div>
                                    </div>
                                    <div className={cn('col', 'text')}>
                                        Требуют<br/>внимания
                                    </div>
                        </div>
                        :
                        <>
                            <div className={cn('col')}>
                                <div className={cn('notification')}>
                                    <i className={cn("pi", "pi-bell")}></i>
                                    {!!data.problem && <span className={cn('badge', 'active')}>{data.problem}</span>}
                                </div>
                            </div>
                            <div className={cn('col', 'text')}>
                                Требуют<br/>внимания
                            </div>
                        </>
                    }
                </div>
            </fieldset>
        </Link>
    );
}

export default SMIntegrationBlock;
