import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import  notificationService from '../../services/notification';
import "./NotificationMark.scss";
import { NavLink } from "react-router-dom";

const NotificationMark = inject('notificationStore')(observer(({ notificationStore }) => {

    const [notification, setNotification] = useState({count: 0, now: false});

    useEffect(() => {
        notificationService.getCountNotification().then(result => {
            setNotification(result);
        });

        setInterval(() => {
            notificationService.getCountNotification().then(result => {
                setNotification(result);
            });
        }, 1000*60*30)
    }, [])

    const markCountInStore = () => {
        notificationStore.markCountInStore()
        setNotification(prev => {
            return {count: prev.count, now:false}
        })
    }

    const url = !!notification.count ? `/integration/attention` : `/integration`;
    return (
        <div className={cn('notification')}>
            <NavLink to={url} onClick={markCountInStore}>
                <i className={cn("pi", "pi-bell")}></i>
                {!!notification.count && <span className={cn('badge', {'active': notification.now})}>{notification.count}</span>}
            </NavLink>
        </div>
    )
}));
export default NotificationMark;
