import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import cn from "classnames";

const OkInputText = (props) => {

    const {
        value,
        required = false,
        validate = '',
        message = '',
    } = props;

    const [blur, setBlur] = useState(false);
    const [focus, setFocus] = useState(false);
    const [invalid, setInvalid] = useState(false);

    const checkValidEmail = (email) => {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(email);
    }

    const checkValidUrl = (url) => {
        return /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
    }

    useEffect(() => {

        if(validate === 'url' && focus === true) {
            if(value === '' && required === false) {
                setInvalid(false);
            }
            else if(!checkValidUrl(value)) {
                setInvalid(true);
            }
            else {
                setInvalid(false)
            }
        }

        if(validate === 'email' && focus === true) {
            if(value === '' && required === false) {
                setInvalid(false);
            }
            else if(!checkValidEmail(value)) {
                setInvalid(true);
            }
            else {
                setInvalid(false)
            }
        }
        if (validate === '' && required && blur === true) {
            setInvalid(!value);
        }

    }, [value, blur]);

    return (
        <>
            <InputText
                {...props}
                className={cn(props.className, {'p-invalid': invalid})}
                onBlur={() => {
                    setBlur(true);
                }}
                onFocus={()=>{
                    setFocus(true);
                }}
            />
            {(invalid && message) && <span className={'inputTextError'}>{message}</span>}
        </>
    )
}

export default OkInputText;
