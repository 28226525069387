import cn from "classnames";
import {Button} from "primereact/button";
import React from "react";
import {Anchor} from 'antd';
import menuModel from "models/float-menu-model";

const FloatMenu = ({checkRequiredFields}) => {

    return (
        <>
            <div className={cn('float-menu__wrapper')}>
                <Anchor
                    className={cn('float-menu')}
                    direction="horizontal"
                    targetOffset={100}
                    items={menuModel}
                />

                <Button
                    label='Сохранить'
                    onClick={() => {
                        checkRequiredFields()
                    }}
                />
            </div>
        </>
    )
}


export default FloatMenu;