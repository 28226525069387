export default class AnalyticsEntityModel {
    page;
    target;
    event;
    info;
    date;
    sessionId;


    constructor(object = {}) {
        this.page = object.page || '';
        this.target = object.target || '';
        this.event = object.event || '';
        this.info = object.info || {};
        this.date = object.date || '';
        this.sessionId = object.sessionId || '';
    }

}
