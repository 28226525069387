import authService from "./auth";
import { serialize } from "../helpers";
import { FilterQRDashboardModel, FilterQRModel } from "../models/filter";

class QrTransitionService {

    getQrFeedbackList(filter = {}) {
        const queryString = serialize(new FilterQRModel(filter).getToAPI());
        return authService.get(
            `/qrfeedback/reviews?${queryString}`,
        ).then((result) => {
            return result
        })
            .catch((e) => e);
    }

    getDashboard(filter = {}) {
        const queryString = serialize(new FilterQRDashboardModel(filter).getToAPI());
        return authService.get(
            `/qrfeedback/reviews/dashboard?${queryString}`,
        )
            .then((result) => {
                return result;
            })
            .catch(e => e);
    }

    getBranchList() {
        return authService.get(
            `/branches`,
        ).then((result) => {
            return  result;
        });
    }

    getStatusList() {
        return authService.get(
            `/qrfeedback/statuses`,
        )
            .then((result) => {
                return result;
            })
            .catch(e => e);

    }

    getItem(id) {
        return authService.get(
            `/qrfeedback/reviews/${id}`,
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }

    getEventsList(id) {
        return authService.get(
            `/qrfeedback/reviews/${id}/events`,
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });

    }

    getPossibleStatuses(id) {
        return authService.get(
            `/qrfeedback/reviews/${id}/statuses`,
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }

    lentSendMessage(id, data) {
        return authService.post(
            `/qrfeedback/reviews/${id}/comments`,
            data
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }

    changeStatus(id, data) {
        return authService.put(
            `/qrfeedback/reviews/${id}/statuses`,
            data
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });
    }

    newNegative() {
        return authService.get(
            `/qrfeedback/reviews/counters/new-negative`,
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });

    }

    exportXls(filter) {
        const queryString = new FilterQRModel(filter).getToAPI();

        return authService.post(
            '/qrfeedback/reviews/export',
            queryString
        ).then((result) => {
            return Promise.resolve(result);
        })
            .catch((e) => {
                return Promise.reject(e)
            });

    }

}

const qrTransitionService = new QrTransitionService();
export default qrTransitionService;
