import React from "react";

import Rating from './Rating';
import Dynamics from "./Dynamics";
import Grade from "./Grade";
import Pie from "./Pie";

import './Charts.scss';

const Charts = () => {

    return (
        <>
            <div className={'chart__item'}>
                <Rating />
            </div>
            <div className={'chart__item'}>
                <Dynamics />
            </div>
            <div className={'chart__item'}>
                <Pie />
            </div>
            <div className={'chart__item'}>
                <Grade />
            </div>

        </>
    );
}

export default Charts;
